<template>
  <!-- 舌诊采集页面 -->
  <sizhen-layout
    @handleLastStep="handleLastStep()"
    @handleNextStep="handleNextStep()"
  >
    <el-row :gutter="20" class="content">
      <el-col :span="8">
        <div class="content-box">
          <div class="title">预览照片</div>
          <div class="camera flex">
            <img src="../../../assets/sizhen/frame.png" alt="" />
            <video id="videoCamera" autoplay></video>
            <canvas
              style="display: none"
              id="canvasCamera"
              :width="videoWidth"
              :height="videoHeight"
            ></canvas>
          </div>
          <div class="button" @click="setImage('she')">拍摄</div>
        </div></el-col
      >
      <el-col :span="8">
        <div class="content-box">
          <div class="title">舌部照片</div>
          <div class="camera">
            <el-image :src="imgSrc" v-if="imgSrc"></el-image>
          </div>
          <div class="button" @click="analysisHandle">确认并分析</div>
        </div></el-col
      >
      <el-col :span="8">
        <div class="content-box">
          <div class="title">辨识结果</div>
          <div class="result-box">
            <el-form>
              <el-form-item label="舌色：" prop="sheShese">
                <el-radio-group v-model="sheForm.sheShese">
                  <el-radio label="青紫舌">青紫舌</el-radio>
                  <el-radio label="淡红舌">淡红舌</el-radio>
                  <el-radio label="红绛舌">红绛舌</el-radio>
                  <el-radio label="淡白舌">淡白舌</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="苔色：" prop="sheTaise">
                <el-radio-group v-model="sheForm.sheTaise">
                  <el-radio label="灰黑苔"></el-radio>
                  <el-radio label="白苔"></el-radio>
                  <el-radio label="黄苔"></el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="齿痕：" prop="sheChihen">
                <el-radio-group v-model="sheForm.sheChihen">
                  <el-radio label="有齿痕"></el-radio>
                  <el-radio label="无齿痕"></el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="裂纹：" prop="sheLiewen">
                <el-radio-group v-model="sheForm.sheLiewen">
                  <el-radio label="有裂纹"></el-radio>
                  <el-radio label="无裂纹"></el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="点刺：" prop="sheDianci">
                <el-radio-group v-model="sheForm.sheDianci">
                  <el-radio label="有点刺"></el-radio>
                  <el-radio label="无点刺"></el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="老嫩：" prop="sheLaonen">
                <el-radio-group v-model="sheForm.sheLaonen">
                  <el-radio label="老舌"></el-radio>
                  <el-radio label="嫩舌"></el-radio>
                  <el-radio label="正常"></el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="胖瘦：" prop="shePangshou">
                <el-radio-group v-model="sheForm.shePangshou">
                  <el-radio label="胖大舌"></el-radio>
                  <el-radio label="瘦舌">瘦薄舌</el-radio>
                  <el-radio label="正常"></el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="剥落：" prop="sheBoluo">
                <el-radio-group v-model="sheForm.sheBoluo">
                  <el-radio label="剥苔"></el-radio>
                  <el-radio label="非剥苔">正常</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="厚薄：" prop="sheHoubo">
                <el-radio-group v-model="sheForm.sheHoubo">
                  <el-radio label="厚苔"></el-radio>
                  <el-radio label="薄苔"></el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="腐腻：" prop="sheFuni">
                <el-radio-group v-model="sheForm.sheFuni">
                  <el-radio label="腐苔"></el-radio>
                  <el-radio label="腻苔"></el-radio>
                  <el-radio label="正常"></el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="润燥：" prop="sheRunzao">
                <el-radio-group v-model="sheForm.sheRunzao">
                  <el-radio label="润苔"></el-radio>
                  <el-radio label="滑苔"></el-radio>
                  <el-radio label="燥常"></el-radio>
                </el-radio-group>
              </el-form-item>
            </el-form>
          </div>
          <div class="button" @click="updataHandle()">更新结果</div>
        </div></el-col
      >
    </el-row>
  </sizhen-layout>
</template>

<script>
import sizhenLayout from "@/views/sizhen/components/layout";
import titleVue from "@/views/sizhen/components/title";
import { shezhenPhotoSave, updataPatMedic } from "@/api/sizhen/sizhen";
import { mapActions } from "vuex";

export default {
  components: { sizhenLayout, titleVue },
  data() {
    return {
      title: "舌诊",
      videoWidth: 520,
      videoHeight: 620,
      imgSrc: "",
      thisCancas: null,
      thisContext: null,
      thisVideo: null,
      sheImgList: [],
      sheForm: {
        sheDianci: "",
        sheLaonen: "",
        sheTaise: "",
        sheFuni: "",
        sheChihen: "",
        sheRunzao: "",
        shePangshou: "",
        sheBoluo: "",
        sheHoubo: "",
        sheShese: "",
        sheLiewen: "",
      },
    };
  },
  mounted() {
    this.getCompetence();
  },
  destroyed() {},
  methods: {
    ...mapActions(["saveShezhen", "touchActive"]),
    getCompetence() {
      var _this = this;
      this.thisCancas = document.getElementById("canvasCamera");
      this.thisContext = this.thisCancas.getContext("2d");
      this.thisVideo = document.getElementById("videoCamera");
      // 旧版本浏览器可能根本不支持mediaDevices，我们首先设置一个空对象
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {};
      }
      // 一些浏览器实现了部分mediaDevices，我们不能只分配一个对象
      // 使用getUserMedia，因为它会覆盖现有的属性。
      // 这里，如果缺少getUserMedia属性，就添加它。
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function (constraints) {
          // 首先获取现存的getUserMedia(如果存在)
          var getUserMedia =
            navigator.webkitGetUserMedia ||
            navigator.mozGetUserMedia ||
            navigator.getUserMedia;
          // 有些浏览器不支持，会返回错误信息
          // 保持接口一致
          if (!getUserMedia) {
            return Promise.reject(
              new Error("getUserMedia is not implemented in this browser")
            );
          }
          // 否则，使用Promise将调用包装到旧的navigator.getUserMedia
          return new Promise(function (resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject);
          });
        };
      }
      var constraints = {
        audio: false,
        video: {
          width: this.videoWidth,
          height: this.videoHeight,
          transform: "scaleX(-1)",
        },
      };
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(function (stream) {
          // 旧的浏览器可能没有srcObject
          if ("srcObject" in _this.thisVideo) {
            _this.thisVideo.srcObject = stream;
          } else {
            // 避免在新的浏览器中使用它，因为它正在被弃用。
            _this.thisVideo.src = window.URL.createObjectURL(stream);
          }
          _this.thisVideo.onloadedmetadata = function (e) {
            _this.thisVideo.play();
          };
        })
        .catch((err) => {
          if (err) {
            console.log(err, "error");
            this.$message.error("摄像头调用失败，请检查设备");
          }
        });
    },

    //  绘制图片（拍照功能）
    setImage(val) {
      var _this = this;
      // 点击，canvas画图
      _this.thisContext.drawImage(
        _this.thisVideo,
        0,
        0,
        _this.videoWidth,
        _this.videoHeight
      );
      // 获取图片base64链接
      var image = this.thisCancas.toDataURL("image/png");
      _this.imgSrc = image;
      const file = image;
      const time = new Date().valueOf();
      const conversions = this.base64ToFile(file, time);
      console.log(conversions, "conversions");
      const data = new FormData();
      data.append("file", conversions);
      console.log(data, "看看是啥玩意");
      if (val == "she") {
        console.log(image);
        this.sheImgList.push(image);
      } else {
        this.faceImgList.push(image);
      }
      // upLoadImage(data).then((res) => {
      //   if (res.data.code == 200) {
      //     console.log('上传成功');
      //   }
      // })
    },
    // base64转文件
    base64ToFile(base64, fileName) {
      // 将base64按照 , 进行分割 将前缀  与后续内容分隔开
      const data = base64.split(",");
      // 利用正则表达式 从前缀中获取图片的类型信息（image/png、image/jpeg、image/webp等）
      const type = data[0].match(/:(.*?);/)[1];
      // 从图片的类型信息中 获取具体的文件格式后缀（png、jpeg、webp）
      const suffix = type.split("/")[1];
      // 使用atob()对base64数据进行解码  结果是一个文件数据流 以字符串的格式输出
      const bstr = window.atob(data[1]);
      // 获取解码结果字符串的长度
      let n = bstr.length;
      // 根据解码结果字符串的长度创建一个等长的整形数字数组
      // 但在创建时 所有元素初始值都为 0
      const u8arr = new Uint8Array(n);
      // 将整形数组的每个元素填充为解码结果字符串对应位置字符的UTF-16 编码单元
      while (n--) {
        // charCodeAt()：获取给定索引处字符对应的 UTF-16 代码单元
        u8arr[n] = bstr.charCodeAt(n);
      }
      // 利用构造函数创建File文件对象
      // new File(bits, name, options)
      const file = new File([u8arr], `${fileName}.${suffix}`, {
        type: type,
      });

      // 将File文件对象返回给方法的调用者
      return file;
    },
    //确认分析
    analysisHandle() {
      let id = this.$store.state.sizhenData.medicId;
      let obj = { id, photo: this.imgSrc, crop: this.imgSrc };
      const params = new URLSearchParams(obj);
      shezhenPhotoSave(params)
        .then((res) => {
          console.log(res);
          if (res.code === 200) {
            Object.assign(this.sheForm, res.data);
            this.saveShezhen(res.data);
          }
        })
        .catch((err) => {
          let res = {
            msg: "操作成功",
            code: 200,
            sheDianci: "无点刺",
            sheLaonen: "",
            sheTaise: "白苔",
            analyze_msg: "OK",
            sheFuni: "腻苔",
            analyze_code: 0,
            sheChihen: "有齿痕",
            sheRunzao: "滑苔",
            shePangshou: "瘦舌",
            sheBoluo: "非剥苔",
            sheHoubo: "薄苔",
            sheShese: "淡白舌",
            photo_url:
              "/profile/upload/2024/05/11/20240511144425_20240511144425A001.png",
            crop_url:
              "/profile/upload/2024/05/11/20240511144425_20240511144425A002.png",
            sheLiewen: "无裂纹",
          };
          this.sheForm = Object.assign(this.sheForm, res);
          this.saveShezhen(this.sheForm);
        });
    },

    updataHandle() {
      let id = this.$store.state.sizhenData.medicId;
      this.sheForm.id = id;
      this.saveShezhen(this.sheForm);
      updataPatMedic(this.sheForm)
        .then((res) => {
          if (res.code == 200) {
            this.$message.success(res.msg);
          }
        })
        .catch((err) => {});
    },
    handleNextStep() {
      this.touchActive(3);
      this.$emit("handleNextStep");
    },
    handleLastStep() {
      this.touchActive(1);
      this.$emit("handleLastStep");
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 8px;

  .content-box {
    .title {
      font-weight: bold;
      font-family: Microsoft YaHei;
      font-size: 18px;
      color: #0166e2;
      margin: 10px 20px;
    }
    .camera {
      box-sizing: border-box;
      position: relative;
      height: 620px;
      border: 1px solid #dde0e7;
      #videoCamera {
        width: 100%;
        height: 100%;
      }
      img {
        width: 520px;
        height: 620px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .flex {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .result-box {
      width: 520px;
      height: 620px;
    }
    .button {
      height: 24px;
      margin: 10px 40%;
      background: #0166e2;
      border-radius: 20px;
      font-weight: 400;
      font-size: 14px;
      text-align: center;
      line-height: 24px;
      color: #ffffff;
    }
  }
}
</style>
