<template>
  <div class="popup-wrap">
    <div v-if="!rows">
      <el-form
        :model="form"
        label-position="left"
        label-width="auto"
        ref="queryForm"
        size="small"
        :inline="true"
      >
        <el-form-item label="搜索条件：" prop="searchKey">
          <div>
            <el-input
              v-model="form.searchKey"
              placeholder="姓名/手机号/身份证号"
              size="mini"
              clearable
              @keyup.enter.native="query"
            />
          </div>
        </el-form-item>
        <el-form-item label="开始时间：" prop="startTime">
          <el-date-picker
            v-model="form.startTime"
            type="date"
            start-placeholder="选择日期"
            value-format="yyyy-MM-dd"
            size="mini"
            class="long-width"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间：" prop="endTime">
          <el-date-picker
            v-model="form.endTime"
            type="date"
            start-placeholder="选择日期"
            value-format="yyyy-MM-dd"
            size="mini"
            class="long-width"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button
            icon="el-icon-search"
            size="mini"
            class="btn"
            @click="query"
            >搜索</el-button
          >
          <el-button
            icon="el-icon-refresh"
            size="mini"
            class="btn"
            @click="resetQuery"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
      <el-table v-loading="loading" :data="dataList"
        >\
        <el-table-column label="序号" width="100" align="center" type="index" />
        <el-table-column
          label="姓名"
          align="center"
          prop="name"
          :show-overflow-tooltip="true"
        />
        <el-table-column label="性别" align="center" prop="gender">
          <template slot-scope="scope">
            <div>{{ scope.row.gender == "M" ? "男" : "女" }}</div>
          </template>
        </el-table-column>
        <el-table-column
          label="年龄"
          align="center"
          prop="age"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          label="手机号"
          align="center"
          prop="cellphone"
          :show-overflow-tooltip="true"
        />
        <el-table-column label="身份证号" align="center">
          <template slot-scope="scope">
            <div>{{ scope.row.idcard }}</div>
          </template>
        </el-table-column>

        <el-table-column
          label="上次就诊时间"
          align="center"
          prop="comeDate"
          :show-overflow-tooltip="true"
        />
        <el-table-column label="状态" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.status == 0">
              <el-tag type="danger">暂存</el-tag>
            </div>
            <div v-else-if="scope.row.status == null || scope.row.status == 1">
              <el-tag type="success">已开方</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
        >
          <template slot-scope="scope">
            <el-button size="mini" type="text" @click="viewDetails(scope.row)"
              >详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="form.pageNum"
        :limit.sync="form.pageSize"
        @pagination="query"
      />
    </div>
    <div v-else>
      <div class="flex">
        <div class="back" @click.stop="backTable">返回列表</div>
        <div class="button" @click="reuseBl" v-if="rows.status == 0">复用</div>
      </div>

      <table cellspacing="0" cellpadding="0">
        <tr>
          <td class="title">病历号</td>
          <td class="value" colspan="7">{{ rows.billno }}</td>
        </tr>
        <tr>
          <td class="title">名称</td>
          <td class="value">{{ rows.name }}</td>
          <td class="title">性别</td>
          <td class="value">
            {{ rows.gender == "F" ? "女" : rows.gender == "M" ? "男" : "" }}
          </td>
          <td class="title">年龄</td>
          <td class="value">{{ rows.age }}</td>
          <td class="title">疾病信息</td>
          <td class="value">{{ rows.disease }}</td>
        </tr>
        <tr>
          <td class="title">出生日期</td>
          <td class="value" colspan="3">{{ rows.birthday }}</td>
          <td class="title">手机号码</td>
          <td class="value" colspan="3">{{ rows.cellphone }}</td>
        </tr>
        <tr>
          <td class="title">发病日期</td>
          <td class="value" colspan="3">{{ rows.happenDate }}</td>
          <td class="title">就诊日期</td>
          <td class="value" colspan="7">{{ rows.comeDate }}</td>
        </tr>
        <tr>
          <td class="title">身份证号</td>
          <td class="value" colspan="3">{{ rows.idcard }}</td>
          <td class="title">就诊卡号</td>
          <td class="value" colspan="3">
            {{ rows.medicalCardNo }}
          </td>
        </tr>
        <tr>
          <td class="title">主诉</td>
          <td class="value" colspan="3">
            {{ rows.zhusu }}
          </td>
          <td class="title">左脉-寸</td>
          <td class="value" colspan="1">
            {{ leftMai["寸"].toString() }}
          </td>
          <td class="title">右脉-寸</td>
          <td class="value" colspan="1">
            {{ rightMai["寸"].toString() }}
          </td>
        </tr>
        <tr>
          <td class="title">既往史</td>
          <td class="value" colspan="3">{{ rows.jiwangshi }}</td>
          <td class="title">左脉-关</td>
          <td class="value" colspan="1">
            {{ leftMai["关"].toString() }}
          </td>
          <td class="title">右脉-关</td>
          <td class="value" colspan="1">
            {{ rightMai["关"].toString() }}
          </td>
        </tr>
        <tr>
          <td class="title">家族史</td>
          <td class="value" colspan="3">{{ rows.jiazushi }}</td>
          <td class="title">左脉-尺</td>
          <td class="value" colspan="1">
            {{ leftMai["尺"].toString() }}
          </td>
          <td class="title">右脉-尺</td>
          <td class="value" colspan="1">
            {{ rightMai["尺"].toString() }}
          </td>
        </tr>
        <tr>
          <td class="title">现病史</td>
          <td class="value" colspan="3">
            {{ rows.xianbingshi }}
          </td>
          <td class="title">舌象</td>
          <td class="value" colspan="3">
            {{ rows.tongueInfo }}
            <i class="el-icon-picture" @click="preView" v-if="shefileUrl"></i>
            <el-image
              style="width: 100px; height: 100px"
              :src="shefileUrl"
              ref="preview"
              :preview-src-list="[shefileUrl]"
              v-show="false"
            >
            </el-image>
          </td>
        </tr>
        <tr>
          <td class="title">刻下症</td>
          <td class="value" colspan="7">
            {{ rows.cardinalSymptom }}
          </td>
        </tr>
        <tr>
          <td class="title">药方</td>
          <td class="value" colspan="7">
            {{ rows.prescriptionDetail }}
            <span
              class="reuse-btn"
              @click="reuseYaofang"
              v-show="rows.prescriptionZcStd"
              >复用</span
            >
          </td>
        </tr>
        <tr>
          <td class="title">医生评价</td>
          <td class="value" colspan="7">
            {{ docmarkDict[rows.docmark] || "-" }}
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { GetPatientCaseSearch } from "@/api/zkyz/aictm";

export default {
  name: "popup-zhenduan",
  props: {},
  data() {
    return {
      form: {
        searchKey: null,
        pageNum: 1,
        pageSize: 10,
        startTime: null,
        endTime: null,
      },
      total: 0,
      dataList: [],
      loading: false,
      docmarkDict: {
        0: "2准确",
        1: "较准确",
        2: "准确",
      },
      rows: null,
      leftMai: {
        寸: [],
        关: [],
        尺: [],
      },
      rightMai: {
        寸: [],
        关: [],
        尺: [],
      },
      shefileUrl: "",
    };
  },
  mounted() {
    this.query();
  },
  computed: {},
  methods: {
    maiRefit(str) {
      let arr = str.split(",");
      this.leftMai = {
        寸: [],
        关: [],
        尺: [],
      };
      this.rightMai = {
        寸: [],
        关: [],
        尺: [],
      };
      for (let i in arr) {
        if (arr[i].indexOf("左") !== -1) {
          if (arr[i].indexOf("关") !== -1) {
            this.leftMai["关"].push(arr[i].slice(-1));
          } else if (arr[i].indexOf("寸") !== -1) {
            this.leftMai["寸"].push(arr[i].slice(-1));
          } else if (arr[i].indexOf("尺") !== -1) {
            this.leftMai["尺"].push(arr[i].slice(-1));
          }
        } else if (arr[i].indexOf("右") !== -1) {
          if (arr[i].indexOf("关") !== -1) {
            this.rightMai["关"].push(arr[i].slice(-1));
          } else if (arr[i].indexOf("寸") !== -1) {
            this.rightMai["寸"].push(arr[i].slice(-1));
          } else if (arr[i].indexOf("尺") !== -1) {
            this.rightMai["尺"].push(arr[i].slice(-1));
          }
        }
      }
    },
    query() {
      GetPatientCaseSearch(this.form)
        .then((res) => {
          this.loading = true;
          if (res.code === 200) {
            this.loading = false;

            let rows = res.rows;
            this.total = res.total;
            if (!rows || rows.length < 1) {
              this.$message.error("未查询到病例");
              this.dataList = [];
              return;
            }
            this.dataList = rows;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.query();
    },
    viewDetails(row) {
      this.rows = row;
      this.maiRefit(row.pulseConditionInfo);
      this.shefileUrl = row.shefileUrl;
    },
    backTable() {
      this.rows = null;
      this.query();
    },
    //查看图片
    preView() {
      this.$refs.preview.clickHandler();
    },
    reuseBl() {
      const {
        idCard,
        name,
        gender,
        age,
        birthday,
        tongueInfo,
        cellphone,
        pulseConditionInfo,
        cardinalSymptom,
        billno,
        status,
        patientCaseId,
        patientInfoId,
      } = this.rows;
      let maixiang = pulseConditionInfo.split(",");

      this.$emit("blInfoReuseData", {
        leftMai: this.leftMai,
        rightMai: this.rightMai,
        idCard,
        age,
        birthday,
        gender,
        name,
        tongueInfo,
        cellphone,
        maixiang,
        cardinalSymptom,
        status,
        billno,
        patientCaseId,
        patientInfoId,
      });
    },
    reuseYaofang() {
      const { prescriptionZcStd } = this.rows;
      this.$emit("prescriptionZcStd", prescriptionZcStd);
    },
  },
};
</script>
<style lang="scss" scoped>
.popup-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: cursive;
  .long-width {
    width: 100%;
  }
  .btn {
    background-color: #ae7000;
    color: #ffffff;
    border: none;
  }
}
.back {
  width: 80px;
  height: 34px;
  line-height: 34px;
  color: #fff;
  background: #ae7000;
  margin-bottom: 20px;
  border-radius: 2px 0px 0px 2px;
  position: relative;
  text-indent: 10px;
}
.back:after {
  content: "";
  width: 0;
  height: 0;
  border-top: 17px solid white;
  border-right: 20px solid white;
  border-left: 17px solid #ae7000;
  border-bottom: 17px solid white;
  position: absolute;
  box-sizing: border-box;
  left: 80px;
}
table {
  width: 100%;
  border-left: 1px solid var(--main-border-color);
  border-top: 1px solid var(--main-border-color);
}

table tr td {
  padding: 0;
}

td {
  height: 30px;
  line-height: 30px;
  width: 73px;
  border-right: 1px solid var(--main-border-color);
  border-bottom: 1px solid var(--main-border-color);
}

.title {
  font-size: 15px;
  font-weight: bold;
  text-align: center;
}

.value {
  font-size: 15px;
  padding-left: 10px;
  box-sizing: border-box;
}
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
::v-deep {
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background: var(--main-border-color);
  }
}
::v-deep {
  .el-dialog__close .el-icon-close {
    color: #ffffff;
  }
  .el-date-editor input {
    padding-left: 28px !important;
  }
}
.el-icon-picture {
  color: #ae7000;
  font-size: 20px;
}
.reuse-btn {
  background-color: #ae7000;
  width: 60px;
  height: 20px;
  text-align: center;
  border-radius: 4px;
  color: white;
  font-size: 15px;
  display: inline-block;
  line-height: 20px;
  cursor: pointer;
}
</style>
