<template>
  <div class="home">
    <div class="page">
      <img src="../assets/images/zhongkeyunzhen.png" alt="" />
      <div class="page-title">中医智能辅助诊断系统</div>
      <div class="hello">欢迎您</div>
      <img
        class="begin-btn"
        src="../assets/images/begin-btn.png"
        alt=""
        @click="goAitcm()"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Index",
  data() {
    return {
      // 版本号
      version: "3.8.6",
    };
  },
  methods: {
    goAitcm() {
      this.$router.push({ path: "/aitcm" });
    },
  },
};
</script>

<style scoped lang="scss">
.home {
  width: 100%;
  height: calc(100vh - 84px);
  background: url("../assets/images/home-bg.png") no-repeat;
  background-size: cover;
  position: relative;
  .page {
    position: absolute;
    left: 160px;
    top: 20%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    img {
      width: 300px;
    }
    .page-title {
      font-size: 70px;
      font-weight: bolder;
      color: #1466a0;
    }
    .hello {
      font-size: 70px;
      font-weight: bolder;
    }
  }
}
</style>
