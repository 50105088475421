<template>
  <div class="popup-wrap">
    <div class="body">
      <div
        class="body-item"
        v-for="(item, index) in dataList"
        :key="index"
        v-show="item.isok !== -1"
      >
        <el-row class="row">
          <el-col :span="8" class="col">
            <div class="item-title">药名</div>
            <div class="item-value bold">
              {{ item.n || "-" }}
            </div>
          </el-col>
          <el-col :span="16" class="col">
            <div class="item-title">毒性</div>
            <div class="item-value bold">
              <span :style="{ color: item.isok === 1 ? 'green' : 'red' }">{{
                item.isok === 1 ? "无毒" : "有毒"
              }}</span>
            </div>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="8" class="col">
            <div class="item-title">常用量</div>
            <div class="item-value">
              {{ item.min }}~{{ item.max }}{{ item.dw }}
            </div>
          </el-col>
          <el-col :span="16" class="col">
            <div class="item-title">用法</div>
            <div class="item-value">
              {{ item.memo }}
            </div>
          </el-col>
        </el-row>
      </div>

      <div v-for="(item, index) in FJ18fanList" :key="item.t">
        <p style="color: brown">
          <span>提示：【{{ item.t }}】</span>
          <span v-for="(vItem, inx) in item.v" :key="inx"
            >{{ Array.isArray(vItem) ? vItem.join(" ") : vItem }}
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { getFJ18Fan, getDuXingByOne } from "@/api/zkyz/aictm";

export default {
  name: "popup-zhenduan",
  props: {},
  data() {
    return {
      yaoWu: null,
      dataList: null,
      list: null,
      callback: null,
      FJ18Fan: null,
      FJ18fanList: [],
    };
  },
  mounted() {},
  methods: {
    open(yaoWu, callback) {
      this.callback = callback;
      this.dataList = [];
      this.yaoWu = yaoWu;
      this.init();
    },
    async init() {
      let data = [];
      for (let item of this.yaoWu) {
        data.push({
          n: item.name,
          v: Number(item.number || 0),
          dw: item.g,
        });
      }
      //循环请求毒性分析接口
      const promiseList = data.map((item) => {
        return getDuXingByOne(item.n).then((res) => {
          return res.data[0];
        });
      });
      await Promise.all(promiseList).then((res) => {
        this.dataList = res;
        let map = {};
        for (let item of this.dataList) {
          if (!item.n) {
            continue;
          }
          map[item.n] = item.isok === 1 ? "n" : "y";
        }
        this.callback(map);
      });
      //请求18反接口
      getFJ18Fan(JSON.stringify(data)).then((res) => {
        if (res.code === 200) {
          //循环药物名称与接口返回数据做到一一对应
          // for (const item of this.dataList) {
          //   for (const ite of res.data) {
          //     for (const vItem of ite.v) {
          //       if (item.n === vItem) {
          //         this.$set(item, 't', ite.t)
          //       }
          //       if (Array.isArray(vItem)) {
          //         for (const i of vItem) {
          //           if (item.n === i) {
          //             this.$set(item, 't', ite.t)
          //           }
          //         }
          //       }
          //     }
          //   }
          // }
          this.FJ18fanList = res.data;
          this.dataList = this.dataList.filter((item) => item.isok !== -1);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  background-color: #c48d6e;
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: left;
  color: white;
  font-size: 20px;
  font-weight: 700;
  text-indent: 20px;
}

.btn {
  background-color: #ae7000;
  border-radius: 20px;
  height: 30px;
  width: 100px;
  padding: 0 10px;
  line-height: 30px;
  text-align: center;
  color: white;
  font-weight: 500;
  cursor: pointer;
  margin: 10px auto;
}

.body {
  margin: 10px 0;
  padding: 10px 6px;

  height: 500px;
  overflow-y: scroll;

  .body-title {
    font-size: 16px;
    font-weight: bold;
    color: black;
    line-height: 20px;
    margin-bottom: 10px;
  }
  .body-item {
    margin-bottom: 10px;
  }
}

.row {
  height: auto;
  border: 1px solid #ebeef5;
  border-top: none;
  display: flex;
  flex-direction: row;

  &:nth-child(1) {
    border-top: 1px solid #ebeef5;
  }

  .col {
    height: auto;
    line-height: 30px;
    display: flex;

    .item-title {
      height: auto;

      padding-left: 20px;
      min-width: 85px;
      font-size: 14px;
      color: #606266;
    }

    .item-value {
      height: auto;
      padding-left: 20px;
      flex: 1;
      // border-left: 1px solid #e6e9f0;
      border-left: 1px solid #ebeef5;
      border-right: 1px solid #ebeef5;
      // color: #3D4966;
      color: #303133;
      font-size: 14px;
      //默认显示
      // display: -webkit-box;
      // -webkit-box-orient: vertical;
      // -webkit-line-clamp: 3;
      // overflow: hidden;
      // text-overflow: ellipsis;
    }

    .red {
      color: #9e6600;
      font-weight: 700;
    }

    .bold {
      font-weight: 700;
    }

    .flex {
      display: flex;
      padding-left: 20px;
      flex-direction: row;
    }

    &:nth-last-child(1) {
      border-right: none;
    }
  }
}
</style>
