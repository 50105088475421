var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "sizhen-layout",
    {
      on: {
        handleLastStep: function ($event) {
          return _vm.handleLastStep()
        },
        handleNextStep: function ($event) {
          return _vm.handleNextStep()
        },
      },
    },
    [
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "item-box" }, [
          _c(
            "div",
            {
              staticClass: "title-box",
              on: {
                click: function ($event) {
                  return _vm.init()
                },
              },
            },
            [
              _c("img", { attrs: { src: "", alt: "" } }),
              _c("div", { staticClass: "title-word" }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.sizhenData.tizhi)),
                ]),
                _c("div", { attrs: { clsss: "title-desc" } }, [
                  _vm._v(
                    " 神情抑郁，情感脆弱，烦闷不乐，舌淡红，苔薄白，脉弦 "
                  ),
                ]),
              ]),
            ]
          ),
          _c("div", [
            _c("div", { staticClass: "left" }, [
              _c("div", [
                _c(
                  "div",
                  { staticClass: "value" },
                  _vm._l(_vm.bingyibingjiList, function (item, index) {
                    return index < 3
                      ? _c(
                          "div",
                          {
                            key: index,
                            staticClass: "item border",
                            attrs: { title: item.desc },
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.convertToChinese(index))),
                            ]),
                            _vm._v(_vm._s(item.title) + " "),
                          ]
                        )
                      : _vm._e()
                  }),
                  0
                ),
              ]),
            ]),
            _c("div", { staticClass: "right" }, [
              _c("div", [
                _c("div", { staticClass: "shade" }),
                _c(
                  "div",
                  { staticClass: "top" },
                  _vm._l(_vm.charts.tabList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "item",
                        class: { active: _vm.charts.curTab === item.value },
                        on: {
                          click: function ($event) {
                            return _vm.changeChartsTab(item)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.label) + " ")]
                    )
                  }),
                  0
                ),
                _c(
                  "div",
                  { ref: "echarts", staticClass: "bottom" },
                  _vm._l(_vm.charts.data, function (item, index) {
                    return _c("div", { key: index, staticClass: "item" }, [
                      _c("div", {
                        staticClass: "boun",
                        style: { height: item * 100 + "%" },
                      }),
                      _c("div", { staticClass: "label" }, [
                        _vm._v(_vm._s(index)),
                      ]),
                    ])
                  }),
                  0
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }