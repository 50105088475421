<template>
  <div class="page-wrap">
    <!-- 病因并位等 -->
    <div class="by">
      <div
        style="
          display: flex;
          flex-direction: row;
          height: 100%;
          width: 100%;
          gap: 5px;
        "
      >
        <!-- 推理症侯。最多显示3个 -->
        <div class="left">
          <div v-show="isShow">
            <div class="label">推理证候</div>
            <div class="value">
              <div
                class="item border"
                v-for="(item, index) in bingyibingjiList"
                :key="index"
                v-if="index < 3"
              >
                <span v-show="item.title"></span>{{ item.title }}
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <div v-show="isShow">
            <div class="shade"></div>
            <div class="top">
              <div
                class="item"
                :class="{ active: charts.curTab === item.value }"
                v-for="(item, index) in charts.tabList"
                :key="index"
                @click="changeChartsTab(item)"
              >
                {{ item.label }}
              </div>
            </div>
            <div class="bottom" ref="echarts">
              <div
                class="item"
                v-for="(item, index) in charts.data"
                :key="index"
              >
                <div class="boun" :style="{ height: item * 100 + '%' }"></div>
                <div class="label">{{ index }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 推荐药方 -->
    <div class="tj margin-top">
      <div class="right-wrap">
        <div v-show="isShow">
          <div class="field-item">
            <div class="label pointer">
              <img src="../../../assets/images/yiyuanchangyong.png" alt="" />
              <div class="fu" v-show="doctorUseList.length > 0">
                <span>{{ doctorUseList.length }}</span>
                <img src="../../../assets/images/fu.png" alt="" />
              </div>
            </div>
            <div class="value box-shadow">
              <div class="tj-item">
                <div class="yaofang-item" v-if="doctorUseList.length > 0">
                  <div
                    @click="std(doctorUseList[0].zc_std, doctorUseList[0].yfs)"
                  >
                    复用
                  </div>
                  <div>
                    <span>{{ doctorUseList[0].fangji }}</span
                    >{{ doctorUseList[0].fangyao }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="field-item">
            <div
              class="label pointer"
              @click="openYaofang('金匮方', jinguifangList, '3')"
            >
              <img
                src="../../../assets/images/jinkuifang.png"
                style="width: 76px"
                alt=""
              />
              <div class="fu" v-show="jinguifangList.length > 0">
                <span>{{ jinguifangList.length }}</span>
                <img src="../../../assets/images/fu.png" alt="" />
              </div>
            </div>
            <div class="value box-shadow">
              <div class="tj-item">
                <div class="yaofang-item" v-if="jinguifangList.length > 0">
                  <div
                    @click="
                      std(
                        jinguifangList[0].yf,
                        jinguifangList[0].yfs,
                        jinguifangList[0].yfm
                      )
                    "
                  >
                    复用
                  </div>
                  <div>
                    <span>【{{ jinguifangList[0].yfm }}】</span>
                    {{ jinguifangList[0].zz }}(其他:{{ jinguifangList[0].yfs }})
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="field-item">
            <div
              class="label pointer"
              @click="openYaofang('名医名方', famousUseList, '4')"
            >
              <img src="../../../assets/images/mingyimingfang.png" alt="" />
              <div class="fu" v-if="famousUseList.length > 0">
                <span>{{ famousUseList.length }}</span>
                <img src="../../../assets/images/fu.png" alt="" />
              </div>
            </div>
            <div class="value box-shadow">
              <div class="tj-item">
                <div class="yaofang-item" v-if="famousUseList.length > 0">
                  <div
                    @click="
                      std(
                        famousUseList[0].yf,
                        famousUseList[0].zhifa,
                        famousUseList[0].yfm
                      )
                    "
                  >
                    复用
                  </div>
                  <div>
                    <span>【{{ famousUseList[0].zz }}】</span
                    ><span>【{{ famousUseList[0].yfm }}】</span
                    >{{ famousUseList[0].yfs }}；——来源<span>{{
                      famousUseList[0].laiyuan || "-"
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="footer">
            <el-popover placement="top" width="600" trigger="click">
              <shoucang ref="shoucang" @reusefList="reusefList"></shoucang>
              <div slot="reference" @click="toSc">
                <i class="el-icon-star-on" />个人常用
              </div>
            </el-popover>

            <!-- <div @click="toYa"><i class="el-icon-s-order" />推荐医案</div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- 开方 -->
    <div class="kf-box margin-top">
      <div class="field-item-uninline">
        <div class="label">
          开方：<span v-if="fList.length">共{{ fList.length }}味</span>
        </div>
        <div class="value" style="padding: 0">
          <div class="kf">
            <div
              class="yf-item"
              :class="[
                fMap[item.name] == 'y'
                  ? 'red'
                  : fMap[item.name] == 'n'
                  ? 'green'
                  : '',
              ]"
              v-for="(item, index) in fList"
              :key="index"
            >
              <div class="yt-item-name">
                <input
                  v-model="item.name"
                  :style="{
                    width: item.name ? item.name.length * 14 + 'px' : '30px',
                  }"
                  :ref="`inputName${index}`"
                  @keyup.enter="focusNext(1, index)"
                />
              </div>
              <div class="yt-item-number">
                <input
                  type="number"
                  :ref="`inputNumber${index}`"
                  v-model="item.number"
                  @keyup.enter="focusNext(2, index)"
                />
              </div>
              <div class="yt-item-g">
                <input
                  :ref="`inputDw${index}`"
                  v-model="item.g"
                  placeholder="g"
                  @keyup.enter="focusNext(3, index)"
                />
              </div>
              <div class="yt-item-remove" @click="removeF(index)">
                <i class="el-icon-close"></i>
              </div>
            </div>
            <div class="add_plus" @click="addPrescription">
              <i class="el-icon-plus" />
            </div>
          </div>
        </div>
      </div>

      <!-- 开方下的操作 -->
      <div class="kf-bottom">
        <div class="button" @click="toYaoxingfenxi">
          <img
            src="../../../assets/images/yaoxingfenxi-icon.png"
            alt=""
            style="margin-right: 5px"
          />
          药性分析
        </div>
        <div class="button" @click="toDuxingfenxi">
          <img
            src="../../../assets/images/duxingfenxi-icon.png"
            alt=""
            style="margin-right: 5px"
          />
          毒性分析
        </div>
        <div class="button" @click="clearPrescription">
          <img
            src="../../../assets/images/qingkong-icon.png"
            alt=""
            style="margin-right: 5px"
          />
          清空
        </div>
        <el-popover
          placement="top"
          width="400"
          v-model="newshoucangShow"
          @show="confirmCollect"
        >
          <!-- 收藏弹窗 -->
          <newShoucang
            ref="newShoucang"
            :fList="fList"
            :yfms="yfms"
            :prescriptionZhifas="prescriptionZhifas"
            @newshoucangShow="changeShow"
          ></newShoucang>
          <div slot="reference" class="button">
            <i class="el-icon-star-off" />收藏
          </div>
        </el-popover>
        <div
          class="button"
          @click="printReportOpen"
          v-show="patientInfo.therapeuticRegimen"
        >
          <img
            src="../../../assets/images/querenkaifang-icon.png"
            alt=""
            style="margin-right: 5px"
          />
          打印报告
        </div>
        <div class="button" @click="printOpen">
          <img
            src="../../../assets/images/querenkaifang-icon.png"
            alt=""
            style="margin-right: 5px"
          />
          打印
        </div>
        <div class="button" @click="confirmPrescription">
          <img
            src="../../../assets/images/querenkaifang-icon.png"
            alt=""
            style="margin-right: 5px"
          />
          确认开方
        </div>
      </div>
    </div>

    <!-- 毒性分析弹窗 -->
    <el-dialog
      :visible.sync="duxingVisible"
      :close-on-click-modal="false"
      :modal="false"
      width="50%"
      title="毒性分析"
      v-dialogDrag
    >
      <duxingfenxi ref="duxingfenxi"></duxingfenxi>
    </el-dialog>

    <!-- 药性分析弹窗 -->
    <el-dialog
      :visible.sync="yaoxingVisible"
      :close-on-click-modal="false"
      :modal="false"
      width="70%"
      title="药性分析"
      v-dialogDrag
    >
      <yaoxingfenxi
        ref="yaoxingfenxi"
        :yfList="fList"
        :fm="yfm"
        style="overflow: auto; max-height: 800px"
      ></yaoxingfenxi>
    </el-dialog>

    <!-- 药方弹窗 -->
    <el-dialog
      :visible.sync="yaofangVisible"
      :close-on-click-modal="false"
      :modal="false"
      width="40%"
      :title="yaofangTitle"
      v-dialogDrag
    >
      <yaofang ref="yaofang"></yaofang>
    </el-dialog>
    <!-- 药房评价弹窗 -->
    <el-dialog
      :visible.sync="pingjiaVisible"
      :close-on-click-modal="false"
      :modal="false"
      title="医生评价"
      width="40%"
      v-dialogDrag
    >
      <pj ref="pj"></pj>
    </el-dialog>
    <!-- 打印病例 -->
    <el-dialog :visible.sync="printCaseVisible" center>
      <div id="printDiv">
        <div class="print-box">
          <div class="print-title"><h2>处方笺</h2></div>
          <div class="patient-title print-item border-bottom">
            <div class="patient-info" style="line-height: 25px">
              <span style="width: 25%"
                >姓名：{{ patientInfo.name || "无" }}
              </span>
              <span style="flex: 1"
                >性别：{{ patientInfo.gender || "无" }}
              </span>
              <span style="flex: 1"
                >手机号：{{ patientInfo.cellphone || "无" }}
              </span>
            </div>
            <div
              class="patient-info"
              style="justify-content: flex-start; line-height: 25px"
            >
              <span style="width: 25%"
                >年龄：{{ patientInfo.age || "无" }}
              </span>
              <span style="flex: 1"
                >门诊单号：{{ patientInfo.billno || "无" }}</span
              >
              <span style="flex: 1"
                >日期： {{ patientInfo.comedate || "无" }}</span
              >
            </div>
          </div>
          <div
            class="patient-illness print-item border-bottom"
            style="height: 60px"
          >
            <div class="illness-info">
              既往病史：{{ patientInfo.jiwangshi || "无" }}
            </div>
            <div class="illness-info">
              家族史：{{ patientInfo.jiazushi || "无" }}
            </div>
            <div class="illness-info">
              主诉：{{ patientInfo.zhusu || "无" }}
            </div>
            <div class="illness-info">
              疾病诊断：{{ patientInfo.disease || "无" }}
            </div>
          </div>
          <div class="medicine-box print-item">
            <div
              class="RpStyle"
              style="padding: 10px 0; font-size: 20px; font-weight: bold"
            >
              Rp：
            </div>
            <div class="medicint-body" style="padding-left: 5px">
              <div class="medicine-temp">
                <div
                  class="medicine-span"
                  style="
                    width: 25%;
                    display: inline-table;
                    font-size: 16px;
                    height: 55px;
                  "
                  v-for="(item, index) in fList"
                  :key="index"
                >
                  <div
                    class="contentStyle"
                    style="display: flex; justify-content: flex-start"
                  >
                    <span class="zhongYaoMingStyle"
                      >{{ item.name }},{{ item.number }}{{ item.g }}
                    </span>
                  </div>
                  <div style="font-size: 12px; font-weight: 300"></div>
                </div>
              </div>
              <div
                style="text-align: right; margin-bottom: 5px; font-size: 20px"
              >
                共{{ fList.length }}味
              </div>
            </div>
          </div>
          <div
            class="border-bottom order-bottom"
            style="display: flex; justify-content: flex-start"
          >
            <div style="width: 26%">医生：</div>
            <div style="width: 22%">审核：<span></span></div>
            <div style="width: 25%">共{{ fList.length }}味药</div>
            <div style="width: 28%; text-align: right"></div>
          </div>
          <div
            class="end-bottom print-item"
            style="display: flex; justify-content: flex-start"
          >
            <div style="width: 26%">调配：<span></span></div>
            <div style="width: 22%">核对：<span></span></div>
            <div style="width: 33%">发药：<span></span></div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="printCaseVisible = false">取 消</el-button>
        <el-button type="primary" v-print="'#printDiv'">打 印</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="printReportVisible" width="220mm">
      <printReport
        v-if="printReportVisible"
        ref="printReport"
        :patientInfo="patientInfo"
        :yfList="fList"
        :fm="yfm"
      ></printReport>
    </el-dialog>
  </div>
</template>

<script>
import Popup from "./popup/popup.vue";
import shoucang from "./popup/popup-shoucang.vue";
import duxingfenxi from "./popup/popup-duxingfenxi.vue";
import yaoxingfenxi from "./popup/popup-yaoxingfenxi.vue";
import yaofang from "./popup/popup-yaofang.vue";
import pj from "./popup/popup-pj.vue";
import newShoucang from "./popup/newShoucang.vue";
import printReport from "./popup/popup-printReport.vue";
import {
  collect,
  getJingFangYaoWu,
  save,
  getMedicinalDictList,
} from "@/api/zkyz/aictm";
import perscription from "@/tools/impl/Prescription";
import { createUniqueString } from "@/utils";
import integrity from "@/tools/impl/Integrity";
import ButtonClick from "@/tools/impl/ButtonClick";
import ModuleClick from "@/tools/impl/ModuleClick";
import * as echarts from "echarts";
import buttonClick from "@/tools/impl/ButtonClick";
import { unbracket } from "@/utils/validate";
import print from "vue-print-nb";

export default {
  directives: { print },
  components: {
    Popup,
    shoucang,
    duxingfenxi,
    yaoxingfenxi,
    yaofang,
    pj,
    newShoucang,
    printReport,
  },
  data() {
    return {
      charts: {
        tabList: [
          {
            label: "病因",
            value: "bx",
          },
          {
            label: "病位",
            value: "bw",
          },
          {
            label: "基本物质",
            value: "jw",
          },
          {
            label: "病理状态",
            value: "blzt",
          },
          {
            label: "阴阳表里",
            value: "yybl",
          },
          {
            label: "寒热虚实",
            value: "hrxs",
          },
        ],
        dataSet: {},
        curTab: null,
        data: [],
      },
      // 推理证候
      bingyibingjiList: [{}, {}, {}],
      // 病例分析
      caseAnalysis: null,
      // 治疗方案
      therapeuticRegimen: null,
      // 名医名方
      famousUseList: [],
      // 金匮方
      jinguifangList: [],
      // 医院常用
      doctorUseList: [],
      // 开方的类型
      prescriptionType: null,
      // 药方详情
      fList: [],
      // 药方毒性分析
      fMap: {},
      // 诊断唯一值
      zdKey: null,
      //医方名
      yfm: null,
      //画卷内容显示
      isShow: false,
      //打印病例弹窗
      printCaseVisible: false,
      //患者信息
      patientInfo: {},
      //药物名称
      drugNameList: [],
      //收藏开关
      newshoucangShow: false,
      //制法
      prescriptionZhifas: "",
      //药方名s
      yfms: "",
      //药性分析弹窗开关
      yaoxingVisible: false,
      //毒性分析弹窗开关
      duxingVisible: false,
      //药方弹窗开关
      yaofangVisible: false,
      //药方标题
      yaofangTitle: "",
      //评价弹窗开关
      pingjiaVisible: false,
      //打印报告弹窗开关
      printReportVisible: false,
    };
  },
  mounted() {
    this.init();
    setTimeout(() => {
      this.isShow = true;
    }, 2500);
  },
  methods: {
    init(zy) {
      let query = {
        zy: zy || "",
        pageSize: 5,
        pageNum: 1,
      };
      getMedicinalDictList(query).then((res) => {
        this.drugNameList = res.rows.map((item) => {
          return { value: item.zy, ...item };
        });
      });
    },
    /**
     * 打开药方
     *
     * @param title 标题
     * @param list 药方集合
     * @param type 药方类型
     */
    openYaofang(title, list, type) {
      if (type == "4") {
        ButtonClick.click("名医名方");
      } else if (type == "3") {
        ButtonClick.click("金匮方");
      }
      this.yaofangTitle = title;
      this.yaofangVisible = true;
      this.$nextTick(() => {
        this.$refs.yaofang.open(title, list, type);
      });
    },
    /**
     * data1 金匮方
     * data2 名医名方
     * mymfIsQ 名医名方是否传的 全
     */
    formatResult(data1, data2, mymfIsQ) {
      // 保存智能辩证结果
      this.zdKey = createUniqueString();
      let map = {};
      map[this.zdKey] = {
        timestamp: new Date().getTime(),
        jkf: data1,
        mymf: data2,
      };
      perscription.zd(map);

      // 重置诊断结果
      this.charts.data = [];
      this.charts.curTab = null;
      this.bingyibingjiList = [{}, {}, {}];
      this.fList = [];
      this.fMap = {};
      this.doctorUseList = [];
      this.jinguifangList = [];
      this.famousUseList = [];

      // data1,data2 默认值
      let json, json2;
      if (!data1) {
        json = {
          diagnoseJson: {
            merge: {},
          },
          data: [],
        };
      } else {
        json = data1;
      }
      if (!data2) {
        json2 = {
          diagnoseJson: {
            merge: {},
          },
        };
      } else {
        json2 = data2;
      }

      // 处理 - 名医名方
      if (mymfIsQ) {
        this.formatResultLocalDisease(json2);
      } else {
        this.formatResultLocal(json2);
      }
      // 处理 - 金匮方
      this.formatResultJkfAliyun(json);
    },
    // 金匮方处理
    formatResultJkfAliyun(json) {
      console.log("金匮方 - 阿里云");
      console.log(json);
      // 药方
      let yf1 = json || [];
      let jinguifangList = [];
      for (let item of yf1) {
        let zz = item.zc;
        let yfm = item.fj;
        let yf = item.zc_std;
        let yfStrList = [];
        for (let vItem of item.zc_std || []) {
          yfStrList.push(vItem.n + vItem.v + vItem.dw);
        }
        jinguifangList.push({ zz, yfm, yf, yfs: item.aozhi });
      }
      this.jinguifangList = jinguifangList;
    },

    // 名医名方  disease全
    formatResultLocalDisease(json2) {
      console.log(json2, "名医名方 - disease全");
      let chartsTab = [
        {
          label: "病因",
          value: "bx",
        },
        {
          label: "病位",
          value: "bw",
        },
        {
          label: "基本物质",
          value: "jw",
        },
        {
          label: "病理状态",
          value: "blzt",
        },
        {
          label: "阴阳表里",
          value: "yybl",
        },
        {
          label: "寒热虚实",
          value: "hrxs",
        },
      ];
      this.charts.tabList = chartsTab;
      this.charts.dataSet["bx"] = json2.diagnoseJson?.vote?.bingyin || {};
      this.charts.dataSet["bw"] = json2.diagnoseJson?.vote?.zangfu || {};
      this.charts.dataSet["jw"] = json2.diagnoseJson?.vote?.qixue || {};
      this.charts.dataSet["blzt"] = json2.diagnoseJson?.vote?.bingli || {};
      this.charts.dataSet["yybl"] = Object.assign(
        json2.diagnoseJson?.vote?.yinyang || {},
        json2.diagnoseJson?.vote?.biaoli || {}
      );
      this.charts.dataSet["hrxs"] = Object.assign(
        json2.diagnoseJson?.vote?.hanre || {},
        json2.diagnoseJson?.vote?.xushi || {}
      );

      this.changeChartsTab(this.charts.tabList[0]);

      // 症候
      let zh1 = json2.diagnoseJson.fj_jf["twobw-bingli-qixue"] || [];
      let zh2 = json2.diagnoseJson.fj_jf["twobw-bingyin"] || [];
      let zhArr = [zh2[0], zh1[0], zh2[1]];
      let zhList = [];
      for (let item of zhArr) {
        let title = item.zhengming;
        zhList.push({
          title,
        });
      }
      this.bingyibingjiList = zhList;

      // 药方
      let yf1 = json2.diagnoseJson.fj_jf["twobw-bingli-qixue"] || [];
      let yf2 = json2.diagnoseJson.fj_jf["twobw-bingyin"] || [];
      let yfArr = [yf2[0], yf1[0], yf2[1], yf1[1]];
      let famousUseList = [];
      for (let item of yfArr) {
        let zz = item.zhengming;
        for (let vItem of item.fangji || []) {
          let yfm = vItem.fjname;
          let yf = vItem.zc_std;
          let yfStrList = [];
          for (let yfItem of yf) {
            yfStrList.push(yfItem.n + yfItem.v + yfItem.dw);
          }
          famousUseList.push({
            zz,
            yfm,
            yf,
            yfs: yfStrList.join(","),
            zhifa: vItem.zhifa,
          });
        }
      }
      this.getMingFangOrigin(famousUseList);
    },
    // 名医名方处理  - disease不是全
    formatResultLocal(json2) {
      console.log(json2, "名医名方 - disease非全");
      let chartsTab = [
        {
          label: "病因",
          value: "bx",
        },
        {
          label: "病位",
          value: "bw",
        },
        {
          label: "基本物质",
          value: "jw",
        },
        {
          label: "病理状态",
          value: "blzt",
        },
        {
          label: "最小症候",
          value: "mz",
        },
      ];
      this.charts.tabList = chartsTab;
      this.charts.dataSet["bx"] = json2.diagnoseJson.merge.bx || {};
      this.charts.dataSet["bw"] = json2.diagnoseJson.merge.bw || {};
      this.charts.dataSet["jw"] = json2.diagnoseJson.merge.jw || {};
      this.charts.dataSet["blzt"] = json2.diagnoseJson.merge.bl || {};
      this.charts.dataSet["mz"] = json2.diagnoseJson.merge.mz || {};
      this.changeChartsTab(this.charts.tabList[0]);

      // 症候
      let zh1 = json2.diagnoseJson.merge.zh || {};
      let zhList = [];
      for (let item in zh1) {
        let title = item;
        let value = zh1[item];
        zhList.push({
          title,
          value,
        });
      }
      this.bingyibingjiList = zhList.sort((a, b) => b.value - a.value);

      // 药方
      let yf1 = json2.fangji || [];

      let famousUseList = [];
      for (let item of yf1) {
        zhList.map((i) => {
          if (item.zhenghou.indexOf(i.title) > -1) {
            item.value = i.value;
          }
        });
        let zz = item?.zhenghou;
        let yfm = item?.fangji;
        let yf = JSON.parse(item.zc_std);
        let yfStrList = [];
        for (let yfItem of yf) {
          yfStrList.push(yfItem.n + yfItem.v + yfItem.dw);
        }
        famousUseList.push({
          value: item.value,
          zz,
          yfm,
          yf,
          yfs: yfStrList.join(","),
        });
      }
      famousUseList.sort((a, b) => {
        return b.value - a.value;
      });
      this.getMingFangOrigin(famousUseList);
    },
    //名医名方 查询来源
    getMingFangOrigin(list) {
      this.famousUseList = list.map((item) => {
        let zc = item.yf.map((i) => i.n).join(",");
        this.$nextTick(() => {
          getJingFangYaoWu({ fm: item.yfm, zc }).then((res) => {
            if (res.code === 200) {
              this.$set(item, "laiyuan", res?.data[0][0]?.laiyuan);
            }
          });
        });
        return item;
      });
    },
    /**
     * 切换顶部的tab
     */
    changeChartsTab(tab) {
      if (Object.keys(this.charts.dataSet).length < 1) {
        return;
      }
      if (this.charts.curTab == tab.value) {
        return;
      }
      this.charts.curTab = tab.value;
      let data = this.charts.dataSet[tab.value] || {};

      let list = [];
      for (let key in data) {
        list.push({
          key,
          value: data[key],
        });
      }

      list.sort((o1, o2) => {
        return o2.value - o1.value;
      });

      if (list.length > 5) {
        list = list.slice(0, 5);
      }

      data = {};
      for (let item of list) {
        data[item.key] = item.value;
      }

      let max = 0;
      for (let key in data) {
        let value = data[key];
        if (max < value) {
          max = value;
        }
      }

      let xList = [];
      let yList = [];
      let i = 0;
      for (let key in data) {
        let newKey = key
          .replace("c", "")
          .replace("d", "")
          .replace("C", "")
          .replace("D", "");
        let value = data[key];
        xList.push(newKey);
        yList.push(value);
        i++;
      }
      let myChart = echarts.init(this.$refs.echarts);
      var option;

      option = {
        color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
          {
            offset: 0,
            color: "#AE7000",
          },
          {
            offset: 1,
            color: "#C48D6E",
          },
        ]),
        xAxis: {
          type: "category",
          data: xList,
          axisLabel: {
            fontSize: 18,
            color: "black",
            fontFamily: "隶书",
          },
        },
        yAxis: {
          show: false,
        },
        series: [
          {
            data: yList,
            type: "bar",
            barWidth: 40,
            itemStyle: {
              borderWidth: 1,
              borderColor: "#00aa00",
            },
          },
        ],
        grid: {
          left: 40,
          top: 30,
          right: 40,
          bottom: 30,
        },
        tooltip: {
          formatter: "{b0}",
        },
      };
      option && myChart.setOption(option);
    },
    /**
     * 个人收藏 - 复用药方
     */
    fy(item, prescriptionType) {
      if (!item) {
        return;
      }
      ButtonClick.click("复用药方");
      let fList = [];
      if (item.indexOf("、") > -1) {
        let list = item.split("、");
        for (let item of list) {
          let arr = item.split(" ");
          let name = arr[0];
          let number = null;
          let g = "g";
          if (arr.length > 1) {
            let gStr = arr[1];
            number = gStr.replace("g", "");
          }
          fList.push({
            name,
            number,
            g,
          });
        }
      } else {
        let list = item.split(",");
        for (let item of list) {
          let number = item.replace(/[^\d]/g, " ");
          if (!number) {
            fList.push({
              item,
              number: null,
              g: "g",
            });
          } else {
            number = parseFloat(number);
            let arr = item.split(number + "");
            let name = arr[0];
            let g = "g";
            if (arr.length > 1) {
              g = arr[1];
            }
            fList.push({
              name,
              number,
              g,
            });
          }
        }
      }
      this.fList.push(...fList);
    },
    /**
     * 金匮方、名医名方 - 复用药方
     */
    std(list, yfs, yfm) {
      this.prescriptionZhifas += yfs;
      this.yfm = yfm;
      this.yfms = this.yfms.concat(yfm, "+");
      if (!list || list.length < 1) {
        return;
      }
      ButtonClick.click("复用药方");
      let fList = [];
      for (let item of list) {
        let name = unbracket(item.n);
        let number = item.v;
        let g = item.dw || "g";
        fList.push({
          name,
          number,
          g,
        });
      }
      this.fList.push(...fList);
    },
    /**
     * 进行毒性分析
     */
    toDuxingfenxi() {
      // 未输入药方
      if (this.fList.length < 1) {
        this.$message.error("未输入药方");
        return;
      }
      buttonClick.click("毒性分析");
      this.duxingVisible = true;
      this.$nextTick(() => {
        this.$refs.duxingfenxi.open(this.fList, (map) => {
          this.fMap = map;
        });
      });
    },
    /**
     * 进行药性分析
     */
    toYaoxingfenxi() {
      // 未输入药房
      if (this.fList.length < 1) {
        this.$message.error("未输入药方");
        return;
      }
      buttonClick.click("药性分析");
      this.yaoxingVisible = true;
      this.$nextTick(() => {
        this.$refs.yaoxingfenxi.init();
      });
    },
    /**
     * 打开推荐医案
     */
    toYa() {},
    /**
     * 打开个人收藏
     */
    toSc() {
      this.$refs.shoucang.getList();
      ButtonClick.click("个人收藏");
    },

    /**
     * @description: reusefList个人收藏复用药方
     * @return {*}
     */
    reusefList(e) {
      this.std(e);
    },
    /**
     * 构建药方
     */
    buildYf() {
      let list = this.fList;
      let strArr = [];
      for (let item of list) {
        if (!item.name || !item.number || item.number <= 0 || !item.g) {
          this.$message.error("请完善药方");
          return {
            result: false,
          };
        }
        let str = item.name + item.number + item.g;
        strArr.push(str);
      }
      if (strArr.length < 1) {
        this.$message.error("请完善药方");
        return {
          result: false,
        };
      }
      return {
        result: true,
        prescriptionDetail: strArr.join(","),
      };
    },
    /**
     * 构建四诊结果
     */
    buildzhenduanForm(zhenduanForm, field) {
      let form = zhenduanForm[field] || {};
      return Object.keys(form).join(",");
    },
    moduleClick(name) {
      ModuleClick.click(name);
    },
    /**
     * 回车获取下一个input焦点
     */
    focusNext(num, index) {
      const that = this;
      if (num === 1) {
        that.$refs[`inputNumber${index}`][0].focus();
      } else if (num === 2) {
        that.$refs[`inputDw${index}`][0].focus();
      } else if (num === 3) {
        if (index >= that.fList.length - 1) {
          that.addPrescription();
          that.$nextTick(() => {
            that.$refs[`inputName${index + 1}`][0].focus();
          });
        } else {
          that.$nextTick(() => {
            that.$refs[`inputName${index + 1}`][0].focus();
          });
        }
      }
    },
    /**
     * 删除药方
     */
    removeF(index) {
      this.fList.splice(index, 1);
    },
    /**
     * 添加药方
     */
    addPrescription() {
      ButtonClick.click("添加药方");
      this.fList.push({
        name: null,
        number: null,
        g: "g",
      });
    },
    /**
     *打印病例
     */
    printOpen() {
      this.printCaseVisible = true;
    },
    /**
     * 打印
     */
    print() {
      window.print();
    },
    /**
     * 打印报告
     */
    printReportOpen() {
      if (this.fList.length < 1) {
        this.$message.error("未输入药方");
        return;
      }
      this.printReportVisible = true;
      let left = this.$parent.$refs.left;
      let form = left.form;
      this.patientInfo = form;
      this.patientInfo.shefileUrl = left.shefileUrl;
      this.$nextTick(() => {
        this.$refs.printReport.init();
      });
    },
    /**
     * 确认开方
     */
    confirmPrescription() {
      let { result, prescriptionDetail } = this.buildYf();
      if (!result) {
        return;
      }
      if (!prescriptionDetail) {
        this.$message.error("输入药方");
        return;
      }
      let left = this.$parent.$refs.left;
      let form = left.form;
      let zhenduanForm = left.zhenduanForm;
      //处理药方
      let prescriptionZcStd = this.fList.map((item) => {
        return { n: item.name, v: item.number, dw: item.g, fw: "", b: 0 };
      });
      // engravingSyndrome 刻下症描述 应该不对吧
      let obj = {
        ...zhenduanForm["zhengzhuang"],
        ...zhenduanForm["guanliantuijian"],
      };
      let arr = Object.keys(obj).filter((key) => obj[key] !== "");
      let kfForm = {
        patientCase: {
          id: form.patientCaseId,
          billno: form.billno,
          accidentDate: form.accidentDate,
          cardinalSymptom: arr.join(","),
          jiwangshi: form.jiwangshi,
          tongueInfo: this.buildzhenduanForm(zhenduanForm, "shezhi"),
          pulseConditionInfo: this.buildzhenduanForm(zhenduanForm, "maixiang"),
          disease: left.jibingbianbieId || "全",
          caseAnalysis: this.caseAnalysis,
          therapeuticRegimen: form.therapeuticRegimen,
          zhusu: form.zhusu,
          happenDate: form.accidentDate,
          comeDate: form.comedate,
          shefileUrl: left.shefileUrl,
          status: 1,
        },
        patientInfo: {
          id: form.patientInfoId,
          name: form.name,
          age: form.age,
          gender: form.gender == "男" ? "M" : "F",
          birthday: form.birthday,
          idType: null,
          cellphone: form.cellphone,
          idcard: form.idcard,
        },
        dieasePrescription: {
          prescriptionType: null,
          prescriptionDetail: prescriptionDetail,
          prescriptionZcStd: JSON.stringify(prescriptionZcStd),
        },
      };

      // 计算完整度
      integrity.compute(kfForm);
      ButtonClick.click("确认开方");
      save(kfForm).then((res) => {
        if (res.code != 200) {
          this.$message.error(res.msg);
        } else {
          this.$message.success(res.msg);
          this.pingjiaVisible = true;
          this.$nextTick(() => {
            this.$refs.pj.open(res.data.patientCase.id);
          });
          // 完整度
          integrity.setId(res.data.patientCase.id);
          // 使用率
          let map = {};
          map[this.zdKey] = {
            timestamp: new Date().getTime(),
            kf: kfForm,
            id: res.data.patientCase.id,
          };
          perscription.kf(map);
        }
      });
    },
    /**
     * 清空药方
     */
    clearPrescription() {
      ButtonClick.click("清空药方");
      this.fList = [];
      this.fMap = {};
      this.yfms = "";
      this.prescriptionZhifas = "";
    },
    /**
     * 收藏药方
     */
    confirmCollect() {
      this.$nextTick(() => {
        this.$refs.newShoucang.getList();
      });
    },
    /**
     * 关闭收藏popover
     */
    changeShow(e) {
      this.newshoucangShow = e;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "style-right";
</style>

<style>
#printDiv {
  display: flex;
  justify-content: center;
}
.print-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  width: 148mm;
  min-height: 600px;
  padding: 10px 20px 20px 20px;
}

.print-title {
  text-align: center;
}

.patient-title {
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  line-height: 25px;
}

.patient-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.patient-illness {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 0;
  flex-wrap: wrap;
  height: 60px;
}

.illness-info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 50%;
  justify-content: center;
}

.border-bottom {
  border-bottom: 1px solid black;
}

.medicine-box {
  flex: 1;
}

.medicint-body {
  display: flex;
  flex-direction: column;
}

.order-date {
  flex-grow: 0;
}

.order-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.end-bottom {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.check-span {
  margin: 0 0 10px 75px;
}
</style>
