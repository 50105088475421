<template>
  <div class="popup-wrap">
    <div class="body">
      <div class="mid">
        <div class="item">
          <div class="key">医生评价：</div>
          <div class="value">
            <div class="switch">
              <div class="switch-item" :class="{ active: form.docmark == 2 }" @click="changeSwitch('2')">准确</div>
              <div class="switch-item" :class="{ active: form.docmark == 1 }" @click="changeSwitch('1')">较准确</div>
              <div class="switch-item" :class="{ active: form.docmark == 0 }" @click="changeSwitch('0')">错误</div>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="key">诊断评价：</div>
          <div class="value border">
            <el-input type="textarea" :autosize="{ minRows: 9 }" placeholder="请填写该医案的医生诊断结果，便于技术人员分析实际诊断与辅助诊断系统的差异"
              v-model="form.docdiagnose" clearable></el-input>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="btn" @click="close">取消</div>
      <div class="btn" @click="confirm">确定</div>
    </div>
  </div>
</template>

<script>
import { saveDocComments } from '@/api/zkyz/aictm'
import ButtonClick from "@/tools/impl/ButtonClick";

export default {
  name: "popup-pj",
  props: {},
  data() {
    return {
      caseId: null,
      form: {
        docmark: null,
        docdiagnose: ""
      }
    }
  },
  mounted() {
  },
  methods: {
    open(caseId) {
      this.caseId = caseId;
      this.form = {
        docmark: null,
        docdiagnose: ""
      }
    },
    close() { 
      this.$parent.$parent.pingjiaVisible = false;
    },
    confirm() {
      if (!this.form.docmark && this.form.docmark != 0) {
        this.$message.error("请选择医生评价")
        return;
      }
      // if (!this.form.docdiagnose && this.form.docdiagnose != 0) {
      //   this.$message.error("请选择诊断评价")
      //   return;
      // }
      ButtonClick.click("保存评价");
      saveDocComments(this.caseId, this.form).then(res => {
        if (res.code == 200) {
          this.$message.success("保存成功");
          this.$router.go(0);
        } else {
          this.$message.error(res.msg);
        }
      })

      this.close();
      this.$router.go(0);
    },
    changeSwitch(e) {
      this.form.docmark = e;
    }

  }
}
</script>
<style lang="scss" scoped>
.title {
  background-color: #c48d6e;
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: left;
  color: white;
  font-size: 20px;
  font-weight: 700;
  text-indent: 20px;
}

.btn {
  background-color: #ae7000;
  border-radius: 20px;
  height: 30px;
  width: 100px;
  padding: 0 10px;
  line-height: 30px;
  text-align: center;
  color: white;
  font-weight: 500;
  cursor: pointer;
  margin: 0 auto;
}

.border {
  height: 200px;
  border: 1px solid var(--main-border-color);
  border-radius: 4px;
  overflow: hidden;
  padding: 2px 4px;
}

.body {
  margin: 10px 0;
  padding: 6px;

  .mid {
    flex: 1;
    padding: 0 2px !important;

    .item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 10px;

      .key {
        width: 80px;
        font-size: 16px;
        font-weight: bold;
        margin-top: 8px;
      }

      .value {
        flex: 1;
      }
    }
  }
}

.switch {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid var(--main-border-color);
  height: 36px;
  border-radius: 4px;
  overflow: hidden;

  .switch-item {
    height: 36px;
    line-height: 36px;
    text-align: center;
    flex: 1;
    font-size: 15px;
    color: #333333;
    border-right: 1px solid var(--main-border-color);
    cursor: pointer;

    &:last-child {
      border-right: 0;
    }

    &.active {
      background: var(--main-border-color);
      color: white;
    }
  }
}

.bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
</style>
