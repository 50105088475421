var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "top" }, [
      _c(
        "div",
        { staticClass: "top_box" },
        [
          _c("Echart", {
            ref: "gender",
            staticStyle: { width: "100%", height: "100%", margin: "0 auto" },
            attrs: { options: _vm.genderOptions, id: "gender" },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "top_box" },
        [
          _c("Echart", {
            ref: "office",
            staticStyle: { width: "100%", height: "100%", margin: "0 auto" },
            attrs: { options: _vm.officeOptions, id: "office" },
          }),
        ],
        1
      ),
      _c(
        "div",
        { ref: "faculty", staticClass: "top_box" },
        [
          _c("Echart", {
            ref: "faculty",
            staticStyle: { width: "100%", height: "100%", margin: "0 auto" },
            attrs: { options: _vm.facultyOptions, id: "faculty" },
          }),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "bot" },
      [
        _vm._v(" 就诊年龄分布 "),
        _c("Echart", {
          ref: "age",
          staticStyle: { width: "100%", height: "100%", margin: "0 auto" },
          attrs: { options: _vm.ageOptions, id: "age" },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }