var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "popup-wrap" }, [
    _c(
      "div",
      { staticClass: "body" },
      [
        _vm._l(_vm.dataList, function (item, index) {
          return _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: item.isok !== -1,
                  expression: "item.isok !== -1",
                },
              ],
              key: index,
              staticClass: "body-item",
            },
            [
              _c(
                "el-row",
                { staticClass: "row" },
                [
                  _c("el-col", { staticClass: "col", attrs: { span: 8 } }, [
                    _c("div", { staticClass: "item-title" }, [_vm._v("药名")]),
                    _c("div", { staticClass: "item-value bold" }, [
                      _vm._v(" " + _vm._s(item.n || "-") + " "),
                    ]),
                  ]),
                  _c("el-col", { staticClass: "col", attrs: { span: 16 } }, [
                    _c("div", { staticClass: "item-title" }, [_vm._v("毒性")]),
                    _c("div", { staticClass: "item-value bold" }, [
                      _c(
                        "span",
                        { style: { color: item.isok === 1 ? "green" : "red" } },
                        [_vm._v(_vm._s(item.isok === 1 ? "无毒" : "有毒"))]
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "row" },
                [
                  _c("el-col", { staticClass: "col", attrs: { span: 8 } }, [
                    _c("div", { staticClass: "item-title" }, [
                      _vm._v("常用量"),
                    ]),
                    _c("div", { staticClass: "item-value" }, [
                      _vm._v(
                        " " +
                          _vm._s(item.min) +
                          "~" +
                          _vm._s(item.max) +
                          _vm._s(item.dw) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("el-col", { staticClass: "col", attrs: { span: 16 } }, [
                    _c("div", { staticClass: "item-title" }, [_vm._v("用法")]),
                    _c("div", { staticClass: "item-value" }, [
                      _vm._v(" " + _vm._s(item.memo) + " "),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        }),
        _vm._l(_vm.FJ18fanList, function (item, index) {
          return _c("div", { key: item.t }, [
            _c(
              "p",
              { staticStyle: { color: "brown" } },
              [
                _c("span", [_vm._v("提示：【" + _vm._s(item.t) + "】")]),
                _vm._l(item.v, function (vItem, inx) {
                  return _c("span", { key: inx }, [
                    _vm._v(
                      _vm._s(Array.isArray(vItem) ? vItem.join(" ") : vItem) +
                        " "
                    ),
                  ])
                }),
              ],
              2
            ),
          ])
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }