<template>
  <div class="block">
    <el-carousel height="240px" indicator-position="none" arrow="never" :interval="60000">
      <el-carousel-item v-for="(item, index) in liuxingbingList" :key="index">
        <ul>
          <li>
            <div class="item_name">
              {{ item.name }}
            </div>
          </li>
          <li>
            表现：{{ item.biaoxian1 }}
          </li>
          <li>
            <div class="item_fangjiname">
              方剂推荐：{{ item.fangjiname1 }}
            </div>
          </li>
          <li>
            <div class="item_yongfa">
              用法：{{ item.yongfa1 }}
            </div>
          </li>
          <!--          <li>-->
          <!--            表现：{{ item.biaoxian2 }}-->
          <!--          </li>-->
          <!-- <li>
            方剂推荐：{{ item.fangjiname2 }}
          </li>
          <li>
            用法：{{ item.yongfa2 }}
          </li> -->
        </ul>
        <!--      <img src="../../../assets/visualScreenImg/lunbo.png" alt=""/>-->
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>


export default {
  components: {},
  data() {
    return {
      liuxingbingList: [
        {
          name: '冠状动脉粥样硬化心脏病',
          biaoxian1: '证见胸闷心痛，心悸气短，时而胸痛彻背背痛彻胸、脉弦而结代，苔薄白',
          fangjiname1: '冠心Ⅱ号、瓜蒌薤白半夏汤',
          yongfa1: '瓜蒌10g，薤白10g，半夏6g，白芍10g，红花6g，降香6g，丹参20g，水煎服，日一剂',
          biaoxian2: '',
          fangjiname2: '',
          yongfa2: ''
        },
        {
          name: '糖尿病',
          biaoxian1: '面暗唇青，心悸身肿，四肢麻木，舌暗红或有瘀点，瘀丝苔薄白，脉涩',
          fangjiname1: '桃红四物汤加减',
          yongfa1: '桃仁12g，红花12g，归尾12g，熟地18g，赤芍12g，川芎10g，鸡血藤15g，丹参20g，仙灵脾15g，黄芪20g，水煎服，日一剂',
          biaoxian2: '',
          fangjiname2: '',
          yongfa2: ''
        }, {
          name: '上呼吸道感染',
          biaoxian1: '恶寒重，发热轻，无汗，鼻塞，流清涕，头痛，身痛或咳嗽，咯白痰，舌苔薄白，脉浮',
          fangjiname1: '荆防败毒散加减',
          yongfa1: '荆芥、防风、羌活、前胡、白芷各10g，生姜3片，水煎服，日一剂',
          biaoxian2: '',
          fangjiname2: '',
          yongfa2: ''
        }, {
          name: '高血压',
          biaoxian1: '头痛，头晕，头重脚轻，口苦咽干，面目红润，心烦失眠，五心烦热，每日烦怒则加重，舌红苔黄，脉弦数或弦细',
          fangjiname1: '天麻钩藤饮加减',
          yongfa1: '天麻10g，蒺藜20g，钩藤30g，生地12g，元参10g，麦冬10g，石决明20g，生牡蛎20g，黄芩6g，桑寄生15g，杜仲15g，夏枯草20g，水煎服，日一剂',
          biaoxian2: '',
          fangjiname2: '',
          yongfa2: ''
        }, {
          name: '流感',
          biaoxian1: '风寒袭表：恶寒发热，头身疼痛，鼻塞声重，咳嗽有痰。舌脉：舌质淡，苔薄白，脉浮。',
          fangjiname1: '荆防败毒散加减',
          yongfa1: '水煎服，每剂水煎450毫升，每次口服150毫升，1日3次',
          biaoxian2: '风热袭表：发热或未发热，咽痛，轻咳少痰。舌脉：舌质红，苔薄黄，脉浮数。',
          fangjiname2: '中成药：疏风解表，清热解毒类，如抗病毒冲剂、金花清感颗粒、莲花清瘟胶囊（颗粒）、清开灵颗粒（胶囊、软胶囊、片）等',
          yongfa2: '水送服'
        }
      ]
    }
  },
  computed: {},
  created() {
  },

  mounted() {

  },
  methods: {}
}
</script>
<style lang="scss" scoped>
.block {
  width: 100%;
  height: 100%;
  padding: 10px 20px 0 0;
  box-sizing: border-box;

  img {
    height: 100%;
    width: 100%;
  }

  ul {
    list-style: none;
    box-sizing: border-box;
    color: white;
    margin: 0;
    padding: 12px;
    background: url("../../../assets/visualScreenImg/border.png") no-repeat;
    background-size: 100% 100%;
    font-family: KaiTi;
    overflow: hidden;
    height: 100%;

    li {
      font-size: 20px;
      line-height: 20px;
      margin-bottom: 6px;

      .item_name {
        font-size: 28px;
        line-height: 28px;
        text-align: center;
        color: #169FEF;
      }
    }
  }

  ::v-deep .el-carousel {}
}
</style>
