<template>
  <div class="container">
    <div class="left">
      <div class="title">子午流注养生提示</div>
      <div class="content_box">
        <img :src="imgUrl2" alt=""/>
      </div>
    </div>
    <div class="right">
      <div class="title">时节养生大全</div>
      <div class="content_box">
        <el-carousel height="100%" indicator-position="none" arrow="never" :interval="5000">
          <el-carousel-item v-for="(item,index) in imgUrl" :key="index">
            <img :src="item" alt=""/>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll'

export default {
  components: {
    vueSeamlessScroll
  },
  data() {
    return {
      timer: null,
      scrollTime: 50,
      jieQiList: [
        {
          name: '立春',
          list: [
            '斗指东北，维为立春，时春气始至，四时之卒始，故名立春。阳历2月4日前后是二十四节气中的第一个节气。',
            '立春时节养生着重要顺应春天阳气生发、万物始生的特点。',
            '春属木，与肝相应。在春季养生方面，主要是护肝，食品则应选择辛湿发散的大枣、豆豉、葱、花生等，可以达到很好的食疗效果。',
            '立春的饮食药膳应以“升补”为主，适宜的膳食有首乌肝片、虾仁韭菜、珍珠三鲜汤等，食之有补肝肾、益精血、乌发明目、温中益气的功效。'
          ],
          desc: '春属木，与肝相应。在春季养生方面，主要是护肝，食品则应选择辛湿发散的大枣、豆豉、葱、花生等，可以达到很好的食疗效果。',
          imgUrl: [require('../../../assets/visualScreenImg/jieqiImg/lichun.png'), require('../../../assets/visualScreenImg/jieqiImg/lichun2.png')]
        },
        {
          name: '雨水',
          list: [
            '斗指壬为雨水时，东风解冻，冰雪皆散而为水，化而为雨，故名雨水。时值公历2月18日前后。',
            '五行中肝属木，味为酸，脾属土，味为甘，木胜土。所以，雨水时节的饮食应少吃酸味，多吃甜味，以养脾脏之气。',
            '可选择香椿、百合、豌豆苗、茼蒿、荠菜、春笋、山药、藕、芋头、萝卜、荸荠、甘蔗等。'
          ],
          imgUrl: [require('../../../assets/visualScreenImg/jieqiImg/yushui.png'), require('../../../assets/visualScreenImg/jieqiImg/yushui1.png')]
        },
        {
          name: '惊蛰',
          list: [
            '斗指丁为惊蛰，雷鸣动，蛰虫皆震起而出，故名惊蛰也。每年阳历3月6日左右时二十四节气的惊蛰。',
            '饮食调养：应多食壮阳食品，如羊肉、狗肉、鸡肉、鹿肉等。',
            '惊蛰的饮食原则是保阴潜阳，多吃清淡食物，也可以适当选用补品，以提高人体的免疫力，还可以适当食用一些具有补益正气作用的食疗粥来增强体质。',
            '维生素对人体的生理功能有着重要的作用，特别是维生素C的摄入能够明显提高人体的抗病能力，维生素C含量丰富的食物有：水萝卜、辣椒、甜椒、西兰花、桂圆、荔枝等。',
            '还应多吃清淡食物：如糯米、芝麻、甘蔗等。一般选服具有调血补气、健脾补肾、养肺补脑的补品。'
          ],
          imgUrl: [require('../../../assets/visualScreenImg/jieqiImg/jingzhe.png'), require('../../../assets/visualScreenImg/jieqiImg/jingzhe1.png')]
        },
        {
          name: '春分',
          list: [
            '斗指壬为春分，行约周天，南北两半球昼夜均分，又当春之半，故名春分。阳历3月21日左右是二十四节气中的春分。',
            '春分节气平分了昼夜、寒暑，因此人们保健养生也应保持人体的阴阳平衡状态。如在烹调鱼、虾、蟹等寒性食物时，其原则比佐以葱、姜、酒、醋类温性调料，又如在食用韭菜、大蒜、木瓜等助阳类菜肴时常配以蛋类滋阴之品，以达到阴阳互补之目的。',
            '春分时节适宜的膳食有白烧鳝鱼，杜仲腰花、大蒜烧茄子等，有补虚损、降血压、凉血止血的功效。'
          ],
          desc: '春分时节适宜的膳食有白烧鳝鱼，杜仲腰花、大蒜烧茄子等，有补虚损、降血压、凉血止血的功效。',
          shiju: '春分之日玄鳥至，又五日雷乃發聲，又五日使電',
          imgUrl: [require('../../../assets/visualScreenImg/jieqiImg/chunfen.png'), require('../../../assets/visualScreenImg/jieqiImg/chunfen1.png')]
        },
        {
          name: '清明',
          list: [
            '斗指丁为清明，时万物清洁而明净，盖时当气清景明，万物皆秋，故名也。阳历4月5日或6日。',
            '古人有云：“食酸咸甜苦，即不得过分食。春不食肝，夏不食心，秋不食肺，冬不食肾，四季不食脾，如能不食，此五脏万顺天理。”',
            '即是告诫人们，养生中对五脏的食物进补要适中，不可过度。',
            '清明正是冷空气与暖空气交替相遇之际，因此在汤品调理中，除了要利水渗湿外，还要适当补益，养血疏筋就最为重要。',
            '所以，清明时节还应服一些适时的滋补品，如银耳，甘平、无毒，能润肺生津、益阴柔肝。',
            '还有人们熟悉的菊花茶，菊花能疏风清热，有平肝、预防感冒、降低血压等作用。'
          ],
          imgUrl: [require('../../../assets/visualScreenImg/jieqiImg/qingming.png'), require('../../../assets/visualScreenImg/jieqiImg/qingming1.png')]
        },
        {
          name: '谷雨',
          list: [
            '斗指癸为谷雨，言雨生百谷也，时必雨不降，百谷滋长之意。阳历4月20号前后。',
            '谷雨已是暮春时节，食疗要点重在养肝清肝，滋养明目。',
            '风寒湿痹之人忌食柿子、柿饼、西瓜、芹菜、生黄瓜、螃蟹、田螺、蚌肉、海带等生冷性凉的食物；热痹患者忌食胡椒、肉桂、辣椒、花椒、生姜、葱白、白酒等温热助火之品。'
          ],
          imgUrl: [require('../../../assets/visualScreenImg/jieqiImg/guyu.png'), require('../../../assets/visualScreenImg/jieqiImg/guyu1.png')]
        },
        {
          name: '立夏',
          list: [
            '斗指东南维为立夏，万物至此皆长大，故名立夏也。每年5月5日或6日。',
            '立夏后气温渐热，心脏的工作强度日渐增大，所以饮食应以顺“心”为主。',
            '宜吃性凉多汁的新鲜果蔬，宜适当饮水和清凉饮料；长夏暑湿，宜吃些具有芳香开胃、健脾化湿作用的食品。',
            '孙思邈在“摄养论”中说：“四月，肝脏已病，心脏渐壮。宜增酸减苦，补肾强肝，调胃气”。'
          ],
          imgUrl: [require('../../../assets/visualScreenImg/jieqiImg/lixia.png'), require('../../../assets/visualScreenImg/jieqiImg/lixia1.png')]
        },
        {
          name: '小满',
          list: [
            '斗指甲为小满，万物长于此少得盈满，麦至此方小满而未全熟，故名也。',
            '小满时值公历5月21日前后。',
            '在这个节气的养生中，“未病先防”的养生观点应该大力提倡。',
            '常吃具有清利湿热作用的食物，如赤小豆、黑木耳、藕、草鱼、鸭肉等，忌食高盐厚味、甘肥滋腻、生湿助湿的食物。'
          ],
          imgUrl: [require('../../../assets/visualScreenImg/jieqiImg/xiaoman.png'), require('../../../assets/visualScreenImg/jieqiImg/xiaoman1.png')]
        },
        {
          name: '芒种',
          list: [
            '斗指巳为芒种，此时可种有芒之谷，过此即失效，故名芒种也。芒种时值阳历6月6日前后。',
            '饮食调养方面，历代养生家都认为夏三月的饮食宜清补。《吕氏春秋尽数篇》指出：',
            '“凡食无强厚味，无以烈味重酒。”唐朝的孙思邈提醒人们“常宜轻清甜淡之物，大小麦曲，糯米为佳”，又说“善养生者常须少食肉，多食饭”。',
            '元代医家朱丹溪的《茹谈论》曰：“少食肉食，多食谷菽菜果，自然冲和之味。”'
          ],
          imgUrl: [require('../../../assets/visualScreenImg/jieqiImg/mangzhong.png'), require('../../../assets/visualScreenImg/jieqiImg/mangzhong1.png')]
        },
        {
          name: '夏至',
          list: [
            '斗指乙为夏至，万物与此皆假大而极至，时夏将至，故名也。夏至大约在阳历6月22日前后。',
            '夏天进补，冬病夏治，也是夏季养生保健的一项重要措施。',
            '饮食调养方面，历代养生家都认为夏三月的饮食宜清补。《吕氏春秋尽数篇》指出：',
            '夏季是多汗的季节，中医认为此时宜多食酸味，以固表，多食咸味以补心。',
            '冷食瓜果当适可而止，不可过食，以免损伤脾胃；厚味肥腻之品宜少勿多，以免化热生风，激发疔疮之疾。'
          ],
          imgUrl: [require('../../../assets/visualScreenImg/jieqiImg/xiazhi.png'), require('../../../assets/visualScreenImg/jieqiImg/xiazhi1.png')]
        },
        {
          name: '小暑',
          list: [
            '斗指辛为小暑，斯时天气已热，尚未达于极点，故名也。每年阳历7月7日左右为小暑。',
            '小暑时节，注意劳逸结合，注意防暑降温。',
            '小暑是消化道疾病多发季节，在饮食调养上要改变饮食不节、不洁、偏嗜等不良习惯，以适量为宜。'
          ],
          imgUrl: [require('../../../assets/visualScreenImg/jieqiImg/xiaoshu.png'), require('../../../assets/visualScreenImg/jieqiImg/xiaoshu1.png')]
        },
        {
          name: '大暑',
          list: [
            '斗指丙为大暑，斯时天气甚烈于小暑，故名曰大暑。大暑时值每年的阳历7月23日左右。',
            '夏令气候炎热，易伤津耗气，因此常可选用药粥滋补身体。《医药六书》赞“粳米粥为资生化育坤丹，糯米粥为温养胃气妙品。”',
            '酒、汤、果汁等都可称为饮品，合理选用都能对人体起到很好的强身健体的作用。盛夏阳热下降，水气上腾，湿气充斥，故在此季节，感受湿邪者较多。',
            '在中医学中，湿为阴邪，其性趋下，重浊粘滞，易阻遏气机，损伤阳气，食疗药膳以清热解暑为宜。'
          ],
          desc: '夏令气候炎热，易伤津耗气，因此常可选用药粥滋补身体。《医药六书》赞“粳米粥为资生化育坤丹，糯米粥为温养胃气妙品。”',
          shiju: '大暑之日腐草為螢，又五日土潤溽暑，又五日大雨時行',
          imgUrl: [require('../../../assets/visualScreenImg/jieqiImg/dashu.png'), require('../../../assets/visualScreenImg/jieqiImg/dashu1.png')]
        },
        {
          name: '立秋',
          list: [
            '斗指西南维为立秋，阴意出地始杀万物，按秋训禾，谷熟也。立秋时值公历8月7日前后。',
            '《素问 脏气法时论》说：“肺主秋……肺欲收，急食酸以收之，用酸补之，辛泻之。',
            '酸味收敛肺气，辛味发散泻肺，秋天宜收不宜散，所以要尽量少吃葱、姜等辛味之品，适当多食酸味果蔬。',
            '秋季时节，可适当食用芝麻、糯米、粳米、蜂蜜、枇杷、菠萝、乳品等柔润食物，以益胃生津。'
          ],
          imgUrl: [require('../../../assets/visualScreenImg/jieqiImg/chunfen.png'), require('../../../assets/visualScreenImg/jieqiImg/chunfen1.png')]
        },
        {
          name: '处暑',
          list: [
            '斗指戎为处暑，暑将退，伏而潜处，故名也。处暑节气时值每年阳历8月23日前后。',
            '秋天雨水渐少，天气逐渐干燥，在饮食上有所禁忌也可预防秋燥。',
            '首先多喝开水、淡茶、果汁饮料、牛奶等，秋燥最容易伤人的津液，多数蔬菜、水果蔬菜、水果等含有大量的水分，能补充人体的津液，有生津润燥、消热通便之功效。',
            '适宜的膳食有芝麻菠菜、青椒拌豆腐等，有补肝益肾、开胸润燥、益气宽中、安神养心的功效。'
          ],
          desc: '处暑适宜的膳食有芝麻菠菜、青椒拌豆腐等，有补肝益肾、开胸润燥、益气宽中、安神养心的功效。',
          shiju: '處暑之日鷹乃祭鳥，又五日天地始肅，又五日禾乃登',
          imgUrl: [require('../../../assets/visualScreenImg/jieqiImg/chushu.png'), require('../../../assets/visualScreenImg/jieqiImg/chushu1.png')]
        },
        {
          name: '白露',
          list: [
            '斗指癸为白露，阴气渐重，凝而为露，故名白露。白露时值每年阳历9月8日左右。',
            '白露节气，早晚气温低，一早一晚要多添衣服。',
            '白露时节，预防秋燥的方法最好是适当地多服一些富含维生素的食品，也可选用一些宣肺化痰、滋阴益气的中药，如人参、沙参、西洋参、百合、杏仁、川贝等，对缓解秋燥多有良效。',
            '白露时天气开始转凉，在饮食调节上更要慎重，宜食清淡、易消化且富含维生素的食物。'
          ],
          desc: '白露时节，预防秋燥的方法最好是适当地多服一些富含维生素的食品，也可选用一些宣肺化痰、滋阴益气的中药，如人参、沙参、西洋参、百合、杏仁、川贝等，对缓解秋燥多有良效。',
          shiju: '白露之日鴻雁來，又五日玄鳥歸，又五日群鳥養羞',
          imgUrl: [require('../../../assets/visualScreenImg/jieqiImg/bailu.png'), require('../../../assets/visualScreenImg/jieqiImg/bailu1.png')]
        },
        {
          name: '秋分',
          list: [
            '斗指己为秋分，南北两半球昼夜均分，又适当秋之半，故名也。秋分时节时值9月23日前后。',
            '秋分以后，气候渐凉，是胃病的多发与复发季节，胃肠道对寒冷敏感，如防护不当、不注意饮食和生活规律，就会引发胃肠道疾病，所以患有慢性胃炎的人，要注意胃部保暖，以防腹部着凉引发胃痛或加重旧病。',
            '《素问 上古天真论》所说的：“其知道者，法于阴阳，和于术数，饮食有节”字食物搭配和饮食调剂方面，中医也是注重调和阴阳的。',
            '枸杞、淮山、茯苓、丁香、豆蔻、桂皮之类，当药食调配得当时，可提高食品保健强身和防止疾病的功效。'
          ],
          imgUrl: [require('../../../assets/visualScreenImg/jieqiImg/qiufen.png'), require('../../../assets/visualScreenImg/jieqiImg/qiufen1.png')]
        },
        {
          name: '寒露',
          list: [
            '斗指甲为寒露，斯时露寒冷而将欲凝结，故名寒露也。寒露时值每年阳历的10月8号前后。',
            '自古秋为金秋，肺在五行中属金。寒露节气的饮食调养应以滋阴润燥肺为宜。',
            '应多食用芝麻、糯米、乳制品等柔润食物，同时增加鸭、牛肉、猪肝、大枣、山药等以增加体质；少食辛辣之品，如辣椒、生姜、葱、蒜类。'
          ],
          imgUrl: [require('../../../assets/visualScreenImg/jieqiImg/hanlu.png'), require('../../../assets/visualScreenImg/jieqiImg/hanlu1.png')]
        },
        {
          name: '霜降',
          list: [
            '斗指巳为霜降，气肃，露凝结为霜而下降，故名霜降也。霜降节气在每年的阳历10月23日左右。',
            '霜降节气是秋天的最后一个节气，按中医理论，此节气为脾脏功能处于旺盛时期，由于脾胃功能过于旺盛，易导致胃病的发生。',
            '饮食要多样，粗细要搭配，宜多食甘薯、鲜果、豆制品及海藻类食品。',
            '此节气适宜的水果膳食有梨、苹果、橄榄、白果、洋葱、芥菜、白果萝卜粥等，有生津润燥、清热化痰、止咳平喘、固肾补肺的功效。'
          ],
          imgUrl: [require('../../../assets/visualScreenImg/jieqiImg/shuangjiang.png'), require('../../../assets/visualScreenImg/jieqiImg/shuangjiang1.png')]
        },
        {
          name: '立冬',
          list: [
            '斗指西北维为立冬，冬者终也，立冬之时，万物终成，故名立冬也。立冬节气时值每年阳历的11月1日左右。',
            '立冬是人们进补的最佳时期，民间有“入冬日补冬”的食俗，补充身体营养食人参、鹿茸、狗肉、羊肉及鸡鸭炖八珍等。',
            '立冬后的起居调养，切记“养藏”二字。',
            '有的放矢的食用一些滋阴潜阳、热量较高的膳食为宜，同时也要多吃新鲜蔬菜以避免维生素的缺乏。'
          ],
          imgUrl: [require('../../../assets/visualScreenImg/jieqiImg/chunfen.png'), require('../../../assets/visualScreenImg/jieqiImg/chunfen1.png')]
        },
        {
          name: '小雪',
          list: [
            '斗指己，斯时天已积阴，寒未深而雪未大，故名小雪。小雪在每年的阳历11月22日前后。',
            '小雪节气后天气一般常是阴冷晦暗的，要适当减少户外活动，避免阳气的消耗。',
            '医学家孙思邈在《千金要方食治篇》中说：“食能祛邪而安脏腑，悦神，爽志，以资气血。”',
            '适宜的水果膳食有香蕉、革莽、芹菜炒香菇、玫瑰烤羊心、芝麻兔等，有补心解郁、清肺止咳的功效。'
          ],
          imgUrl: [require('../../../assets/visualScreenImg/jieqiImg/chunfen.png'), require('../../../assets/visualScreenImg/jieqiImg/chunfen1.png')]
        },
        {
          name: '大雪',
          list: [
            '斗指甲，斯时积阴为雪，至此栗烈而大，过于小雪，故名大雪也。大雪时节时值每年阳历的12月7日前后。',
            '从中医养生学的角度看，大雪已到了“进补”的大好时节。在进行调养时应采取动静结合、劳逸结合、补泻结合、形神共养的方法。',
            '大雪时节多食羊肉、牛肉、狗肉、鸡肉、鹌鹑、大蒜、辣椒、生姜、香菜、洋葱、花山药、桂圆、栗子及杏脯等性属温热的食物，也有助于御寒。'
          ],
          desc: '夏令气候炎热，易伤津耗气，因此常可选用药粥滋补身体。《医药六书》赞“粳米粥为资生化育坤丹，糯米粥为温养胃气妙品。”',
          shiju: '大暑之日腐草為螢，又五日土潤溽暑，又五日大雨時行',
          imgUrl: [require('../../../assets/visualScreenImg/jieqiImg/chunfen.png'), require('../../../assets/visualScreenImg/jieqiImg/chunfen1.png')]
        },
        {
          name: '冬至',
          list: [
            '斗指戎，斯时阴气始至明，阳气之至，日行南至，北半球昼最短，夜最长也。冬至时节时值每年阳历12月22或23日。',
            '俗话话：“吃了冬至饭，一天长一线”，立冬至立春，是“进补”的最佳时期。',
            '按照传统的中医理论，滋补通常可分为四类：即补气、补血、补阴、补阳。因此，冬令进补应注意“有的放矢”，切莫“多多益善”。'
          ],
          imgUrl: [require('../../../assets/visualScreenImg/jieqiImg/chunfen.png'), require('../../../assets/visualScreenImg/jieqiImg/chunfen1.png')]
        },
        {
          name: '小寒',
          list: [
            '斗指戊为小寒，时天气渐寒，尚未大寒，故名小寒。小寒时节时值每年阳历的1月5日前后。',
            '在冬令进补时应食补、药补相结合，以温补为宜。',
            '常用补药有人参、黄芪 、阿胶、冬虫夏草、首乌、枸杞、当归等；食补要根据阴阳气血的偏盛偏衰，结合食物之性来选择羊肉、狗肉、猪肉、鸡肉、鸭肉、鳝鱼、甲鱼、鱿鱼和海虾等，其他食物如核桃仁、大枣、龙眼肉、芝麻、山药、莲子、百合、栗子等。',
            '适宜的膳食有山药羊肉汤、强肾狗肉汤、素炒三丝、丝瓜西红柿粥等，有补脾胃、温肾阳、健脾化滞、化痰止咳的功效。'
          ],
          imgUrl: [require('../../../assets/visualScreenImg/jieqiImg/chunfen.png'), require('../../../assets/visualScreenImg/jieqiImg/chunfen1.png')]
        },
        {
          name: '大寒',
          list: [
            '斗指癸为大寒，时大寒栗烈已极，故名大寒也。每年阳历的1月20日前后。',
            '《灵枢 本神》曰：“智者之养生也，必顺四时而适寒暑，和喜怒而安居处，节阴阳而调刚柔，如是僻邪不至，长生久视。”',
            '古有：“大寒大寒，防风御寒，早喝人参、黄芪酒，晚服杞菊地黄丸”。',
            '大寒时节适宜的膳食有当归生姜羊肉汤、红杞田七鸡、糖醋胡萝卜丝、牛奶粥等，有温中散寒、补虚益血、润肺通肠的功效。'
          ],
          desc: '古有：“大寒大寒，防风御寒，早喝人参、黄芪酒，晚服杞菊地黄丸”。',
          shiju: '大寒之日雞使乳，又五日鷲鳥厲疾，又五日水澤腹堅',
          imgUrl: [require('../../../assets/visualScreenImg/jieqiImg/chunfen.png'), require('../../../assets/visualScreenImg/jieqiImg/chunfen1.png')]
        }
      ],
      ul1: null,
      ul2: null,
      ulbox: null,
      jieqiyangshengList: [],
      imgUrl: null,
      imgUrl2: null,
      desc: null,
      shiju: null,
      dataList: [],
      classOption: {
        step: .3,
        limitMoveNum: 1,
        autoPlay: true
      }
    }
  },
  props: {
    jieqi: {
      type: String,
      default: null
    },
    shichenObj: {
      type: Object,
      default: {}
    }
  },
  watch: {
    jieqi(val) {
      this.jieQiList.map((item) => {
        if (val === item.name) {
          this.jieqiyangshengList = item.list
          this.imgUrl = item.imgUrl
          this.desc = item.desc
          this.shiju = item.shiju
        }
      })
    },
    shichenObj(newVal, oldVal) {
      if (newVal) {
        this.imgUrl2 = newVal.imgUrl2
      }
    }
  },
  mounted() {

  },
  destroyed() {

  },
  methods: {}
}
</script>
<style lang="scss" scoped>
::v-deep .el-carousel {
  width: 100%;
  height: 100%;
}

.container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  gap: 10px;

  .left,
  .right {
    width: 50%;
    height: 100%;

    .title {
      height: 44px;
      color: rgb(255, 255, 255);
      text-align: left;
      font-size: 24px;
      padding-left: 40px;
      padding-right: 0;
      line-height: 44px;
      background: url("../../../assets/visualScreenImg/screen_item_title.png") no-repeat 0 15px;
    }

    .content_box {
      height: calc(100% - 44px);
      box-sizing: border-box;
      padding: 10px 0;
      img{
        width: 100%;
        height: 100%;
      }
    }

    .warp {
      height: 100%;
      width: 100%;
      overflow: hidden;
    }

    .content {
      font-size: 16px;
      overflow: hidden;
      height: 100%;

      ul {
        padding: 0;
        list-style: none;

        li {
          display: block;
          line-height: 24px;
          margin-bottom: 20px;
          font-size: 24px;
          font-family: KaiTi;
          font-weight: 400;
          color: #FFFFFF;

          span {
            font-size: 16px;
            margin-right: 10px;
          }
        }
      }


    }
  }
}
</style>
