import Base from './Base'

/**
 * 5. 开方使用率
 * 确认开放点击次数/智能辨症次数
 * 展示方式：开方使用率：0.56（确认开方点击次数/智能辨症次数）
 */
class Prescription extends Base {

  constructor() {
    super('prescription');
    // 诊断集合
    this.zdList = [];
    // 开方集合
    this.kfList = [];
  }

  zd(data) {
    this.zdList.push(data);
  }

  kf(data) {
    this.kfList.push(data);
  }

  getData() {
    if(this.zdList.length < 1){
      return null;
    }
    return {
      zdList: this.zdList,
      kfList: this.kfList,
      p: this.kfList.length / this.zdList.length
    }
  }
}

let prescription = new Prescription();
export default prescription
