import request from "@/utils/request";

export function getVisualScreenData(data) {
  return request({
    url: `/screen/visual/info/${data}`,
    method: 'post',
  })
}

//疾病诊断量查询
export function getVisualHisDiagnosis(data) {
  return request({
    url: `/screen/visual/his/diagnosis`,
    method: 'get',
    data:data
  })
}

//科室排队人数查询
export function getVisualHisQueueStatusList(data) {
  return request({
    url: `/screen/visual/his/queueStatusList`,
    method: 'get',
    data:data
  })
}

//科室排队人数查询
export function getVisualHisDoctorInfoList(data) {
  return request({
    url: `/screen/visual/his/doctorInfoList`,
    method: 'get',
    data:data
  })
}

//近七日设备采集量
export function getsmfData(data) {
  return request({
    url: `/screen/visual/tracking/smf`,
    method: 'get',
    data:data
  })
}

//近七日设备采集量
export function gethisData(data) {
  return request({
    url: `/screen/visual/tracking/his`,
    method: 'get',
    data:data
  })
}
//公司同步中牟人民医院数据接口 query为apifox中接口排序
export function gethisDataSync(data,query) {
  return request({
    url: `/screen/visual/1lou/${query}`,
    method: 'get',
    data:data
  })
}
