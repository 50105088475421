<script>
import { generateToken } from "@/api/zkyz/aictm";
import { setToken } from "@/utils/auth";

export default {
  data() {
    return {};
  },
  mounted() {
    // let data = {
    //   docId: 1560,
    //   docName: "潘登辉",
    //   depId: 1160,
    //   depName: "住院测试",
    //   hospital: "东院区",
    //   role: "主治医师",
    // };
    let str = this.$route.query.data;
    let query = JSON.parse(str);
    console.log(query);
    generateToken(query).then((res) => {
      if (res.code === 200) {
        setToken(res.token);
        window.location.href = "http://10.10.2.150/aitcm";
      } else {
      }
    });
  },
};
</script>

<template></template>
