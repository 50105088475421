var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loadPopup
    ? _c(
        "div",
        {
          ref: "popup-container",
          staticClass: "popup-container",
          style: {
            position: _vm.fixed ? "fixed" : "absolute",
            overflow: _vm.overflow,
            top: _vm.top + "px",
            left: _vm.left + "px",
            width: _vm.width ? _vm.width + "px" : "auto",
            height: _vm.height ? _vm.height + "px" : "auto",
            transform: _vm.animation ? "scale(1)" : "scale(0)",
            visibility: _vm.show ? "visible" : "hidden",
            padding: _vm.padding,
            zIndex: _vm.zIndex || 99,
          },
          attrs: { id: _vm.popupId },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.click($event)
            },
            mouseup: function ($event) {
              return _vm.popupMouse("up", $event)
            },
          },
        },
        [
          _vm.showTitle
            ? _c(
                "div",
                {
                  staticClass: "top",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.click($event)
                    },
                    mousedown: function ($event) {
                      if ($event.target !== $event.currentTarget) {
                        return null
                      }
                      return _vm.popupMouse("down", $event)
                    },
                  },
                },
                [
                  _vm.menuImage
                    ? _c("el-image", {
                        staticClass: "icon",
                        attrs: { fit: "cover", src: _vm.menuImage },
                      })
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "title",
                      style: { color: _vm.titleColor },
                      on: {
                        mousedown: function ($event) {
                          if ($event.target !== $event.currentTarget) {
                            return null
                          }
                          return _vm.popupMouse("down", $event)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.title) + " ")]
                  ),
                  _vm.showClose
                    ? _c(
                        "div",
                        {
                          staticClass: "close",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.closeHandler()
                            },
                          },
                        },
                        [_vm._v("×")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          (_vm.showTitle && _vm.showClose) || _vm.fixedClose
            ? _c(
                "div",
                {
                  staticClass: "close",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.closeHandler()
                    },
                  },
                },
                [_vm._v(" × ")]
              )
            : _vm._e(),
          _vm._t("default"),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }