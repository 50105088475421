<template>
  <ScaleScreen
    :width="1920"
    :height="1080"
    class="scale-wrap"
    :selfAdaption="true"
  >
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <!-- 头部 s -->
        <div class="title_wrap">
          <div class="headline">
            <div>中科云诊中医智能管理平台</div>
          </div>
          <div class="timers">
            <div class="dateyear">
              <div class="clock_box"></div>
              <div>{{ dateYear }}</div>
            </div>
            <div class="timers_left">
              <div class="yinli_box">
                {{ YearInGanZhi }}年 {{ MonthInChinese }}月 {{ DayInChinese }}
                <span v-show="jieQi">今日{{ jieQi }}</span>
                <span v-show="!jieQi">{{ lastJieQi }}</span>
              </div>
              <div class="yinli_box">
                动静结合、劳逸结合、补泻结合、形神共养
              </div>
            </div>
          </div>
          <div class="skip" @click="toVisualScreen"></div>
        </div>
        <!-- 头部 e-->
        <!-- 内容  s-->
        <div class="content">
          <div class="left">
            <div class="topbox">
              <div class="topbox-mid">
                <div class="item">
                  <itemTitle :title="'院内就诊统计'"></itemTitle>
                  <div class="com-box">
                    <rightCenter />
                  </div>
                </div>
              </div>
              <div class="topbox-top">
                <div class="item">
                  <itemTitle :title="'中医处方质控统计'"></itemTitle>
                  <div class="com-box">
                    <rightTop />
                  </div>
                </div>
              </div>
              <div class="topbox-bot">
                <div class="item">
                  <itemTitle :title="'就诊趋势'"></itemTitle>
                  <div class="com-box">
                    <rightBottom />
                  </div>
                </div>
              </div>
            </div>
            <div class="bottombox">
              <div class="bottombox-bot">
                <div class="item">
                  <itemTitle :title="'高发疾病统计'"></itemTitle>
                  <div class="com-box">
                    <rightBottomTwo />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="centre">
            <div class="centre-top">
              <div style="height: 100%">
                <CenterMap></CenterMap>
                <div class="yuanpan">
                  <img
                    class="rotate zindex1"
                    src="../../assets/qualityConyrolScreen/one_spin1.png"
                    alt=""
                  />
                  <img
                    class="rotate zindex1 rotate-high"
                    src="../../assets/qualityConyrolScreen/one_spin2.png"
                    alt=""
                  />
                  <img
                    class="fixed zindex1"
                    src="../../assets/qualityConyrolScreen/one_fiexd.png"
                    alt=""
                  />
                  <img
                    class="fixed zindex2"
                    src="../../assets/qualityConyrolScreen/two_fiexd.png"
                    alt=""
                  />
                  <img
                    class="rotate zindex2 rotate-low"
                    src="../../assets/qualityConyrolScreen/two_spin.png"
                    alt=""
                  />
                  <img
                    class="rotate zindex3"
                    src="../../assets/qualityConyrolScreen/three_spin1.png"
                    alt=""
                  />
                  <img
                    class="rotate zindex3 rotate-low"
                    src="../../assets/qualityConyrolScreen/three_spin2.png"
                    alt=""
                  />
                  <img
                    class="fixed zindex3"
                    src="../../assets/qualityConyrolScreen/three_fixed.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="centre-mid">
              <div class="item" style="height: 100%">
                <itemTitle :title="'中医智能诊断报告'"></itemTitle>
                <div class="com-box">
                  <CenterBottom
                    :jieqi="lastJieQi"
                    :shichenObj="shichenObj"
                  ></CenterBottom>
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="topbox">
              <div class="topbox-top">
                <div class="item">
                  <itemTitle :title="'中医智能系统概况'"></itemTitle>
                  <div class="com-box">
                    <leftTop />
                  </div>
                </div>
              </div>
              <div class="topbox-mid">
                <div class="item">
                  <itemTitle :title="'中医智能平台使用情况'"></itemTitle>
                  <div class="com-box">
                    <leftCenter />
                  </div>
                </div>
              </div>
              <div class="topbox-bot">
                <div class="item">
                  <itemTitle :title="'中医知识库学习情况'"></itemTitle>
                  <div class="com-box">
                    <leftCenterTwo />
                  </div>
                </div>
              </div>
            </div>
            <div class="bottombox">
              <div class="bottombox-bot">
                <div class="item">
                  <itemTitle :title="'医生简介'"></itemTitle>
                  <div class="com-box">
                    <leftBottom />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 内容 e -->
      </div>
    </div></ScaleScreen
  >
</template>

<script>
import ScaleScreen from "@/components/scale-screen/scale-screen.vue";

import leftTop from "./components/left-top.vue";
import leftCenterTwo from "./components/left-center-two.vue";
import leftCenter from "./components/left-center.vue";
import leftBottom from "./components/left-bottom.vue";
import CenterMap from "./components/center-map.vue";
import CenterBottom from "./components/center-bottom.vue";
import rightTop from "./components/right-top.vue";
import rightBottom from "./components/right-bottom.vue";
import rightCenter from "./components/right-center.vue";
import rightBottomTwo from "@/views/qualityControlScreen/components/right-bottom-two.vue";
import itemTitle from "@/views/qualityControlScreen/components/item_title.vue";
import { screenTime } from "@/utils";
import { Lunar, LunarUtil } from "@/utils/lunar";

export default {
  components: {
    ScaleScreen,
    leftTop,
    leftCenter,
    leftCenterTwo,
    leftBottom,
    CenterMap,
    CenterBottom,
    rightTop,
    rightBottom,
    rightCenter,
    rightBottomTwo,
    itemTitle,
  },
  data() {
    return {
      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      YearInGanZhi: null, //干支年
      MonthInChinese: null, //农历月
      DayInChinese: null, //农历初几
      jieQi: null, //今日节气
      lastJieQi: null, //上一个节气
      shichen: null,
      shichenObj: null,
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
    };
  },
  filters: {},
  computed: {},
  created() {},
  mounted() {
    this.timeFn();
    this.cancelLoading();
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = screenTime(new Date(), "HH:mm");
        this.dateYear = screenTime(new Date(), "yyyy.MM.dd");
        this.dateWeek = this.weekday[new Date().getDay()];
        let d = Lunar.fromDate(new Date());
        this.YearInGanZhi = d.getYearInGanZhiByLiChun();
        this.MonthInChinese = d.getMonthInChinese();
        this.DayInChinese = d.getDayInChinese();
        this.jieQi = d.getJieQi();
        let prev = d.getPrevJieQi();
        this.lastJieQi = prev.getName();
        this.shichen = LunarUtil.convertTime(this.dateDay) + "时";
      }, 1000);
    },
    toVisualScreen() {
      this.$router.push({ name: "中牟人民医院智慧平台" });
    },
    cancelLoading() {
      let timer = setTimeout(() => {
        this.loading = false;
        clearTimeout(timer);
      }, 500);
    },
    shichenData(e) {
      this.shichenObj = e;
    },
  },
};
</script>
<style scoped lang="scss">
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  /* 初始状态为不旋转 */
  100% {
    transform: rotate(360deg);
  }
  /* 结束状态为完全旋转一周（360度）*/
}

@keyframes rotate_reverse {
  0% {
    transform: rotate(0deg);
  }
  /* 初始状态为不旋转 */
  100% {
    transform: rotate(-360deg);
  }
  /* 结束状态为完全旋转一周（360度）*/
}

.scale-wrap {
  color: #d3d6dd;

  .bg {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-image: url("../../assets/qualityConyrolScreen/pageBg.png");
    background-size: cover;
    //background-position: center center;
  }

  .host-body {
    height: 100%;

    .com-box {
      height: calc(100% - 44px);
      width: 100%;
    }

    .title_wrap {
      height: 114px;
      background-image: url("../../assets/qualityConyrolScreen/title_bg.png");
      background-size: cover;
      background-position: center center;
      position: relative;

      .timers {
        height: 60px;
        position: absolute;
        left: 0;
        top: 7px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .dateyear {
          color: #c2e7f3;
          font-size: 30px;
          font-family: Bebas Neue;
          font-weight: 400;
          line-height: 30px;
          display: flex;
          align-items: center;

          .clock_box {
            background-image: url("../../assets/visualScreenImg/clock.png");
            background-repeat: no-repeat;
            background-position: -15px -8px;
            width: 30px;
            height: 30px;
          }
        }

        .timers_left {
          color: #c2e7f3;
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          .yinli_box {
            font-size: 16px;
            font-weight: normal;
            line-height: 20px;
            height: 20px;
          }
        }
      }

      .headline {
        font-size: 44px;
        font-family: DOUYU;
        font-weight: 700;
        color: #f9cb9d;
        line-height: 80px;
        width: 769px;
        height: 80px;
        text-align: center;
        position: absolute;
        left: calc(50% - 385px);
        background: linear-gradient(0deg, #e0edf4 0%, #14c7ec 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .skip {
        height: 60px;
        width: 200px;
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    .content {
      padding: 0 10px;
      height: calc(100% - 114px);
      display: flex;
      justify-content: space-between;

      .left,
      .right {
        width: 27%;
        height: calc(100% + 34px);
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        transform: translateY(-34px);

        .topbox {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          flex: 1;

          .topbox-top,
          .topbox-bot,
          .topbox-mid {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .item {
              width: 100%;
              height: 100%;
            }
          }
        }

        .bottombox {
          height: 35%;

          .bottombox-bot {
            height: 100%;

            .item {
              height: 100%;
            }
          }
        }
      }

      .left {
        .topbox-top {
          height: 20%;
        }

        .topbox-bot {
          height: 30%;
        }

        .topbox-mid {
          height: 50%;
        }
      }

      .right {
        .bottombox {
          padding-top: 10px;
        }

        .topbox-top {
          height: 30%;
        }

        .topbox-bot {
          height: 35%;
        }

        .topbox-mid {
          height: 35%;
        }
      }

      .right-mid,
      .right-top,
      .right-bot {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .item {
          width: 100%;
        }
      }

      .right-top {
        height: 10%;
      }

      .right-mid {
        height: 30%;
      }

      .right-bot {
        height: 30%;
      }

      .centre {
        flex: 1;
        width: 720px;
        box-sizing: border-box;
        padding: 0 20px;

        .data_box {
          width: 300px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 5px;
          position: absolute;
          left: 0;
          bottom: 0;
          font-size: 14px;
        }

        .centre-top {
          position: relative;
          height: 65%;

          .yuanpan {
            position: absolute;
            width: 540px;
            height: 540px;
            transform: rotateX(70deg);
            top: 300px;
            left: calc(50% - 270px);
            z-index: 0;
            img {
              width: 540px;
              height: 540px;
              -webkit-user-drag: none;
            }

            .rotate {
              position: absolute;
              animation-name: rotate; /* 指定要应用的动画名称 */
              animation-duration: 60s; /* 设置动画持续时间 */
              animation-iteration-count: infinite; /* 无限次重复播放动画 */
              animation-timing-function: linear;
            }

            .rotate-low {
              animation-duration: 120s; /* 设置动画持续时间 */
              animation-name: rotate_reverse; /* 指定要应用的动画名称 */
            }

            .rotate-high {
              animation-duration: 20s; /* 设置动画持续时间 */
              animation-name: rotate_reverse; /* 指定要应用的动画名称 */
            }

            .fixed {
              position: absolute;
            }

            .zindex1 {
              z-index: 1;
            }

            .zindex2 {
              z-index: 2;
            }

            .zindex3 {
              z-index: 3;
            }
          }
        }

        .centre-mid {
          height: 35%;
        }
      }

      .right {
        .right-mid {
        }
      }
    }
  }
}
</style>
