var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "popup-wrap" }, [
    !_vm.rows
      ? _c(
          "div",
          [
            _c(
              "el-form",
              {
                ref: "queryForm",
                attrs: {
                  model: _vm.form,
                  "label-position": "left",
                  "label-width": "auto",
                  size: "small",
                  inline: true,
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "搜索条件：", prop: "searchKey" } },
                  [
                    _c(
                      "div",
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "姓名/手机号/身份证号",
                            size: "mini",
                            clearable: "",
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.query($event)
                            },
                          },
                          model: {
                            value: _vm.form.searchKey,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "searchKey", $$v)
                            },
                            expression: "form.searchKey",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "开始时间：", prop: "startTime" } },
                  [
                    _c("el-date-picker", {
                      staticClass: "long-width",
                      attrs: {
                        type: "date",
                        "start-placeholder": "选择日期",
                        "value-format": "yyyy-MM-dd",
                        size: "mini",
                      },
                      model: {
                        value: _vm.form.startTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "startTime", $$v)
                        },
                        expression: "form.startTime",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "结束时间：", prop: "endTime" } },
                  [
                    _c("el-date-picker", {
                      staticClass: "long-width",
                      attrs: {
                        type: "date",
                        "start-placeholder": "选择日期",
                        "value-format": "yyyy-MM-dd",
                        size: "mini",
                      },
                      model: {
                        value: _vm.form.endTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "endTime", $$v)
                        },
                        expression: "form.endTime",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        attrs: { icon: "el-icon-search", size: "mini" },
                        on: { click: _vm.query },
                      },
                      [_vm._v("搜索")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        attrs: { icon: "el-icon-refresh", size: "mini" },
                        on: { click: _vm.resetQuery },
                      },
                      [_vm._v("重置")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                attrs: { data: _vm.dataList },
              },
              [
                _vm._v("\\ "),
                _c("el-table-column", {
                  attrs: {
                    label: "序号",
                    width: "100",
                    align: "center",
                    type: "index",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "姓名",
                    align: "center",
                    prop: "name",
                    "show-overflow-tooltip": true,
                  },
                }),
                _c("el-table-column", {
                  attrs: { label: "性别", align: "center", prop: "gender" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(scope.row.gender == "M" ? "男" : "女")
                              ),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3142027298
                  ),
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "年龄",
                    align: "center",
                    prop: "age",
                    "show-overflow-tooltip": true,
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "手机号",
                    align: "center",
                    prop: "cellphone",
                    "show-overflow-tooltip": true,
                  },
                }),
                _c("el-table-column", {
                  attrs: { label: "身份证号", align: "center" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [_c("div", [_vm._v(_vm._s(scope.row.idcard))])]
                        },
                      },
                    ],
                    null,
                    false,
                    151248552
                  ),
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "上次就诊时间",
                    align: "center",
                    prop: "comeDate",
                    "show-overflow-tooltip": true,
                  },
                }),
                _c("el-table-column", {
                  attrs: { label: "状态", align: "center" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.status == 0
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-tag",
                                      { attrs: { type: "danger" } },
                                      [_vm._v("暂存")]
                                    ),
                                  ],
                                  1
                                )
                              : scope.row.status == null ||
                                scope.row.status == 1
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-tag",
                                      { attrs: { type: "success" } },
                                      [_vm._v("已开方")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3243326372
                  ),
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "操作",
                    align: "center",
                    "class-name": "small-padding fixed-width",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.viewDetails(scope.row)
                                  },
                                },
                              },
                              [_vm._v("详情")]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    906001357
                  ),
                }),
              ],
              1
            ),
            _c("pagination", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.total > 0,
                  expression: "total > 0",
                },
              ],
              attrs: {
                total: _vm.total,
                page: _vm.form.pageNum,
                limit: _vm.form.pageSize,
              },
              on: {
                "update:page": function ($event) {
                  return _vm.$set(_vm.form, "pageNum", $event)
                },
                "update:limit": function ($event) {
                  return _vm.$set(_vm.form, "pageSize", $event)
                },
                pagination: _vm.query,
              },
            }),
          ],
          1
        )
      : _c("div", [
          _c("div", { staticClass: "flex" }, [
            _c(
              "div",
              {
                staticClass: "back",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.backTable($event)
                  },
                },
              },
              [_vm._v("返回列表")]
            ),
            _vm.rows.status == 0
              ? _c(
                  "div",
                  { staticClass: "button", on: { click: _vm.reuseBl } },
                  [_vm._v("复用")]
                )
              : _vm._e(),
          ]),
          _c("table", { attrs: { cellspacing: "0", cellpadding: "0" } }, [
            _c("tr", [
              _c("td", { staticClass: "title" }, [_vm._v("病历号")]),
              _c("td", { staticClass: "value", attrs: { colspan: "7" } }, [
                _vm._v(_vm._s(_vm.rows.billno)),
              ]),
            ]),
            _c("tr", [
              _c("td", { staticClass: "title" }, [_vm._v("名称")]),
              _c("td", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.rows.name)),
              ]),
              _c("td", { staticClass: "title" }, [_vm._v("性别")]),
              _c("td", { staticClass: "value" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.rows.gender == "F"
                        ? "女"
                        : _vm.rows.gender == "M"
                        ? "男"
                        : ""
                    ) +
                    " "
                ),
              ]),
              _c("td", { staticClass: "title" }, [_vm._v("年龄")]),
              _c("td", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.rows.age)),
              ]),
              _c("td", { staticClass: "title" }, [_vm._v("疾病信息")]),
              _c("td", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.rows.disease)),
              ]),
            ]),
            _c("tr", [
              _c("td", { staticClass: "title" }, [_vm._v("出生日期")]),
              _c("td", { staticClass: "value", attrs: { colspan: "3" } }, [
                _vm._v(_vm._s(_vm.rows.birthday)),
              ]),
              _c("td", { staticClass: "title" }, [_vm._v("手机号码")]),
              _c("td", { staticClass: "value", attrs: { colspan: "3" } }, [
                _vm._v(_vm._s(_vm.rows.cellphone)),
              ]),
            ]),
            _c("tr", [
              _c("td", { staticClass: "title" }, [_vm._v("发病日期")]),
              _c("td", { staticClass: "value", attrs: { colspan: "3" } }, [
                _vm._v(_vm._s(_vm.rows.happenDate)),
              ]),
              _c("td", { staticClass: "title" }, [_vm._v("就诊日期")]),
              _c("td", { staticClass: "value", attrs: { colspan: "7" } }, [
                _vm._v(_vm._s(_vm.rows.comeDate)),
              ]),
            ]),
            _c("tr", [
              _c("td", { staticClass: "title" }, [_vm._v("身份证号")]),
              _c("td", { staticClass: "value", attrs: { colspan: "3" } }, [
                _vm._v(_vm._s(_vm.rows.idcard)),
              ]),
              _c("td", { staticClass: "title" }, [_vm._v("就诊卡号")]),
              _c("td", { staticClass: "value", attrs: { colspan: "3" } }, [
                _vm._v(" " + _vm._s(_vm.rows.medicalCardNo) + " "),
              ]),
            ]),
            _c("tr", [
              _c("td", { staticClass: "title" }, [_vm._v("主诉")]),
              _c("td", { staticClass: "value", attrs: { colspan: "3" } }, [
                _vm._v(" " + _vm._s(_vm.rows.zhusu) + " "),
              ]),
              _c("td", { staticClass: "title" }, [_vm._v("左脉-寸")]),
              _c("td", { staticClass: "value", attrs: { colspan: "1" } }, [
                _vm._v(" " + _vm._s(_vm.leftMai["寸"].toString()) + " "),
              ]),
              _c("td", { staticClass: "title" }, [_vm._v("右脉-寸")]),
              _c("td", { staticClass: "value", attrs: { colspan: "1" } }, [
                _vm._v(" " + _vm._s(_vm.rightMai["寸"].toString()) + " "),
              ]),
            ]),
            _c("tr", [
              _c("td", { staticClass: "title" }, [_vm._v("既往史")]),
              _c("td", { staticClass: "value", attrs: { colspan: "3" } }, [
                _vm._v(_vm._s(_vm.rows.jiwangshi)),
              ]),
              _c("td", { staticClass: "title" }, [_vm._v("左脉-关")]),
              _c("td", { staticClass: "value", attrs: { colspan: "1" } }, [
                _vm._v(" " + _vm._s(_vm.leftMai["关"].toString()) + " "),
              ]),
              _c("td", { staticClass: "title" }, [_vm._v("右脉-关")]),
              _c("td", { staticClass: "value", attrs: { colspan: "1" } }, [
                _vm._v(" " + _vm._s(_vm.rightMai["关"].toString()) + " "),
              ]),
            ]),
            _c("tr", [
              _c("td", { staticClass: "title" }, [_vm._v("家族史")]),
              _c("td", { staticClass: "value", attrs: { colspan: "3" } }, [
                _vm._v(_vm._s(_vm.rows.jiazushi)),
              ]),
              _c("td", { staticClass: "title" }, [_vm._v("左脉-尺")]),
              _c("td", { staticClass: "value", attrs: { colspan: "1" } }, [
                _vm._v(" " + _vm._s(_vm.leftMai["尺"].toString()) + " "),
              ]),
              _c("td", { staticClass: "title" }, [_vm._v("右脉-尺")]),
              _c("td", { staticClass: "value", attrs: { colspan: "1" } }, [
                _vm._v(" " + _vm._s(_vm.rightMai["尺"].toString()) + " "),
              ]),
            ]),
            _c("tr", [
              _c("td", { staticClass: "title" }, [_vm._v("现病史")]),
              _c("td", { staticClass: "value", attrs: { colspan: "3" } }, [
                _vm._v(" " + _vm._s(_vm.rows.xianbingshi) + " "),
              ]),
              _c("td", { staticClass: "title" }, [_vm._v("舌象")]),
              _c(
                "td",
                { staticClass: "value", attrs: { colspan: "3" } },
                [
                  _vm._v(" " + _vm._s(_vm.rows.tongueInfo) + " "),
                  _vm.shefileUrl
                    ? _c("i", {
                        staticClass: "el-icon-picture",
                        on: { click: _vm.preView },
                      })
                    : _vm._e(),
                  _c("el-image", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: false,
                        expression: "false",
                      },
                    ],
                    ref: "preview",
                    staticStyle: { width: "100px", height: "100px" },
                    attrs: {
                      src: _vm.shefileUrl,
                      "preview-src-list": [_vm.shefileUrl],
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("tr", [
              _c("td", { staticClass: "title" }, [_vm._v("刻下症")]),
              _c("td", { staticClass: "value", attrs: { colspan: "7" } }, [
                _vm._v(" " + _vm._s(_vm.rows.cardinalSymptom) + " "),
              ]),
            ]),
            _c("tr", [
              _c("td", { staticClass: "title" }, [_vm._v("药方")]),
              _c("td", { staticClass: "value", attrs: { colspan: "7" } }, [
                _vm._v(" " + _vm._s(_vm.rows.prescriptionDetail) + " "),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.rows.prescriptionZcStd,
                        expression: "rows.prescriptionZcStd",
                      },
                    ],
                    staticClass: "reuse-btn",
                    on: { click: _vm.reuseYaofang },
                  },
                  [_vm._v("复用")]
                ),
              ]),
            ]),
            _c("tr", [
              _c("td", { staticClass: "title" }, [_vm._v("医生评价")]),
              _c("td", { staticClass: "value", attrs: { colspan: "7" } }, [
                _vm._v(
                  " " + _vm._s(_vm.docmarkDict[_vm.rows.docmark] || "-") + " "
                ),
              ]),
            ]),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }