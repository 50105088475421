var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "item_title" }, [_vm._v(" 视频 ")]),
        _c("div", { staticClass: "item_num" }, [
          _c("img", {
            attrs: {
              src: require("../../../assets/qualityConyrolScreen/left_top_video.png"),
              alt: "",
            },
          }),
          _c("span", [_vm._v("623个")]),
        ]),
      ]),
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "item_title" }, [_vm._v(" 文献 ")]),
        _c("div", { staticClass: "item_num" }, [
          _c("img", {
            attrs: {
              src: require("../../../assets/qualityConyrolScreen/left_top_audio.png"),
              alt: "",
            },
          }),
          _c("span", [_vm._v("3817部")]),
        ]),
      ]),
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "item_title" }, [_vm._v(" 医案 ")]),
        _c("div", { staticClass: "item_num" }, [
          _c("img", {
            attrs: {
              src: require("../../../assets/qualityConyrolScreen/left_top_yian.png"),
              alt: "",
            },
          }),
          _c("span", [_vm._v("826例")]),
        ]),
      ]),
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "item_title" }, [_vm._v(" 医生 ")]),
        _c("div", { staticClass: "item_num" }, [
          _c("img", {
            attrs: {
              src: require("../../../assets/qualityConyrolScreen/left_top_doctor.png"),
              alt: "",
            },
          }),
          _c("span", [_vm._v("497名")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }