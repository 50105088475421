var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "home" }, [
    _c("div", { staticClass: "page" }, [
      _c("img", {
        attrs: { src: require("../assets/images/zhongkeyunzhen.png"), alt: "" },
      }),
      _c("div", { staticClass: "page-title" }, [
        _vm._v("中医智能辅助诊断系统"),
      ]),
      _c("div", { staticClass: "hello" }, [_vm._v("欢迎您")]),
      _c("img", {
        staticClass: "begin-btn",
        attrs: { src: require("../assets/images/begin-btn.png"), alt: "" },
        on: {
          click: function ($event) {
            return _vm.goAitcm()
          },
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }