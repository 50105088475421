import Base from './Base'

/**
 * 1. 在线时长
 * 从医生登录页面开始计时，在页面操作开始计时
 * 定时间页面无操作停止计时（比如30s），点击确认开方停止计时，关闭网页停止
 *
 */
class OnlineTime extends Base {

  constructor() {
    super('onlineTime');
    // 定时30s，无操作停止计时
    this.duration = 30 * 1000;
    // 在线时长(s)
    this.time = 0;
    // 定时器
    this.interval = null;
    // 活跃监听倒计时
    this.inactiveTimeout = null;
  }

  /**
   * 开始计时
   */
  start() {
    if (!this.interval) {
      this.interval = setInterval(() => {
        this.time++;
      }, 1000)
      super.start();
    }
    this.inactive();
  }

  inactive() {
    if(this.inactiveTimeout){
      clearTimeout(this.inactiveTimeout);
    }
    this.inactiveTimeout = setTimeout(() => {
      this.stop();
    }, this.duration)
  }

  stop() {
    if (!this.interval) {
      return;
    }
    clearInterval(this.interval);
    this.interval = false;
    super.stop();
  }

  getData() {
    return {
      onlineTime: this.time
    }
  }
}
let onlineTime = new OnlineTime();
export default onlineTime
