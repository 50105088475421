<template>
  <Echart
    :options="options"
    id="leftTop"
    style="width: 100%; height: 100%; margin: 0 auto"
    ref="lefttop"
  />
</template>

<script>
import { getsmfData, gethisDataSync } from "@/api/visualScreen";
import * as echarts from "echarts";
import { screenTime } from "@/utils";
export default {
  name: "leftTop",
  data() {
    return {
      timer: null,
      options: {},
    };
  },
  filters: {},
  created() {},
  mounted() {
    this.getData();
    this.timer = setInterval(() => {
      this.getData();
    }, 300000); // 每5秒发送一次请求
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    getData() {
      let params = {
        pageNum: 1,
        pageSize: 10,
      };
      const now = screenTime(new Date(), "MM/dd");
      // let arr = [
      //   {
      //     'name': now,
      //     'value': '13'
      //   },
      //   {
      //     'name': now,
      //     'value': '11'
      //   },
      //   {
      //     'name': now,
      //     'value': '1'
      //   },
      //   {
      //     'name': now,
      //     'value': '15'
      //   },
      //   {
      //     'name': now,
      //     'value': '8'
      //   },
      //   {
      //     'name': now,
      //     'value': '4'
      //   },
      //   {
      //     'name': now,
      //     'value': '17'
      //   }
      // ]
      // let nameList = arr.map(item => item.name)
      // let valueList = arr.map(item => item.value)
      // this.init(nameList, valueList)
      gethisDataSync(params, 5)
        .then((res) => {
          console.log(JSON.parse(res.data[0].data).data);

          let data = JSON.parse(res.data[0].data).data;

          let nameList = data.map((item) => item.name);
          let valueList = data.map((item) => item.value);
          this.init(nameList, valueList);
        })
        .catch((err) => {
          let arr = [
            {
              name: "1/26",
              value: "13",
            },
            {
              name: "1/27",
              value: "11",
            },
            {
              name: "1/28",
              value: "1",
            },
            {
              name: "1/29",
              value: "15",
            },
            {
              name: "1/30",
              value: "8",
            },
            {
              name: "1/31",
              value: "4",
            },
            {
              name: "2/01",
              value: "17",
            },
          ];
          let nameList = arr.map((item) => item.name);
          let valueList = arr.map((item) => item.value);
          this.init(nameList, valueList);
        });
    },
    init(nameList, valueList) {
      this.options = {
        title: {
          text: "",
          textStyle: {
            color: "#fff",
            fontSize: 15,
          },
        },
        grid: {
          left: "7%",
          right: "1%",
          top: "12%",
          bottom: "10%",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#fff",
            },
          },
        },
        legend: {
          show: false,
          data: ["采集量"],
          textStyle: {
            color: "#fff",
            fontSize: 12,
            rich: {
              a: {
                color: "#fff",
              },
            },
          },
          formatter: function (name) {
            return name;
          },
        },
        xAxis: [
          {
            type: "category",
            data: nameList,
            axisPointer: {
              type: "shadow",
            },
            color: "#fff",
            axisLabel: {
              interval: "0",
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "",
            interval: 10,
            axisLabel: {
              formatter: "{value}",
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "RGBA(59, 60, 64, 1)",
                type: [5, 10],
                dashOffset: 5,
                width: "1",
              },
            },
          },
        ],
        series: [
          {
            name: "采集量",
            type: "line",
            tooltip: {
              valueFormatter: function (value) {
                return value;
              },
            },
            lineStyle: {
              width: 3,
            },
            itemStyle: {
              color: "#32e3ea",
            },
            smooth: true,
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "RGBA(50, 227, 234, .7)",
                    },
                    {
                      offset: 1,
                      color: "RGBA(50, 227, 234, 0)",
                    },
                  ],
                  false
                ),
                shadowBlur: 20,
              },
            },
            data: valueList,
            symbol: "emptyCircle",
            symbolSize: 10,
          },
        ],
      };
    },
  },
};
</script>
<style lang="scss" scoped></style>
