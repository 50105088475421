<template>
  <div class="popup-wrap">
    <div class="body">
      <div class="charts-wrap">
        <div class="charts-item">
          <div class="charts-title">药性分析</div>
          <div class="charts-ref" ref="yaoxing"></div>
        </div>
        <div class="charts-item">
          <div class="charts-title">药味分析</div>
          <div class="charts-ref" ref="yaowei"></div>
        </div>
        <div class="charts-item">
          <div class="charts-title">归经分析</div>
          <div class="charts-ref" ref="guijing"></div>
        </div>
      </div>
      <div class="body-item">
        <el-row class="row">
          <el-col :span="12" class="col">
            <div class="item-title">方名</div>
            <div class="item-value red">
              {{ jingfangList.fangming || "-" }}
            </div>
          </el-col>
          <el-col :span="12" class="col">
            <div class="item-title">来源</div>
            <div class="item-value red">
              {{ jingfangList.laiyuan || "-" }}
            </div>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="24" class="col">
            <div class="item-title">组成</div>
            <div class="item-value bold flex">
              <div v-for="(item, index) in yaomingList" :key="index">
                <el-popover placement="top" width="800" trigger="hover">
                  <div class="body-item">
                    <el-row class="row">
                      <el-col :span="12" class="col">
                        <div class="item-title">药名</div>
                        <div class="item-value bold">
                          {{ item.ym || "-" }}
                        </div>
                      </el-col>
                      <el-col :span="12" class="col">
                        <div class="item-title">别名</div>
                        <div class="item-value">
                          {{ item.bm || "-" }}
                        </div>
                      </el-col>
                    </el-row>
                    <el-row class="row">
                      <el-col :span="6" class="col">
                        <div class="item-title">拼音</div>
                        <div class="item-value">
                          {{ item.py || "-" }}
                        </div>
                      </el-col>
                      <el-col :span="6" class="col">
                        <div class="item-title">性</div>
                        <div class="item-value bold">
                          {{ item.xing || "-" }}
                        </div>
                      </el-col>
                      <el-col :span="6" class="col">
                        <div class="item-title">味</div>
                        <div class="item-value bold">
                          {{ item.wei || "-" }}
                        </div>
                      </el-col>
                      <el-col :span="6" class="col">
                        <div class="item-title bold">毒</div>
                        <div class="item-value">
                          {{ item.duxing || "-" }}
                        </div>
                      </el-col>
                    </el-row>
                    <el-row class="row">
                      <el-col :span="12" class="col">
                        <div class="item-title">归经</div>
                        <div class="item-value bold">
                          {{ item.gj || "-" }}
                        </div>
                      </el-col>
                      <el-col :span="12" class="col">
                        <div class="item-title">功效</div>
                        <div class="item-value bold">
                          {{ item.gx || "-" }}
                        </div>
                      </el-col>
                    </el-row>
                    <el-row class="row">
                      <el-col :span="24" class="col">
                        <div class="item-title">主治</div>
                        <div class="item-value bold">
                          {{ item.zz || "-" }}
                        </div>
                      </el-col>
                    </el-row>
                    <el-row class="row">
                      <el-col :span="24" class="col">
                        <div class="item-title">用法用量</div>
                        <div class="item-value bold">
                          {{ item.yfyl || "-" }}
                        </div>
                      </el-col>
                    </el-row>
                    <el-row class="row">
                      <el-col :span="24" class="col">
                        <div class="item-title">应用禁忌</div>
                        <div class="item-value red">
                          {{ item.yyjj || "-" }}
                        </div>
                      </el-col>
                    </el-row>
                    <el-row class="row">
                      <el-col :span="24" class="col">
                        <div class="item-title">配制方法</div>
                        <div class="item-value">
                          {{ item.pzff || "-" }}
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                  <span slot="reference">
                    {{
                      index + 1 === yaomingList.length
                        ? item.ym + "。"
                        : item.ym + "、"
                    }}</span
                  >
                </el-popover>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="24" class="col">
            <div class="item-title">用法</div>
            <div class="item-value">
              {{ jingfangList.yongfa || "-" }}
            </div>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="24" class="col">
            <div class="item-title">医案举例</div>
            <div class="item-value">
              {{ jingfangList.yianjuli || "-" }}
            </div>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="24" class="col">
            <div class="item-title">功用</div>
            <div class="item-value bold">
              {{ jingfangList.gongyong || "-" }}
            </div>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="24" class="col">
            <div class="item-title">主治</div>
            <div class="item-value">
              {{ yaoxingForm.zhuzhi || "-" }}
            </div>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="24" class="col">
            <div class="item-title">证治机理</div>
            <div class="item-value">
              {{ jingfangList.zhengzhijili || "-" }}
            </div>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="24" class="col">
            <div class="item-title">方解</div>
            <div class="item-value">
              {{ jingfangList.fangjie || "-" }}
            </div>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="24" class="col">
            <div class="item-title">配伍特点</div>
            <div class="item-value">
              {{ jingfangList.peiwutedian || "-" }}
            </div>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="24" class="col">
            <div class="item-title">运用</div>
            <div class="item-value">
              {{ jingfangList.yunyong || "-" }}
            </div>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="24" class="col">
            <div class="item-title">鉴别</div>
            <div class="item-value">
              {{ jingfangList.jianbie || "-" }}
            </div>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="24" class="col">
            <div class="item-title">方论选录</div>
            <div class="item-value">
              {{ jingfangList.fanglunxuanlu || "-" }}
            </div>
          </el-col>
        </el-row>

        <el-row class="row">
          <el-col :span="24" class="col">
            <div class="item-title">方歌</div>
            <div class="item-value">
              {{ jingfangList.fangge || "-" }}
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { getJingFangYaoWu, getYaoxingfenxi } from "@/api/zkyz/aictm";
import * as echarts from "echarts";

export default {
  name: "popup-zhenduan",
  props: {
    yfList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    fm: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dataList: null,
      callback: null,
      yaoxingForm: {
        gongxiao: null,
        zhuzhi: null,
      },
      jingfangList: [],
      yaomingList: [],
    };
  },
  mounted() {},
  methods: {
    init() {
      let data = [];

      for (let item of this.yfList) {
        data.push({
          n: item.name,
          v: Number(item.number || 0),
          dw: item.g,
        });
      }

      getYaoxingfenxi(data)
        .then((res) => {
          // 药性分析guixing，归经分析guijing，药味分析wei
          let guijing = res.data?.guijing || [];
          this.yaoxingForm = {
            gongxiao: res.data?.gongxiao,
            zhuzhi: res.data?.zhuzhi,
          };
          let yaoxing = res.data?.xing || [];
          let yaowei = res.data?.wei || [];
          this.buildEcharts("yaoxing", this.formatDataList(yaoxing));
          this.buildEcharts("yaowei", this.formatDataList(yaowei));
          this.buildEcharts("guijing", this.formatDataList(guijing));
        })
        .catch((error) => {
          this.$mesesage.error(error);
        });
      let zc = this.yfList
        .map((item) => {
          return item.name;
        })
        .join(",");

      getJingFangYaoWu({ zc, fm: this.fm }).then((res) => {
        this.jingfangList = res.data[0][0] || [];
        this.yaomingList = res.data[1];
      });
    },
    formatDataList(dataList) {
      return dataList.filter((r) => {
        return r.n != "nan";
      });
    },
    buildEcharts(refStr, list, type) {
      // console.log(refStr, list, type);
      let xList = [];
      let yList = [];
      let pieList = [];
      let radarList = [
        { name: "甘", value: 0 },
        { name: "咸", value: 0 },
        { name: "涩", value: 0 },
        { name: "淡", value: 0 },
        { name: "苦", value: 0 },
        { name: "辛", value: 0 },
      ];
      let i = 0;
      for (let item of list) {
        xList.push(item.n);
        yList.push(item.v);
        let value = item.v;
        let name = item.n;
        pieList.push({ value, name });
        radarList.map((item) => {
          if (item.name === name) {
            item.value = value;
          }
        });
        i++;
      }
      radarList = radarList.map((item) => item.value);
      let myChart = echarts.init(this.$refs[refStr]);
      var option;
      option = {
        color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
          {
            offset: 0,
            color: "#d8f0d8",
          },
          {
            offset: 1,
            color: "#00cf00",
          },
        ]),
        xAxis: {
          type: "category",
          data: xList,
          axisLabel: {
            fontSize: 16,
            color: "black",
            fontFamily: "隶书",
          },
        },
        yAxis: {
          type: "value",
          show: false,
        },
        series: [
          {
            data: yList,
            type: "bar",
            barMaxWidth: 30,
            itemStyle: {
              borderWidth: 1,
              borderColor: "#00aa00",
            },
          },
        ],
        grid: {
          left: 40,
          top: 20,
          right: 10,
          bottom: 30,
        },
        tooltip: {
          formatter: "{b0}",
        },
      };
      var option1 = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          top: "5%",
          left: "center",
        },
        series: [
          {
            name: "药性分析",
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: pieList,
          },
        ],
      };
      var option2 = {
        legend: {
          data: ["药味分析"],
          show: false,
        },
        radar: {
          // shape: 'circle',
          indicator: [
            { name: "甘" },
            { name: "咸" },
            { name: "涩" },
            { name: "淡" },
            { name: "苦" },
            { name: "辛" },
          ],
        },
        series: [
          {
            name: "",
            type: "radar",
            data: [
              {
                value: radarList,
                name: "药味分析",
                areaStyle: {
                  color: "rgba(0, 106, 254, 0.2)",
                },
              },
            ],
          },
        ],
      };
      if (refStr === "yaoxing") {
        option && myChart.setOption(option1);
      } else if (refStr === "yaowei") {
        option && myChart.setOption(option2);
      } else {
        option && myChart.setOption(option);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::deep .el-col {
  height: 100%;
}

::deep .el-popover {
  // background-color: rbg(250, 246, 242);
  background-color: #000000;
}

.btn {
  background-color: #ae7000;
  border-radius: 20px;
  height: 30px;
  width: 100px;
  padding: 0 10px;
  line-height: 30px;
  text-align: center;
  color: white;
  font-weight: 500;
  cursor: pointer;
  margin: 10px auto;
}

.body {
  margin: 10px 0;
  padding: 6px;
  width: 100%;
  height: 500px;
  overflow-y: scroll;
}

.charts-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;

  .charts-item {
    .charts-title {
      font-size: 18px;
      text-align: center;
      font-weight: bold;
      color: black;
    }

    .charts-ref {
      height: 300px;
      width: 400px;
    }
  }
}

.body-item {
  font-size: 18px;
  color: black;
  padding: 10px 20px;
  user-select: none;
  .body-item-title {
    font-weight: bolder;
  }
}

.row {
  height: auto;
  border: 1px solid #ebeef5;
  border-top: none;
  display: flex;
  flex-direction: row;

  &:nth-child(1) {
    border-top: 1px solid #ebeef5;
  }

  .col {
    height: auto;
    line-height: 30px;
    display: flex;

    .item-title {
      height: auto;

      padding-left: 20px;
      min-width: 85px;
      font-size: 14px;
      color: #606266;
    }

    .item-value {
      height: auto;
      padding-left: 20px;
      flex: 1;
      // border-left: 1px solid #e6e9f0;
      border-left: 1px solid #ebeef5;
      border-right: 1px solid #ebeef5;
      // color: #3D4966;
      color: #303133;
      font-size: 14px;
      //默认显示
      // display: -webkit-box;
      // -webkit-box-orient: vertical;
      // -webkit-line-clamp: 3;
      // overflow: hidden;
      // text-overflow: ellipsis;
    }

    .red {
      color: #9e6600;
      font-weight: 700;
    }

    .bold {
      font-weight: 700;
    }

    .flex {
      display: flex;
      padding-left: 20px;
      flex-direction: row;
    }

    &:nth-last-child(1) {
      border-right: none;
    }
  }
}
</style>
