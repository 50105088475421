import request from "@/utils/request";

// 查询症状列表
export function getPatternZyListZhengzhuang(query) {
  return request({
    url: "/weakhis/main_symptom_dict/getPatternZyList",
    method: "get",
    params: query,
  });
}

// 查询舌质
export function getPatternZyListShe(query) {
  return request({
    url: "/weakhis/tongue_quality_dict/getPatternZyList",
    method: "get",
    params: query,
  });
}

// 查询舌苔
export function getPatternZyListTai(query) {
  return request({
    url: "/weakhis/tongue_furred_dict/getPatternZyList",
    method: "get",
    params: query,
  });
}

// 查询脉象
export function getPatternZyListMai(query) {
  return request({
    url: "/weakhis/pulse_condition_dict/getPatternZyList",
    method: "get",
    params: query,
  });
}

// 查询专病类型
export function getBkdDiseaseList(query) {
  return request({
    url: "/weakhis/disease_dict/getPatternZyList",
    method: "get",
    params: query,
  });
}

// 查询症状弹窗
export function getDiseaseCard(query) {
  return request({
    url: `/aitcm/pattern/symptom`,
    method: "post",
    data: query,
  });
}

// ai智能诊断1-名医名方
export function aiDiagnoseByFamousDoctor(data) {
  return request({
    url: "/aitcm/pattern/diagnose/famous_doctor",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    method: "post",
    data: data,
  });
}

// ai智能诊断2-金方
export function aiDiagnoseWithgoldPrescription(data) {
  return request({
    url: "/aitcm/pattern/diagnose/gold_prescription",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    method: "post",
    data: data,
  });
}

// 伤寒金匮接口（本地）
export function aiDiagnoseWithgoldPrescriptionNew(zhengZhuang) {
  const params = new URLSearchParams();
  params.append("zhengZhuang", zhengZhuang);
  return request({
    url: "/aitcm/pattern/diagnose/ShangHanJinKui",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    method: "post",
    data: params,
  });
}

// 收藏-保存接口
export function collect(data) {
  return request({
    url: "/weakhis/case/collect",
    method: "post",
    data: data,
  });
}

// 收藏-查询接口
export function collectList(CollectId) {
  return request({
    url: "/weakhis/case/collect/getList",
    method: "get",
    params: {
      CollectId,
      pageNum: 1,
      pageSize: 99999999,
    },
  });
}

// 开方
/**
 * patientCase    病例信息
 *    billno  病例号
 *    doctorId  医生id可不传
 *    accidentDate  发病日期，格式yyyy-mm-dd
 *    cardinalSymptom  症状，多个逗号分割
 *    allergicHistory    过敏史
 *    engravingSyndrome  刻下症描述
 *    tongueFurredInfo  舌苔，多个逗号分割
 *    tongueQualityInfo  舌质，多个逗号分割
 *    pulseConditionInfo  脉象，多个逗号分割
 *    disease  疾病（疾病辨别）
 *    caseAnalysis  病例分析信息
 *    therapeuticRegimen  治疗方案
 * patientInfo    病人信息
 *    name  病人名字
 *    sex  性别，男-M,女-F
 *    birthday  病人生日，yyyy-mm-dd格式
 *    doctorId  医生id可不传
 *    idType  证件类型，预留字段
 *    idNo  证件号码，预留字段
 *    gender 性别
 *    idcard 身份证号
 * dieasePrescription    开方信息
 *    prescriptionType  药方来源2、医院常用;3、金匮金方;4、名医名方
 *    prescriptionDetail  药方信息
 *
 * @param data
 */
export function save(data) {
  return request({
    url: "/weakhis/case/save",
    method: "post",
    data: data,
  });
}

/**
 * 获取病历号
 */
export function getBillNo() {
  return request({
    url: "/weakhis/case/next_id",
    method: "get",
  });
}

/**
 * 获取病历
 */
export function getPatientCase(idCard, billNo, name) {
  return request({
    url: "/weakhis/case/patient_case/last_one",
    method: "get",
    params: {
      name,
      idCard,
      billNo,
    },
  });
}

export function getPatientCaseMore(
  name,
  age,
  gender,
  comedate,
  pageNum,
  pageSize
) {
  return request({
    url: "/weakhis/case/patient_case/list",
    method: "get",
    params: {
      name,
      age,
      gender,
      comedate,
      pageNum,
      pageSize,
    },
  });
}

export function getDoctorMore(pageNum, pageSize) {
  return request({
    url: "/weakhis/case/doctor_case/list",
    method: "get",
    params: {
      pageNum,
      pageSize,
    },
  });
}

export function saveDocComments(caseId, data) {
  return request({
    url: "/weakhis/case/save/docComments/" + caseId,
    method: "post",
    params: data,
  });
}

export function getTj(v) {
  const params = new URLSearchParams();
  params.append("v", v);
  return request({
    url: "/aitcm/pattern/apipublic/getRelatedZZ",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    method: "post",
    data: params,
  });
}

export function getFJ18Fan(yaoWu) {
  const params = new URLSearchParams();
  params.append("yaoWuList", yaoWu);
  return request({
    url: "/aitcm/pattern/apipublic/getFJ18Fan19WeiYunFu",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    method: "post",
    data: params,
  });
}
//多条毒性分析
export function getDuxingfenxi(yaoWu) {
  const params = new URLSearchParams();
  params.append("yaoWuList", yaoWu);
  return request({
    url: "/aitcm/pattern/apipublic/getDuXingList",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    method: "post",
    data: params,
  });
}
//单条毒性分析
export function getDuXingByOne(yaoWu) {
  return request({
    url: `/aitcm/pattern/apipublic/getDuXing?yaoWu=${yaoWu}`,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    method: "post",
  });
}
export function getYaoxingfenxi(yaoWuList) {
  const params = new URLSearchParams();
  params.append("yaoWuList", JSON.stringify(yaoWuList));
  return request({
    url: "/aitcm/pattern/apipublic/getXingWeiGuiJingGongXiao",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    method: "post",
    data: params,
  });
}

export function getPatternZyListsheAll(query) {
  return request({
    url: "/weakhis/TongueInfo/getPatternZyList",
    method: "get",
    params: query,
  });
}

export function getJingFangYaoWu(query) {
  return request({
    url: "/aitcm/pattern/apipublic/getJingFangYaoWu",
    method: "post",
    data: query,
  });
}

// 天中医脉 病例或身份证号查询
export function GetPatientInfo(query) {
  return request({
    url: "external/sizhen/tzym/GetPatientInfo",
    method: "post",
    data: query,
  });
}

// 中科芯创-四诊接口 病例或身份证号查询
export function GetPatientInfoZKXC(query) {
  return request({
    url: "external/sizhen/zkxc/GetPatientInfo",
    method: "post",
    data: query,
    params: query,
  });
}

export function GetPatientInfoSearch(query) {
  return request({
    url: "/weakhis/case/patientInfo/search",
    method: "get",
    params: query,
  });
}

//病例查询 开始日期结束日期
export function GetPatientCaseSearch(query) {
  return request({
    url: "/weakhis/case/patientCase/search",
    method: "get",
    params: query,
  });
}

//医院his 免登录
export function generateToken(query) {
  return request({
    url: "/external/zmPeopleHospital/hangchuang/generateToken",
    method: "post",
    data: query,
  });
}

//药方搜索
export function getMedicinalDictList(query) {
  return request({
    url: "/dict/medicinal/getMedicinalDictList",
    method: "get",
    data: query,
  });
}
//收藏夹列表
export function getFavoritesList(query) {
  return request({
    url: "/system/collect/getList",
    method: "get",
    data: query,
  });
}

//处方收藏
export function saveFavorites(query) {
  return request({
    url: "/system/collect/preCollectSave ",
    method: "post",
    data: query,
  });
}

//新增收藏夹
export function collectSave(query) {
  return request({
    url: "/system/collect/collectSave",
    method: "post",
    data: query,
  });
}

export function deteleCollect(id) {
  return request({
    url: `/system/collect/${id}`,
    method: "delete",
  });
}

//查询四诊仪详细报告
export function getReportDetail(id) {
  return request({
    url: `/external/sizhen/zkxc/GetReportInfo/${id}`,
    method: "get",
  });
}

// 获取图片，转为base64
export const toBase64 = (urlPath) =>
  request({
    url: "/external/sizhen/getImgBase64",
    method: "post",
    params: { urlPath },
    isRepeat: true,
  });

//根据二维码获取病人信息
export function getPatientInfoByQrCode(query) {
  return request({
    url: `/weixin/user/getInfo/${query}`,
    method: "get",
  });
}
