var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "sizhen-layout",
    {
      attrs: { lastPathShow: false },
      on: {
        handleNextStep: function ($event) {
          return _vm.handleNextStep()
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "el-form",
            {
              ref: "infoForm",
              attrs: {
                model: _vm.infoForm,
                inline: true,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "div",
                { staticClass: "item" },
                [
                  _c("title-vue", { attrs: { title: "基本信息" } }),
                  _c(
                    "div",
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "患者姓名：", prop: "name" },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "患者姓名" },
                                    model: {
                                      value: _vm.infoForm.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.infoForm, "name", $$v)
                                      },
                                      expression: "infoForm.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "身份证号：",
                                    prop: "idcard",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "身份证号" },
                                    model: {
                                      value: _vm.infoForm.idcard,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.infoForm, "idcard", $$v)
                                      },
                                      expression: "infoForm.idcard",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "患者性别：",
                                    prop: "gender",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value: _vm.infoForm.gender,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.infoForm, "gender", $$v)
                                        },
                                        expression: "infoForm.gender",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio",
                                        { attrs: { label: "男" } },
                                        [_vm._v("男性")]
                                      ),
                                      _c(
                                        "el-radio",
                                        { attrs: { label: "女" } },
                                        [_vm._v("女性")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "出生日期：",
                                    prop: "birthday",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      type: "date",
                                      placeholder: "出生日期",
                                    },
                                    on: { change: _vm.changeBirthday },
                                    model: {
                                      value: _vm.infoForm.birthday,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.infoForm, "birthday", $$v)
                                      },
                                      expression: "infoForm.birthday",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "年龄：", prop: "age" } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "年龄" },
                                    on: { input: _vm.agechange },
                                    model: {
                                      value: _vm.infoForm.age,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.infoForm,
                                          "age",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "infoForm.age",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "民族：", prop: "nation" } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "民族" },
                                    model: {
                                      value: _vm.infoForm.nation,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.infoForm, "nation", $$v)
                                      },
                                      expression: "infoForm.nation",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "身高：", prop: "height" } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "身高" },
                                    model: {
                                      value: _vm.infoForm.height,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.infoForm, "height", $$v)
                                      },
                                      expression: "infoForm.height",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "体重：", prop: "weight" } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "体重" },
                                    model: {
                                      value: _vm.infoForm.weight,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.infoForm, "weight", $$v)
                                      },
                                      expression: "infoForm.weight",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "职业：",
                                    prop: "occupation",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "职业" },
                                    model: {
                                      value: _vm.infoForm.occupation,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.infoForm,
                                          "occupation",
                                          $$v
                                        )
                                      },
                                      expression: "infoForm.occupation",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "手机号：",
                                    prop: "cellphone",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "手机号",
                                      type: "tel",
                                    },
                                    model: {
                                      value: _vm.infoForm.cellphone,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.infoForm,
                                          "cellphone",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "infoForm.cellphone",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "item" },
                [
                  _c("title-vue", { attrs: { title: "既往病史" } }),
                  _c(
                    "div",
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "过敏史：" } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "过敏史" },
                                    model: {
                                      value: _vm.infoForm.guominshi,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.infoForm, "guominshi", $$v)
                                      },
                                      expression: "infoForm.guominshi",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "既往史：" } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "既往史" },
                                    model: {
                                      value: _vm.infoForm.jiwangshi,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.infoForm, "jiwangshi", $$v)
                                      },
                                      expression: "infoForm.jiwangshi",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "家族史：" } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "家族史" },
                                    model: {
                                      value: _vm.infoForm.jiazushi,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.infoForm, "jiazushi", $$v)
                                      },
                                      expression: "infoForm.jiazushi",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: false,
                      expression: "false",
                    },
                  ],
                  staticClass: "item",
                },
                [
                  _c("title-vue", { attrs: { title: "生活健康" } }),
                  _c(
                    "div",
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "吸烟：" } },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value: _vm.infoForm.smoke,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.infoForm, "smoke", $$v)
                                        },
                                        expression: "infoForm.smoke",
                                      },
                                    },
                                    [
                                      _c("el-radio", {
                                        attrs: { label: "是" },
                                      }),
                                      _c("el-radio", {
                                        attrs: { label: "否" },
                                      }),
                                      _c("el-radio", {
                                        attrs: { label: "已戒" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "烟龄：" } },
                                [
                                  _c("el-input", {
                                    scopedSlots: _vm._u([
                                      {
                                        key: "suffix",
                                        fn: function () {
                                          return [_vm._v("年")]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                    model: {
                                      value: _vm.infoForm.smoke,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.infoForm, "smoke", $$v)
                                      },
                                      expression: "infoForm.smoke",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "每日吸烟量：" } },
                                [
                                  _c("el-input", {
                                    scopedSlots: _vm._u([
                                      {
                                        key: "suffix",
                                        fn: function () {
                                          return [_vm._v("支/天")]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                    model: {
                                      value: _vm.infoForm.smoke,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.infoForm, "smoke", $$v)
                                      },
                                      expression: "infoForm.smoke",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "饮酒：" } },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value: _vm.infoForm.smoke,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.infoForm, "smoke", $$v)
                                        },
                                        expression: "infoForm.smoke",
                                      },
                                    },
                                    [
                                      _c("el-radio", {
                                        attrs: { label: "是" },
                                      }),
                                      _c("el-radio", {
                                        attrs: { label: "否" },
                                      }),
                                      _c("el-radio", {
                                        attrs: { label: "偶尔" },
                                      }),
                                      _c("el-radio", {
                                        attrs: { label: "已戒" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "酒龄：" } },
                                [
                                  _c("el-input", {
                                    scopedSlots: _vm._u([
                                      {
                                        key: "suffix",
                                        fn: function () {
                                          return [_vm._v("年")]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                    model: {
                                      value: _vm.infoForm.smoke,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.infoForm, "smoke", $$v)
                                      },
                                      expression: "infoForm.smoke",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "每日饮酒量：" } },
                                [
                                  _c("el-input", {
                                    scopedSlots: _vm._u([
                                      {
                                        key: "suffix",
                                        fn: function () {
                                          return [_vm._v("ml/天")]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                    model: {
                                      value: _vm.infoForm.smoke,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.infoForm, "smoke", $$v)
                                      },
                                      expression: "infoForm.smoke",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "饮食：" } },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value: _vm.infoForm.smoke,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.infoForm, "smoke", $$v)
                                        },
                                        expression: "infoForm.smoke",
                                      },
                                    },
                                    [
                                      _c("el-radio", {
                                        attrs: { label: "规律" },
                                      }),
                                      _c("el-radio", {
                                        attrs: { label: "不规律" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c("el-slider", {
                                attrs: { "format-tooltip": _vm.formatTooltip },
                                model: {
                                  value: _vm.infoForm.smoke,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.infoForm, "smoke", $$v)
                                  },
                                  expression: "infoForm.smoke",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "每日饮酒量：" } },
                                [
                                  _c("el-input", {
                                    scopedSlots: _vm._u([
                                      {
                                        key: "suffix",
                                        fn: function () {
                                          return [_vm._v("ml/天")]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                    model: {
                                      value: _vm.infoForm.smoke,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.infoForm, "smoke", $$v)
                                      },
                                      expression: "infoForm.smoke",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }