import request from "@/utils/request";

/**
 * 获取性别
 * */
export function getGenderRatio(data) {
  return request({
    url: `screen/visual/his/selectGenderRatio`,
    method: 'get',
  })
}

/**
 * 获取东西院区就诊人数
 * */
export function getWeeklyVisitStatistics(data) {
  return request({
    url: `screen/visual/his/selectWeeklyVisitStatistics`,
    method: 'get',
  })
}

/**
 * 就诊年龄统计
 * */
export function getAgeStatistics(data) {
  return request({
    url: `screen/visual/his/selectAgeStatistics`,
    method: 'get',
  })
}

/**
 * 就诊科室类别TOP5
 * */
export function getTopDepartmentVisitTrend(data) {
  return request({
    url: `screen/visual/his/selectTopDepartmentVisitTrend`,
    method: 'get',
  })
}

/**
 * 就诊趋势统计
 * */
export function getVisitTrendByDepartment(data) {
  return request({
    url: `screen/visual/his/selectVisitTrendByDepartment`,
    method: 'get',
  })
}

//公司同步中牟人民医院数据接口 query为apifox中接口排序
export function gethisDataSync(data,query) {
  return request({
    url: `/screen/visual/5lou/${query}`,
    method: 'get',
    data:data
  })
}
