<template>
  <div class="login-wrap">
    <p class="title">中医智能辅助诊断服务系统</p>

    <el-form
      label-position="left"
      :model="loginForm"
      :rules="loginRules"
      ref="loginForm"
      label-width="0px"
      class="login-container"
    >
      <el-row :gutter="20">
        <el-col :span="6">
          <div class="button1">用户名</div>
        </el-col>
        <el-col :span="18">
          <el-form-item prop="username">
            <el-input
              type="text"
              v-model="loginForm.username"
              auto-complete="off"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="6">
          <div class="button1">密码</div>
        </el-col>
        <el-col :span="18">
          <el-form-item prop="password">
            <el-input
              type="password"
              v-model="loginForm.password"
              auto-complete="off"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <!--      <el-row :gutter="20">-->
      <!--        <el-col :span="6"> <div  class="button1">验证码</div></el-col>-->
      <!--        <el-col :span="12">-->
      <!--          <el-input-->
      <!--            placeholder="请输入验证码"-->
      <!--            v-model="loginForm.code"></el-input>-->
      <!--        </el-col>-->
      <!--        <el-col :span="5">-->
      <!--          <div class="login-code" width="100%" @click="refreshCode">-->
      <!--            &lt;!&ndash;验证码组件&ndash;&gt;-->
      <!--            <dentify :identifyCode="identifyCode"></dentify></div>-->
      <!--        </el-col>-->
      <!--      </el-row>-->

      <el-row :gutter="20">
        <el-col :span="6">
          <div class="button1">验证码</div>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="code" v-if="captchaEnabled">
            <el-input
              v-model="loginForm.code"
              auto-complete="off"
              placeholder="请输入验证码"
              style="width: 100%"
              @keyup.enter.native="handleLogin"
            >
              <svg-icon
                slot="prefix"
                icon-class="validCode"
                class="el-input__icon input-icon"
              />
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="1">
          <div class="login-code">
            <img :src="codeUrl" @click="getCode" class="login-code-img" />
          </div>
        </el-col>
      </el-row>

      <el-row :gutter="20" style="margin-top: 20px">
        <el-col :span="24">
          <div class="button2" @click.stop="handleLogin">
            <i class="el-icon-loading" v-if="loading"></i>
            登录
          </div>
        </el-col>
        <!-- <el-col :span="12">
          <router-link :to="'/register'">
            <div class="button2" :to="'/register'">
              <i class="el-icon-loading" v-if="loading"></i>
              注册
            </div>
          </router-link>
        </el-col> -->
      </el-row>
    </el-form>
    <div class="login-footer">
      <div>中科云诊（河南）医疗科技有限公司</div>
      <div class="">
        <a href="https://beian.miit.gov.cn/" target="_blank">
          豫ICP备2024065559号 </a
        >|
        <img
          src="@/assets/images/gongan.png"
          alt=""
          style="width: 18px; height: 18px"
        />
        <a
          href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=41010702003447"
          target="_blank"
        >
          豫公网安备41010702003447号
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { getCodeImg } from "@/api/login";
// import Cookies from "js-cookie";
import { decrypt, encrypt } from "@/utils/jsencrypt";
// import dentify from "./dentify";

export default {
  name: "Login",
  components: {
    // dentify,
  },
  data() {
    return {
      codeUrl: "",
      //验证码
      // identifyCodes: "1234567890abcdefjhijklinopqrsduvwxyz", //随机串内容,从这里随机抽几个显示验证码
      identifyCodes: "1234567890", //随机串内容,从这里随机抽几个显示验证码
      identifyCode: "", //验证码图片内容

      loginForm: {
        username: "",
        password: "",
        rememberMe: false,
        code: "",
        uuid: "",
      },
      loginRules: {
        username: [
          { required: true, trigger: "blur", message: "请输入您的账号" },
        ],
        password: [
          { required: true, trigger: "blur", message: "请输入您的密码" },
        ],
        code: [{ required: true, trigger: "change", message: "请输入验证码" }],
      },
      loading: false,
      // 验证码开关
      captchaEnabled: true,
      // 注册开关
      register: false,
      redirect: undefined,
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  created() {
    this.getCode();
    // this.makeCode(this.identifyCodes, 4);

    this.getCookie();
  },
  methods: {
    // // 重置验证码
    // refreshCode() {
    //   this.identifyCode = "";
    //   this.makeCode(this.identifyCodes, 4);
    // },
    // //获取验证码的值
    // makeCode(o, l) {
    //   for (let i = 0; i < l; i++) {
    //     //通过循环获取字符串内随机几位
    //     this.identifyCode +=
    //         this.identifyCodes[this.randomNum(0, this.identifyCodes.length)];
    //   }
    // },
    // //随机数字：用于当角标拿字符串的值
    // randomNum(min, max) {
    //   return Math.floor(Math.random() * (max - min) + min);
    // },

    getCode() {
      getCodeImg().then((res) => {
        this.captchaEnabled =
          res.captchaEnabled === undefined ? true : res.captchaEnabled;
        if (this.captchaEnabled) {
          this.codeUrl = "data:image/gif;base64," + res.img;
          this.loginForm.uuid = res.uuid;
        }
      });
    },
    getCookie() {
      // const username = Cookies.get("username");
      // const password = Cookies.get("password");
      // const rememberMe = Cookies.get("rememberMe");
      const username = localStorage.getItem("username");
      const password = localStorage.getItem("password");
      const rememberMe = localStorage.getItem('rememberMe')
      this.loginForm = {
        // username: username === undefined ? this.loginForm.username : username,
        // password:
        //   password === undefined ? this.loginForm.password : decrypt(password),
        // rememberMe: rememberMe === undefined ? false : Boolean(rememberMe),
        username: username === null ? this.loginForm.username : username,
        password: password === null ? this.loginForm.password : decrypt(password),
        rememberMe: rememberMe === null ? false : Boolean(rememberMe)
      };
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          if (this.loginForm.rememberMe) {
            // Cookies.set("username", this.loginForm.username, { expires: 30 });
            // Cookies.set("password", encrypt(this.loginForm.password), {
            //   expires: 30,
            // });
            // Cookies.set("rememberMe", this.loginForm.rememberMe, {
            //   expires: 30,
            // });
            localStorage.setItem("username", this.loginForm.username);
            localStorage.setItem("password", encrypt(this.loginForm.password));
            localStorage.setItem('rememberMe', this.loginForm.rememberMe);
          } else {
            // Cookies.remove("username");
            // Cookies.remove("password");
            // Cookies.remove("rememberMe");
            localStorage.removeItem("username");
            localStorage.removeItem("password");
            localStorage.removeItem('rememberMe');
          }
          this.$store
            .dispatch("Login", this.loginForm)
            .then(() => {
              location.href = "/aitcm";
            })
            .catch(() => {
              this.loading = false;
              if (this.captchaEnabled) {
                // this.refreshCode();
                this.getCode();
              }
            });
        }
      });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss">
:root {
  --el-input-border-color: green;
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-image: url("../assets/images/background.jpg");
  background-size: cover;
}

.title {
  margin: 0px auto 30px auto;
  text-align: center;
  color: #707070;
}

.login-form {
  border-radius: 6px;
  width: 600px;
  padding: 25px 25px 5px 25px;

  .el-input {
    height: 38px;

    input {
      height: 38px;
    }
  }

  .input-icon {
    height: 39px;
    width: 14px;
    margin-left: 2px;
  }
}

.login-tip {
  font-size: 13px;
  text-align: center;
  color: #bfbfbf;
}

.login-code {
  width: 33%;
  height: 38px;
  float: right;

  img {
    cursor: pointer;
    vertical-align: middle;
  }
}

.el-login-footer {
  height: 40px;
  line-height: 40px;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 12px;
  letter-spacing: 1px;
}

.login-code-img {
  height: 36px;
}

.login-wrap {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-image: url("../assets/images/background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.button1 {
  background: url("../assets/images/04.png") no-repeat center center;
  background-size: 100% 100%;
  color: #f0f0f0;
  height: 36px;
  line-height: 36px;
  text-align: center;
  user-select: none;
}

.button2 {
  width: 100%;
  height: 36px;
  line-height: 36px;
  text-align: center;
  color: white;
  font-size: 16px;
  background: url("../assets/images/14.png") no-repeat center center;
  background-size: 100% 100%;
  border: none;
  cursor: pointer;
  user-select: none;
}

.login-container {
  border-radius: 10px;
  margin: 0px auto;
  width: 600px;
  padding: 30px 35px 15px 35px;
  background: #fff;
  border: 1px solid #eaeaea;
  text-align: left;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 150px;
}

.title {
  margin: 0px auto 40px auto;
  text-align: center;
  color: #505458;
  font-size: 50px;
  font-weight: 800;
}

.remember {
  margin: 0px 0px 35px 0px;
}

.code-box {
  text-align: right;
}

.codeimg {
  height: 40px;
}

.login-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  color: #73777a;
  text-align: center;
  padding: 10px;
  a {
    font-size: 14px;
  }
  a:hover {
    color: #1587d4;
  }
}
</style>
