<template>
  <div class="popup-wrap">
    <div class="title">添加收藏</div>
    <div class="wrap-item">
      <div class="key">药方名称</div>
      <div class="value">
        <el-input
          v-model="form.prescriptionName"
          placeholder=""
          size="mini"
          clearable
        />
      </div>
    </div>
    <div class="wrap-item">
      <div class="key">药方</div>
      <div class="value">
        <div class="kf">
          <div class="yf-item" v-for="(item, index) in fList" :key="index">
            <div class="yt-item-name">
              <input
                v-model="item.name"
                :style="{
                  width: item.name ? item.name.length * 14 + 'px' : '30px',
                }"
              />
            </div>
            <div class="yt-item-number">
              <input type="number" v-model="item.number" />
            </div>
            <div class="yt-item-g"><input v-model="item.g" /></div>
            <div class="yt-item-remove" @click="removeF(index)">
              <i class="el-icon-close"></i>
            </div>
          </div>
          <div class="add_plus" @click="addPrescription">
            <i class="el-icon-plus" />
          </div>
        </div>
      </div>
    </div>
    <div class="wrap-item">
      <div class="key">用法、制法</div>
      <div class="value">
        <el-input
          v-model="form.prescriptionZhifa"
          placeholder=""
          size="mini"
          clearable
        />
      </div>
    </div>
    <div class="wrap-item">
      <div class="key">收藏夹</div>
      <div class="value">
        <el-select
          v-model="form.label"
          placeholder="请选择"
          style="width: 100%"
          @change="labelChange"
        >
          <el-option
            v-for="item in shoucangList"
            :key="item.id"
            :label="item.label"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="bottom">
      <div class="button quxiao" @click="newFolder">新建收藏夹</div>
      <div></div>
      <div class="button" @click="save">保存</div>
    </div>
    <el-dialog
      title="新建处方夹"
      :visible.sync="dialogVisible"
      width="30%"
      :modal="false"
      modal-append-to-body
      :append-to-body="true"
      :before-close="dialogBeforeClose"
    >
      <div>
        <el-form
          ref="addForm"
          :model="addForm"
          :rules="rules"
          label-width="120px"
          label-position="top"
        >
          <el-form-item label="处方夹名称" prop="label">
            <el-input v-model="addForm.label"></el-input>
          </el-form-item>
          <el-form-item label="描述" prop="remark">
            <el-input type="textarea" v-model="addForm.remark"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              style="float: right"
              type="primary"
              @click="onSubmit('addForm')"
            >
              确认创建
            </el-button>
            <el-button
              style="float: right; margin-right: 10px"
              @click="dialogVisible = false"
            >
              取消
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { saveFavorites, collectSave, getFavoritesList } from "@/api/zkyz/aictm";

export default {
  name: "new-shoucang",
  props: {
    fList: {
      type: Array,
      default: [],
    },
    yfms: {
      type: String,
      default: "",
    },
    prescriptionZhifas: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      form: {
        collectId: null, //登录人id
        pid: null, //需要挂载的收藏夹id
        label: null, //收藏夹标题
        publicFlag: 0, //
        prescriptionType: 1, //
        prescriptionDetail: null, //"枳实2g、厚朴、薤白、桂枝、瓜蒌、芍药、细辛、通草、甘草、大枣"
        disease: null, //"冠心病"
        prescriptionName: null, //"方剂名称"
        prescriptionZcStdJson: null, //"药方明细Json"
        prescriptionZhifa: null, //制发
        prescriptionYfyl: null, //用法用量
        remark: null, //收藏夹描述
      },
      addForm: {
        label: "",
        remark: "",
        pid: 0,
        publicFlag: 0,
      },
      //新建收藏夹开关
      dialogVisible: false,
      rules: {
        label: [
          { required: true, message: "请填写处方夹名称", trigger: "blur" },
          {
            min: 5,
            max: 15,
            message: "长度在 5 到 15 个字符",
            trigger: "blur",
          },
        ],
        remark: [{ required: false, message: "", trigger: "blur" }],
      },
      shoucangList: [],
    };
  },
  watch: {
    yfms: {
      handler(newVal, oldVal) {
        this.form.prescriptionName = newVal.slice(0, -1);
      },
    },
    prescriptionZhifas: {
      handler(newVal, oldVal) {
        // console.log(newVal, "newVal");
        this.form.prescriptionZhifa = newVal;
      },
    },
  },
  mounted() {},
  methods: {
    getList() {
      getFavoritesList().then((res) => {
        this.shoucangList = res.data;
        this.form.label = this.shoucangList[0]?.label;
        this.form.pid = this.shoucangList[0]?.id;
      });
    },
    /**
     * 删除药
     */
    removeF(index) {
      this.fList.splice(index, 1);
    },
    /**
     * 添加药
     */
    addPrescription() {
      this.fList.push({
        name: null,
        number: null,
        g: "g",
      });
    },

    //打开新建收藏夹弹窗
    newFolder() {
      this.dialogVisible = true;
    },
    //关闭新建收藏夹弹窗事件
    dialogBeforeClose(e) {
      // console.log(e);
    },
    //提交 新建收藏夹表单
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          collectSave(this.addForm).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.data);
              this.dialogVisible = false;
              this.$refs[formName].resetFields();
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    //文件夹选择事件
    labelChange(e) {
      console.log(e);
      this.shoucangList.map((item) => {
        if (item.id === e) {
          this.form.label = item.label;
          this.form.pid = item.id;
        }
      });
    },
    //保存收藏 处方
    save() {
      let arr = this.fList.map((item) => {
        return item.name + item.number + item.g;
      });
      let str = arr.toString();
      this.form.prescriptionDetail = str;
      this.form.prescriptionZcStdJson = this.fList.map((item) => {
        return {
          dw: item.g,
          n: item.name,
          v: item.number,
        };
      });
      if (this.shoucangList.length < 1) {
        this.dialogVisible = true;
      }
      saveFavorites(this.form).then((res) => {
        if (res.code === 200) {
          this.$message.success(res.msg);
          this.form = {
            collectId: null, //登录人id
            pid: null, //需要挂载的收藏夹id
            label: null, //收藏夹标题
            publicFlag: 0, //
            prescriptionType: 1, //
            prescriptionDetail: null, //"枳实2g、厚朴、薤白、桂枝、瓜蒌、芍药、细辛、通草、甘草、大枣"
            disease: null, //"冠心病"
            prescriptionName: null, //"方剂名称"
            prescriptionZcStdJson: this.fList, //"药方明细Json"
            prescriptionZhifa: null, //制发
            prescriptionYfyl: null, //用法用量
            remark: null, //收藏夹描述
          };
          this.$emit("newshoucangShow", false);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__header {
  background-color: #ae7000;
  width: 100%;
  height: 50px;
  text-align: left;
  font-weight: 700;
  margin-bottom: 10px;
  padding: 0;
  .el-dialog__title {
    line-height: 50px;
    font-size: 20px;
    color: white;
    margin-left: 20px;
  }
}
.popup-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: Microsoft YaHei;
  .title {
    font-size: 20px;
    line-height: 20px;
    height: 20px;
    font-weight: bold;
    color: #ae7000;
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    div {
      height: 30px;
    }
  }
}

.kf {
  width: 100%;
  height: 216px;
  padding: 0 8px 8px 8px;
  box-sizing: border-box;
  overflow-y: scroll;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  background-size: 100% 100%;

  .yf-item {
    min-height: 30px;
    margin-top: 8px;
    margin-right: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 4px;
    border: 1px solid var(--main-border-color);
    width: fit-content;
    border-radius: 4px;

    input {
      background: rgba(0, 0, 0, 0);
    }

    .yt-item-name {
      font-weight: 500;
      font-size: 16px;
      color: black;
      border-bottom: 1px dashed gray;

      input {
        border: none;
        outline: none;
        min-width: 40px;
        width: auto;
        text-align: center;
      }
    }

    .yt-item-number {
      border-bottom: 1px dashed gray;
      margin: 0 10px;
      font-size: 16px;

      input {
        border: none;
        outline: none;
        width: 25px;
        text-align: center;
      }
    }

    .yt-item-g {
      font-weight: 500;
      font-size: 16px;
      color: black;
      border-bottom: 1px dashed gray;

      input {
        border: none;
        outline: none;
        width: 25px;
        text-align: center;
      }
    }

    .yt-item-remove {
      width: 20px;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background: #ad5800;
      font-size: 12px;
      cursor: pointer;
      color: white;
      margin-left: 4px;
    }

    &.red {
      border-color: red;

      .yt-item-remove {
        background: red;
      }

      .yt-item-g {
        border-color: red;
      }

      .yt-item-number {
        border-color: red;
      }

      .yt-item-name {
        border-color: red;
      }
    }

    &.green {
      border-color: green;

      .yt-item-remove {
        background: green;
      }

      .yt-item-g {
        border-color: green;
      }

      .yt-item-number {
        border-color: green;
      }

      .yt-item-name {
        border-color: green;
      }
    }
  }

  .add_plus {
    width: 32px;
    height: 32px;
    background: #ad5800;
    border-radius: 4px;
    text-align: center;
    line-height: 32px;
    margin-top: 8px;
  }
}
</style>
