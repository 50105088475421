<template>
  <div class="app-container">
    <div class="app-container-top">
      <div class="page-wrap-div">
        <div class="page-wrap">
          <div class="wenku-content">
            <div class="banner">
              <div class="banner-border">
                <div class="wenku-title">中医文库</div>
                <div class="search">
                  <input
                    class="search-input"
                    type="text"
                    placeholder="请输入检索内容"
                  />
                  <button class="search-btn" @click="">检索</button>
                </div>
              </div>
            </div>
            <div class="book-list">
              <div
                class="book-item"
                v-for="item in bookList"
                :key="item.bookId"
                @click="toPreview(item.bookId)"
              >
                <img src="../../assets/images/book.jpg" alt="" />
                <div class="book">
                  <div class="book-name">{{ item.title }}</div>
                  <div class="book-desc">作者：{{ item.author }}</div>
                </div>
              </div>
              <Pagination
                :total="bookTotal"
                :page.sync="getBookListForm.pageNum"
                :limit.sync="getBookListForm.pageSize"
                @pagination="getList"
              >
              </Pagination>
            </div>
            <h3>名家视频</h3>
            <div class="book-list">
              <div
                class="book-item"
                v-for="item in viedoList"
                :key="item.bookId"
                @click="toVideo('1')"
              >
                <img src="../../assets/images/book.jpg" alt="" />
                <div class="book">
                  <div class="book-name">{{ item.name }}</div>
                  <div class="book-desc">作者：{{ item.author }}</div>
                </div>
              </div>
              <Pagination
                :total="videoTotal"
                :page.sync="getVideoListForm.pageNum"
                :limit.sync="getVideoListForm.pageSize"
                @pagination="getList"
              >
              </Pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pdf from "vue-pdf";
import videoPreview from "./components/videoPreview.vue";
import { getBookList } from "@/api/library/library";
export default {
  components: {
    pdf,
    videoPreview,
  },
  data() {
    return {
      bookList: [],
      viedoList: [{ videoId: "1", name: "视频一", author: "未知" }],
      picUrl: "",
      getBookListForm: {
        pageNum: 1,
        pageSize: 10,
      },
      getVideoListForm: {
        pageNum: 1,
        pageSize: 10,
      },
      bookTotal: 0,
      videoTotal: 0,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      getBookList(this.getListForm).then((res) => {
        this.bookList = res.rows || [];
        this.total = res.total;
      });
    },
    toPreview(id) {
      this.$router.push({ path: "bookPreview", query: { bookId: id } });
    },
    toVideo(id) {
      this.$router.push({ name: "videoPreview", params: { videoId: id } });
    },
  },
};
</script>

<style scoped lang="scss">
.wenku-content {
  width: 1200px;
  margin: 0 auto;
  .banner {
    background: url(../../assets/images/searchBg.jpg);
    height: 300px;

    .banner-border {
      height: 100%;
      width: 100%;
      background: url(../../assets/images/banner_border.png);
      overflow: hidden;

      .wenku-title {
        font-family: "隶书";
        font-size: 32px;
        color: #8a4506;
        text-align: center;
        line-height: 80px;
        height: 80px;
        margin-top: 70px;
      }
      .search {
        display: flex;
        justify-content: center;
        input:focus,
        textarea:focus {
          outline: none;
          border: 1px solid #f60;
        }
        .search-input {
          background: #ffffff;
          border: 1px #913600 solid;
          width: 500px;
          height: 50px;
          box-sizing: border-box;
          padding: 5px;
          font-size: 16px;
        }
        .search-btn {
          background: #913600;
          border: 0;
          width: auto;
          padding: 0 25px 4px 60px;
          height: 50px;
          font-size: 16px;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }
  }
  .book-list {
    margin-top: 40px;
    border-top: #999 solid 1px;
    padding: 10px 0;
    .book-item {
      display: flex;
      cursor: pointer;
      border-bottom: 1px #cccccc dashed;
      margin-bottom: 30px;
      padding-bottom: 20px;
      img {
        width: 80px;
        height: 120px;
        margin-right: 40px;
      }
      .book {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }
    }
    .book-item:hover {
      // background: #f0efee;
      // border: 1px #ebb976 solid;
      // text-decoration: none;
      color: #ce4e00;
    }
  }
}
.pagination-container {
  background: none;
}
</style>
