<template>
  <div class="app-container">
    <el-table v-loading="loading" :data="dataList">
      <el-table-column label="设备ID" prop="deviceId" />
      <el-table-column label="设备名称" prop="deviceName" />
      <el-table-column label="mac" prop="mac" />
      <el-table-column label="备注" prop="descs" />
      <el-table-column
        label="操作"
        align="center"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button type="text" @click="getAllocatedList(scope.row)"
            >查看用户</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />

    <!-- 添加或修改角色配置对话框 -->
    <el-dialog
      :title="title"
      :visible.sync="open"
      width="80%"
      append-to-body
      :close-on-click-modal="false"
      v-dialogDrag
    >
      <el-row :gutter="10" class="mb8">
        <el-col :span="1.5">
          <el-button
            type="primary"
            plain
            icon="el-icon-plus"
            size="mini"
            @click="openSelectUser"
            >新增用户</el-button
          >
        </el-col>
        <el-col :span="1.5">
          <el-button
            type="danger"
            plain
            icon="el-icon-circle-close"
            size="mini"
            :disabled="multiple"
            @click="cancelAuthUserAll"
            >批量取消授权</el-button
          >
        </el-col>
      </el-row>
      <el-table
        v-loading="loading"
        :data="userList"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column label="用户名称" prop="userName" />
        <el-table-column label="用户昵称" prop="nickName" />
        <el-table-column label="性别" prop="sex" />
        <el-table-column label="邮箱" prop="email" />
        <el-table-column label="部门" prop="dept.deptName" />
        <el-table-column label="手机号" prop="phonenumber" />
        <el-table-column
          label="操作"
          align="center"
          v-if="mac"
          class-name="small-padding fixed-width"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="cancelRelevance(scope.row)"
              >取消关联</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="userQueryParams.pageNum"
        :limit.sync="userQueryParams.pageSize"
        @pagination="getAllocatedList({ mac: mac })"
      />
    </el-dialog>
    <select-user ref="select" :mac="mac" @ok="handleQuery" />
  </div>
</template>

<script>
import {
  getDeviceList,
  getUserList,
  cancelBind,
  batchCancelBind,
} from "@/api/sizhen/sizhen";
import selectUser from "./selectUser";
export default {
  name: "sizhenDeviceManagement",
  components: { selectUser },
  data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非多个禁用
      multiple: true,
      userIds: [],
      // 总条数
      total: 0,
      // 角色表格数据
      dataList: [],
      // 用户数据
      userList: [],
      // 选中mac
      mac: null,
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
      },
      userQueryParams: {
        pageNum: 1,
        pageSize: 10,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    /** 查询设备列表 */
    getList() {
      this.loading = true;
      getDeviceList().then((response) => {
        this.dataList = response.rows;
        this.total = response.total;
        this.loading = false;
      });
    },
    /** 查询用户列表 */
    getAllocatedList(row) {
      this.mac = row.mac;
      this.open = true;
      this.title = "分配用户";
      this.userQueryParams.mac = this.mac;
      getUserList(this.userQueryParams).then((response) => {
        this.userList = response.rows;
        this.total = response.total;
        this.loading = false;
      });
    },
    //取消关联
    cancelRelevance(row) {
      cancelBind({ mac: this.mac, userId: row.userId }).then((response) => {
        if (response.code === 200) {
          this.$message.success(response.msg);
          this.getAllocatedList({ mac: this.mac });
        }
      });
    },

    //添加用户
    openSelectUser() {
      this.$refs.select.show();
    },
    /** 批量取消授权按钮操作 */
    cancelAuthUserAll(row) {
      const mac = this.mac;
      const userIds = this.userIds.join(",");
      this.$modal
        .confirm("是否取消选中用户授权数据项？")
        .then(function () {
          return batchCancelBind({ mac: mac, userIds: userIds });
        })
        .then(() => {
          this.getAllocatedList({ mac: this.mac });
          this.$modal.msgSuccess("取消授权成功");
        })
        .catch((err) => {});
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.userIds = selection.map((item) => item.userId);
      this.multiple = !selection.length;
    },
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getAllocatedList({ mac: this.mac });
    },
  },
};
</script>
