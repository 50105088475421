import request from "@/utils/request";

export function pushData(params) {
  return request({
    url: "/weixin/user/scanCode",
    method: "post",
    data: params,
  });
}

export function getLsit(query) {
  return request({
    url: "/weixin/user/list",
    method: "post",
    data: query,
  });
}

export function saveForm(query) {
  return request({
    url: "/weixin/user/save",
    method: "post",
    data: query,
  });
}

export function deleteForm(query) {
  return request({
    url: `/weixin/user/delete/${query}`,
    method: "delete",
  });
}

// 获取assessToken
export function getAssessToken(query) {
  return request({
    url: `/weixin/user/get-token?code=${query.code}`,
    method: "get",
  });
}

// 获取用户信息
export function getUserInfo(query) {
  return request({
    url: `/weixin/user/get-user-info?access_token=${query.access_token}&openid=${query.openid}`,
    method: "get",
  });
}
