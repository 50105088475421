<template>
  <div class="popup-wrap">
    <el-form
      :model="queryForm"
      label-position="left"
      label-width="auto"
      ref="queryForm"
      size="small"
      :inline="true"
    >
      <el-form-item label="身份证号：" prop="idcard">
        <el-input
          v-model="queryForm.idcard"
          placeholder="身份证号"
          size="mini"
          clearable
          @keyup.enter.native="query"
        />
      </el-form-item>
      <el-form-item label="手机号：" prop="telephone">
        <el-input
          v-model="queryForm.telephone"
          placeholder="手机号"
          size="mini"
          clearable
          @keyup.enter.native="query"
        />
      </el-form-item>
      <el-form-item label="开始时间：" prop="startTime">
        <el-date-picker
          v-model="queryForm.startTime"
          type="date"
          start-placeholder="选择日期"
          value-format="yyyy-MM-dd"
          size="mini"
          class="long-width"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="结束时间：" prop="endTime">
        <el-date-picker
          v-model="queryForm.endTime"
          type="date"
          start-placeholder="选择日期"
          value-format="yyyy-MM-dd"
          size="mini"
          class="long-width"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button icon="el-icon-search" size="mini" class="btn" @click="query">
          搜索
        </el-button>
        <el-button
          icon="el-icon-refresh"
          size="mini"
          class="btn"
          @click="resetQuery"
          >重置</el-button
        >
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" :data="dataList"
      >\
      <el-table-column label="序号" width="50" align="center" type="index" />
      <el-table-column
        label="姓名"
        align="center"
        width="150"
        prop="patientName"
        :show-overflow-tooltip="true"
      />
      <el-table-column label="性别" align="center" width="50" prop="sex">
        <template slot-scope="scope">
          <div>{{ scope.row.sex }}</div>
        </template>
      </el-table-column>
      <el-table-column
        label="年龄"
        align="center"
        prop="age"
        width="50"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        label="手机号"
        align="center"
        width="150"
        prop="telephone"
        :show-overflow-tooltip="true"
        :formatter="hidePhoneMiddle"
      />

      <el-table-column
        label="采集时间"
        align="center"
        prop="createTime"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        label="操作"
        width="80"
        align="center"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button size="mini" type="text" @click="checkedItem(scope.row)"
            >导入</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryForm.pageNum"
      :limit.sync="queryForm.pageSize"
      @pagination="query"
    />
  </div>
</template>
<script>
import { GetPatientInfoZKXC } from "@/api/zkyz/aictm";

export default {
  name: "popup-sizhen",
  props: {
    idcard: {
      type: String,
      default: "",
    },
    telephone: {
      type: String,
      default: "",
    },
  },
  watch: {
    telephone: {
      handler(newVal, oldVal) {
        this.queryForm.telephone = newVal;
      },
    },
    idcard: {
      handler(newVal, oldVal) {
        this.queryForm.idcard = newVal;
      },
    },
  },
  data() {
    return {
      queryForm: {
        idcard: null,
        telephone: null,
        startTime: null,
        endTime: null,
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      dataList: [],
      loading: false,
      maiConclusion: null,
    };
  },
  mounted() {
    if (this.telephone || this.idCard) {
      this.queryForm.telephone = this.telephone;
      this.queryForm.idcard = this.idcard;
    }
    this.query();
  },
  methods: {
    query() {
      GetPatientInfoZKXC(this.queryForm)
        .then((res) => {
          if (res.code === 200) {
            this.dataList = res.rows;
            this.total = res.total;
          }
        })
        .catch((err) => {
          this.dataList = [];
        });
    },
    checkedItem(row) {
      const {
        maixiangResult,
        mianxiangResult,
        shexiangResult,
        patientName,
        sex,
        age,
        shefileUrl,
        wzResult,
        id,
        idcard,
        telephone,
        name,
        gender,
        cellphone
      } = row;
      const maixiang = JSON.parse(maixiangResult || null) || {};
      const mianxiang = JSON.parse(mianxiangResult || null) || {};
      const shexiang = JSON.parse(shexiangResult || null) || {};
      //处理脉象数据，和主页面保持一致
      const leftMai = {
        寸: [],
        关: [],
        尺: [],
      };
      const rightMai = {
        寸: [],
        关: [],
        尺: [],
      };
      for (let i in maixiang) {
        if (i.indexOf("左") !== -1) {
          if (i.indexOf("关") !== -1) {
            leftMai["关"] = Object.values(maixiang[i]);
          } else if (i.indexOf("寸") !== -1) {
            leftMai["寸"] = Object.values(maixiang[i]);
          } else if (i.indexOf("尺") !== -1) {
            leftMai["尺"] = Object.values(maixiang[i]);
          }
        } else if (i.indexOf("右") !== -1) {
          if (i.indexOf("关") !== -1) {
            rightMai["关"] = Object.values(maixiang[i]);
          } else if (i.indexOf("寸") !== -1) {
            rightMai["寸"] = Object.values(maixiang[i]);
          } else if (i.indexOf("尺") !== -1) {
            rightMai["尺"] = Object.values(maixiang[i]);
          }
        } else if (i.indexOf("脉象结论") !== -1) {
          // console.log(maixiang[i], "maixiang[i]");
          this.maiConclusion = maixiang[i];
          // console.log(this.maiConclusion, "maiConclusion");
          delete maixiang[i];
        }
      }
      //处理舌象数据，和主页面保持一致
      for (let i in shexiang) {
        if (shexiang[i] === "无" || shexiang[i] === "") {
          delete shexiang[i];
        } else if (shexiang[i].indexOf("、") !== -1) {
          shexiang[i] = shexiang[i].split("、");
        }
      }
      let shexiangList = Array.from(
        new Set(Object.values(shexiang).flat(Infinity))
      );
      let maixiangList = [];
      for (let i in maixiang) {
        if (Object.keys(maixiang[i]).length === 0) {
          continue;
        } else {
          for (let j in maixiang[i]) {
            maixiangList.push(i + "脉" + maixiang[i][j]);
          }
        }
      }
      this.$emit("sizhenData", {
        leftMai,
        rightMai,
        shexiangList,
        maixiangList,
        idcard,
        telephone,
        patientName,
        sex,
        age,
        shefileUrl,
        maiConclusion: this.maiConclusion,
        wzResult,
        id,
        name,
        gender,
        cellphone
      });
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.query();
    },
    getIdCardInfo(idCard) {
      var p =
        /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
      if (p.test(idCard)) {
        this.verifyFlag = true;
      } else {
        this.verifyFlag = false;
        this.$message.error("输入正确的身份证号");
        return;
      }
    },
    confirm() {},
    //手机号隐藏中间四位
    hidePhoneMiddle(row, column, cellValue) {
      return cellValue.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-radio-group {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;
}
::v-deep .el-date-editor > .el-input__inner {
  padding: 0 28px;
}
.popup-wrap {
  display: flex;
  flex-direction: column;
  font-family: cursive;
  .long-width {
    width: 100%;
  }
}

.btn {
  background-color: #ae7000;
  color: #ffffff;
  border: none;
}

::v-deep {
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background: var(--main-border-color);
  }
}
</style>
