var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "popup-wrap" }, [
    _c("div", { staticClass: "body" }, [
      _c("div", { staticClass: "charts-wrap" }, [
        _c("div", { staticClass: "charts-item" }, [
          _c("div", { staticClass: "charts-title" }, [_vm._v("药性分析")]),
          _c("div", { ref: "yaoxing", staticClass: "charts-ref" }),
        ]),
        _c("div", { staticClass: "charts-item" }, [
          _c("div", { staticClass: "charts-title" }, [_vm._v("药味分析")]),
          _c("div", { ref: "yaowei", staticClass: "charts-ref" }),
        ]),
        _c("div", { staticClass: "charts-item" }, [
          _c("div", { staticClass: "charts-title" }, [_vm._v("归经分析")]),
          _c("div", { ref: "guijing", staticClass: "charts-ref" }),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "body-item" },
        [
          _c(
            "el-row",
            { staticClass: "row" },
            [
              _c("el-col", { staticClass: "col", attrs: { span: 12 } }, [
                _c("div", { staticClass: "item-title" }, [_vm._v("方名")]),
                _c("div", { staticClass: "item-value red" }, [
                  _vm._v(" " + _vm._s(_vm.jingfangList.fangming || "-") + " "),
                ]),
              ]),
              _c("el-col", { staticClass: "col", attrs: { span: 12 } }, [
                _c("div", { staticClass: "item-title" }, [_vm._v("来源")]),
                _c("div", { staticClass: "item-value red" }, [
                  _vm._v(" " + _vm._s(_vm.jingfangList.laiyuan || "-") + " "),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "row" },
            [
              _c("el-col", { staticClass: "col", attrs: { span: 24 } }, [
                _c("div", { staticClass: "item-title" }, [_vm._v("组成")]),
                _c(
                  "div",
                  { staticClass: "item-value bold flex" },
                  _vm._l(_vm.yaomingList, function (item, index) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "top",
                              width: "800",
                              trigger: "hover",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "body-item" },
                              [
                                _c(
                                  "el-row",
                                  { staticClass: "row" },
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "col",
                                        attrs: { span: 12 },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "item-title" },
                                          [_vm._v("药名")]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "item-value bold" },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.ym || "-") + " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "col",
                                        attrs: { span: 12 },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "item-title" },
                                          [_vm._v("别名")]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "item-value" },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.bm || "-") + " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  { staticClass: "row" },
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "col",
                                        attrs: { span: 6 },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "item-title" },
                                          [_vm._v("拼音")]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "item-value" },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.py || "-") + " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "col",
                                        attrs: { span: 6 },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "item-title" },
                                          [_vm._v("性")]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "item-value bold" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.xing || "-") +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "col",
                                        attrs: { span: 6 },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "item-title" },
                                          [_vm._v("味")]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "item-value bold" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.wei || "-") +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "col",
                                        attrs: { span: 6 },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "item-title bold" },
                                          [_vm._v("毒")]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "item-value" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.duxing || "-") +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  { staticClass: "row" },
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "col",
                                        attrs: { span: 12 },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "item-title" },
                                          [_vm._v("归经")]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "item-value bold" },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.gj || "-") + " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "col",
                                        attrs: { span: 12 },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "item-title" },
                                          [_vm._v("功效")]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "item-value bold" },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.gx || "-") + " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  { staticClass: "row" },
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "col",
                                        attrs: { span: 24 },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "item-title" },
                                          [_vm._v("主治")]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "item-value bold" },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.zz || "-") + " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  { staticClass: "row" },
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "col",
                                        attrs: { span: 24 },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "item-title" },
                                          [_vm._v("用法用量")]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "item-value bold" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.yfyl || "-") +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  { staticClass: "row" },
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "col",
                                        attrs: { span: 24 },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "item-title" },
                                          [_vm._v("应用禁忌")]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "item-value red" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.yyjj || "-") +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  { staticClass: "row" },
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "col",
                                        attrs: { span: 24 },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "item-title" },
                                          [_vm._v("配制方法")]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "item-value" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.pzff || "-") +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "span",
                              {
                                attrs: { slot: "reference" },
                                slot: "reference",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      index + 1 === _vm.yaomingList.length
                                        ? item.ym + "。"
                                        : item.ym + "、"
                                    )
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "row" },
            [
              _c("el-col", { staticClass: "col", attrs: { span: 24 } }, [
                _c("div", { staticClass: "item-title" }, [_vm._v("用法")]),
                _c("div", { staticClass: "item-value" }, [
                  _vm._v(" " + _vm._s(_vm.jingfangList.yongfa || "-") + " "),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "row" },
            [
              _c("el-col", { staticClass: "col", attrs: { span: 24 } }, [
                _c("div", { staticClass: "item-title" }, [_vm._v("医案举例")]),
                _c("div", { staticClass: "item-value" }, [
                  _vm._v(" " + _vm._s(_vm.jingfangList.yianjuli || "-") + " "),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "row" },
            [
              _c("el-col", { staticClass: "col", attrs: { span: 24 } }, [
                _c("div", { staticClass: "item-title" }, [_vm._v("功用")]),
                _c("div", { staticClass: "item-value bold" }, [
                  _vm._v(" " + _vm._s(_vm.jingfangList.gongyong || "-") + " "),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "row" },
            [
              _c("el-col", { staticClass: "col", attrs: { span: 24 } }, [
                _c("div", { staticClass: "item-title" }, [_vm._v("主治")]),
                _c("div", { staticClass: "item-value" }, [
                  _vm._v(" " + _vm._s(_vm.yaoxingForm.zhuzhi || "-") + " "),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "row" },
            [
              _c("el-col", { staticClass: "col", attrs: { span: 24 } }, [
                _c("div", { staticClass: "item-title" }, [_vm._v("证治机理")]),
                _c("div", { staticClass: "item-value" }, [
                  _vm._v(
                    " " + _vm._s(_vm.jingfangList.zhengzhijili || "-") + " "
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "row" },
            [
              _c("el-col", { staticClass: "col", attrs: { span: 24 } }, [
                _c("div", { staticClass: "item-title" }, [_vm._v("方解")]),
                _c("div", { staticClass: "item-value" }, [
                  _vm._v(" " + _vm._s(_vm.jingfangList.fangjie || "-") + " "),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "row" },
            [
              _c("el-col", { staticClass: "col", attrs: { span: 24 } }, [
                _c("div", { staticClass: "item-title" }, [_vm._v("配伍特点")]),
                _c("div", { staticClass: "item-value" }, [
                  _vm._v(
                    " " + _vm._s(_vm.jingfangList.peiwutedian || "-") + " "
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "row" },
            [
              _c("el-col", { staticClass: "col", attrs: { span: 24 } }, [
                _c("div", { staticClass: "item-title" }, [_vm._v("运用")]),
                _c("div", { staticClass: "item-value" }, [
                  _vm._v(" " + _vm._s(_vm.jingfangList.yunyong || "-") + " "),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "row" },
            [
              _c("el-col", { staticClass: "col", attrs: { span: 24 } }, [
                _c("div", { staticClass: "item-title" }, [_vm._v("鉴别")]),
                _c("div", { staticClass: "item-value" }, [
                  _vm._v(" " + _vm._s(_vm.jingfangList.jianbie || "-") + " "),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "row" },
            [
              _c("el-col", { staticClass: "col", attrs: { span: 24 } }, [
                _c("div", { staticClass: "item-title" }, [_vm._v("方论选录")]),
                _c("div", { staticClass: "item-value" }, [
                  _vm._v(
                    " " + _vm._s(_vm.jingfangList.fanglunxuanlu || "-") + " "
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "row" },
            [
              _c("el-col", { staticClass: "col", attrs: { span: 24 } }, [
                _c("div", { staticClass: "item-title" }, [_vm._v("方歌")]),
                _c("div", { staticClass: "item-value" }, [
                  _vm._v(" " + _vm._s(_vm.jingfangList.fangge || "-") + " "),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }