var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-core-video-player", {
        staticStyle: { height: "540px", width: "720px" },
        attrs: {
          src: _vm.videoUrl,
          cover: _vm.cover,
          title: _vm.title,
          core: _vm.HLSCore,
          autoplay: "",
          loop: true,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }