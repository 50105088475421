var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "qr-page" },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showForm,
              expression: "showForm",
            },
          ],
          ref: "elForm",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            size: "medium",
            "label-width": "auto",
          },
        },
        [
          _c("h3", { staticStyle: { "text-align": "center" } }, [
            _vm._v("请填写信息"),
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "姓名", prop: "name" } },
            [
              _c("el-input", {
                style: { width: "100%" },
                attrs: { placeholder: "请输入姓名", clearable: "" },
                model: {
                  value: _vm.formData.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "name", $$v)
                  },
                  expression: "formData.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "年龄", prop: "age" } },
            [
              _c("el-input", {
                style: { width: "100%" },
                attrs: { placeholder: "请输入年龄", clearable: "" },
                model: {
                  value: _vm.formData.age,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "age", _vm._n($$v))
                  },
                  expression: "formData.age",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "性别", prop: "gender" } },
            [
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.genderChange },
                  model: {
                    value: _vm.sex,
                    callback: function ($$v) {
                      _vm.sex = $$v
                    },
                    expression: "sex",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("男性")]),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("女性")]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "手机号", prop: "cellphone" } },
            [
              _c("el-input", {
                style: { width: "100%" },
                attrs: { placeholder: "请输入手机号", clearable: "" },
                model: {
                  value: _vm.formData.cellphone,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "cellphone", $$v)
                  },
                  expression: "formData.cellphone",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "出生日期", prop: "birthday" } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  type: "date",
                  placeholder: "请输入出生日期",
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.formData.birthday,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "birthday", $$v)
                  },
                  expression: "formData.birthday",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "身份证号", prop: "idcard" } },
            [
              _c("el-input", {
                style: { width: "100%" },
                attrs: { placeholder: "请输入身份证号", clearable: "" },
                model: {
                  value: _vm.formData.idcard,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "idcard", $$v)
                  },
                  expression: "formData.idcard",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { size: "large" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.submitForm },
                },
                [_vm._v("提交")]
              ),
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.resetForm } },
                [_vm._v("重置")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showForm,
              expression: "!showForm",
            },
          ],
        },
        [
          _c(
            "div",
            { staticStyle: { display: "flex", "flex-direction": "column" } },
            [
              _c("h3", { staticStyle: { "text-align": "center" } }, [
                _vm._v("信息列表"),
              ]),
              _c(
                "el-button",
                {
                  staticStyle: { "align-self": "flex-end" },
                  attrs: { type: "number", size: "small" },
                  on: { click: _vm.adduser },
                },
                [_vm._v("新增")]
              ),
            ],
            1
          ),
          _c("el-divider"),
          _vm._l(_vm.infoList, function (item, index) {
            return _c(
              "div",
              { key: index },
              [
                _c("span", [_vm._v(" 推送设备：")]),
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "请选择推送设备" },
                    model: {
                      value: item.deviceId,
                      callback: function ($$v) {
                        _vm.$set(item, "deviceId", $$v)
                      },
                      expression: "item.deviceId",
                    },
                  },
                  _vm._l(_vm.deviceIdList, function (item) {
                    return _c("el-option", {
                      key: item.deviceId,
                      attrs: { label: item.descs, value: item.deviceId },
                    })
                  }),
                  1
                ),
                _c(
                  "el-descriptions",
                  {
                    staticClass: "margin-top",
                    attrs: { column: 3, size: "small" },
                  },
                  [
                    _c(
                      "template",
                      { slot: "extra" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteItem(item)
                              },
                            },
                          },
                          [_vm._v("删除 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.generateCode(item.userId)
                              },
                            },
                          },
                          [_vm._v("生成二维码 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.pushItem(item)
                              },
                            },
                          },
                          [_vm._v("推送 ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-descriptions-item",
                      [
                        _c("template", { slot: "label" }, [_vm._v(" 姓名 ")]),
                        _vm._v(" " + _vm._s(item.name) + " "),
                      ],
                      2
                    ),
                    _c(
                      "el-descriptions-item",
                      [
                        _c("template", { slot: "label" }, [_vm._v(" 年龄 ")]),
                        _vm._v(" " + _vm._s(item.age) + " "),
                      ],
                      2
                    ),
                    _c(
                      "el-descriptions-item",
                      [
                        _c("template", { slot: "label" }, [_vm._v(" 性别 ")]),
                        _vm._v(" " + _vm._s(item.gender) + " "),
                      ],
                      2
                    ),
                    _c(
                      "el-descriptions-item",
                      [
                        _c("template", { slot: "label" }, [
                          _vm._v(" 出生日期 "),
                        ]),
                        _vm._v(" " + _vm._s(item.birthday) + " "),
                      ],
                      2
                    ),
                    _c(
                      "el-descriptions-item",
                      [
                        _c("template", { slot: "label" }, [_vm._v(" 手机号 ")]),
                        _vm._v(" " + _vm._s(item.cellphone) + " "),
                      ],
                      2
                    ),
                    _c(
                      "el-descriptions-item",
                      [
                        _c("template", { slot: "label" }, [
                          _vm._v(" 身份证号 "),
                        ]),
                        _vm._v(" " + _vm._s(item.idcard) + " "),
                      ],
                      2
                    ),
                  ],
                  2
                ),
                _c("el-divider"),
              ],
              1
            )
          }),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "二维码", visible: _vm.tipsShow, width: "250px" },
          on: {
            "update:visible": function ($event) {
              _vm.tipsShow = $event
            },
          },
        },
        [
          _c("div", { ref: "myQrCode", attrs: { id: "myQrCode" } }),
          _c("p", { staticStyle: { "text-align": "center" } }, [
            _vm._v("长按二维码保存到本地"),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }