<template>
  <sizhen-layout
    @handleLastStep="handleLastStep()"
    @handleNextStep="handleNextStep()"
  >
    <div class="content" v-if="itemShow">
      <div class="start_bg">
        <div class="begin-btn" @click="beginHandle">开始问诊</div>
      </div>
    </div>
    <div v-else class="content">
      <div
        class="item-box"
        v-for="(item, index) in questionData"
        :key="index"
        v-if="item.num === nowNum"
      >
        <h1>
          {{ item.questionName }}
        </h1>
        <ol>
          <li
            v-for="(vItem, ind) in item.option"
            :key="ind"
            @click="itemHandle(vItem.label)"
          >
            {{ vItem.value }}
          </li>
        </ol>
      </div>
      <div class="content" v-if="submitShow">
        <div class="begin-btn" @click="onSubmit">提交</div>
      </div>
      <div class="content" v-if="contentShow">
        <div class="item-box">
          <div class="title">您的体质辨识结果为</div>
          <div class="tizhi-name">
            {{ tizhiData.tizhi }}-<span>主体质</span>
          </div>
          <div class="tizhi-desc">
            <div>
              易导致疾病：
              <div class="desc-item" v-html="tizhiData.yhjb"></div>
            </div>

            <div>
              养生原则：
              <div class="desc-item" v-html="tizhiData.ysyz"></div>
            </div>
            <div>
              饮食宜忌：
              <div class="desc-item" v-html="tizhiData.ysjj"></div>
            </div>
            <div>
              宜吃：
              <div class="desc-item" v-html="tizhiData.yc"></div>
            </div>
            <div>
              忌吃：
              <div class="desc-item" v-html="tizhiData.jc"></div>
            </div>
            <div>
              精神养生：
              <div class="desc-item" v-html="tizhiData.jsys"></div>
            </div>
            <div>
              起居养生：
              <div class="desc-item" v-html="tizhiData.qjys"></div>
            </div>
            <div>
              运动：
              <div class="desc-item" v-html="tizhiData.yd"></div>
            </div>
            <div>
              适合汤品/中药茶饮：
              <div class="desc-item" v-html="tizhiData.shtpzycy"></div>
            </div>
            <div>
              注意事项：
              <div class="desc-item" v-html="tizhiData.zysx"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </sizhen-layout>
</template>

<script>
import sizhenLayout from "@/views/sizhen/components/layout";
import { getTiZhi } from "@/api/sizhen/sizhen";
import { mapActions, mapState } from "vuex";
import sizhenData from "../../../store/modules/sizhen";
export default {
  name: "sizhen-tizhi",
  components: { sizhenLayout },
  data() {
    return {
      itemShow: true,
      submitShow: false,
      contentShow: false,
      answerList: [],
      nowNum: 1,
      gender: null,
      questionData: [
        {
          num: 1,
          questionName: "您体力充沛吗？",
          option: [
            { value: "没有", label: 1 },
            { value: "很少", label: 2 },
            { value: "有时", label: 3 },
            { value: "经常", label: 4 },
            { value: "总是", label: 5 },
          ],
        },
        {
          num: 2,
          questionName: "您容易疲乏吗？",
          option: [
            { value: "没有", label: 1 },
            { value: "很少", label: 2 },
            { value: "有时", label: 3 },
            { value: "经常", label: 4 },
            { value: "总是", label: 5 },
          ],
        },
        {
          num: 3,
          questionName: "您容易气短，呼吸短促，接不上气吗？",
          option: [
            { value: "没有", label: 1 },
            { value: "很少", label: 2 },
            { value: "有时", label: 3 },
            { value: "经常", label: 4 },
            { value: "总是", label: 5 },
          ],
        },
        {
          num: 4,
          questionName: "您说话声音低弱无力吗?（指说话没有力气）",
          option: [
            { value: "没有", label: 1 },
            { value: "很少", label: 2 },
            { value: "有时", label: 3 },
            { value: "经常", label: 4 },
            { value: "总是", label: 5 },
          ],
        },
        {
          num: 5,
          questionName: "您感到闷闷不乐、情绪低沉吗?（指心情不愉快，情绪低落）",
          option: [
            { value: "没有", label: 1 },
            { value: "很少", label: 2 },
            { value: "有时", label: 3 },
            { value: "经常", label: 4 },
            { value: "总是", label: 5 },
          ],
        },
        {
          num: 6,
          questionName: "您容易精神紧张、焦虑不安吗?（指遇事是否心情紧张）",
          option: [
            { value: "没有", label: 1 },
            { value: "很少", label: 2 },
            { value: "有时", label: 3 },
            { value: "经常", label: 4 },
            { value: "总是", label: 5 },
          ],
        },
        {
          num: 7,
          questionName: "您因为生活状态改变而感到孤独、失落吗？",
          option: [
            { value: "没有", label: 1 },
            { value: "很少", label: 2 },
            { value: "有时", label: 3 },
            { value: "经常", label: 4 },
            { value: "总是", label: 5 },
          ],
        },
        {
          num: 8,
          questionName: "您容易感到害怕或受到惊吓吗？",
          option: [
            { value: "没有", label: 1 },
            { value: "很少", label: 2 },
            { value: "有时", label: 3 },
            { value: "经常", label: 4 },
            { value: "总是", label: 5 },
          ],
        },
        {
          num: 9,
          questionName:
            "您感到身体超重不轻松吗?(感觉身体沉重) [BMI指数=体重（kg）/身高2（m）]",
          option: [
            { value: "没有/BMI<24", label: 1 },
            { value: "很少/24=<BMI<25", label: 2 },
            { value: "有时/25=<BMI<26", label: 3 },
            { value: "经常/26=<BMI<28", label: 4 },
            { value: "总是/BMI>=28", label: 5 },
          ],
        },
        {
          num: 10,
          questionName: "您眼睛干涩吗？",
          option: [
            { value: "没有", label: 1 },
            { value: "很少", label: 2 },
            { value: "有时", label: 3 },
            { value: "经常", label: 4 },
            { value: "总是", label: 5 },
          ],
        },
        {
          num: 11,
          questionName:
            "您手脚发凉吗？（不包含因周围温度低或穿的少导致的手脚发冷）",
          option: [
            { value: "没有", label: 1 },
            { value: "很少", label: 2 },
            { value: "有时", label: 3 },
            { value: "经常", label: 4 },
            { value: "总是", label: 5 },
          ],
        },
        {
          num: 12,
          questionName:
            "您胃脘部、背部或腰膝部怕冷吗？（指上腹部、背部、腰部或膝关节等，有一处或多处怕冷）",
          option: [
            { value: "没有", label: 1 },
            { value: "很少", label: 2 },
            { value: "有时", label: 3 },
            { value: "经常", label: 4 },
            { value: "总是", label: 5 },
          ],
        },
        {
          num: 13,
          questionName:
            "您比一般人耐受不了寒冷吗？（指比别人容易害怕冬天或是夏天的冷空调、电扇等）",
          option: [
            { value: "没有", label: 1 },
            { value: "很少", label: 2 },
            { value: "有时", label: 3 },
            { value: "经常", label: 4 },
            { value: "总是", label: 5 },
          ],
        },
        {
          num: 14,
          questionName: "您容易患感冒吗？（指每年感冒的次数）",
          option: [
            { value: "一年＜2次", label: 1 },
            { value: "一年感冒2-4次", label: 2 },
            { value: "一年感冒5-6次", label: 3 },
            { value: "一年8次以上", label: 4 },
            { value: "几乎每月都感冒", label: 5 },
          ],
        },
        {
          num: 15,
          questionName: "您没有感冒时也会鼻塞、流鼻涕吗？",
          option: [
            { value: "没有", label: 1 },
            { value: "很少", label: 2 },
            { value: "有时", label: 3 },
            { value: "经常", label: 4 },
            { value: "总是", label: 5 },
          ],
        },
        {
          num: 16,
          questionName: "您有口粘口腻，或睡眠打鼾吗？",
          option: [
            { value: "没有", label: 1 },
            { value: "很少", label: 2 },
            { value: "有时", label: 3 },
            { value: "经常", label: 4 },
            { value: "总是", label: 5 },
          ],
        },
        {
          num: 17,
          questionName:
            "您容易过敏(对药物、食物、气味、花粉或在季节交替、气候变化时)吗？",
          option: [
            { value: "从来没有", label: 1 },
            { value: "一年1、2次", label: 2 },
            { value: "一年3、4次", label: 3 },
            { value: "一年5、6次", label: 4 },
            { value: "每次遇到上述原因都过敏", label: 5 },
          ],
        },
        {
          num: 18,
          questionName: "您的皮肤容易起荨麻疹吗? (包括风团、风疹块、风疙瘩)",
          option: [
            { value: "没有", label: 1 },
            { value: "很少", label: 2 },
            { value: "有时", label: 3 },
            { value: "经常", label: 4 },
            { value: "总是", label: 5 },
          ],
        },
        {
          num: 19,
          questionName:
            "您的皮肤在不知不觉中会出现青紫瘀斑、皮下出血吗?（指皮肤在没有外伤的情况下出现青一块紫）",
          option: [
            { value: "没有", label: 1 },
            { value: "很少", label: 2 },
            { value: "有时", label: 3 },
            { value: "经常", label: 4 },
            { value: "总是", label: 5 },
          ],
        },
        {
          num: 20,
          questionName:
            "您的皮肤一抓就红，并出现抓痕吗?（指被指甲或钝物划过后皮肤的反应）",
          option: [
            { value: "没有", label: 1 },
            { value: "很少", label: 2 },
            { value: "有时", label: 3 },
            { value: "经常", label: 4 },
            { value: "总是", label: 5 },
          ],
        },
        {
          num: 21,
          questionName: "您皮肤或口唇干吗?",
          option: [
            { value: "没有", label: 1 },
            { value: "很少", label: 2 },
            { value: "有时", label: 3 },
            { value: "经常", label: 4 },
            { value: "总是", label: 5 },
          ],
        },
        {
          num: 22,
          questionName: "您有肢体麻木或固定部位疼痛的感觉吗？",
          option: [
            { value: "没有", label: 1 },
            { value: "很少", label: 2 },
            { value: "有时", label: 3 },
            { value: "经常", label: 4 },
            { value: "总是", label: 5 },
          ],
        },
        {
          num: 23,
          questionName: "您面部或鼻部有油腻感或者油亮发光吗?（指脸上或鼻子）",
          option: [
            { value: "没有", label: 1 },
            { value: "很少", label: 2 },
            { value: "有时", label: 3 },
            { value: "经常", label: 4 },
            { value: "总是", label: 5 },
          ],
        },
        {
          num: 24,
          questionName: "您面色或目眶晦黯，或出现褐色斑块/斑点吗? ",
          option: [
            { value: "没有", label: 1 },
            { value: "很少", label: 2 },
            { value: "有时", label: 3 },
            { value: "经常", label: 4 },
            { value: "总是", label: 5 },
          ],
        },
        {
          num: 25,
          questionName: "您有皮肤湿疹、疮疖吗？",
          option: [
            { value: "没有", label: 1 },
            { value: "很少", label: 2 },
            { value: "有时", label: 3 },
            { value: "经常", label: 4 },
            { value: "总是", label: 5 },
          ],
        },
        {
          num: 26,
          questionName: "您感到口干咽燥、总想喝水吗？",
          option: [
            { value: "没有", label: 1 },
            { value: "很少", label: 2 },
            { value: "有时", label: 3 },
            { value: "经常", label: 4 },
            { value: "总是", label: 5 },
          ],
        },
        {
          num: 27,
          questionName: "您感到口苦或嘴里有异味吗?（指口苦或口臭）",
          option: [
            { value: "没有", label: 1 },
            { value: "很少", label: 2 },
            { value: "有时", label: 3 },
            { value: "经常", label: 4 },
            { value: "总是", label: 5 },
          ],
        },
        {
          num: 28,
          questionName: "您腹部肥大吗?（指腹部脂肪肥厚）",
          option: [
            { value: "腹围<80cm， 相当于2.4 尺", label: 1 },
            { value: "腹围 80-85cm， 2.4-2.55 尺", label: 2 },
            { value: "腹围86-90cm，2.56-2.7尺", label: 3 },
            { value: "腹围91-105cm，2.71-3.15尺", label: 4 },
            { value: "腹围>105cm 或3.15尺", label: 5 },
          ],
        },
        {
          num: 29,
          questionName:
            "您吃(喝)凉的东西会感到不舒服或者怕吃(喝)凉的东西吗？（指不喜欢吃凉的食物，或吃了凉的食物后会不舒服）",
          option: [
            { value: "没有", label: 1 },
            { value: "很少", label: 2 },
            { value: "有时", label: 3 },
            { value: "经常", label: 4 },
            { value: "总是", label: 5 },
          ],
        },
        {
          num: 30,
          questionName:
            "您有大便黏滞不爽、解不尽的感觉吗?(大便容易粘在马桶或便坑壁上)",
          option: [
            { value: "没有", label: 1 },
            { value: "很少", label: 2 },
            { value: "有时", label: 3 },
            { value: "经常", label: 4 },
            { value: "总是", label: 5 },
          ],
        },
        {
          num: 31,
          questionName: "您容易大便干燥吗?",
          option: [
            { value: "没有", label: 1 },
            { value: "很少", label: 2 },
            { value: "有时", label: 3 },
            { value: "经常", label: 4 },
            { value: "总是", label: 5 },
          ],
        },
        {
          num: 32,
          questionName:
            "您舌苔厚腻或有舌苔厚厚的感觉吗?（如果自我感觉不清楚可由调查员观察后填写）",
          option: [
            { value: "没有", label: 1 },
            { value: "很少", label: 2 },
            { value: "有时", label: 3 },
            { value: "经常", label: 4 },
            { value: "总是", label: 5 },
          ],
        },
        {
          num: 33,
          questionName: "您舌下静脉瘀紫或增粗吗？（可由调查员辅助观察后填写）",
          option: [
            { value: "没有", label: 1 },
            { value: "很少", label: 2 },
            { value: "有时", label: 3 },
            { value: "经常", label: 4 },
            { value: "总是", label: 5 },
          ],
        },
      ],

      tizhiData: {},
    };
  },
  computed: {
    ...mapState(["sizhenData"]),
  },
  methods: {
    ...mapActions(["saveTizhi", "touchActive"]),

    beginHandle() {
      this.itemShow = !this.itemShow;
    },
    itemHandle(label) {
      this.answerList.push(label);
      this.nowNum++;
      if (this.nowNum === 34) {
        this.submitShow = true;
      }
    },
    onSubmit() {
      let str = this.answerList.toString();
      getTiZhi({ id: this.sizhenData.medicId, v: str }).then((res) => {
        console.log(res);
        if (res.code === 200) {
          this.submitShow = false;
          this.contentShow = true;
          this.tizhiData = res.jsonTiZhiGuide;
          this.saveTizhi(this.tizhiData.tizhi);
        }
      });
    },
    handleNextStep() {
      this.touchActive(2);
      this.$emit("handleNextStep");
    },
    handleLastStep() {
      this.touchActive(0);
      this.$emit("handleLastStep");
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep {
  .el-form-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .el-radio-group {
    display: flex;
    flex-direction: column;
    .el-radio {
      margin-top: 8px;
    }
  }
  .btn {
    display: flex;
    justify-content: center;
    .el-button {
      margin: 0 auto;
    }
  }
}
h1,
h3 {
  text-align: center;
}
.start_bg {
  background-image: url("../../../assets/sizhen/start_bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 1200px;
  min-height: 600px;
  max-height: 700px;
  border-radius: 16px;
  color: #323233;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content {
  width: 100%;
  height: calc(100vh - 120px);
  display: flex;
  justify-content: center;
  align-items: center;
  .item-box {
    user-select: none;
    width: 1200px;
    min-height: 600px;
    max-height: 700px;
    background: #ffffff;
    border-radius: 16px;
    color: #323233;
    padding: 16px;
    display: flex;
    flex-direction: column;
    ol {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      list-style-position: inside;
      padding: 0;
      li {
        font-family: Microsoft YaHei;
        font-weight: bold;
        font-size: 24px;
        width: 100%;
        flex: 1;
        text-align: center;
        line-height: 100px;
      }
      li:hover {
        color: #0166e2;
        background: RGBA(229, 239, 252, 1);
        border-radius: 8px;
      }
    }
    .title {
      font-family: Microsoft YaHei;
      font-weight: bold;
      font-size: 24px;
      color: #323233;
      line-height: 24px;
    }
    .tizhi-name {
      line-height: 60px;
      color: #0166e2;
      background: RGBA(229, 239, 252, 1);
      border-radius: 8px;
      text-align: center;
      margin-top: 10px;
      font-size: 24px;
    }
    .tizhi-desc {
      height: 100%;
      padding: 16px;
      overflow-y: auto;
    }
    .tizhi-desc > div {
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 10px;
      .desc-item {
        font-weight: 400;
        color: #0166e2;
        font-size: 14px;
      }
    }
  }
}
.container {
  width: 50%;
  margin: 0 auto;
}
.begin-btn {
  width: 220px;
  height: 84px;
  background: #ffffff;
  border-radius: 16px;
  line-height: 84px;
  text-align: center;
  color: #0166e2;
  font-weight: bold;
  font-size: 24px;
}
</style>
