<template>
  <div
    :id="popupId"
    ref="popup-container"
    class="popup-container"
    v-if="loadPopup"
    :style="{
      position: fixed ? 'fixed' : 'absolute',
      overflow: overflow,
      top: top + 'px',
      left: left + 'px',
      width: width ? width + 'px' : 'auto',
      height: height ? height + 'px' : 'auto',
      transform: animation ? 'scale(1)' : 'scale(0)',
      visibility: show ? 'visible' : 'hidden',
      padding: padding,
      zIndex: zIndex || 99,
    }"
    @click.stop="click"
    @mouseup="popupMouse('up', $event)"
  >
    <div
      class="top"
      @click.stop="click"
      v-if="showTitle"
      @mousedown.self="popupMouse('down', $event)"
    >
      <el-image
        class="icon"
        fit="cover"
        v-if="menuImage"
        :src="menuImage"
      ></el-image>
      <div
        class="title"
        @mousedown.self="popupMouse('down', $event)"
        :style="{ color: titleColor }"
      >
        {{ title }}
      </div>
      <div class="close" @click.stop="closeHandler()" v-if="showClose">×</div>
    </div>
    <div
      class="close"
      @click.stop="closeHandler()"
      v-if="(showTitle && showClose) || fixedClose"
    >
      ×
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "base-popup",
  props: {
    fixed: {
      type: Boolean,
      default: true,
    },
    padding: {
      type: String,
      default: "0",
    },
    width: {
      type: Number,
      default: null,
    },
    height: {
      type: Number,
      default: null,
    },
    mask: {
      type: Boolean,
      default: true,
    },
    overflow: {
      type: String,
      default: "hidden",
    },
    popupId: {
      type: String,
      default: () => {
        return "base-popup-" + new Date().getTime();
      },
    },
    title: {
      type: String,
      default: "",
    },
    titleColor: {
      type: String,
      default: "#000000",
    },
    menuImage: {
      type: String,
      default: null,
    },
    minLeft: {
      type: Number,
      default: 0,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    allowDrag: {
      type: Boolean,
      default: true,
    },
    fixedClose: {
      type: Boolean,
      default: false,
    },
    duration: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loadPopup: false,
      show: false,
      animation: false,
      move: false,
      options: null,
      type: "center",
      top: 0,
      left: 0,
      zIndex: 99,
      listenWin: null,
      element: {
        offsetX: 0,
        offsetY: 0,
        width: 0,
        height: 0,
        minLeft: 0,
        minTop: 0,
        clientWidth: 0,
        clientHeight: 0,
      },
      transitionDuration: "0.25s",
    };
  },
  mounted() {},
  methods: {
    mousemove(e) {
      if (!this.move) {
        return;
      }
      let clientX = e.clientX;
      let clientY = e.clientY;

      // 减去padding - image - margin
      let left = clientX - this.element.offsetX - 20;
      if (this.menuImage) {
        left -= 35;
      }
      let top = clientY - this.element.offsetY - 11;
      if (left < this.element.minLeft) {
        left = this.element.minLeft;
      }
      if (top < 0) {
        top = 0;
      }
      if (top + this.element.height > this.element.clientHeight) {
        top = this.element.clientHeight - this.element.height;
      }
      if (left + this.element.width > this.element.clientWidth) {
        left = this.element.clientWidth - this.element.width;
      }
      this.top = top;
      this.left = left;
    },
    popupMouse(type, e) {
      if (!this.allowDrag) {
        return;
      }
      this.click();
      if (type !== "down") {
        document.body.removeEventListener("mousemove", this.mousemove, false);
        this.move = false;
        return;
      }
      let el = document.getElementById(this.popupId);
      let body = document.body;
      this.element = {
        offsetX: e.offsetX,
        offsetY: e.offsetY,
        width: el.clientWidth,
        height: el.clientHeight,
        minLeft: this.minLeft,
        minTop: 0,
        clientWidth: body.clientWidth,
        clientHeight: body.clientHeight,
      };
      document.body.addEventListener("mousemove", this.mousemove, false);
      // 鼠标离开屏幕时取消拖动
      document.body.addEventListener(
        "mouseleave",
        () => {
          document.body.removeEventListener("mousemove", this.mousemove, false);
        },
        false
      );
      this.move = true;
    },
    // 点击窗口，关闭右键菜单
    click(e) {
      // e.preventDefault();
      this.$emit("click");
    },
    // 打开窗口
    open(options = { type: "center" }) {
      if (this.loadPopup) {
        return;
      }
      this.options = options;
      this.type = options.type;
      this.loadPopup = true;
      setTimeout(() => {
        let el = this.$refs["popup-container"];
        el.remove();
        document.body.appendChild(el);
        this.createMask();
        this.zIndex = this.mask ? 99 : 98;
        this.compute(el, options);
        this.show = true;
        this.animation = true;
      }, 0);
    },
    // 刷新位置
    refresh() {
      let el = this.$refs["popup-container"];
      this.compute(el);
    },
    // 关闭窗口
    async close() {
      this.$emit("close");
      let duration = this.transitionDuration;
      duration = duration.replace("s", "") * 1000;
      return new Promise((resolve, reject) => {
        this.move = false;
        this.animation = false;
        setTimeout(() => {
          this.show = false;
          this.loadPopup = false;
          resolve();
        }, duration);
        this.removeMask();
      });
    },
    // 手动关闭窗口
    async closeHandler() {
      this.$emit("closeHandler");
      this.close();
    },
    // 创建遮罩层
    createMask() {
      // 判断是否需要遮罩
      if (!this.mask) {
        return;
      }
      let durationStr = this.transitionDuration;
      let maskDiv = document.createElement("div");
      maskDiv.className = "popup-mask";
      maskDiv.id = this.popupId + "-mask";
      maskDiv.style =
        "position: fixed;left: 0; right: 0;top: 0;bottom: 0;z-index: 98;background: rgba(0, 0, 0, 0);transition: " +
        durationStr +
        " background;";
      document.body.appendChild(maskDiv);
      setTimeout(() => {
        maskDiv.style.background = "rgba(0,0,0,0.4)";
      }, 0);
    },
    // 删除遮罩层
    removeMask() {
      let maskEle = document.getElementById(this.popupId + "-mask");
      if (!maskEle) {
        return;
      }
      maskEle.style.background = "rgba(0,0,0,0)";
      let duration = this.transitionDuration;
      duration = duration.replace("s", "") * 1000;
      setTimeout(() => {
        maskEle.remove();
      }, duration);
    },
    compute(el, options) {
      if (!options) {
        options = this.options;
      }
      if (!options) {
        return;
      }
      let { type, fixTop, fixLeft, fixBottom, fixRight, rect } = options;
      let top = 0,
        left = 0;
      // 获取屏幕高度
      let clientWidth = document.body.clientWidth;
      let clientHeight = document.body.clientHeight;
      let popupWidth = el.clientWidth;
      let popupHeight = el.clientHeight;
      if (type == "center") {
        top = (clientHeight - popupHeight) / 2;
        left = (clientWidth - popupWidth) / 2;
      } else if (type == "top-center") {
        top = 0;
        left = (clientWidth - popupWidth) / 2;
      } else if (type == "left-top") {
        top = 0;
        left = 0;
      } else if (type == "right") {
        left = clientWidth - popupWidth;
        top = 0;
      } else if (type == "bottom") {
        left = 0;
        top = clientHeight - popupHeight;
      } else if (type == "auto") {
        // 根据四至坐标 rect =（minX,maxX,minY,maxY），自动计算出现的位置，位置顺序，右 - 左 - 上 - 下
        if (!rect) {
          left = 0;
          top = 0;
        } else {
          let exp = 10;
          let minX = rect[0] - exp,
            maxX = rect[1] + exp,
            minY = rect[2] - exp,
            maxY = rect[3] + exp;
          if (maxX + popupWidth < clientWidth) {
            // 右侧
            // 计算 y， 优先正中
            left = maxX;
            if (
              minY + (maxY - minY) / 2 + popupHeight / 2 < clientHeight &&
              minY + (maxY - minY) / 2 - popupHeight / 2 > 0
            ) {
              top = minY + (maxY - minY) / 2 - popupHeight / 2;
            } else if (
              minY + (maxY - minY) / 2 + popupHeight / 2 >
              clientHeight
            ) {
              top = clientHeight - popupHeight;
            } else {
              top = 0;
            }
          } else if (popupWidth < minX) {
            // 左侧
            left = minX - popupWidth;
            if (
              minY + (maxY - minY) / 2 + popupHeight / 2 < clientHeight &&
              minY + (maxY - minY) / 2 - popupHeight / 2 > 0
            ) {
              top = minY + (maxY - minY) / 2 - popupHeight / 2;
            } else if (
              minY + (maxY - minY) / 2 + popupHeight / 2 >
              clientHeight
            ) {
              top = clientHeight - popupHeight;
            } else {
              top = 0;
            }
          } else if (popupHeight < minY) {
            // 上
            top = minY - popupHeight;
            top = maxY;
            if (
              minX + (maxX - minX) / 2 + popupWidth / 2 < clientWidth &&
              minX + (maxX - minX) / 2 - popupWidth / 2 > 0
            ) {
              left = minX + (maxX - minX) / 2 - popupWidth / 2;
            } else if (
              minX + (maxX - minX) / 2 + popupWidth / 2 >
              clientWidth
            ) {
              left = clientWidth - popupWidth;
            } else {
              top = 0;
            }
          } else {
            // 下
            top = maxY;
            if (
              minX + (maxX - minX) / 2 + popupWidth / 2 < clientWidth &&
              minX + (maxX - minX) / 2 - popupWidth / 2 > 0
            ) {
              left = minX + (maxX - minX) / 2 - popupWidth / 2;
            } else if (
              minX + (maxX - minX) / 2 + popupWidth / 2 >
              clientWidth
            ) {
              left = clientWidth - popupWidth;
            } else {
              top = 0;
            }
          }
        }
      } else {
        if (fixLeft == 0 || fixLeft) {
          left = fixLeft;
        } else if (fixRight == 0 || fixRight) {
          left = clientWidth - fixRight - popupWidth;
        }
        if (fixTop == 0 || fixTop) {
          top = fixTop;
        } else if (fixBottom == 0 || fixBottom) {
          top = clientHeight - fixBottom - popupHeight;
        }
        if (clientHeight - popupHeight < top) {
          top = clientHeight - popupHeight;
        }
        if (clientWidth - popupWidth < left) {
          left = clientWidth - popupWidth;
        }
      }
      this.top = fixTop ? fixTop : top;
      this.left = fixLeft ? fixLeft : left;
    },
  },
};
</script>
<style lang="scss" scoped>
.popup-container {
  user-select: none;
  box-sizing: border-box;
  transition: 0.25s transform;
  background-color: white;
  filter: drop-shadow(6px 10px 6px rgb(180, 180, 180));
  background-repeat: no-repeat;
  background-size: 100% 100%;
  //border: 1px solid #535353;
  scrollbar-width: none;
  .top {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 9px;
    box-sizing: border-box;
    cursor: move;
    height: 45px;

    .icon {
      width: 25px;
      margin-right: 10px;
    }

    .title {
      text-align: left;
      flex: 1;
      font-size: 20px;
      font-weight: 500;
    }
  }

  .close {
    width: 20px;
    height: 20px;
    z-index: 100;
    position:absolute;
    right: 19px;
    top: 13px;
    font-size: 20px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.85);
  }
}
</style>
