var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c("div", { staticClass: "page-top" }, [
      _vm._m(0),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false",
            },
          ],
          staticClass: "page-search",
        },
        [
          _c(
            "el-input",
            {
              staticClass: "input-with-select",
              attrs: { placeholder: "请输入内容" },
              model: {
                value: _vm.pageSearchValue,
                callback: function ($$v) {
                  _vm.pageSearchValue = $$v
                },
                expression: "pageSearchValue",
              },
            },
            [
              _c(
                "el-select",
                {
                  attrs: { slot: "prepend", placeholder: "请选择类型" },
                  slot: "prepend",
                  model: {
                    value: _vm.pageSearchType,
                    callback: function ($$v) {
                      _vm.pageSearchType = $$v
                    },
                    expression: "pageSearchType",
                  },
                },
                [
                  _c(
                    "el-option",
                    {
                      staticClass: "select-option",
                      attrs: { label: "方剂", value: "1" },
                    },
                    [
                      _c("span", { staticClass: "option-ym" }, [
                        _vm._v("金匮方"),
                      ]),
                      _c("span", { staticClass: "option-yf" }, [
                        _vm._v("赤小豆当归散"),
                      ]),
                      _c("span", { staticClass: "option-yj" }, [
                        _vm._v("利水渗湿，温阳化气"),
                      ]),
                    ]
                  ),
                  _c(
                    "el-option",
                    {
                      staticClass: "select-option",
                      attrs: { label: "药物", value: "2" },
                    },
                    [
                      _c("span", { staticClass: "option-ym" }, [
                        _vm._v("金匮方"),
                      ]),
                      _c("span", { staticClass: "option-yf" }, [
                        _vm._v("赤小豆当归散"),
                      ]),
                      _c("span", { staticClass: "option-yj" }, [
                        _vm._v("利水渗湿，温阳化气"),
                      ]),
                    ]
                  ),
                  _c(
                    "el-option",
                    {
                      staticClass: "select-option",
                      attrs: { label: "古方", value: "3" },
                    },
                    [
                      _c("span", { staticClass: "option-ym" }, [
                        _vm._v("金匮方"),
                      ]),
                      _c("span", { staticClass: "option-yf" }, [
                        _vm._v("赤小豆当归散"),
                      ]),
                      _c("span", { staticClass: "option-yj" }, [
                        _vm._v("利水渗湿，温阳化气"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c("el-button", {
                attrs: { slot: "append", icon: "el-icon-search" },
                on: { click: _vm.searchHandle },
                slot: "append",
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "page-top-right" }, [
        _c(
          "div",
          { staticClass: "list-box" },
          [
            _c(
              "el-dropdown",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: false,
                    expression: "false",
                  },
                ],
                attrs: { trigger: "click" },
                on: { command: _vm.moduleClick },
              },
              [
                _c("span", { staticClass: "el-dropdown-link" }, [
                  _vm._v(" 中医文库"),
                  _c("i", {
                    staticClass: "el-icon-caret-bottom el-icon--right",
                  }),
                ]),
                _c(
                  "el-dropdown-menu",
                  { attrs: { slot: "dropdown" }, slot: "dropdown" },
                  [
                    _c(
                      "el-dropdown-item",
                      { attrs: { command: "中医文库-经典文献" } },
                      [_vm._v("经典文献 ")]
                    ),
                    _c(
                      "el-dropdown-item",
                      { attrs: { command: "中医文库-名医名方" } },
                      [_vm._v("名医名方 ")]
                    ),
                    _c(
                      "el-dropdown-item",
                      { attrs: { command: "中医文库-视频教学" } },
                      [_vm._v("视频教学 ")]
                    ),
                    _c(
                      "el-dropdown-item",
                      { attrs: { command: "中医文库-线上课堂" } },
                      [_vm._v("线上课堂 ")]
                    ),
                    _c(
                      "el-dropdown-item",
                      { attrs: { command: "中医文库-穴位经络" } },
                      [_vm._v("穴位经络 ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-dropdown",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: false,
                    expression: "false",
                  },
                ],
                attrs: { trigger: "click" },
                on: { command: _vm.moduleClick },
              },
              [
                _c("span", { staticClass: "el-dropdown-link" }, [
                  _vm._v(" 中医养生"),
                  _c("i", {
                    staticClass: "el-icon-caret-bottom el-icon--right",
                  }),
                ]),
                _c(
                  "el-dropdown-menu",
                  { attrs: { slot: "dropdown" }, slot: "dropdown" },
                  [
                    _c(
                      "el-dropdown-item",
                      { attrs: { command: "中医养生-经典养方" } },
                      [_vm._v("经典养方 ")]
                    ),
                    _c(
                      "el-dropdown-item",
                      { attrs: { command: "中医养生-食补食疗" } },
                      [_vm._v("食补食疗 ")]
                    ),
                    _c(
                      "el-dropdown-item",
                      { attrs: { command: "中医养生-刮痧" } },
                      [_vm._v("刮痧")]
                    ),
                    _c(
                      "el-dropdown-item",
                      { attrs: { command: "中医养生-按摩" } },
                      [_vm._v("按摩")]
                    ),
                    _c(
                      "el-dropdown-item",
                      { attrs: { command: "中医养生-针灸" } },
                      [_vm._v("针灸")]
                    ),
                    _c(
                      "el-dropdown-item",
                      { attrs: { command: "中医养生-艾灸" } },
                      [_vm._v("艾灸")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-dropdown",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: false,
                    expression: "false",
                  },
                ],
                attrs: { trigger: "click" },
                on: { command: _vm.moduleClick },
              },
              [
                _c("span", { staticClass: "el-dropdown-link" }, [
                  _vm._v(" 中医科研"),
                  _c("i", {
                    staticClass: "el-icon-caret-bottom el-icon--right",
                  }),
                ]),
                _c(
                  "el-dropdown-menu",
                  { attrs: { slot: "dropdown" }, slot: "dropdown" },
                  [
                    _c(
                      "el-dropdown-item",
                      { attrs: { command: "中医科研-病例分析" } },
                      [_vm._v("病例分析 ")]
                    ),
                    _c(
                      "el-dropdown-item",
                      { attrs: { command: "中医科研-药性分析" } },
                      [_vm._v("药性分析 ")]
                    ),
                    _c(
                      "el-dropdown-item",
                      { attrs: { command: "中医科研-实验报告" } },
                      [_vm._v("实验报告 ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-dropdown",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: false,
                    expression: "false",
                  },
                ],
                attrs: { trigger: "click" },
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "el-dropdown-link",
                    on: { command: _vm.moduleClick },
                  },
                  [
                    _vm._v(" 远程会诊"),
                    _c("i", {
                      staticClass: "el-icon-caret-bottom el-icon--right",
                    }),
                  ]
                ),
                _c(
                  "el-dropdown-menu",
                  { attrs: { slot: "dropdown" }, slot: "dropdown" },
                  [
                    _c(
                      "el-dropdown-item",
                      { attrs: { command: "远程会诊-审议药方" } },
                      [_vm._v("审议药方 ")]
                    ),
                    _c(
                      "el-dropdown-item",
                      { attrs: { command: "远程会诊-医共会诊" } },
                      [_vm._v("医共会诊 ")]
                    ),
                    _c(
                      "el-dropdown-item",
                      { attrs: { command: "远程会诊-上级会诊" } },
                      [_vm._v("上级会诊 ")]
                    ),
                    _c(
                      "el-dropdown-item",
                      { attrs: { command: "远程会诊-专家指导" } },
                      [_vm._v("专家指导 ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "logo-empty" }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "avatar-box" },
          [
            _c(
              "div",
              { staticStyle: { "margin-right": "20px", "font-size": "16px" } },
              [_vm._v(" 你好，" + _vm._s(_vm.name) + " ")]
            ),
            _c(
              "el-dropdown",
              {
                staticClass: "avatar-container right-menu-item hover-effect",
                attrs: { trigger: "click" },
              },
              [
                _c("div", { staticClass: "avatar-wrapper" }, [
                  _c("img", {
                    staticClass: "user-avatar",
                    attrs: { src: _vm.avatar },
                  }),
                  _c("i", { staticClass: "el-icon-caret-bottom" }),
                ]),
                _c(
                  "el-dropdown-menu",
                  { attrs: { slot: "dropdown" }, slot: "dropdown" },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/user/profile" } },
                      [_c("el-dropdown-item", [_vm._v("个人中心")])],
                      1
                    ),
                    _c(
                      "el-dropdown-item",
                      {
                        attrs: { divided: "" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.logout($event)
                          },
                        },
                      },
                      [_c("span", [_vm._v("退出登录")])]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "app-container-top" }, [
      _c(
        "div",
        { staticClass: "page-wrap-div" },
        [_c("Left", { ref: "left" })],
        1
      ),
      _c(
        "div",
        { staticClass: "page-wrap-div" },
        [_c("Right", { ref: "right" })],
        1
      ),
    ]),
    _vm._m(1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-log" }, [
      _c("img", {
        attrs: { src: require("../../../assets/logo/logo.png"), alt: "" },
      }),
      _c("div", { staticClass: "page-title" }, [
        _vm._v("中医智能辅助诊断服务系统"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "app-container-bottom" }, [
      _vm._v(" 中科云诊（河南）医疗科技有限公司 | "),
      _c(
        "a",
        { attrs: { href: "https://beian.miit.gov.cn/", target: "_blank" } },
        [_vm._v(" 豫ICP备2024065559号 ")]
      ),
      _vm._v(" | "),
      _c("img", {
        staticStyle: { width: "18px", height: "18px" },
        attrs: { src: require("../../../assets/images/gongan.png"), alt: "" },
      }),
      _c(
        "a",
        {
          attrs: {
            href: "https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=41010702003447",
            target: "_blank",
          },
        },
        [_vm._v(" 豫公网安备41010702003447号 ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }