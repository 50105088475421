<template>
  <dv-scroll-board :config="option" style="width: 100%; height: 100%" />
</template>

<script>
import { screenTime } from '@/utils'

export default {
  data() {
    return {
      option: {
        headerBGC: 'rgba(58, 141, 251, .6)',
        'header': [
          '上报医院',
          '坐诊疾病',
          '诊断医生',
          '上报时间'
        ],
        'data': [
          [
            '雁鸣湖院区',
            '风湿肺热病',
            '许慧美',
            '2024-01-19 14:16'
          ],
          [
            '雁鸣湖院区',
            '亨特综合征',
            '王艳敏',
            '2024-01-19 17:57'
          ],
          [
            '雁鸣湖院区',
            '肺炎喘嗽（毛细支气管炎）',
            '金兰英',
            '2024-01-19 13:17'
          ],
          [
            '雁鸣湖院区',
            '痿病（视神经脊髓炎）',
            '许慧美',
            '2024-01-19 15:53'
          ],
          [
            '雁鸣湖院区',
            '痿病（视神经脊髓炎）',
            '周安志',
            '2024-01-19 18:54'
          ],
          [
            '雁鸣湖院区',
            '亨特综合征',
            '梁乐邦',
            '2024-01-19 10:13'
          ],
          [
            '雁鸣湖院区',
            '亨特综合征',
            '王艳敏',
            '2024-01-19 14:12'
          ],
          [
            '雁鸣湖院区',
            '痿病（视神经脊髓炎）',
            '许慧美',
            '2024-01-19 13:20'
          ],
          [
            '雁鸣湖院区',
            '亨特综合征',
            '罗正豪',
            '2024-01-19 17:35'
          ]
        ]
      }

    }
  },
  created() {
  },
  mounted() {
    this.getData()

  },
  beforeDestroy() {
  },
  methods: {
    getData() {
      //生成今天的日期
      const now = screenTime(new Date(), 'yyyy-MM-dd')
      this.option = {
        headerBGC: 'rgba(58, 141, 251, .6)',
        'header': [
          '上报医院',
          '坐诊疾病',
          '诊断医生',
          '上报时间'
        ],
        'data': [
          [
            '雁鸣湖院区',
            '支气管炎',
            '卢**',
            now
          ],
          [
            '雁鸣湖院区',
            '流行性感冒',
            '刘**',
            now
          ],
          [
            '雁鸣湖院区',
            '肺炎喘嗽（毛细支气管炎）',
            '李**',
            now
          ],
          [
            '雁鸣湖院区',
            '呼吸道感染',
            '张**',
            now
          ],
          [
            '雁鸣湖院区',
            '呼吸道感染',
            '安**',
            now
          ],
          [
            '雁鸣湖院区',
            '心脏病',
            '宋**',
            now
          ],
          [
            '雁鸣湖院区',
            '高血压',
            '李**',
            now
          ],
          [
            '雁鸣湖院区',
            '中风',
            '高**',
            now
          ],
          [
            '雁鸣湖院区',
            '肠胃炎',
            '罗**',
            now
          ]
        ]
      }
    }
  }
}
</script>
<style lang="scss" scoped></style>
