<template>
  <Echart id="leftCenter" :options="options" ref="charts" />
</template>

<script>
import { getVisualHisDiagnosis, gethisDataSync } from '@/api/visualScreen'

export default {
  data() {
    return {
      timer: null, // 定时器标志
      diagnosis: [],
      options: {}
    }
  },
  created() {
  },
  mounted() {
    this.getData()
    this.timer = setInterval(() => {
      this.getData()
    }, 300000) // 每5秒发送一次请求
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    getData() {
      let params = {
        pageNum: 1,
        pageSize: 10
      }
      gethisDataSync(params,1).then((res) => {
        let data = JSON.parse(res.data[0].data).rows
        this.diagnosis = data || []
        let nameList = data.map(item => item.name)
        let valueList = data.map(item => item.value)
        this.init(nameList, valueList)
      }).catch(err => {
        this.diagnosis = [
          { name: '冠状动脉粥样硬化性心脏病', value: 414 },
          { name: '高血压', value: 410 },
          { name: '糖尿病伴多个并发症', value: 397 },
          { name: '血液透析', value: 266 },
          { name: '呼吸道感染', value: 192 },
          { name: '肺炎', value: 50 },
          { name: '肺炎', value: 40 },
          { name: '肺炎', value: 30 }]
        let nameList = this.diagnosis.map(item => item.name)
        let valueList = this.diagnosis.map(item => item.value)
        this.init(nameList, valueList)
      })
    },

    init(nameList, valueList) {
      this.options = {
        tooltip: {
          show: false,
          trigger: 'item',
          padding: [8, 15],
          backgroundColor: 'rgba(12, 51, 115,0.8)',
          borderColor: 'rgba(3, 11, 44, 0.5)',
          color: 'rgba(255, 255, 255, 1)',
          fontFamily: 'PingFangSC',
          fontSize: 24
        },
        legend: {
          show: true
        },
        grid: {
          left: '0',
          right: '15%',
          top: '9%',
          bottom: ''
        },
        xAxis: [
          {
            splitLine: {
              show: false
            },
            type: 'value',
            show: false,
            axisLine: {     //x轴坐标轴，false为隐藏，true为显示
              show: false
            }
          }
        ],
        yAxis: [
          {
            show: true,
            splitLine: {
              show: false
            },
            axisLine: {
              show: false
            },
            type: 'category',
            axisTick: {
              show: false
            },
            inverse: true,
            data: [1, 2, 3, 4, 5, 6, 7, 8],
            axisLabel: {
              show: true
            },
            axisPointer: {
              label: {
                show: true,
                margin: 0
              }
            }
          },
          {
            type: 'category',
            inverse: true,
            axisTick: 'none',
            axisLine: 'none',
            show: true,
            position: 'right',
            axisLabel: {
              inside: true,
              verticalAlign: 'middle',
              shadowOffsetX: '-20',
              lineHeight: 54,
              align: 'left',
              interval: 0,
              margin: -10,       //刻度标签与轴线之间的距离
              show: true,
              color: '#fff',
              fontFamily: 'PingFangSC',
              fontSize: 20,
              formatter: function (value) {
                return value + '次'
              }
            },
            data: valueList
          }
        ],
        series: [
          {
            show: true,
            name: '',
            type: 'bar',
            data: this.diagnosis,
            barWidth: 10, // 柱子宽度
            showBackground: true,
            backgroundStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 1,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(35, 74, 96, 1)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(35, 74, 96, 1)'
                  }
                ]
              }
            },
            label: {
              show: true,
              offset: [5, -20],
              color: 'rgba(255, 255, 255, 1)',
              fontFamily: 'PingFangSC',
              fontSize: 20,
              fontWeight: 600,
              position: 'left',
              align: 'left',
              formatter: function (params) {
                return params.data.name
              }
            },
            itemStyle: {
              BorderRadius: [20, 20, 20, 20],
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 1,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(50, 227, 234, .3)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(50, 227, 234, 1)'
                  }
                ]
              },
              shadowColor: '#26E1F7'
            }

          }
        ],
        dataZoom: [
          {
            yAxisIndex: [0, 1], // 这里是从X轴的0刻度开始
            show: false, // 是否显示滑动条，不影响使用
            type: 'slider', // 这个 dataZoom 组件是 slider 型 dataZoom 组件
            startValue: 0, // 从头开始。
            endValue: 4 // 展示到第几个。
          }
        ]
      }
    }
  }
}
</script>
<style lang="scss" scoped></style>
