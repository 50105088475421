var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popup-wrap" },
    [
      _c("div", { staticClass: "title" }, [_vm._v("添加收藏")]),
      _c("div", { staticClass: "wrap-item" }, [
        _c("div", { staticClass: "key" }, [_vm._v("药方名称")]),
        _c(
          "div",
          { staticClass: "value" },
          [
            _c("el-input", {
              attrs: { placeholder: "", size: "mini", clearable: "" },
              model: {
                value: _vm.form.prescriptionName,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "prescriptionName", $$v)
                },
                expression: "form.prescriptionName",
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "wrap-item" }, [
        _c("div", { staticClass: "key" }, [_vm._v("药方")]),
        _c("div", { staticClass: "value" }, [
          _c(
            "div",
            { staticClass: "kf" },
            [
              _vm._l(_vm.fList, function (item, index) {
                return _c("div", { key: index, staticClass: "yf-item" }, [
                  _c("div", { staticClass: "yt-item-name" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: item.name,
                          expression: "item.name",
                        },
                      ],
                      style: {
                        width: item.name
                          ? item.name.length * 14 + "px"
                          : "30px",
                      },
                      domProps: { value: item.name },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(item, "name", $event.target.value)
                        },
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "yt-item-number" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: item.number,
                          expression: "item.number",
                        },
                      ],
                      attrs: { type: "number" },
                      domProps: { value: item.number },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(item, "number", $event.target.value)
                        },
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "yt-item-g" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: item.g,
                          expression: "item.g",
                        },
                      ],
                      domProps: { value: item.g },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(item, "g", $event.target.value)
                        },
                      },
                    }),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "yt-item-remove",
                      on: {
                        click: function ($event) {
                          return _vm.removeF(index)
                        },
                      },
                    },
                    [_c("i", { staticClass: "el-icon-close" })]
                  ),
                ])
              }),
              _c(
                "div",
                { staticClass: "add_plus", on: { click: _vm.addPrescription } },
                [_c("i", { staticClass: "el-icon-plus" })]
              ),
            ],
            2
          ),
        ]),
      ]),
      _c("div", { staticClass: "wrap-item" }, [
        _c("div", { staticClass: "key" }, [_vm._v("用法、制法")]),
        _c(
          "div",
          { staticClass: "value" },
          [
            _c("el-input", {
              attrs: { placeholder: "", size: "mini", clearable: "" },
              model: {
                value: _vm.form.prescriptionZhifa,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "prescriptionZhifa", $$v)
                },
                expression: "form.prescriptionZhifa",
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "wrap-item" }, [
        _c("div", { staticClass: "key" }, [_vm._v("收藏夹")]),
        _c(
          "div",
          { staticClass: "value" },
          [
            _c(
              "el-select",
              {
                staticStyle: { width: "100%" },
                attrs: { placeholder: "请选择" },
                on: { change: _vm.labelChange },
                model: {
                  value: _vm.form.label,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "label", $$v)
                  },
                  expression: "form.label",
                },
              },
              _vm._l(_vm.shoucangList, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.label, value: item.id },
                })
              }),
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "bottom" }, [
        _c(
          "div",
          { staticClass: "button quxiao", on: { click: _vm.newFolder } },
          [_vm._v("新建收藏夹")]
        ),
        _c("div"),
        _c("div", { staticClass: "button", on: { click: _vm.save } }, [
          _vm._v("保存"),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "新建处方夹",
            visible: _vm.dialogVisible,
            width: "30%",
            modal: false,
            "modal-append-to-body": "",
            "append-to-body": true,
            "before-close": _vm.dialogBeforeClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "addForm",
                  attrs: {
                    model: _vm.addForm,
                    rules: _vm.rules,
                    "label-width": "120px",
                    "label-position": "top",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "处方夹名称", prop: "label" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.addForm.label,
                          callback: function ($$v) {
                            _vm.$set(_vm.addForm, "label", $$v)
                          },
                          expression: "addForm.label",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "描述", prop: "remark" } },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea" },
                        model: {
                          value: _vm.addForm.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.addForm, "remark", $$v)
                          },
                          expression: "addForm.remark",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { float: "right" },
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.onSubmit("addForm")
                            },
                          },
                        },
                        [_vm._v(" 确认创建 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            float: "right",
                            "margin-right": "10px",
                          },
                          on: {
                            click: function ($event) {
                              _vm.dialogVisible = false
                            },
                          },
                        },
                        [_vm._v(" 取消 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }