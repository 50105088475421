import request from "@/utils/request";

//四诊患者信息保存接口
export function sizhenInfoSave(data) {
  return request({
    url: "/tcmrobot/patinfo/save",
    method: "post",
    data: data,
  });
}

//舌诊照片保存
export function shezhenPhotoSave(data) {
  return request({
    url: "/tcmrobot/patinfo/sz_she_take_save",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    method: "post",
    data: data,
  });
}
//舌诊结果更新
export function updataPatMedic(data) {
  return request({
    url: "/tcmrobot/patinfo/savePatMedic",
    method: "post",
    data: data,
  });
}
//获取体质辨识结果
export function getTiZhi(data) {
  return request({
    url: `/tcmrobot/patinfo/sz_tizhi_calc`,
    method: "post",
    params: data,
  });
}

//设备管理列表查询
export function getDeviceList(data) {
  return request({
    url: `/external/sizhen/device/zkxc/GetList`,
    method: "get",
    params: data,
  });
}

//用户列表查询
export function getUserList(data) {
  return request({
    url: `/external/sizhen/device/user/allocatedList`,
    method: "get",
    params: data,
  });
}

//取消绑定
export function cancelBind(data) {
  return request({
    url: `/external/sizhen/device/user/cancel`,
    method: "post",
    params: data,
  });
}
//批量取消绑定
export function batchCancelBind(data) {
  return request({
    url: `/external/sizhen/device/user/cancelAll`,
    method: "post",
    params: data,
  });
}

//获取未关联的用户列表
export function getUnBindUserList(data) {
  return request({
    url: `/external/sizhen/device/user/unallocatedList`,
    method: "get",
    params: data,
  });
}

//批量绑定用户
export function batchBind(data) {
  return request({
    url: `/external/sizhen/device/user/addDeviceUserAll`,
    method: "post",
    params: data,
  });
}
