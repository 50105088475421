<template>
  <sizhen-layout :lastPathShow="false" @handleNextStep="handleNextStep()">
    <div class="content">
      <el-form
        :model="infoForm"
        :inline="true"
        :rules="rules"
        ref="infoForm"
        label-width="100px"
      >
        <div class="item">
          <title-vue :title="'基本信息'"></title-vue>
          <div>
            <el-row>
              <el-col :span="6">
                <el-form-item label="患者姓名：" prop="name">
                  <el-input
                    v-model="infoForm.name"
                    placeholder="患者姓名"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="身份证号：" prop="idcard">
                  <el-input
                    v-model="infoForm.idcard"
                    placeholder="身份证号"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="患者性别：" prop="gender">
                  <el-radio-group v-model="infoForm.gender">
                    <el-radio label="男">男性</el-radio>
                    <el-radio label="女">女性</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="出生日期：" prop="birthday">
                  <el-date-picker
                    v-model="infoForm.birthday"
                    type="date"
                    placeholder="出生日期"
                    @change="changeBirthday"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                <el-form-item label="年龄：" prop="age">
                  <el-input
                    v-model.number="infoForm.age"
                    placeholder="年龄"
                    @input="agechange"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="民族：" prop="nation">
                  <el-input
                    v-model="infoForm.nation"
                    placeholder="民族"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="身高：" prop="height">
                  <el-input
                    v-model="infoForm.height"
                    placeholder="身高"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="体重：" prop="weight">
                  <el-input
                    v-model="infoForm.weight"
                    placeholder="体重"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                <el-form-item label="职业：" prop="occupation">
                  <el-input
                    v-model="infoForm.occupation"
                    placeholder="职业"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="手机号：" prop="cellphone">
                  <el-input
                    v-model.number="infoForm.cellphone"
                    placeholder="手机号"
                    type="tel"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>

        <div class="item">
          <title-vue :title="'既往病史'"></title-vue>
          <div>
            <el-row>
              <el-col :span="6">
                <el-form-item label="过敏史：">
                  <el-input
                    v-model="infoForm.guominshi"
                    placeholder="过敏史"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="既往史：">
                  <el-input
                    v-model="infoForm.jiwangshi"
                    placeholder="既往史"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="家族史：">
                  <el-input
                    v-model="infoForm.jiazushi"
                    placeholder="家族史"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>

        <div class="item" v-show="false">
          <title-vue :title="'生活健康'"></title-vue>
          <div>
            <el-row>
              <el-col :span="6">
                <el-form-item label="吸烟：">
                  <el-radio-group v-model="infoForm.smoke">
                    <el-radio label="是"></el-radio>
                    <el-radio label="否"></el-radio>
                    <el-radio label="已戒"></el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="烟龄：">
                  <el-input v-model="infoForm.smoke">
                    <template v-slot:suffix>年</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="每日吸烟量：">
                  <el-input v-model="infoForm.smoke">
                    <template v-slot:suffix>支/天</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                <el-form-item label="饮酒：">
                  <el-radio-group v-model="infoForm.smoke">
                    <el-radio label="是"></el-radio>
                    <el-radio label="否"></el-radio>
                    <el-radio label="偶尔"></el-radio>
                    <el-radio label="已戒"></el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="酒龄：">
                  <el-input v-model="infoForm.smoke">
                    <template v-slot:suffix>年</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="每日饮酒量：">
                  <el-input v-model="infoForm.smoke">
                    <template v-slot:suffix>ml/天</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                <el-form-item label="饮食：">
                  <el-radio-group v-model="infoForm.smoke">
                    <el-radio label="规律"></el-radio>
                    <el-radio label="不规律"></el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-slider
                  v-model="infoForm.smoke"
                  :format-tooltip="formatTooltip"
                ></el-slider>
              </el-col>
              <el-col :span="6">
                <el-form-item label="每日饮酒量：">
                  <el-input v-model="infoForm.smoke">
                    <template v-slot:suffix>ml/天</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-form>
    </div>
  </sizhen-layout>
</template>

<script>
import sizhenLayout from "@/views/sizhen/components/layout";
import titleVue from "@/views/sizhen/components/title";
import { getBillNo } from "@/api/zkyz/aictm";
import { mapActions } from "vuex";
import { formatDate } from "@/utils";
export default {
  name: "sizhenInfo",
  components: {
    sizhenLayout,
    titleVue,
  },
  data() {
    return {
      infoForm: {
        comedate: null,
        billno: null,
        name: null,
        gender: null,
        age: null,
        birthday: null,
        idcard: null,
        nation: null,
        height: null,
        weight: null,
        occupation: null,
        guominshi: null,
        jiwangshi: null,
        jiazushi: null,
        xianbingshi: null,
        cellphone: null,
        smoke: null,
      },
      rules: {
        name: [
          { required: true, message: "请输入患者名称", trigger: "blur" },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        // idcard: [
        //   { required: true, message: "身份证号不能为空" },
        //   { type: "string", message: "身份号必须为数字" },
        //   {
        //     pattern:
        //       /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
        //     message: "不是合法的身份证号！",
        //     trigger: "blur",
        //   },
        // ],
        gender: [{ required: true, message: "请选择性别", trigger: "change" }],
        cellphone: [
          { required: true, message: "手机号不能为空" },
          { type: "number", message: "手机号必须为数字" },
          {
            pattern: /^1(3|4|5|6|7|8|9)\d{9}$/,
            message: "手机号格式不对",
            trigger: "blur",
          },
        ],
        age: [
          { required: true, message: "年龄不能为空" },
          { type: "number", message: "年龄必须为数字值" },
        ],
      },
      nextPath: "/shezhen",
    };
  },
  beforeDestroy() {},
  mounted() {
    let date = new Date();
    this.infoForm.comedate = formatDate(date, "yyyy-MM-dd 00:00:00");
    getBillNo().then((res) => {
      this.infoForm.billno = res.msg;
    });
  },
  methods: {
    ...mapActions(["saveInfo", "touchActive"]),
    /**
     * 修改年龄
     */
    agechange(e) {
      let date = new Date();
      let year = date.getFullYear();
      let birthdayYear = year - e + "";
      let val = date + "";
      let arr = val.split("");
      arr.splice(11, 4, birthdayYear);
      let time = arr.join("");
      this.infoForm.birthday = formatDate(time, "yyyy-MM-dd 00:00:00");
    },
    /**
     * 修改生日
     */
    changeBirthday() {
      let date = this.infoForm.birthday;
      if (!date) {
        return;
      }
      let val = date + "";
      let currentYear = new Date().getFullYear();
      let calculationYear = new Date(val).getFullYear();
      const wholeTime = currentYear + val.substring(4);
      let calculationAge = currentYear - calculationYear;
      if (new Date().getTime() <= new Date(wholeTime).getTime()) {
        calculationAge = calculationAge - 1;
      }
      if (calculationAge < 0) {
        return;
      }
      this.infoForm.age = calculationAge;
    },
    /**
     * 下一步，验证必填项
     */
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveInfo(this.infoForm);
          this.touchActive(1);
        } else {
          return false;
        }
      });
    },
    handleNextStep() {
      this.submitForm("infoForm");
      this.$emit("handleNextStep");
    },
    formatTooltip(val) {
      return val / 100;
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  .item {
    background: #ffffff;
    border-radius: 8px;
    margin-bottom: 10px;
    padding: 16px;
  }
}
</style>
