var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "popup-wrap" }, [
    _c(
      "div",
      { staticClass: "body" },
      _vm._l(_vm.list, function (item, index) {
        return _c("div", { key: index, staticClass: "item" }, [
          _c(
            "div",
            {
              staticClass: "label",
              on: {
                click: function ($event) {
                  return _vm.fy(item)
                },
              },
            },
            [_vm._v("复用")]
          ),
          _vm.type == 3
            ? _c("div", { staticClass: "value" }, [
                _c("span", [_vm._v("【" + _vm._s(item.yfm) + "】")]),
                _vm._v(_vm._s(item.zz) + "(其他:" + _vm._s(item.yfs) + ") "),
              ])
            : _c("div", { staticClass: "value" }, [
                _c("span", [_vm._v("【" + _vm._s(item.zz) + "】")]),
                _c("span", [_vm._v("【" + _vm._s(item.yfm) + "】")]),
                _vm._v(_vm._s(item.yfs) + "——来源"),
                _c("span", [_vm._v(_vm._s(item.laiyuan || "-"))]),
              ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }