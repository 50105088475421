var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ScaleScreen",
    {
      staticClass: "scale-wrap",
      attrs: { width: 1920, height: 1080, selfAdaption: true },
    },
    [
      _c(
        "div",
        { staticClass: "bg" },
        [
          _vm.loading
            ? _c("dv-loading", [_vm._v("Loading...")])
            : _c("div", { staticClass: "host-body" }, [
                _c("div", { staticClass: "title_wrap" }, [
                  _c("div", { staticClass: "headline" }, [
                    _c("div", [_vm._v("中科云诊中医智能管理平台")]),
                  ]),
                  _c("div", { staticClass: "timers" }, [
                    _c("div", { staticClass: "dateyear" }, [
                      _c("div", { staticClass: "clock_box" }),
                      _c("div", [_vm._v(_vm._s(_vm.dateYear))]),
                    ]),
                    _c("div", { staticClass: "timers_left" }, [
                      _c("div", { staticClass: "yinli_box" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.YearInGanZhi) +
                            "年 " +
                            _vm._s(_vm.MonthInChinese) +
                            "月 " +
                            _vm._s(_vm.DayInChinese) +
                            " "
                        ),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.jieQi,
                                expression: "jieQi",
                              },
                            ],
                          },
                          [_vm._v("今日" + _vm._s(_vm.jieQi))]
                        ),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.jieQi,
                                expression: "!jieQi",
                              },
                            ],
                          },
                          [_vm._v(_vm._s(_vm.lastJieQi))]
                        ),
                      ]),
                      _c("div", { staticClass: "yinli_box" }, [
                        _vm._v(" 动静结合、劳逸结合、补泻结合、形神共养 "),
                      ]),
                    ]),
                  ]),
                  _c("div", {
                    staticClass: "skip",
                    on: { click: _vm.toVisualScreen },
                  }),
                ]),
                _c("div", { staticClass: "content" }, [
                  _c("div", { staticClass: "left" }, [
                    _c("div", { staticClass: "topbox" }, [
                      _c("div", { staticClass: "topbox-mid" }, [
                        _c(
                          "div",
                          { staticClass: "item" },
                          [
                            _c("itemTitle", {
                              attrs: { title: "院内就诊统计" },
                            }),
                            _c(
                              "div",
                              { staticClass: "com-box" },
                              [_c("rightCenter")],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "topbox-top" }, [
                        _c(
                          "div",
                          { staticClass: "item" },
                          [
                            _c("itemTitle", {
                              attrs: { title: "中医处方质控统计" },
                            }),
                            _c(
                              "div",
                              { staticClass: "com-box" },
                              [_c("rightTop")],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "topbox-bot" }, [
                        _c(
                          "div",
                          { staticClass: "item" },
                          [
                            _c("itemTitle", { attrs: { title: "就诊趋势" } }),
                            _c(
                              "div",
                              { staticClass: "com-box" },
                              [_c("rightBottom")],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "bottombox" }, [
                      _c("div", { staticClass: "bottombox-bot" }, [
                        _c(
                          "div",
                          { staticClass: "item" },
                          [
                            _c("itemTitle", {
                              attrs: { title: "高发疾病统计" },
                            }),
                            _c(
                              "div",
                              { staticClass: "com-box" },
                              [_c("rightBottomTwo")],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "centre" }, [
                    _c("div", { staticClass: "centre-top" }, [
                      _c(
                        "div",
                        { staticStyle: { height: "100%" } },
                        [
                          _c("CenterMap"),
                          _c("div", { staticClass: "yuanpan" }, [
                            _c("img", {
                              staticClass: "rotate zindex1",
                              attrs: {
                                src: require("../../assets/qualityConyrolScreen/one_spin1.png"),
                                alt: "",
                              },
                            }),
                            _c("img", {
                              staticClass: "rotate zindex1 rotate-high",
                              attrs: {
                                src: require("../../assets/qualityConyrolScreen/one_spin2.png"),
                                alt: "",
                              },
                            }),
                            _c("img", {
                              staticClass: "fixed zindex1",
                              attrs: {
                                src: require("../../assets/qualityConyrolScreen/one_fiexd.png"),
                                alt: "",
                              },
                            }),
                            _c("img", {
                              staticClass: "fixed zindex2",
                              attrs: {
                                src: require("../../assets/qualityConyrolScreen/two_fiexd.png"),
                                alt: "",
                              },
                            }),
                            _c("img", {
                              staticClass: "rotate zindex2 rotate-low",
                              attrs: {
                                src: require("../../assets/qualityConyrolScreen/two_spin.png"),
                                alt: "",
                              },
                            }),
                            _c("img", {
                              staticClass: "rotate zindex3",
                              attrs: {
                                src: require("../../assets/qualityConyrolScreen/three_spin1.png"),
                                alt: "",
                              },
                            }),
                            _c("img", {
                              staticClass: "rotate zindex3 rotate-low",
                              attrs: {
                                src: require("../../assets/qualityConyrolScreen/three_spin2.png"),
                                alt: "",
                              },
                            }),
                            _c("img", {
                              staticClass: "fixed zindex3",
                              attrs: {
                                src: require("../../assets/qualityConyrolScreen/three_fixed.png"),
                                alt: "",
                              },
                            }),
                          ]),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "centre-mid" }, [
                      _c(
                        "div",
                        {
                          staticClass: "item",
                          staticStyle: { height: "100%" },
                        },
                        [
                          _c("itemTitle", {
                            attrs: { title: "中医智能诊断报告" },
                          }),
                          _c(
                            "div",
                            { staticClass: "com-box" },
                            [
                              _c("CenterBottom", {
                                attrs: {
                                  jieqi: _vm.lastJieQi,
                                  shichenObj: _vm.shichenObj,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "right" }, [
                    _c("div", { staticClass: "topbox" }, [
                      _c("div", { staticClass: "topbox-top" }, [
                        _c(
                          "div",
                          { staticClass: "item" },
                          [
                            _c("itemTitle", {
                              attrs: { title: "中医智能系统概况" },
                            }),
                            _c(
                              "div",
                              { staticClass: "com-box" },
                              [_c("leftTop")],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "topbox-mid" }, [
                        _c(
                          "div",
                          { staticClass: "item" },
                          [
                            _c("itemTitle", {
                              attrs: { title: "中医智能平台使用情况" },
                            }),
                            _c(
                              "div",
                              { staticClass: "com-box" },
                              [_c("leftCenter")],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "topbox-bot" }, [
                        _c(
                          "div",
                          { staticClass: "item" },
                          [
                            _c("itemTitle", {
                              attrs: { title: "中医知识库学习情况" },
                            }),
                            _c(
                              "div",
                              { staticClass: "com-box" },
                              [_c("leftCenterTwo")],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "bottombox" }, [
                      _c("div", { staticClass: "bottombox-bot" }, [
                        _c(
                          "div",
                          { staticClass: "item" },
                          [
                            _c("itemTitle", { attrs: { title: "医生简介" } }),
                            _c(
                              "div",
                              { staticClass: "com-box" },
                              [_c("leftBottom")],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }