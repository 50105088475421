import Base from './Base'

/**
 * 6. 按钮点击次数
 *
 */
class ButtonClick extends Base {

  constructor() {
    super('buttonClick');
    this.pool = {};
    this.list = [];
  }

  click(button) {
    let count = this.pool[button] || 0;
    count++;
    this.pool[button] = count;

    this.list.push({
      timestamp: new Date().getTime(),
      button: button
    })
  }

  getData() {
    if (this.list.length < 1) {
      return null;
    }
    return {
      pool: this.pool,
      list: this.list
    }
  }
}

let buttonClick = new ButtonClick();
export default buttonClick
