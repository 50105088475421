import { sizhenInfoSave } from "@/api/sizhen/sizhen";

const sizhenData = {
  state: {
    comedate: "",
    name: "",
    gender: "",
    age: null,
    birthday: "",
    idcard: "",
    cellphone: "",
    doctorId: "",
    medicId: "",
    active: 0,
    tizhi: "",
    shezhen: {},
  },
  mutations: {
    SET_NAME: (state, name) => {
      state.name = name;
    },
    SET_GENDER: (state, gender) => {
      state.gender = gender;
    },
    SET_AGE: (state, age) => {
      state.age = age;
    },
    SET_BIRTHDAY: (state, birthday) => {
      state.birthday = birthday;
    },
    SET_ID_CARD: (state, idcard) => {
      state.idcard = idcard;
    },
    SET_CELL_PHONE: (state, cellphone) => {
      state.cellphone = cellphone;
    },
    SET_DOCTOR_ID: (state, doctorId) => {
      state.doctorId = doctorId;
    },
    SET_MEDIC_ID: (state, medicId) => {
      state.medicId = medicId;
    },
    SET_ACTIVE: (state, active) => {
      state.active = active;
    },
    ADD_ACTIVE: (state) => {
      state++;
    },
    SET_TIZHI: (state, tizhi) => {
      state.tizhi = tizhi;
    },
    SET_SHEZHEN: (state, shezhen) => {
      state.shezhen = shezhen;
    },
    SET_COME_DATE: (state, comedate) => {
      state.comedate = comedate;
    },
    SET_BILLNO: (state, billno) => {
      state.billno = billno;
    },
  },
  actions: {
    saveInfo({ dispatch }, info) {
      sizhenInfoSave(info).then((res) => {
        if (res.code === 200) {
          dispatch("saveName", info.name);
          dispatch("saveGender", info.gender);
          dispatch("saveAge", info.age);
          dispatch("saveBirthday", info.birthday);
          dispatch("saveIdcard", info.idcard);
          dispatch("saveCellphone", info.cellphone);
          dispatch("saveDoctorId", info.doctorId);
          dispatch("saveMedicId", res.data.medicId);
          dispatch("saveComeDate", info.comedate);
          dispatch("saveBillno", info.billno);
        }
      });
    },
    saveName({ commit }, name) {
      commit("SET_NAME", name);
    },
    saveGender({ commit }, gender) {
      commit("SET_GENDER", gender);
    },
    saveAge({ commit }, age) {
      commit("SET_AGE", age);
    },
    saveBirthday({ commit }, birthday) {
      commit("SET_BIRTHDAY", birthday);
    },
    saveIdcard({ commit }, idcard) {
      commit("SET_ID_CARD", idcard);
    },
    saveCellphone({ commit }, cellphone) {
      console.log(cellphone,'cellphone');
      commit("SET_CELL_PHONE", cellphone);
    },
    saveDoctorId({ commit }, doctorId) {
      commit("SET_DOCTOR_ID", doctorId);
    },
    saveMedicId({ commit }, medicId) {
      commit("SET_MEDIC_ID", medicId);
    },
    touchActive({ commit }, active) {
      commit("SET_ACTIVE", active);
    },
    addActive({ commit }) {
      commit("ADD_ACTIVE");
    },
    saveTizhi({ commit }, tizhi) {
      commit("SET_TIZHI", tizhi);
    },
    saveShezhen({ commit }, shezhen) {
      commit("SET_SHEZHEN", shezhen);
    },
    saveComeDate({ commit }, comedate) {
      commit("SET_COME_DATE", comedate);
    },
    saveBillno({ commit }, billno) {
      commit("SET_BILLNO", billno);
    },
  },
};
export default sizhenData;
