const defaultOptions = {
  pdf: null, // jsPDF 对象
  quality: 'st', // 清晰度，st-标准清晰，hd-高清, uhd-超清
  qualityObject: {
    st: 2,
    hd: 4,
    uhd: 8
  },
  html2canvasConfig: {
    scale: 1,
    dpi: 350
  },
  a4: {
    // 准备好不同英寸点数对应的不同尺寸规格，一般这里只用 xDpi 就足够了，特殊的机子暂时没碰到，碰到了也不管，爱谁谁
    72: [595, 842],
    96: [794, 1123],
    120: [1487, 2105],
    150: [1240, 1754],
    300: [2480, 3508],
  },
  
  ratio: 1, // 物理像素和设备像素之间的比率
  xDpi: 0, // 每英寸x方向点数
  yDpi: 0, // 每英寸y方向点数
  a4W: 0, // a4纸宽转px之后
  a4H: 0, // a4纸高转px之后

  headerHeight: 50, // 页眉高
  footerHeight: 50, // 页脚高
  headerFlag: false, // 是否展示页眉
  footerFlag: false, // 是否展示页脚

  headEl: null, // 页眉元素对象
  footEl: null, // 页脚元素对象
  homeEl: null, // 首页元素对象
  pagesEl: null, // 内容页元素对象

  headCanvas: null, // 页眉内容canvas对象
  footCanvas: null, // 页脚内容canvas对象
  homeCanvas: null, // 首页内容canvas对象
  pagesCanvas: [], // 合同内容canvas对象

  previewBox: null, // 要预览的盒子元素对象

  copyCanvas: null, // 临时的 canvas
  copyContext: null, // 临时的 context

  totalPage: 1, // pdf总页数，默认就是1页
  currentPage: 1, // pdf当前页码 默认第一页
  pageClass: '',

  copyCanvasRemainingH: 0, // 临时的 canvas 剩余高度
  currentCanvasRemainingH: 0, // 当前绘制的 canvas 对象剩余高度

  showLoading: true,
  loading: null,
}

export default defaultOptions