<template>
  <Echart
    :options="options"
    id="leftTops"
    style="width: 240px; height: 200px; margin: 0 auto"
  />
</template>

<script>
import { getVisualScreenData } from '@/api/visualScreen'

export default {
  name: 'leftTops',
  data() {
    return {
      timer: null,
      options: {}
    }
  },
  filters: {},
  created() {
  },
  mounted() {
    this.getData()
    this.timer = setInterval(() => {
      this.getData()
    }, 300000) // 每5秒发送一次请求
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    getData() {
      getVisualScreenData('histogramog').then((res) => {
        let newData = res.data
        this.init(newData)
      })
    },
    init(newData) {
      this.options = {
        title: {
          left: 'center'
        },
        tooltip: {
          show: true,
          trigger: 'item',
          textStyle: {
            color: '#000'
          }
        },
        series: [
          {
            name: newData.name,
            type: 'pie',
            radius: ['30%', '50%'],
            data: newData.data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
                borderColor: '#fff',
                borderWidth: 3
              },
              display: false
            }
          }
        ]
      }
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
