<template>
  <Popup ref="popup" :show-title="false" padding="16px">
    <div class="popup-wrap">
      <div class="title">{{ form.zy }}</div>
      <div class="body">
        <div class="item">
          <div class="label">是否主症:</div>
          <div class="value">
            <div class="text-item" :class="{active: form.main.indexOf(item) > -1}"
                 @click="choose('main', item, true)" v-for="(item,index) in info.main" :key="index">{{ item }}
            </div>
          </div>
        </div>
        <div class="item">
          <div class="label">发生因素:</div>
          <div class="value">
            <div class="text-item" :class="{active: form.fashengyinsu.indexOf(item) > -1}"
                 @click="choose('fashengyinsu', item)" v-for="(item,index) in info.fashengyinsu" :key="index">{{ item }}
            </div>
          </div>
        </div>
        <div class="item">
          <div class="label">症状特征:</div>
          <div class="value">
            <div class="text-item" :class="{active: form.tezheng.indexOf(item) > -1}"
                 @click="choose('tezheng', item)" v-for="(item,index) in info.tezheng" :key="index">{{ item }}
            </div>
          </div>
        </div>
        <div class="item">
          <div class="label">轻重程度:</div>
          <div class="value">
            <div class="text-item" :class="{active: form.chengdu.indexOf(item) > -1}"
                 @click="choose('chengdu', item)" v-for="(item,index) in info.chengdu" :key="index">{{ item }}
            </div>
          </div>
        </div>
        <div class="item">
          <div class="label">发作频次:</div>
          <div class="value">
            <div class="text-item" :class="{active: form.fazuopinci.indexOf(item) > -1}"
                 @click="choose('fazuopinci', item)" v-for="(item,index) in info.fazuopinci" :key="index">{{ item }}
            </div>
          </div>
        </div>
        <div class="item">
          <div class="label">病程时长:</div>
          <div class="value">
            <div class="text-item" :class="{active: form.bingcheng.indexOf(item) > -1}"
                 @click="choose('bingcheng', item)" v-for="(item,index) in info.bingcheng" :key="index">{{ item }}
            </div>
          </div>
        </div>
        <div class="item">
          <div class="label">加重因素:</div>
          <div class="value">
            <div class="text-item" :class="{active: form.jiazhong.indexOf(item) > -1}"
                 @click="choose('jiazhong', item)" v-for="(item,index) in info.jiazhong" :key="index">{{ item }}
            </div>
          </div>
        </div>
        <div class="item">
          <div class="label">缓解因素:</div>
          <div class="value">
            <div class="text-item" :class="{active: form.huanjie.indexOf(item) > -1}"
                 @click="choose('huanjie', item)" v-for="(item,index) in info.huanjie" :key="index">{{ item }}
            </div>
          </div>
        </div>
        <div class="item">
          <div class="label">持续时间:</div>
          <div class="value">
            <div class="text-item" :class="{active: form.chixushijian.indexOf(item) > -1}"
                 @click="choose('chixushijian', item)" v-for="(item,index) in info.chixushijian" :key="index">{{ item }}
            </div>
          </div>
        </div>
      </div>
      <div class="btn" @click="confirm">确认</div>
    </div>
  </Popup>
</template>

<script>
import Popup from '@/views/zkyz/aitcm/popup/popup.vue'
import {getDiseaseCard} from '@/api/zkyz/aictm'

export default {
  name: "popup-zhenduan",
  components: {Popup},
  props: {},
  data() {
    return {
      loading: true,
      info: {
        main: [],
        bingcheng: [],
        chengdu: [],
        chixushijian: [],
        disease: [],
        fashengyinsu: [],
        fazuopinci: [],
        huanjie: [],
        in_zy: [],
        jiazhong: [],
        tezheng: [],
        zy: null,
        zy_pinyin: null
      },
      item: {},
      dis:null,
      form: {
        zy: null,
        main: [],
        fashengyinsu: [],
        tezheng: [],
        chengdu: [],
        fazuopinci: [],
        bingcheng: [],
        jiazhong: [],
        huanjie: [],
        chixushijian: [],
      }
    }
  },
  mounted() {
  },
  methods: {
    close() {
      this.$refs.popup.close();
    },
    confirm() {
      this.close();

      // 构建表单
      let form = JSON.parse(JSON.stringify(this.form));
      let ignoreList = ['zy', 'in_zy'];
      for (let field in form) {
        if (ignoreList.indexOf(field) > -1) {
          continue;
        }
        form[field] = form[field].join('|')
      }
      JSON.stringify()
      this.form.field = this.item.field;
      form.field = this.item.field;
      this.$emit('confirm', {
        form,
        item: this.item
      });
    },

    open(item, dis) {
      this.item = item;
      this.dis = dis;
      let loading = this.$loading()
      getDiseaseCard({
        dis: this.dis || '全',
        v: item.zy
      }).then(res => {
        loading.close();
        let rows = res.data;
        if (!rows || rows.length < 1) {
          this.$message.error("无数据");
          setTimeout(() => {
            this.close();
          }, 1000)
          return;
        }
        this.formatInfo(rows[0])
      }).catch(() => {
        loading.close();
      })
    },
    formatInfo(info) {
      this.reset();

      let ignoreList = ['zy', 'in_zy', 'zy_pinyin'];
      for (let field in info) {
        if (ignoreList.indexOf(field) > -1) {
          continue;
        }
        let arr = info[field] ? info[field].split('|') : [];
        let list = [];
        for (let item of arr) {
          if (!item) {
            continue;
          }
          if (list.indexOf(item) > -1) {
            continue;
          }
          list.push(item);
        }
        info[field] = list;
      }
      info.main = ['是', '否'];
      this.info = info;
      this.form.zy = info.in_zy;

      this.$refs.popup.open();
    },
    reset() {
      this.form = {
        zy: null,
        main: [],
        fashengyinsu: [],
        tezheng: [],
        chengdu: [],
        fazuopinci: [],
        bingcheng: [],
        jiazhong: [],
        huanjie: [],
        chixushijian: [],
      }
    },
    choose(field, item, singleton = false) {
      let list = this.form[field];
      let index = list.indexOf(item);
      if (index > -1) {
        list.splice(index, 1);
      } else {
        if (singleton) {
          list = [item];
        } else {
          list.push(item);
        }
      }
      this.form[field] = list;
    },
  }
}
</script>
<style lang="scss" scoped>
.popup-wrap {
  width: 500px;
  height: 50vh;
  display: flex;
  flex-direction: column;
  font-family: cursive;

}

.title {
  background: url("../../../../assets/images/15.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: white;
  font-size: 18px;
  font-weight: 500;
}

.btn {
  background: url("../../../../assets/images/04.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 30px;
  width: 100px;
  padding: 0 10px;
  line-height: 30px;
  text-align: center;
  color: white;
  font-weight: 500;
  cursor: pointer;
  margin: 0 auto;
}

.body {
  border: 1px solid var(--main-border-color);
  border-radius: 8px;
  margin: 10px 0;
  padding: 0 8px 8px 8px;
  flex: 1;
  overflow-y: scroll;

  .item {
    display: flex;
    flex-direction: row;
    padding: 2px 0;

    .label {
      font-size: 16px;
      font-weight: bold;
      margin-right: 4px;
      margin-top: 10px;
    }

    .value {
      flex: 1;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      font-size: 16px;

      .text-item {
        cursor: pointer;
        color: var(--main-border-color);
        padding: 1px 4px;
        border: 1px solid var(--main-border-color);
        margin-right: 8px;
        margin-top: 8px;

        &.active {
          background: var(--main-border-color);
          color: white;
        }
      }
    }
  }
}
</style>

