import { render, staticRenderFns } from "./left-bottom.vue?vue&type=template&id=20c8f19e&scoped=true"
import script from "./left-bottom.vue?vue&type=script&lang=js"
export * from "./left-bottom.vue?vue&type=script&lang=js"
import style0 from "./left-bottom.vue?vue&type=style&index=0&id=20c8f19e&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20c8f19e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\code\\gitee\\zkyz-frout\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('20c8f19e')) {
      api.createRecord('20c8f19e', component.options)
    } else {
      api.reload('20c8f19e', component.options)
    }
    module.hot.accept("./left-bottom.vue?vue&type=template&id=20c8f19e&scoped=true", function () {
      api.rerender('20c8f19e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/visualScreen/components/left-bottom.vue"
export default component.exports