export const mixins = {
  data() {
    return {
      dateArray: [],
    };
  },
  created() {},
  mounted() {
    this.getDay(7);
  },
  methods: {
    //获取前几天
    getDay(day) {
      // let myDate = new Date(); //获取今天日期
      // myDate.setDate(myDate.getDate() - (day - 1)); //setDate() 方法用于设置一个月的某一天。
      // let month = myDate.getMonth() + 1;
      // let dateArray = [];
      // let dateTemp;
      // let flag = 1;
      // for (let i = 1; i < day; i++) {
      //   dateTemp = month + "月" + myDate.getDate() + "日";
      //   console.log(dateTemp, month, "dateTemp");
      //   dateArray.push(dateTemp);
      //   myDate.setDate(myDate.getDate() + flag);
      // }
      // dateArray.push(month + "月" + myDate.getDate() + "日");
      // this.dateArray = dateArray;
      var date = new Date();
      var base = new Date(date).getTime();
      var oneDay = 24 * 3600 * 1000;
      var date = [];
      var data = [Math.random() * 300];
      var time = new Date(base);
      date.push(
        [time.getFullYear(), time.getMonth() + 1, time.getDate()].join("/")
      );
      for (var i = 1; i < 7; i++) {
        //控制需要的天数
        var now = new Date((base -= oneDay)); //这里控制往前一周还是往后一周
        date.push(
          [now.getFullYear(), now.getMonth() + 1, now.getDate()].join("/")
        );
        data.push(Math.round((Math.random() - 0.5) * 20 + data[i - 1]));
      }
      var newdate = date.reverse(); //往后一周的话需要将数组倒叙，完后不需要
      this.dateArray = newdate;
    },
  },
};
