var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "top" },
      [
        _c("img", {
          staticClass: "fixed",
          staticStyle: { "z-index": "2" },
          attrs: {
            src: require("../../../assets/visualScreenImg/fixed_bottom.png"),
            alt: "",
          },
        }),
        _c("img", {
          staticClass: "rotate rotate-high",
          attrs: {
            src: require("../../../assets/visualScreenImg/yuan1.png"),
            alt: "",
          },
        }),
        _c("img", {
          staticClass: "rotate",
          attrs: {
            src: require("../../../assets/visualScreenImg/yuan2.png"),
            alt: "",
          },
        }),
        _c("img", {
          staticClass: "fixed yuanpan",
          attrs: {
            id: "yuanpan",
            src: require("../../../assets/visualScreenImg/yuan3.png"),
            alt: "",
          },
        }),
        _c("img", {
          staticClass: "rotate",
          attrs: {
            src: require("../../../assets/visualScreenImg/yuan4.png"),
            alt: "",
          },
        }),
        _c("img", {
          staticClass: "rotate rotate-low",
          attrs: {
            src: require("../../../assets/visualScreenImg/yuan5.png"),
            alt: "",
          },
        }),
        _c("img", {
          staticClass: "rotate rotate-high",
          attrs: {
            src: require("../../../assets/visualScreenImg/yuan6.png"),
            alt: "",
          },
        }),
        _vm._l(_vm.ziwuliuList, function (item) {
          return _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: item.shichen === _vm.shichen,
                  expression: "item.shichen ===shichen",
                },
              ],
              staticClass: "ziwuliu_box",
            },
            [
              _c("img", { attrs: { src: item.imgUrl, alt: "" } }),
              _c("div", [_vm._v(_vm._s(item.name))]),
              _c("div", [_vm._v(_vm._s(item.desc) + "当令")]),
            ]
          )
        }),
      ],
      2
    ),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bottom" }, [
      _c("img", {
        attrs: {
          src: require("../../../assets/visualScreenImg/fixed_bottom.png"),
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }