<template>
  <div class="app-container">
    <div class="page-top">
      <div class="page-log">
        <img src="../../../assets/logo/logo.png" alt="" />
        <div class="page-title">中医智能辅助诊断服务系统</div>
      </div>
      <div class="page-search" v-show="false">
        <el-input
          placeholder="请输入内容"
          v-model="pageSearchValue"
          class="input-with-select"
        >
          <el-select
            v-model="pageSearchType"
            slot="prepend"
            placeholder="请选择类型"
          >
            <el-option class="select-option" label="方剂" value="1">
              <span class="option-ym">金匮方</span>
              <span class="option-yf">赤小豆当归散</span>
              <span class="option-yj">利水渗湿，温阳化气</span>
            </el-option>
            <el-option class="select-option" label="药物" value="2">
              <span class="option-ym">金匮方</span>
              <span class="option-yf">赤小豆当归散</span>
              <span class="option-yj">利水渗湿，温阳化气</span>
            </el-option>
            <el-option class="select-option" label="古方" value="3">
              <span class="option-ym">金匮方</span>
              <span class="option-yf">赤小豆当归散</span>
              <span class="option-yj">利水渗湿，温阳化气</span>
            </el-option>
          </el-select>
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="searchHandle"
          ></el-button>
        </el-input>
      </div>
      <div class="page-top-right">
        <div class="list-box">
          <el-dropdown trigger="click" v-show="false" @command="moduleClick">
            <span class="el-dropdown-link">
              中医文库<i class="el-icon-caret-bottom el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="中医文库-经典文献"
                >经典文献
              </el-dropdown-item>
              <el-dropdown-item command="中医文库-名医名方"
                >名医名方
              </el-dropdown-item>
              <el-dropdown-item command="中医文库-视频教学"
                >视频教学
              </el-dropdown-item>
              <el-dropdown-item command="中医文库-线上课堂"
                >线上课堂
              </el-dropdown-item>
              <el-dropdown-item command="中医文库-穴位经络"
                >穴位经络
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown trigger="click" v-show="false" @command="moduleClick">
            <span class="el-dropdown-link">
              中医养生<i class="el-icon-caret-bottom el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="中医养生-经典养方"
                >经典养方
              </el-dropdown-item>
              <el-dropdown-item command="中医养生-食补食疗"
                >食补食疗
              </el-dropdown-item>
              <el-dropdown-item command="中医养生-刮痧">刮痧</el-dropdown-item>
              <el-dropdown-item command="中医养生-按摩">按摩</el-dropdown-item>
              <el-dropdown-item command="中医养生-针灸">针灸</el-dropdown-item>
              <el-dropdown-item command="中医养生-艾灸">艾灸</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown trigger="click" v-show="false" @command="moduleClick">
            <span class="el-dropdown-link">
              中医科研<i class="el-icon-caret-bottom el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="中医科研-病例分析"
                >病例分析
              </el-dropdown-item>
              <el-dropdown-item command="中医科研-药性分析"
                >药性分析
              </el-dropdown-item>
              <el-dropdown-item command="中医科研-实验报告"
                >实验报告
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown trigger="click" v-show="false">
            <span class="el-dropdown-link" @command="moduleClick">
              远程会诊<i class="el-icon-caret-bottom el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="远程会诊-审议药方"
                >审议药方
              </el-dropdown-item>
              <el-dropdown-item command="远程会诊-医共会诊"
                >医共会诊
              </el-dropdown-item>
              <el-dropdown-item command="远程会诊-上级会诊"
                >上级会诊
              </el-dropdown-item>
              <el-dropdown-item command="远程会诊-专家指导"
                >专家指导
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <div class="logo-empty"></div>
        </div>
        <div class="avatar-box">
          <div style="margin-right: 20px; font-size: 16px">
            你好，{{ name }}
          </div>
          <el-dropdown
            class="avatar-container right-menu-item hover-effect"
            trigger="click"
          >
            <div class="avatar-wrapper">
              <img :src="avatar" class="user-avatar" />
              <i class="el-icon-caret-bottom" />
            </div>
            <el-dropdown-menu slot="dropdown">
              <router-link to="/user/profile">
                <el-dropdown-item>个人中心</el-dropdown-item>
              </router-link>
              <!-- <el-dropdown-item @click.native="setting = true">
                <span>布局设置</span>
              </el-dropdown-item> -->
              <el-dropdown-item divided @click.native="logout">
                <span>退出登录</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>

    <div class="app-container-top">
      <div class="page-wrap-div">
        <Left ref="left"></Left>
      </div>
      <div class="page-wrap-div">
        <Right ref="right"></Right>
      </div>
    </div>
    <div class="app-container-bottom">
      中科云诊（河南）医疗科技有限公司 |
      <a href="https://beian.miit.gov.cn/" target="_blank">
        豫ICP备2024065559号
      </a>
      |
      <img
        src="../../../assets/images/gongan.png"
        alt=""
        style="width: 18px; height: 18px"
      />
      <a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=41010702003447" target="_blank">
        豫公网安备41010702003447号
      </a>
    </div>
  </div>
</template>

<script>
import Left from "./left.vue";
import Right from "./right.vue";
import { mapGetters } from "vuex";
import ModuleClick from "@/tools/impl/ModuleClick";

export default {
  data() {
    return {
      pageSearchValue: "",
      pageSearchType: "",
    };
  },
  components: { Left, Right },
  created() {
    this.$tracking.init("aitcm");
    document.oncontextmenu = new Function("event.returnValue=false"); //aitcm页面禁用右键
  },
  mounted() {},
  computed: {
    ...mapGetters(["sidebar", "avatar", "device", "name"]),
    setting: {
      get() {
        return this.$store.state.settings.showSettings;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "showSettings",
          value: val,
        });
      },
    },
    topNav: {
      get() {
        return this.$store.state.settings.topNav;
      },
    },
  },
  methods: {
    searchHandle() {
      if (this.pageSearchType && this.pageSearchValue) {
        console.log(this.pageSearchType, this.pageSearchValue);
      } else {
        return;
      }
    },
    toggleSideBar() {
      this.$store.dispatch("app/toggleSideBar");
    },
    async logout() {
      this.$confirm("确定注销并退出系统吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$store.dispatch("LogOut").then(() => {
            location.href = "/index";
          });
        })
        .catch(() => {});
    },
    moduleClick(name) {
      ModuleClick.click(name);
    },
  },
};
</script>
<style lang="scss">
@import "style";

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--main-color);
  border-radius: 100px;
}

::-webkit-scrollbar-track {
  border-radius: 100px;
  background-color: rgba(196, 141, 110, 0.5);
}

::-webkit-scrollbar-button {
  background-color: transparent;
  height: 4px;
}
</style>
