import request from '@/utils/request'

// 查询统计列表
export function listTrackingStatics(query) {
  return request({
    url: '/tracking/trackingStatics/list',
    method: 'get',
    params: query
  })
}

export function listTrackingStaticsScore(query) {
  return request({
    url: '/tracking/trackingStatics/listScore',
    method: 'get',
    params: query
  })
}

export function trackingStaticsScoreInfo(query) {
  return request({
    url: '/tracking/trackingStatics/scoreInfo',
    method: 'get',
    params: query
  })
}


// 查询统计详细
export function getTrackingStatics(id) {
  return request({
    url: '/tracking/trackingStatics/' + id,
    method: 'get'
  })
}

// 新增统计
export function addTrackingStatics(data) {
  return request({
    url: '/tracking/trackingStatics',
    method: 'post',
    data: data
  })
}

// 修改统计
export function updateTrackingStatics(data) {
  return request({
    url: '/tracking/trackingStatics',
    method: 'put',
    data: data
  })
}

// 删除统计
export function delTrackingStatics(id) {
  return request({
    url: '/tracking/trackingStatics/' + id,
    method: 'delete'
  })
}
