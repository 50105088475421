var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c("div", { staticClass: "app-container-top" }, [
      _c("div", { staticClass: "page-wrap-div" }, [
        _c("div", { staticClass: "page-wrap" }, [
          _c("div", { staticClass: "wenku-content" }, [
            _c("div", { staticClass: "banner" }, [
              _c("div", { staticClass: "banner-border" }, [
                _c("div", { staticClass: "wenku-title" }, [_vm._v("中医文库")]),
                _c("div", { staticClass: "search" }, [
                  _c("input", {
                    staticClass: "search-input",
                    attrs: { type: "text", placeholder: "请输入检索内容" },
                  }),
                  _c(
                    "button",
                    {
                      staticClass: "search-btn",
                      on: { click: function ($event) {} },
                    },
                    [_vm._v("检索")]
                  ),
                ]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "book-list" },
              [
                _vm._l(_vm.bookList, function (item) {
                  return _c(
                    "div",
                    {
                      key: item.bookId,
                      staticClass: "book-item",
                      on: {
                        click: function ($event) {
                          return _vm.toPreview(item.bookId)
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("../../assets/images/book.jpg"),
                          alt: "",
                        },
                      }),
                      _c("div", { staticClass: "book" }, [
                        _c("div", { staticClass: "book-name" }, [
                          _vm._v(_vm._s(item.title)),
                        ]),
                        _c("div", { staticClass: "book-desc" }, [
                          _vm._v("作者：" + _vm._s(item.author)),
                        ]),
                      ]),
                    ]
                  )
                }),
                _c("Pagination", {
                  attrs: {
                    total: _vm.bookTotal,
                    page: _vm.getBookListForm.pageNum,
                    limit: _vm.getBookListForm.pageSize,
                  },
                  on: {
                    "update:page": function ($event) {
                      return _vm.$set(_vm.getBookListForm, "pageNum", $event)
                    },
                    "update:limit": function ($event) {
                      return _vm.$set(_vm.getBookListForm, "pageSize", $event)
                    },
                    pagination: _vm.getList,
                  },
                }),
              ],
              2
            ),
            _c("h3", [_vm._v("名家视频")]),
            _c(
              "div",
              { staticClass: "book-list" },
              [
                _vm._l(_vm.viedoList, function (item) {
                  return _c(
                    "div",
                    {
                      key: item.bookId,
                      staticClass: "book-item",
                      on: {
                        click: function ($event) {
                          return _vm.toVideo("1")
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("../../assets/images/book.jpg"),
                          alt: "",
                        },
                      }),
                      _c("div", { staticClass: "book" }, [
                        _c("div", { staticClass: "book-name" }, [
                          _vm._v(_vm._s(item.name)),
                        ]),
                        _c("div", { staticClass: "book-desc" }, [
                          _vm._v("作者：" + _vm._s(item.author)),
                        ]),
                      ]),
                    ]
                  )
                }),
                _c("Pagination", {
                  attrs: {
                    total: _vm.videoTotal,
                    page: _vm.getVideoListForm.pageNum,
                    limit: _vm.getVideoListForm.pageSize,
                  },
                  on: {
                    "update:page": function ($event) {
                      return _vm.$set(_vm.getVideoListForm, "pageNum", $event)
                    },
                    "update:limit": function ($event) {
                      return _vm.$set(_vm.getVideoListForm, "pageSize", $event)
                    },
                    pagination: _vm.getList,
                  },
                }),
              ],
              2
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }