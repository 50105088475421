var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "sizhen-layout",
    {
      on: {
        handleLastStep: function ($event) {
          return _vm.handleLastStep()
        },
        handleNextStep: function ($event) {
          return _vm.handleNextStep()
        },
      },
    },
    [
      _c(
        "el-row",
        { staticClass: "content", attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "content-box" }, [
              _c("div", { staticClass: "title" }, [_vm._v("预览照片")]),
              _c("div", { staticClass: "camera flex" }, [
                _c("img", {
                  attrs: {
                    src: require("../../../assets/sizhen/frame.png"),
                    alt: "",
                  },
                }),
                _c("video", { attrs: { id: "videoCamera", autoplay: "" } }),
                _c("canvas", {
                  staticStyle: { display: "none" },
                  attrs: {
                    id: "canvasCamera",
                    width: _vm.videoWidth,
                    height: _vm.videoHeight,
                  },
                }),
              ]),
              _c(
                "div",
                {
                  staticClass: "button",
                  on: {
                    click: function ($event) {
                      return _vm.setImage("she")
                    },
                  },
                },
                [_vm._v("拍摄")]
              ),
            ]),
          ]),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "content-box" }, [
              _c("div", { staticClass: "title" }, [_vm._v("舌部照片")]),
              _c(
                "div",
                { staticClass: "camera" },
                [
                  _vm.imgSrc
                    ? _c("el-image", { attrs: { src: _vm.imgSrc } })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "button", on: { click: _vm.analysisHandle } },
                [_vm._v("确认并分析")]
              ),
            ]),
          ]),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "content-box" }, [
              _c("div", { staticClass: "title" }, [_vm._v("辨识结果")]),
              _c(
                "div",
                { staticClass: "result-box" },
                [
                  _c(
                    "el-form",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "舌色：", prop: "sheShese" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.sheForm.sheShese,
                                callback: function ($$v) {
                                  _vm.$set(_vm.sheForm, "sheShese", $$v)
                                },
                                expression: "sheForm.sheShese",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "青紫舌" } }, [
                                _vm._v("青紫舌"),
                              ]),
                              _c("el-radio", { attrs: { label: "淡红舌" } }, [
                                _vm._v("淡红舌"),
                              ]),
                              _c("el-radio", { attrs: { label: "红绛舌" } }, [
                                _vm._v("红绛舌"),
                              ]),
                              _c("el-radio", { attrs: { label: "淡白舌" } }, [
                                _vm._v("淡白舌"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "苔色：", prop: "sheTaise" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.sheForm.sheTaise,
                                callback: function ($$v) {
                                  _vm.$set(_vm.sheForm, "sheTaise", $$v)
                                },
                                expression: "sheForm.sheTaise",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "灰黑苔" } }),
                              _c("el-radio", { attrs: { label: "白苔" } }),
                              _c("el-radio", { attrs: { label: "黄苔" } }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "齿痕：", prop: "sheChihen" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.sheForm.sheChihen,
                                callback: function ($$v) {
                                  _vm.$set(_vm.sheForm, "sheChihen", $$v)
                                },
                                expression: "sheForm.sheChihen",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "有齿痕" } }),
                              _c("el-radio", { attrs: { label: "无齿痕" } }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "裂纹：", prop: "sheLiewen" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.sheForm.sheLiewen,
                                callback: function ($$v) {
                                  _vm.$set(_vm.sheForm, "sheLiewen", $$v)
                                },
                                expression: "sheForm.sheLiewen",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "有裂纹" } }),
                              _c("el-radio", { attrs: { label: "无裂纹" } }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "点刺：", prop: "sheDianci" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.sheForm.sheDianci,
                                callback: function ($$v) {
                                  _vm.$set(_vm.sheForm, "sheDianci", $$v)
                                },
                                expression: "sheForm.sheDianci",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "有点刺" } }),
                              _c("el-radio", { attrs: { label: "无点刺" } }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "老嫩：", prop: "sheLaonen" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.sheForm.sheLaonen,
                                callback: function ($$v) {
                                  _vm.$set(_vm.sheForm, "sheLaonen", $$v)
                                },
                                expression: "sheForm.sheLaonen",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "老舌" } }),
                              _c("el-radio", { attrs: { label: "嫩舌" } }),
                              _c("el-radio", { attrs: { label: "正常" } }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "胖瘦：", prop: "shePangshou" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.sheForm.shePangshou,
                                callback: function ($$v) {
                                  _vm.$set(_vm.sheForm, "shePangshou", $$v)
                                },
                                expression: "sheForm.shePangshou",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "胖大舌" } }),
                              _c("el-radio", { attrs: { label: "瘦舌" } }, [
                                _vm._v("瘦薄舌"),
                              ]),
                              _c("el-radio", { attrs: { label: "正常" } }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "剥落：", prop: "sheBoluo" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.sheForm.sheBoluo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.sheForm, "sheBoluo", $$v)
                                },
                                expression: "sheForm.sheBoluo",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "剥苔" } }),
                              _c("el-radio", { attrs: { label: "非剥苔" } }, [
                                _vm._v("正常"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "厚薄：", prop: "sheHoubo" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.sheForm.sheHoubo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.sheForm, "sheHoubo", $$v)
                                },
                                expression: "sheForm.sheHoubo",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "厚苔" } }),
                              _c("el-radio", { attrs: { label: "薄苔" } }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "腐腻：", prop: "sheFuni" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.sheForm.sheFuni,
                                callback: function ($$v) {
                                  _vm.$set(_vm.sheForm, "sheFuni", $$v)
                                },
                                expression: "sheForm.sheFuni",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "腐苔" } }),
                              _c("el-radio", { attrs: { label: "腻苔" } }),
                              _c("el-radio", { attrs: { label: "正常" } }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "润燥：", prop: "sheRunzao" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.sheForm.sheRunzao,
                                callback: function ($$v) {
                                  _vm.$set(_vm.sheForm, "sheRunzao", $$v)
                                },
                                expression: "sheForm.sheRunzao",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "润苔" } }),
                              _c("el-radio", { attrs: { label: "滑苔" } }),
                              _c("el-radio", { attrs: { label: "燥常" } }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "button",
                  on: {
                    click: function ($event) {
                      return _vm.updataHandle()
                    },
                  },
                },
                [_vm._v("更新结果")]
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }