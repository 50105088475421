var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "page-wrap", staticStyle: { "margin-bottom": "20px" } },
        [
          _c("div", { staticClass: "page-search" }, [
            _c(
              "div",
              {
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.querySiZhen($event)
                  },
                },
              },
              [
                _c("img", {
                  staticStyle: { "margin-right": "5px" },
                  attrs: {
                    src: require("../../../assets/images/sizhenyi-icon.png"),
                    alt: "",
                  },
                }),
                _vm._v(" 四诊仪 "),
              ]
            ),
            _c(
              "div",
              {
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.readCard($event)
                  },
                },
              },
              [
                _c("img", {
                  staticStyle: { "margin-right": "5px" },
                  attrs: {
                    src: require("../../../assets/images/duqujiuzhenka-icon.png"),
                    alt: "",
                  },
                }),
                _vm._v(" 读卡 "),
              ]
            ),
            _c("div", [_vm._v(_vm._s(_vm.form.billno))]),
            _c(
              "div",
              {
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.scanCode($event)
                  },
                },
              },
              [
                _c("img", {
                  staticStyle: { "margin-right": "5px" },
                  attrs: {
                    src: require("../../../assets/images/duqujiuzhenka-icon.png"),
                    alt: "",
                  },
                }),
                _vm._v(" 扫码 "),
              ]
            ),
            _c(
              "div",
              {
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.queryBl($event)
                  },
                },
              },
              [
                _c("img", {
                  staticStyle: { "margin-right": "5px" },
                  attrs: {
                    src: require("../../../assets/images/lishibingli-icon.png"),
                    alt: "",
                  },
                }),
                _vm._v(" 历史病历 "),
              ]
            ),
            _c(
              "div",
              {
                staticStyle: { width: "30px" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.refreshPage($event)
                  },
                },
              },
              [
                _c("i", {
                  staticClass: "el-icon-refresh-right",
                  staticStyle: { margin: "0" },
                }),
              ]
            ),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.getCardLoading,
                  expression: "getCardLoading",
                },
              ],
              staticClass: "page-info",
              attrs: { background: "rgba(0,0,0,0)" },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "item" }, [
                      _c("div", { staticClass: "key" }, [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("*"),
                        ]),
                        _vm._v("患者姓名："),
                      ]),
                      _c(
                        "div",
                        { staticClass: "value" },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                "allow-create": "",
                                filterable: "",
                                remote: "",
                                placeholder: "请输入关键词",
                                "default-first-option": "",
                                clearable: "",
                                "value-key": "patientId",
                                "remote-method": _vm.getNameList,
                                loading: _vm.selectLoading,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.handleSelect($event)
                                },
                                clear: _vm.clearName,
                                blur: _vm.inputBlur,
                              },
                              model: {
                                value: _vm.form.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "name", $$v)
                                },
                                expression: "form.name",
                              },
                            },
                            _vm._l(_vm.patientsName, function (item) {
                              return _c("el-option", {
                                key: item.patientId,
                                attrs: { label: item.name, value: item },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "item" }, [
                      _c("div", { staticClass: "key" }, [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("*"),
                        ]),
                        _vm._v("性别："),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "value",
                          staticStyle: { "margin-top": "8px" },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              on: { change: _vm.genderChange },
                              model: {
                                value: _vm.form.gender,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "gender", $$v)
                                },
                                expression: "form.gender",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "男" } }, [
                                _vm._v("男性"),
                              ]),
                              _c("el-radio", { attrs: { label: "女" } }, [
                                _vm._v("女性"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "item" }, [
                      _c("div", { staticClass: "key" }, [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("*"),
                        ]),
                        _vm._v("年龄："),
                      ]),
                      _c(
                        "div",
                        { staticClass: "value" },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100%" },
                            attrs: { type: "number", placeholder: "年龄" },
                            on: { change: _vm.agechange },
                            model: {
                              value: _vm.form.age,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "age", $$v)
                              },
                              expression: "form.age",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "item" }, [
                      _c("div", { staticClass: "key" }, [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("*"),
                        ]),
                        _vm._v("手机号："),
                      ]),
                      _c(
                        "div",
                        { staticClass: "value" },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "手机号" },
                            model: {
                              value: _vm.form.cellphone,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "cellphone", $$v)
                              },
                              expression: "form.cellphone",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "item" }, [
                      _c("div", { staticClass: "key" }, [_vm._v("出生日期：")]),
                      _c(
                        "div",
                        { staticClass: "value" },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              type: "date",
                              "prefix-icon": "none",
                              placeholder: "出生日期",
                            },
                            on: { change: _vm.changeBirthday },
                            model: {
                              value: _vm.form.birthday,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "birthday", $$v)
                              },
                              expression: "form.birthday",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "item" }, [
                      _c("div", { staticClass: "key" }, [_vm._v("发病日期：")]),
                      _c(
                        "div",
                        { staticClass: "value" },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              type: "date",
                              "prefix-icon": "none",
                              placeholder: "发病日期",
                            },
                            model: {
                              value: _vm.form.accidentDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "accidentDate", $$v)
                              },
                              expression: "form.accidentDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "item" }, [
                      _c("div", { staticClass: "key" }, [_vm._v("身份证号：")]),
                      _c(
                        "div",
                        { staticClass: "value" },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "身份证号" },
                            on: { change: _vm.getIdCardInfo },
                            model: {
                              value: _vm.form.idcard,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "idcard", $$v)
                              },
                              expression: "form.idcard",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "item" }, [
                      _c("div", { staticClass: "key" }, [_vm._v("患者类型：")]),
                      _c(
                        "div",
                        { staticClass: "value" },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "", placeholder: "患者类型" },
                              model: {
                                value: _vm.form.patientType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "patientType", $$v)
                                },
                                expression: "form.patientType",
                              },
                            },
                            _vm._l(_vm.patientTypeList, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "item" }, [
                      _c("div", { staticClass: "key" }, [_vm._v("主诉症状：")]),
                      _c(
                        "div",
                        { staticClass: "value" },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100%" },
                            attrs: { type: "textarea", placeholder: "" },
                            model: {
                              value: _vm.form.zhusu,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "zhusu", $$v)
                              },
                              expression: "form.zhusu",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "item" }, [
                      _c("div", { staticClass: "key" }, [_vm._v("既往史：")]),
                      _c(
                        "div",
                        { staticClass: "value" },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100%" },
                            attrs: { type: "textarea", placeholder: "" },
                            model: {
                              value: _vm.form.jiwangshi,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "jiwangshi", $$v)
                              },
                              expression: "form.jiwangshi",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "item" }, [
                      _c("div", { staticClass: "key" }, [_vm._v("家族史：")]),
                      _c(
                        "div",
                        { staticClass: "value" },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100%" },
                            attrs: { type: "textarea", placeholder: "" },
                            model: {
                              value: _vm.form.jiazushi,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "jiazushi", $$v)
                              },
                              expression: "form.jiazushi",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "item" }, [
                      _c("div", { staticClass: "key" }, [_vm._v("现病史：")]),
                      _c(
                        "div",
                        { staticClass: "value" },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100%" },
                            attrs: { type: "textarea", placeholder: "" },
                            model: {
                              value: _vm.form.xianbingshi,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "xianbingshi", $$v)
                              },
                              expression: "form.xianbingshi",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "page-wrap" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c(
                  "div",
                  { staticClass: "item" },
                  [
                    _c(
                      "div",
                      { staticClass: "key preview-box" },
                      [
                        _vm._v(" 舌象： "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.shefileUrl,
                                expression: "shefileUrl",
                              },
                            ],
                            staticClass: "preview",
                            on: { click: _vm.preview },
                          },
                          [_vm._v(" 查看图片 ")]
                        ),
                        _c("el-image", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: false,
                              expression: "false",
                            },
                          ],
                          ref: "preview",
                          staticStyle: { width: "100px", height: "100px" },
                          attrs: {
                            src: _vm.shefileUrl,
                            "preview-src-list": [_vm.shefileUrl],
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-select",
                      {
                        directives: [
                          {
                            name: "el-select-loadmore",
                            rawName: "v-el-select-loadmore",
                            value: _vm.selectLoadmoreSheAll,
                            expression: "selectLoadmoreSheAll",
                          },
                        ],
                        staticClass: "multiple-select-half",
                        attrs: {
                          multiple: "",
                          "allow-create": "",
                          filterable: "",
                          remote: "",
                          placeholder: "请输入关键词",
                          "default-first-option": "",
                          "remote-method": _vm.remotesheAllMethod,
                          loading: _vm.selectLoading,
                        },
                        on: { focus: _vm.shexiangFocus },
                        model: {
                          value: _vm.sheAllIdList,
                          callback: function ($$v) {
                            _vm.sheAllIdList = $$v
                          },
                          expression: "sheAllIdList",
                        },
                      },
                      _vm._l(_vm.sheAllList, function (item, index) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.zy, value: item.zy },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c("el-row", [
                    _c("div", { staticClass: "item" }, [
                      _c("div", { staticClass: "key" }, [_vm._v("左脉：")]),
                      _c(
                        "div",
                        { staticClass: "value" },
                        [
                          _c(
                            "el-popover",
                            {
                              attrs: { placement: "top", width: "240" },
                              model: {
                                value: _vm.leftMaiShow,
                                callback: function ($$v) {
                                  _vm.leftMaiShow = $$v
                                },
                                expression: "leftMaiShow",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "mai-container" },
                                _vm._l(_vm.maiType, function (item, index) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "box" },
                                    [
                                      _c("div", { staticClass: "mai-name" }, [
                                        _vm._v(_vm._s(item.name)),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "mai-value" },
                                        _vm._l(
                                          item.children,
                                          function (mai, i) {
                                            return _c(
                                              "div",
                                              {
                                                key: i,
                                                class: {
                                                  active:
                                                    _vm.leftMai[
                                                      item.value
                                                    ].indexOf(mai.name) > -1,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.leftMai[
                                                      item.value
                                                    ].indexOf(mai.name) > -1
                                                      ? _vm.removeMai(
                                                          "左",
                                                          item.value,
                                                          mai.name,
                                                          _vm.leftMai[
                                                            item.value
                                                          ].findIndex(function (
                                                            val
                                                          ) {
                                                            return (
                                                              val === mai.name
                                                            )
                                                          })
                                                        )
                                                      : _vm.maiHandle(
                                                          "左",
                                                          item.value,
                                                          mai.name
                                                        )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(mai.name) + " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  )
                                }),
                                0
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "keratosis hei",
                                  attrs: { slot: "reference" },
                                  slot: "reference",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "item-wrap tags" },
                                    _vm._l(_vm.leftMai, function (item, index) {
                                      return _c(
                                        "div",
                                        { key: index, staticClass: "item-tag" },
                                        [
                                          item.length
                                            ? _c("span", [
                                                _vm._v(_vm._s(index) + "："),
                                              ])
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            { staticClass: "item-box" },
                                            _vm._l(item, function (ite, ind) {
                                              return _c(
                                                "div",
                                                {
                                                  key: ind,
                                                  staticClass: "item",
                                                },
                                                [
                                                  _vm._v(
                                                    " " + _vm._s(ite) + " "
                                                  ),
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-close",
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.removeMai(
                                                          "左",
                                                          index,
                                                          ite,
                                                          ind
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ]
                                              )
                                            }),
                                            0
                                          ),
                                        ]
                                      )
                                    }),
                                    0
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c("el-row", [
                    _c("div", { staticClass: "item" }, [
                      _c("div", { staticClass: "key" }, [_vm._v("右脉：")]),
                      _c(
                        "div",
                        { staticClass: "value" },
                        [
                          _c(
                            "el-popover",
                            {
                              attrs: { placement: "top", width: "240" },
                              model: {
                                value: _vm.rightMaiShow,
                                callback: function ($$v) {
                                  _vm.rightMaiShow = $$v
                                },
                                expression: "rightMaiShow",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "mai-container" },
                                _vm._l(_vm.maiType, function (item, index) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "box" },
                                    [
                                      _c("div", { staticClass: "mai-name" }, [
                                        _vm._v(_vm._s(item.name)),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "mai-value" },
                                        _vm._l(
                                          item.children,
                                          function (mai, i) {
                                            return _c(
                                              "div",
                                              {
                                                key: i,
                                                class: {
                                                  active:
                                                    _vm.rightMai[
                                                      item.value
                                                    ].indexOf(mai.name) > -1,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.rightMai[
                                                      item.value
                                                    ].indexOf(mai.name) > -1
                                                      ? _vm.removeMai(
                                                          "右",
                                                          item.value,
                                                          mai.name,
                                                          _vm.rightMai[
                                                            item.value
                                                          ].findIndex(function (
                                                            val
                                                          ) {
                                                            return (
                                                              val === mai.name
                                                            )
                                                          })
                                                        )
                                                      : _vm.maiHandle(
                                                          "右",
                                                          item.value,
                                                          mai.name
                                                        )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(mai.name) + " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  )
                                }),
                                0
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "keratosis hei",
                                  attrs: { slot: "reference" },
                                  slot: "reference",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "item-wrap tags" },
                                    _vm._l(
                                      _vm.rightMai,
                                      function (item, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "item-tag",
                                          },
                                          [
                                            item.length
                                              ? _c("span", [
                                                  _vm._v(_vm._s(index) + "："),
                                                ])
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              { staticClass: "item-box" },
                                              _vm._l(item, function (ite, ind) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: ind,
                                                    staticClass: "item",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " + _vm._s(ite) + " "
                                                    ),
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-close",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.removeMai(
                                                            "右",
                                                            index,
                                                            ite,
                                                            ind
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                )
                                              }),
                                              0
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c("div", { staticClass: "item" }, [
                  _c("div", { staticClass: "key" }, [_vm._v("刻下症：")]),
                  _c(
                    "div",
                    { staticClass: "value" },
                    [
                      _c(
                        "el-select",
                        {
                          directives: [
                            {
                              name: "el-select-loadmore",
                              rawName: "v-el-select-loadmore",
                              value: _vm.selectLoadmoreZhengzhuang,
                              expression: "selectLoadmoreZhengzhuang",
                            },
                          ],
                          staticClass: "multiple-select-half",
                          attrs: {
                            multiple: "",
                            "allow-create": "",
                            filterable: "",
                            remote: "",
                            placeholder: "请输入关键词",
                            "remote-method": _vm.remotezhengzhuangMethod,
                            "default-first-option": "",
                            loading: _vm.selectLoading,
                          },
                          on: { focus: _vm.zhuzhengFocus },
                          model: {
                            value: _vm.zhengzhuangIdList,
                            callback: function ($$v) {
                              _vm.zhengzhuangIdList = $$v
                            },
                            expression: "zhengzhuangIdList",
                          },
                        },
                        _vm._l(_vm.zhengzhuangList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.zy, value: item.zy },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c("el-row", [
            _c("div", { staticClass: "bottom-symptom" }, [
              _c(
                "div",
                { staticClass: "item-wrap" },
                _vm._l(_vm.guanliantuijianList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "item",
                      class: {
                        active: _vm.guanliantuijianIdList.indexOf(item) > -1,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.clickGuanliantuijian(item)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(item) + " ")]
                  )
                }),
                0
              ),
            ]),
          ]),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c("div", { staticClass: "item" }, [
                  _c("div", { staticClass: "key" }, [_vm._v("疾病辨别：")]),
                  _c(
                    "div",
                    { staticClass: "value" },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: {
                            "margin-top": "8px",
                            "text-align": "left",
                          },
                          attrs: {
                            filterable: "",
                            remote: "",
                            "reserve-keyword": "",
                            clearable: "",
                            placeholder: "请输入关键词",
                            "remote-method": _vm.remotejibingbianbieMethod,
                            loading: _vm.selectLoading,
                          },
                          on: { clear: _vm.clearjibingbianbie },
                          model: {
                            value: _vm.jibingbianbieId,
                            callback: function ($$v) {
                              _vm.jibingbianbieId = $$v
                            },
                            expression: "jibingbianbieId",
                          },
                        },
                        _vm._l(_vm.jibingbianbieList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.zy, value: item.zy },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c("el-row", [
            _c("div", { staticClass: "page-search" }, [
              _c("div", { staticClass: "button", on: { click: _vm.confirm } }, [
                _c("img", {
                  staticStyle: { "margin-right": "5px" },
                  attrs: {
                    src: require("../../../assets/images/zhinengbianzheng-icon.png"),
                    alt: "",
                  },
                }),
                _vm._v("智能辨证 "),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            visible: _vm.queryVisible,
            "close-on-click-modal": false,
            modal: false,
            width: "70%",
            title: "查询",
          },
          on: {
            "update:visible": function ($event) {
              _vm.queryVisible = $event
            },
          },
        },
        [
          _c("query", {
            ref: "query",
            on: {
              blInfoReuseData: _vm.blInfoReuseData,
              prescriptionZcStd: _vm.prescriptionZcStd,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          ref: "sizhenRef",
          attrs: {
            visible: _vm.sizhenVisible,
            modal: false,
            "close-on-click-modal": false,
            width: "50%",
            title: "四诊仪",
          },
          on: {
            "update:visible": function ($event) {
              _vm.sizhenVisible = $event
            },
          },
        },
        [
          _c("popupSizhen", {
            ref: "sizhen",
            attrs: { idcard: _vm.form.idcard, telephone: _vm.form.cellphone },
            on: { sizhenData: _vm.sizhenData },
          }),
        ],
        1
      ),
      _c("zhenduan", {
        ref: "zhenduan",
        on: { confirm: _vm.zhenzhuangPopupConfirm },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }