var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "sizhen-layout",
    {
      on: {
        handleLastStep: function ($event) {
          return _vm.handleLastStep()
        },
        handleNextStep: function ($event) {
          return _vm.handleNextStep()
        },
      },
    },
    [
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "item-box" }, [_vm._v("sss")]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }