<template>
  <div class="sizhen-container">
    <div class="bg">
      <dv-loading v-if="loading" style="height: 100%; width: 100%"
        >Loading...</dv-loading
      >
      <div v-else class="host-body">
        <!-- 头部 s -->
        <div class="title_wrap">
          <div class="headline">
            <img src="../../assets/sizhen/title-word.png" alt="" />
          </div>
          <div class="timers">
            <div class="dateyear">
              <i class="el-icon-time"></i>
              <div class="time">{{ dateDay }}</div>
              <div>{{ dateYear }}</div>
            </div>
          </div>
          <div class="skip">
            <img src="../../assets/logo/logo.png" alt="" />中医辅助智能采集系统
          </div>
        </div>
        <!-- 头部 e-->
        <!-- 内容  s-->
        <div class="content">
          <div class="menu">
            <div class="menu-item" @click="gotoStep(0)">
              <img
                class="item-img"
                src="../../assets/sizhen/huanzhetianjia.png"
              />
            </div>
            <div class="menu-item" @click="gotoStep(1)">
              <img
                class="item-img"
                src="../../assets/sizhen/tizhibianshi.png"
              />
            </div>
            <div class="menu-item" @click="gotoStep(2)">
              <img
                class="item-img"
                src="../../assets/sizhen/shexiangzhenduan.png"
              />
            </div>
            <div class="menu-item" @click="gotoStep(3)">
              <img class="item-img" src="../../assets/sizhen/aizhenduan.png" />
            </div>
            <div class="menu-item" @click="gotoStep(4)">
              <img
                class="item-img"
                src="../../assets/sizhen/bianzhengbaogao.png"
              />
            </div>
          </div>
        </div>

        <!-- 内容 e -->
      </div>
    </div>
  </div>
</template>

<script>
import { screenTime } from "@/utils";
import { Lunar, LunarUtil } from "@/utils/lunar";
import { mapActions } from "vuex";
export default {
  components: {},
  data() {
    return {
      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      YearInGanZhi: null, //干支年
      MonthInChinese: null, //农历月
      DayInChinese: null, //农历初几
      jieQi: null, //今日节气
      lastJieQi: null, //上一个节气
      shichen: null,
      shichenObj: null,
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
    };
  },
  filters: {},
  computed: {},
  created() {},
  mounted() {
    this.timeFn();
    this.cancelLoading();
  },
  methods: {
    ...mapActions(["touchActive"]),

    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = screenTime(new Date(), "HH:mm:ss");
        this.dateYear = screenTime(new Date(), "yyyy.MM.dd");
        this.dateWeek = this.weekday[new Date().getDay()];
        let d = Lunar.fromDate(new Date()); //节气
        this.YearInGanZhi = d.getYearInGanZhiByLiChun(); //年干支
        this.MonthInChinese = d.getMonthInChinese(); //农历月
        this.DayInChinese = d.getDayInChinese(); //农历初几
        this.jieQi = d.getJieQi();
        let prev = d.getPrevJieQi();
        this.lastJieQi = prev.getName();
        this.shichen = LunarUtil.convertTime(this.dateDay) + "时";
      }, 1000);
    },
    toQualityControlScreen() {
      this.$router.push({ name: "中科云诊质控平台" });
    },
    cancelLoading() {
      let timer = setTimeout(() => {
        this.loading = false;
        clearTimeout(timer);
      }, 500);
    },
    shichenData(e) {
      this.shichenObj = e;
    },
    gotoStep(step) {
      this.touchActive(step);
      this.$router.push({ name: "采集平台", params: {} });
    },
  },
};
</script>
<style scoped lang="scss">
.sizhen-container {
  width: 100%;
  height: 100%;
  user-select: none;
  .bg {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: #eaeff4;
  }

  .host-body {
    height: 100%;

    .title_wrap {
      height: 100px;
      background-image: url("../../assets/sizhen/title_bg.png");
      background-size: cover;
      background-position: center center;
      position: relative;

      .timers {
        height: 30px;
        position: absolute;
        right: 80px;
        top: 15px;
        display: flex;
        align-items: flex-end;

        .dateyear {
          font-size: 20px;
          font-family: Bebas Neue;
          font-weight: 400;
          line-height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          div {
            margin-right: 10px;
          }
          .el-icon-time,
          .time {
            color: #0a95fe;
          }
        }

        .yinli_box {
          font-size: 16px;
          font-weight: normal;
          color: #0874c7;
          line-height: 40px;
          height: 30px;
          margin-left: 30px;
        }
      }

      .skip {
        height: 30px;
        position: absolute;
        left: 80px;
        top: 15px;
        display: flex;
        justify-content: center;
        gap: 20px;
        img {
          height: 30px;
        }
      }
    }

    .headline {
      display: flex;
      align-items: center;
      height: 60px;
      justify-content: center;
      font-size: 46px;
      color: #0166e2;
      font-family: "PingFang SC";
      img {
        position: absolute;
        height: 44px;
        width: 620px;
        -webkit-user-drag: none;
      }
    }

    .content {
      padding: 0 10px;
      height: calc(100% - 100px);

      .menu {
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .menu-item {
          height: 430px;
          width: 300px;
          background: url("../../assets/sizhen/item-bg.png") 100% 100% no-repeat;
          display: flex;
          flex-direction: column;
          position: relative;
          justify-content: center;
          align-items: center;

          .item-img {
            width: 167px;
            height: 254px;
          }
        }
        .menu-item:after {
          content: "";
          width: 24px;
          background: url("../../assets/sizhen/tuoyuan.png") no-repeat;
          height: 48px;
          width: 44px;
          position: absolute;
          left: calc(50% - 22px);
          bottom: calc(50% - 270px);
          display: none;
        }

        .menu-item:hover {
          background: url("../../assets/sizhen/bg-hover.png") no-repeat;
          background-size: contain;
        }
        .menu-item:hover:after {
          display: block;
        }
      }
    }
  }
}
</style>
