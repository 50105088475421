import Vue from 'vue'

Vue.directive('el-select-loadmore', {
  bind(el, binding) {
    let value = ''
    el.addEventListener('input', function() {
      value = el.querySelector('.el-select__input').value
    })
    // 获取滚动页面DOM
    const SCROLL_DOM = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap')
    let scrollPosition = 0
    SCROLL_DOM.addEventListener('scroll', function() {
      // 当前的滚动位置 减去  上一次的滚动位置
      // 如果为true则代表向上滚动，false代表向下滚动
      const flag= this.scrollTop - scrollPosition > 0

      // 记录当前的滚动位置
      scrollPosition = this.scrollTop

      // 记录滚动位置距离底部的位置,在滚动位置距离滚动页面底部一定高度时在触发，例如距页面底部只有100px时触发loadMore事件
      const LIMIT_HEIGHT= 10
      const scrollBottom = this.scrollHeight - (this.scrollTop + this.clientHeight) < LIMIT_HEIGHT
      const loadMore = binding.value;
      // 如果已达到指定位置则触发
      // 如果向下滚动 并且距离底部只有10px
      if (flag && scrollBottom) {
        // 将滚动行为告诉组件
        loadMore(flag, value)
      }
    })
  }
})
