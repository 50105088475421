<template>
  <div>
    <el-button @click="exportPdf">导出pdf</el-button>
    <div ref="pdf" :style="'background: #ffffff;width: ' + pageWidth + 'px;'">
      <div
        class="page-home"
        :style="'width: ' + pageWidth + 'px;height:' + pageHeight + 'px'"
      >
        <div class="first-page">
          <img
            src="@/assets/report/cover.png"
            class="first-page-cover"
            alt=""
          />
          <div class="title-word">中医健康体质报告</div>
          <div class="title-en">TCM Health Physique Report</div>
          <div class="info">
            <div>姓名:{{ patientInfo.name }}</div>
            <div>性别:{{ patientInfo.gender }}</div>
            <div>采集编号:{{ patientInfo.therapeuticRegimen }}</div>
          </div>
        </div>
      </div>

      <div class="page-cont">
        <el-descriptions>
          <el-descriptions-item label="姓名">{{
            patientInfo.name
          }}</el-descriptions-item>
          <el-descriptions-item label="性别">{{
            patientInfo.gender
          }}</el-descriptions-item>
          <el-descriptions-item label="采集编号">{{
            patientInfo.therapeuticRegimen
          }}</el-descriptions-item>
          <el-descriptions-item label="年龄"> 99 </el-descriptions-item>
          <el-descriptions-item label="出生日期">
            {{ patientInfo.birthday || "-" }}
          </el-descriptions-item>
          <el-descriptions-item label="手机号">
            {{ patientInfo.cellphone }}
          </el-descriptions-item>
        </el-descriptions>
      </div>
      <div class="page-cont">
        <!-- 舌象解读 -->
        <h1 style="text-align: center">舌象解读</h1>
        <el-row :gutter="10" style="height: 278px; display: flex; justify-content: center;">
          <el-col :span="12" style="height: 100%">
            <img
              :src="patientInfo.shefileUrl"
              alt="舌象照片加载失败"
              style="width: 100%"
            />
          </el-col>
          <el-col v-if="isEmptyObject(shexiangtezheng)" :span="12" style="height: 100%">
            <Echart :id="'shexiangOption'" :options="shexiangOption" />
          </el-col>
        </el-row>
        <el-row :gutter="10" v-if="isEmptyObject(shexiangtezheng)">
          <el-col :span="20" :offset="2">
            <table border="1">
              <tr class="table-title">
                <td colspan="4">舌象特征提示</td>
              </tr>
              <tr class="bg-gray">
                <td>舌象特征</td>
                <td>数值</td>
                <td>特征结果</td>
                <td>参考范围</td>
              </tr>
              <tr v-for="(item, index) in shexiangtezheng" :key="index">
                <td class="bg-gray">{{ item.name }}</td>
                <td>{{ item.value["数值"] }}</td>
                <td>{{ item.value["特征结果"] }}</td>
                <td>{{ item.value["参考范围"] }}</td>
              </tr>
            </table>
          </el-col>
        </el-row>
        <h1 v-if="isEmptyObject(maixiangbo) && isEmptyObject(maixiangcanshubiao) && isEmptyObject(maixiangyaosufenxi)" style="text-align: center">脉象解读</h1>
        <div
          v-if="isEmptyObject(maixiangbo)"
          style="
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
          "
        >
          <Echart
            :options="leftguan"
            :id="'leftguan'"
            style="width: 50%; height: 100%; margin: 10px auto"
          ></Echart>
          <Echart
            :options="rightguan"
            :id="'rightguan'"
            style="width: 50%; height: 100%; margin: 10px auto"
          ></Echart>
          <Echart
            :options="leftcun"
            :id="'leftcun'"
            style="width: 50%; height: 100%; margin: 10px auto"
          ></Echart>
          <Echart
            :options="rightcun"
            ref="rightcun"
            style="width: 50%; height: 100%; margin: 10px auto"
          ></Echart>
          <Echart
            :options="leftchi"
            :id="'leftchi'"
            style="width: 50%; height: 100%; margin: 10px auto"
          ></Echart>
          <Echart
            :options="rightchi"
            :id="'rightchi'"
            style="width: 50%; height: 100%; margin: 10px auto"
          ></Echart>
        </div>

        <div v-if="isEmptyObject(maixiangyaosufenxi)" style="display: flex; flex-direction: row; flex-wrap: wrap">
          <Echart
            :options="maiweiOption"
            :id="'maiweiOption'"
            style="width: 50%; margin: 0 auto"
            :height="'200px'"
          ></Echart>
          <Echart
            :options="mailvOption"
            :id="'mailvOption'"
            :height="'200px'"
            style="width: 50%; margin: 0 auto"
          ></Echart>
          <Echart
            :options="maixingOption"
            :id="'maixingOption'"
            :height="'200px'"
            style="width: 50%; margin: 0 auto"
          ></Echart>
          <Echart
            :options="mailiOption"
            :id="'mailiOption'"
            :height="'200px'"
            style="width: 50%; margin: 0 auto"
          ></Echart>
        </div>
      </div>
      <div class="page-cont" v-if="isEmptyObject(maixiangcanshubiao)">
        <el-row :gutter="10">
          <el-col :span="20" :offset="2">
            <table border="1">
              <tr class="table-title">
                <td colspan="7">脉象参数量化表</td>
              </tr>
              <tr class="bg-gray">
                <td></td>
                <td>脉位</td>
                <td>脉率</td>
                <td>节律</td>
                <td>脉力</td>
                <td>紧张度</td>
                <td>流利度</td>
              </tr>
              <tr v-for="(item, index) in maixiangcanshubiao" :key="index">
                <td class="bg-gray">{{ item.name }}</td>
                <td>{{ item.value["脉位"] }}</td>
                <td>{{ item.value["脉率"] }}</td>
                <td>{{ item.value["节律"] }}</td>
                <td>{{ item.value["脉力"] }}</td>
                <td>{{ item.value["紧张度"] }}</td>
                <td>{{ item.value["流利度"] }}</td>
              </tr>
            </table>
          </el-col>
        </el-row>
      </div>
      <div class="page-cont" v-if="isEmptyObject(wenzhenjieguo) || isEmptyObject(wenzhentizhifangan)">
        <div>
          <h1 style="text-align: center">中医体检检测结果（一）</h1>
          <h1 style="text-align: center">体质状态评估</h1>
          <Echart
            v-if="isEmptyObject(wenzhenjieguo)"
            :options="tizhiOption"
            :height="'400px'"
            :width="'100%'"
          ></Echart>
        </div>
        <template v-if="isEmptyObject(wenzhentizhifangan)">
          <p>
            根据中医健康评估分析得出您的体质结果为<span style="color: #ef4e51">{{
              wenzhentizhifangan.constitution
            }}</span
            >兼<span style="color: #f7992a">{{
              wenzhentizhifangan.tendency
            }}</span>
          </p>
          <p>您的结果分析：</p>
          <p style="text-indent: 2em">{{ wenzhentizhifangan.resultAnalysis }}</p>
          <p style="color: #232f60; font-size: 16px">体质特征</p>
          <div
            style="display: flex; justify-content: space-between; flex-wrap: wrap"
          >
            <span
              v-for="(
                item, index
              ) in wenzhentizhifangan.constitutionCharacteristics"
              :key="index"
              style="width: 160px; height: 30px"
              >{{ item }}</span
            >
          </div>
          <p>宜：{{ wenzhentizhifangan.foodUseful }}</p>
          <p>忌：{{ wenzhentizhifangan.foodAvoid }}</p>
          <p style="color: #232f60; font-size: 16px">季节调理：</p>
          <p style="text-indent: 2em">{{ wenzhentizhifangan.seasonAdvice }}</p>
          <p style="color: #232f60; font-size: 16px">运动调理：</p>
          <p style="text-indent: 2em">{{ wenzhentizhifangan.exerciseAdvice }}</p>

          <table border="1" v-for="(item, index) in wenzhentizhifangan.exercises" :key="index">
            <tr class="table-title">
              <td colspan="2">{{ item.exercise }}</td>
            </tr>
            <tr>
              <td style="text-align: left">
                <p>动作一：{{ item.operate1 }}</p>
                <p>动作二：{{ item.operate2 }}</p>
                <p>动作三：{{ item.operate3 }}</p>
              </td>
              <td style="height: 240px; width: 300px">
                <img
                  :src="getBaDuanJinSrc(item.imgUrl)"
                  style="width: 100%; height: 100%"
                  alt=""
                />
              </td>
            </tr>
          </table>
        </template>
      </div>
      <div class="page-cont">
        <h1>推理证候</h1>
        <div style="display: flex; justify-content: space-evenly;margin-bottom: 30px">
          <div
            class="zhenghou-item"
            v-for="(item, index) in bingyibingjiList"
            :key="index"
            v-if="index < 3"
          >
            <span></span>{{ item.title }}
          </div>
        </div>
        <div style="display: flex; flex-direction: row; flex-wrap: wrap">
          <Echart
            :options="bingyin"
            :id="'bingyin'"
            style="width: 50%; height: 100%; margin: 0 auto"
          ></Echart>
          <Echart
            :options="bingwei"
            :id="'bingwei'"
            style="width: 50%; height: 100%; margin: 0 auto"
          ></Echart>
          <Echart
            :options="jibenwuzhi"
            :id="'jibenwuzhi'"
            style="width: 50%; height: 100%; margin: 0 auto"
          ></Echart>
          <Echart
            :options="binglizhuangtai"
            :id="'binglizhuangtai'"
            style="width: 50%; height: 100%; margin: 0 auto"
          ></Echart>
          <Echart
            :options="yinyangbiaoli"
            :id="'yinyangbiaoli'"
            style="width: 50%; height: 100%; margin: 0 auto"
          ></Echart>
          <Echart
            :options="hanrexushi"
            :id="'hanrexushi'"
            style="width: 50%; height: 100%; margin: 0 auto"
          ></Echart>
        </div>
        <div class="body">
          <div style="display: flex; flex-direction: row; flex-wrap: wrap">
            <Echart
              :options="yaoxingOption"
              :id="'yaoxingOption'"
              style="width: 50%; margin: 0 auto"
              :height="'300px'"
            ></Echart>
            <Echart
              :options="yaoweiOption"
              :id="'yaoweiOption'"
              :height="'300px'"
              style="width: 50%; margin: 0 auto"
            ></Echart>
            <Echart
              :options="guijingOption"
              :id="'guijingOption'"
              :height="'300px'"
              :width="'100%'"
            ></Echart>
          </div>
          <div class="body-item">
            <el-row class="row">
              <el-col :span="12" class="col">
                <div class="item-title">方名</div>
                <div class="item-value red">
                  {{ jingfangList.fangming || "-" }}
                </div>
              </el-col>
              <el-col :span="12" class="col">
                <div class="item-title">来源</div>
                <div class="item-value red">
                  {{ jingfangList.laiyuan || "-" }}
                </div>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="24" class="col">
                <div class="item-title">组成</div>
                <div class="item-value bold flex">
                  <div v-for="(item, index) in yaomingList" :key="index">
                    <el-popover placement="top" width="800" trigger="hover">
                      <div class="body-item">
                        <el-row class="row">
                          <el-col :span="12" class="col">
                            <div class="item-title">药名</div>
                            <div class="item-value bold">
                              {{ item.ym || "-" }}
                            </div>
                          </el-col>
                          <el-col :span="12" class="col">
                            <div class="item-title">别名</div>
                            <div class="item-value">
                              {{ item.bm || "-" }}
                            </div>
                          </el-col>
                        </el-row>
                        <el-row class="row">
                          <el-col :span="6" class="col">
                            <div class="item-title">拼音</div>
                            <div class="item-value">
                              {{ item.py || "-" }}
                            </div>
                          </el-col>
                          <el-col :span="6" class="col">
                            <div class="item-title">性</div>
                            <div class="item-value bold">
                              {{ item.xing || "-" }}
                            </div>
                          </el-col>
                          <el-col :span="6" class="col">
                            <div class="item-title">味</div>
                            <div class="item-value bold">
                              {{ item.wei || "-" }}
                            </div>
                          </el-col>
                          <el-col :span="6" class="col">
                            <div class="item-title bold">毒</div>
                            <div class="item-value">
                              {{ item.duxing || "-" }}
                            </div>
                          </el-col>
                        </el-row>
                        <el-row class="row">
                          <el-col :span="12" class="col">
                            <div class="item-title">归经</div>
                            <div class="item-value bold">
                              {{ item.gj || "-" }}
                            </div>
                          </el-col>
                          <el-col :span="12" class="col">
                            <div class="item-title">功效</div>
                            <div class="item-value bold">
                              {{ item.gx || "-" }}
                            </div>
                          </el-col>
                        </el-row>
                        <el-row class="row">
                          <el-col :span="24" class="col">
                            <div class="item-title">主治</div>
                            <div class="item-value bold">
                              {{ item.zz || "-" }}
                            </div>
                          </el-col>
                        </el-row>
                        <el-row class="row">
                          <el-col :span="24" class="col">
                            <div class="item-title">用法用量</div>
                            <div class="item-value bold">
                              {{ item.yfyl || "-" }}
                            </div>
                          </el-col>
                        </el-row>
                        <el-row class="row">
                          <el-col :span="24" class="col">
                            <div class="item-title">应用禁忌</div>
                            <div class="item-value red">
                              {{ item.yyjj || "-" }}
                            </div>
                          </el-col>
                        </el-row>
                        <el-row class="row">
                          <el-col :span="24" class="col">
                            <div class="item-title">配制方法</div>
                            <div class="item-value">
                              {{ item.pzff || "-" }}
                            </div>
                          </el-col>
                        </el-row>
                      </div>
                      <span slot="reference">
                        {{
                          index + 1 === yaomingList.length
                            ? item.ym + "。"
                            : item.ym + "、"
                        }}</span
                      >
                    </el-popover>
                  </div>
                </div>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="24" class="col">
                <div class="item-title">用法</div>
                <div class="item-value">
                  {{ jingfangList.yongfa || "-" }}
                </div>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="24" class="col">
                <div class="item-title">医案举例</div>
                <div class="item-value">
                  {{ jingfangList.yianjuli || "-" }}
                </div>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="24" class="col">
                <div class="item-title">功用</div>
                <div class="item-value bold">
                  {{ jingfangList.gongyong || "-" }}
                </div>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="24" class="col">
                <div class="item-title">主治</div>
                <div class="item-value">
                  {{ yaoxingForm.zhuzhi || "-" }}
                </div>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="24" class="col">
                <div class="item-title">证治机理</div>
                <div class="item-value">
                  {{ jingfangList.zhengzhijili || "-" }}
                </div>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="24" class="col">
                <div class="item-title">方解</div>
                <div class="item-value">
                  {{ jingfangList.fangjie || "-" }}
                </div>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="24" class="col">
                <div class="item-title">配伍特点</div>
                <div class="item-value">
                  {{ jingfangList.peiwutedian || "-" }}
                </div>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="24" class="col">
                <div class="item-title">运用</div>
                <div class="item-value">
                  {{ jingfangList.yunyong || "-" }}
                </div>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="24" class="col">
                <div class="item-title">鉴别</div>
                <div class="item-value">
                  {{ jingfangList.jianbie || "-" }}
                </div>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="24" class="col">
                <div class="item-title">方论选录</div>
                <div class="item-value">
                  {{ jingfangList.fanglunxuanlu || "-" }}
                </div>
              </el-col>
            </el-row>

            <el-row class="row">
              <el-col :span="24" class="col">
                <div class="item-title">方歌</div>
                <div class="item-value">
                  {{ jingfangList.fangge || "-" }}
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <div class="page-head" :style="'height:' + headerHeight + 'px'">
        <div class="page-head-left">
          <img
            class="page-head-left-img"
            src="@/assets/logo/logoLine.png"
            alt=""
          />
        </div>
        <div class="page-head-right">
          <div>姓名:{{ patientInfo.name }}</div>
          <div>性别:{{ patientInfo.gender }}</div>
          <div>采集编号:{{ patientInfo.therapeuticRegimen }}</div>
        </div>
      </div>
      <div class="page-foot" :style="'height:' + footerHeight + 'px'">
        <div class="page-foot-right">
          <span id="pageNumber" v-show="false"></span
          >温馨提示：此方案是面向具有法定中医执业资格的医师作参考之用，如需要进行任何治疗，请前往具有资质的医疗机构向专业医生咨询
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {
  getJingFangYaoWu,
  getYaoxingfenxi,
  getReportDetail,
  toBase64
} from "@/api/zkyz/aictm";

export default {
  props: {
    patientInfo: {
      type: Object,
      default: () => {},
    },
    yfList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    fm: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      sizhenDetail: {}, //四诊详情数据 总数据
      bingyibingjiList: [], // 推理证候
      //药性分析数据
      dataList: null,
      yaoxingOption: {},
      yaoweiOption: {},
      guijingOption: {},
      yaoxingForm: {
        gongxiao: null,
        zhuzhi: null,
      },
      jingfangList: [],
      yaomingList: [],

      shexiangtezheng: undefined,
      maixiangcanshubiao: undefined,
      maixiangbo: undefined,
      maixiangyaosufenxi: undefined, //脉象要素分析
      wenzhenjieguo: undefined,
      leftguan: {},
      leftcun: {},
      leftchi: {},
      rightguan: {},
      rightcun: {},
      rightchi: {},
      tizhiOption: {},

      bingyin: {},
      bingwei: {},
      jibenwuzhi: {},
      yinyangbiaoli: {},
      hanrexushi: {},
      binglizhuangtai: {},

      wenzhentizhifangan: {},

      maiweiOption: {},
      mailvOption: {},
      maixingOption: {},
      mailiOption: {},

      headerHeight: 80,
      footerHeight: 80,

      pageHeight: 0,
      pageWidth: 0,

      headEl: null, // 页眉
      footEl: null, // 页脚
      homeEl: null, // 封面
      els: [[]], // 合同内容

      totalPage: 0, // pdf总页码
      currentPage: 1,
      shexiangOption: {},
    };
  },
  filters: {},
  mounted() {},
  methods: {
    // img加载完成后转为base64以便打印功能使用
    // imgload(e) {
    //   const img = e.target
    //   toBase64(img.src).then(res => {
    //     if (res.code === 200) {
    //       img.src = res.msg
    //     }
    //   })
    // },
    // 根据返回的八段锦名称，获取对应的图片
    getBaDuanJinSrc(url) {
      let img = url.split('/').slice(-1)[0]
      let temp = img.split('.')[0]
      return require(`@/assets/baDuanJin/${temp}.png`)
    },
    init() {
      this.generatePdfInstance = this.$generatePdfFiles({
        headerHeight: this.headerHeight,
        footerHeight: this.footerHeight,
      });
      this.pageHeight = this.generatePdfInstance.options.a4H;
      this.pageWidth = this.generatePdfInstance.options.a4W;
      let id = this.patientInfo.therapeuticRegimen;
      getReportDetail(id).then((res) => {
        if (res.code == 200) {
          this.sizhenDetail = res.data;
        } else {
          this.sizhenDetail = null;
        }
        this.getData(this.sizhenDetail);
      });
    },
    getData(sizhenDetail) {
      //调用药性分析接口
      let arr = [];
      for (let item of this.yfList) {
        arr.push({
          n: item.name,
          v: Number(item.number || 0),
          dw: item.g,
        });
      }

      getYaoxingfenxi(arr)
        .then((res) => {
          let guijing = res.data?.guijing || [];
          this.yaoxingForm = {
            gongxiao: res.data?.gongxiao,
            zhuzhi: res.data?.zhuzhi,
          };
          let yaoxing = res.data?.xing || [];
          let yaowei = res.data?.wei || [];
          this.$nextTick(() => {
            this.buildyaoxingfenxiEcharts(
              "yaoxing",
              this.formatDataList(yaoxing)
            );
            this.buildyaoxingfenxiEcharts(
              "yaowei",
              this.formatDataList(yaowei)
            );
            this.buildyaoxingfenxiEcharts(
              "guijing",
              this.formatDataList(guijing)
            );
          });
        })
        .catch((error) => {
          this.$mesesage.error(error);
        });
      let zc = this.yfList
        .map((item) => {
          return item.name;
        })
        .join(",");
      getJingFangYaoWu({ zc, fm: this.fm }).then((res) => {
        this.jingfangList = res.data[0][0] || [];
        this.yaomingList = res.data[1];
      });
      //证候echarts渲染
      let parentData = this.$parent.$parent;
      this.bingyibingjiList = parentData.bingyibingjiList;
      let echartData = parentData.charts.dataSet;
      this.$nextTick(() => {
        //证候echarts
        this.binglizhuangtai = this.buildZhenghouOption(
          echartData["blzt"],
          "病理状态"
        );
        this.bingwei = this.buildZhenghouOption(echartData["bw"], "病位");
        this.bingyin = this.buildZhenghouOption(echartData["bx"], "病因");
        this.jibenwuzhi = this.buildZhenghouOption(
          echartData["jw"],
          "基本物质"
        );
        this.hanrexushi = this.buildZhenghouOption(
          echartData["hrxs"],
          "寒热虚实"
        );
        this.yinyangbiaoli = this.buildZhenghouOption(
          echartData["yybl"],
          "阴阳表里"
        );
      });
      let data = sizhenDetail || {};
      //舌象特征提示
      let shexiangtezheng = JSON.parse(data["舌象特征提示"] || "{}");
      let shexiangList = [];
      for (let key in shexiangtezheng) {
        shexiangList.push({ name: key, value: shexiangtezheng[key] });
      }
      this.shexiangtezheng = shexiangList;

      //脉象参数量化表
      console.log(data);
      let maixiangcanshu = JSON.parse(data["脉象参数量化表"] || {});
      let maixiangList = [];
      for (let key in maixiangcanshu) {
        maixiangList.push({ name: key, value: maixiangcanshu[key] });
      }
      this.maixiangcanshubiao = maixiangList;
      //脉象波形图
      let maixiangbo = JSON.parse(data["脉象波形图"] || {});
      this.maixiangbo = maixiangbo
      //体质状态评估
      let {
        pingheNum,
        qixuNum,
        qiyuNum,
        shireNum,
        tanshiNum,
        tebingNum,
        xueyuNum,
        yangxuNum,
        yinxuNum,
        constitution,
        tendency,
      } = data["问诊结果"] || {};
      this.wenzhenjieguo = data["问诊结果"] || {}
      if (this.isEmptyObject(this.wenzhenjieguo)) {
        let indicator = [
          { name: "平和质" },
          { name: "阳虚质" },
          { name: "阴虚质" },
          { name: "气虚质" },
          { name: "痰湿质" },
          { name: "湿热质" },
          { name: "血瘀质" },
          { name: "特禀质" },
          { name: "气郁质" },
        ];
        indicator.map((item) => {
          if (constitution?.indexOf(item.name) > -1) {
            item.color = "#F25D62";
          }
          if (tendency?.indexOf(item.name) > -1) {
            item.color = "#F79C32";
          }
        });
        //体质状态评估饼图
        this.tizhiOption = {
          radar: {
            shape: "polygon",
            indicator: indicator,
            splitNumber: 3,
            axisName: {
              color: "#000000",
              fontSize: 16,
              fontWeight: "bold",
            },
            splitArea: {
              areaStyle: {
                color: ["#A1C7D8", "#699CC5", "#678AB1"],
              },
            },
          },
          series: [
            {
              type: "radar",
              areaStyle: {
                color: "#FEAE01",
                opacity: 0.2, //默认存在透明度
              },
              itemStyle: {},
              data: [
                {
                  value: [
                    pingheNum,
                    yangxuNum,
                    yinxuNum,
                    qixuNum,
                    tanshiNum,
                    shireNum,
                    xueyuNum,
                    tebingNum,
                    qiyuNum,
                  ],
                },
              ],
            },
          ],
        };
      }
      //问诊体质方案
      this.wenzhentizhifangan = data["问诊体质方案"] || {};
      if (this.isEmptyObject(this.wenzhentizhifangan)) {
        this.wenzhentizhifangan.tendency = this.wenzhentizhifangan?.tendency?.substring(1);
        this.wenzhentizhifangan.constitutionCharacteristics = this.wenzhentizhifangan?.constitutionCharacteristics?.split(",");
      }

      //脉象要素分析图
      let maixiangyaosufenxi = JSON.parse(data["脉象要素分析图"] || {});
      this.maixiangyaosufenxi = maixiangyaosufenxi
      this.$nextTick(() => {
        this.shexiangOption = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          legend: {
            show: false,
          },
          grid: {
            top: '1%',
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: [
            {
              type: "value",
            },
          ],
          yAxis: [
            {
              type: "category",
              axisTick: {
                show: false,
              },
              data: shexiangList.map((item) => item.name),
            },
          ],
          series: [
            {
              name: "数值",
              type: "bar",
              label: {
                show: true,
                position: "inside",
              },
              emphasis: {
                focus: "series",
              },
              data: shexiangList.map((item) => item.value["数值"]),
              itemStyle: {
                color: function (params) {
                  // 根据索引判断柱子颜色
                  if (params.data > 1.1) {
                    return "#FE0000"; // 最大值柱子颜色为红色
                  } else if (params.data < 0.9) {
                    return "#1D99D6"; // 最小值柱子颜色为绿色
                  } else {
                    return "#A2C142"; // 其他柱子使用蓝色
                  }
                },
              },
            },
          ],
        };
        if (this.isEmptyObject(this.maixiangbo)) {
          this.leftguan = this.buildMaixiangOption("左关", maixiangbo["左关"]);
          this.leftcun = this.buildMaixiangOption("左寸", maixiangbo["左寸"]);
          this.leftchi = this.buildMaixiangOption("左尺", maixiangbo["左尺"]);
          this.rightguan = this.buildMaixiangOption("右关", maixiangbo["右关"]);
          this.rightcun = this.buildMaixiangOption("右寸", maixiangbo["右寸"]);
          this.rightchi = this.buildMaixiangOption("右尺", maixiangbo["右尺"]);
        }


        //脉象要素分析
        if (this.isEmptyObject(this.maixiangyaosufenxi)) {
          this.maiweiOption = this.buildmaixiangyaosuOption(
            "脉位偏差分析图",
            maixiangyaosufenxi["脉位偏差分析图"].floats,
            maixiangyaosufenxi["脉位偏差分析图"].upLineValue,
            maixiangyaosufenxi["脉位偏差分析图"].downLineValue
          );
          this.mailvOption = this.buildmaixiangyaosuOption(
            "脉率偏差分析图",
            maixiangyaosufenxi["脉率偏差分析图"].floats,
            maixiangyaosufenxi["脉率偏差分析图"].upLineValue,
            maixiangyaosufenxi["脉率偏差分析图"].downLineValue
          );
          this.maixingOption = this.buildmaixiangyaosuOption(
            "脉形偏差分析图",
            maixiangyaosufenxi["脉形偏差分析图"].floats,
            maixiangyaosufenxi["脉形偏差分析图"].upLineValue,
            maixiangyaosufenxi["脉形偏差分析图"].downLineValue
          );
          this.mailiOption = this.buildmaixiangyaosuOption(
            "脉力偏差分析图",
            maixiangyaosufenxi["脉力偏差分析图"].floats,
            maixiangyaosufenxi["脉力偏差分析图"].upLineValue,
            maixiangyaosufenxi["脉力偏差分析图"].downLineValue
          );
        }
      });
    },
    //构建脉象echarts
    buildMaixiangOption(name, valueList) {
      valueList = JSON.parse(valueList);
      return {
        title: {
          text: name,
          left: "center",
        },
        xAxis: {
          type: "category",
          show: false,
        },
        yAxis: {
          type: "value",
          show: false,
        },
        series: [
          {
            data: valueList,
            type: "line",
            symbol: "none",
          },
        ],
      };
    },
    //构建证候echarts
    buildZhenghouOption(data, name) {
      let list = [];
      for (let key in data) {
        list.push({
          key,
          value: data[key],
        });
      }

      list.sort((o1, o2) => {
        return o2.value - o1.value;
      });

      if (list.length > 5) {
        list = list.slice(0, 5);
      }

      data = {};
      for (let item of list) {
        data[item.key] = item.value;
      }

      let max = 0;
      for (let key in data) {
        let value = data[key];
        if (max < value) {
          max = value;
        }
      }

      let xList = [];
      let yList = [];
      let i = 0;
      for (let key in data) {
        let newKey = key
          .replace("c", "")
          .replace("d", "")
          .replace("C", "")
          .replace("D", "");
        let value = data[key];
        xList.push(newKey);
        yList.push(value);
        i++;
      }
      return {
        title: {
          text: name,
          left: "top",
        },
        xAxis: {
          type: "category",
          data: xList,
          axisLabel: {
            fontSize: 18,
            color: "black",
            fontFamily: "隶书",
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          show: false,
        },
        series: [
          {
            data: yList,
            type: "bar",
            barWidth: 20,
            itemStyle: {
              borderRadius: [5, 5, 0, 0],
            },
          },
        ],
        grid: {
          left: 40,
          top: 30,
          right: 40,
          bottom: 30,
        },
        tooltip: {
          formatter: "{b0}",
        },
      };
    },
    //构建脉象要素分析echarts
    buildmaixiangyaosuOption(name, data, maxvalue, minvalue) {
      let axisLabel = {
        show: true,
        interval: "auto",
        formatter: "{value} %",
      };
      if (name == "脉力偏差分析图") {
        axisLabel.formatter = "{value}";
      } else {
        axisLabel.formatter = "{value} %";
      }
      return {
        grid: {
          left: 40,
          top: 30,
          right: 40,
          bottom: 30,
        },
        title: {
          text: name,
          left: "center",
        },
        xAxis: {
          type: "category",
          data: ["左寸", "左关", "左尺", "右寸", "右关", "右尺"],
        },
        yAxis: {
          type: "value",
          axisLabel: axisLabel,
        },
        series: [
          {
            data: data,
            type: "bar",
            name: "正常",
            itemStyle: {
              color: function (params) {
                // 根据索引判断柱子颜色
                if (params.data > maxvalue) {
                  return "#FE0000"; // 最大值柱子颜色为红色
                } else if (params.data < minvalue) {
                  return "#1D99D6"; // 最小值柱子颜色为绿色
                } else {
                  return "#A2C142"; // 其他柱子使用蓝色
                }
              },
            },
            markLine: {
              lineStyle: {
                type: "dashed",
              },
              symbol: "none",
              label: {
                show: false,
              },
              data: [
                {
                  name: "最大值",
                  yAxis: maxvalue,
                },
                {
                  name: "最小值",
                  yAxis: minvalue,
                },
              ],
            },
          },
        ],
      };
    },
    //构建药性分析echarts
    formatDataList(dataList) {
      return dataList.filter((r) => {
        return r.n != "nan";
      });
    },
    buildyaoxingfenxiEcharts(refStr, list) {
      let xList = [];
      let yList = [];
      let pieList = [];
      let radarList = [
        { name: "甘", value: 0 },
        { name: "咸", value: 0 },
        { name: "涩", value: 0 },
        { name: "淡", value: 0 },
        { name: "苦", value: 0 },
        { name: "辛", value: 0 },
      ];
      let i = 0;
      for (let item of list) {
        xList.push(item.n);
        yList.push(item.v);
        let value = item.v;
        let name = item.n;
        pieList.push({ value, name });
        radarList.map((item) => {
          if (item.name === name) {
            item.value = value;
          }
        });
        i++;
      }
      radarList = radarList.map((item) => item.value);
      var option;
      option = {
        xAxis: {
          type: "category",
          data: xList,
          axisLabel: {
            fontSize: 16,
            color: "black",
            fontFamily: "隶书",
          },
        },
        yAxis: {
          type: "value",
          show: false,
        },
        series: [
          {
            data: yList,
            type: "bar",
            barMaxWidth: 30,
            color: "#A2C142",
            itemStyle: {
              borderWidth: 1,
              borderRadius: [5, 5, 0, 0],
            },
          },
        ],
        grid: {
          left: 40,
          top: 20,
          right: 10,
          bottom: 30,
        },
        tooltip: {
          formatter: "{b0}",
        },
      };
      var option1 = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          top: "5%",
          left: "center",
        },
        series: [
          {
            name: "药性分析",
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: pieList,
          },
        ],
      };
      var option2 = {
        legend: {
          data: ["药味分析"],
          show: false,
        },
        radar: {
          // shape: 'circle',
          indicator: [
            { name: "甘" },
            { name: "咸" },
            { name: "涩" },
            { name: "淡" },
            { name: "苦" },
            { name: "辛" },
          ],
        },
        series: [
          {
            name: "",
            type: "radar",
            data: [
              {
                value: radarList,
                name: "药味分析",
                areaStyle: {
                  color: "rgba(0, 106, 254, 0.2)",
                },
              },
            ],
          },
        ],
      };
      if (refStr === "yaoxing") {
        this.yaoxingOption = option1;
      } else if (refStr === "yaowei") {
        this.yaoweiOption = option2;
      } else {
        this.guijingOption = option;
      }
    },
    getEls() {
      this.headEl = this.$refs.pdf.querySelector(".page-head");
      this.footEl = this.$refs.pdf.querySelector(".page-foot");
      this.homeEl = this.$refs.pdf.querySelector(".page-home");
      let part1El = this.$refs.pdf.querySelectorAll(".page-cont");
      let part2El = this.$refs.pdf.querySelectorAll(".page-part");
      let previewBox = this.$refs.showpdf;
      this.els = [part1El, part2El];
      return {
        headEl: this.headEl,
        footEl: this.footEl,
        homeEl: this.homeEl,
        pagesEl: this.els,
        previewBox,
      };
    },
    async exportPdf() {
      let elsOptions = this.getEls();
      let info = await this.generatePdfInstance.generateFile(elsOptions);
      // console.log(info);
      this.generatePdfInstance.exportFile("中科云诊中医健康体质报告");
    },
    // 判断对象是否为空
    isEmptyObject(obj) {
      if (obj === null || typeof obj !== 'object') {
        return false
      }
      return Object.keys(obj).length > 0;
    }
  },
};
</script>

<style lang="scss" scoped>
.page-head {
  display: flex;
  justify-content: space-between;
  justify-content: space-evenly;
  align-items: center;
  font-size: 14px;
  padding: 8px;
  border-bottom: 1px solid #000000;
  color: #000;
  .page-head-left {
    box-sizing: border-box;
    .page-head-left-img {
      width: 148px;
      height: 34px;
    }
  }
  .page-head-right {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 10px;
  }
}
.page-foot {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  color: #ffffff;
  font-size: 14px;
  background: #1066a0;
  line-height: 28px;
  .page-foot-right {
    flex: 1;
    align-self: center;
    text-align: center;
    padding: 0 16px;
    box-sizing: border-box;
  }
}
.page-home {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: #333333;
  font-size: 16px;
  .first-page {
    width: 100%;
    height: 100%;
    font-family: Alibaba PuHuiTi;
    font-weight: bold;
    position: relative;
    .first-page-cover {
      width: 100%;
      height: 100%;
    }
    .title-word {
      font-size: 50px;
      color: #1066a0;
      position: absolute;
      top: 80px;
      left: 40px;
    }
    .title-en {
      font-size: 32px;
      color: #333333;
      position: absolute;
      top: 160px;
      left: 40px;
    }
    .info {
      position: absolute;
      bottom: 80px;
      font-size: 22px;
      left: 40px;
    }
  }
  .page-home-top {
    width: 100%;
    box-sizing: border-box;
    text-align: right;
    padding-top: 24px;
    padding-right: 24px;
  }
  .page-home-title {
    width: 100%;
    height: 50%;
    box-sizing: border-box;
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    letter-spacing: 6px;
  }
  .page-home-info {
    width: 40%;
    box-sizing: border-box;
    padding-bottom: 24px;
    font-size: 18px;
    .page-home-info-line {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      padding-top: 12px;
      .page-home-info-line-tt {
        width: 60px;
        &.date {
          width: 100px;
        }
      }
      .page-home-info-line-txt {
        width: calc(100% - 60px);
        &.date {
          width: calc(100% - 100px);
        }
      }
    }
  }
}
.page-cont,
.page-part {
  font-size: 14px;
  color: #000000;
  box-sizing: border-box;
  padding: 0 24px;
  .zhenghou-item {
    display: flex;
    background-color: #f7931d;
    height: 24px;
    padding: 0 16px;
    align-items: center;
    border-radius: 12px;
    justify-content: center;
    color: #ffffff;
    font-size: 16px;
    font-weight: 200;
    span {
      width: 20px;
      height: 20px;
      background: url("../../../../assets/images/num-border.png");
      background-repeat: no-repeat;
      background-size: 20px 20px;
      background-position: 50% 50%;
    }
  }

  table {
    width: 100%;
    height: 100%;
    border-collapse: collapse;
    margin: 40px 0;
    tr {
      height: 40px;
    }
    td {
      border: 1px solid #000;
      text-align: center;
    }
    .table-title {
      background-color: #87b3df;
    }
    .bg-gray {
      background-color: #bcbdc0;
    }
  }
  .page-cont-title {
    font-size: 18px;
    text-align: center;
    font-weight: bold;
    padding: 24px 0;
  }
  .page-cont-txt {
    text-indent: 2em;
    line-height: 2;
  }
  .page-cont-part {
    .page-cont-part-tt {
      font-weight: bold;
      line-height: 2;
    }
    .page-cont-part-list {
      .page-cont-part-list-item {
        .page-cont-part-list-item-txt {
          text-indent: 2em;
          line-height: 2;
        }
      }
    }
  }
  .body {
    margin: 10px 0;
    padding: 6px;
    width: 100%;
  }

  .body-item {
    font-size: 18px;
    color: black;
    padding: 10px 20px;
    user-select: none;
    .body-item-title {
      font-weight: bolder;
    }
  }

  .row {
    height: auto;
    border: 1px solid #ebeef5;
    border-top: none;
    display: flex;
    flex-direction: row;

    &:nth-child(1) {
      border-top: 1px solid #ebeef5;
    }

    .col {
      height: auto;
      line-height: 30px;
      display: flex;

      .item-title {
        height: auto;

        padding-left: 20px;
        min-width: 85px;
        font-size: 14px;
        color: #606266;
      }

      .item-value {
        height: auto;
        padding-left: 20px;
        flex: 1;
        // border-left: 1px solid #e6e9f0;
        border-left: 1px solid #ebeef5;
        border-right: 1px solid #ebeef5;
        // color: #3D4966;
        color: #303133;
        font-size: 14px;
        //默认显示
        // display: -webkit-box;
        // -webkit-box-orient: vertical;
        // -webkit-line-clamp: 3;
        // overflow: hidden;
        // text-overflow: ellipsis;
      }

      .red {
        color: #9e6600;
        font-weight: 700;
      }

      .bold {
        font-weight: 700;
      }

      .flex {
        display: flex;
        padding-left: 20px;
        flex-direction: row;
        flex-wrap: wrap;
      }

      &:nth-last-child(1) {
        border-right: none;
      }
    }
  }
}
</style>
