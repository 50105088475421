<template>
  <div class="container">
    <div class="item">
      <div class="img_box">
        <img class="quan" src="../../../assets/qualityConyrolScreen/quan1.png" alt=""/>
        <img class="icon" src="../../../assets/qualityConyrolScreen/quan1_icon.png" alt=""/>
      </div>
      <div class="word_box">
        <span class="word">药性</span>
        <span class="num">79</span>
      </div>
    </div>

    <div class="item">
      <div class="img_box">
        <img class="quan" src="../../../assets/qualityConyrolScreen/quan3.png" alt=""/>
        <img class="icon" src="../../../assets/qualityConyrolScreen/quan3_icon.png" alt=""/>
      </div>
      <div class="word_box">
        <span class="word">用量</span>
        <span class="num">68</span>
      </div>
    </div>
    <!--    <div class="item">-->
    <!--      <div class="img_box">-->
    <!--        <img class="quan" src="../../../assets/qualityConyrolScreen/quan2.png" alt=""/>-->
    <!--        <img class="icon" src="../../../assets/qualityConyrolScreen/quan2_icon.png" alt=""/>-->
    <!--      </div>-->
    <!--      <div class="word_box">-->
    <!--        <span class="word">已审核</span>-->
    <!--        <span class="num">789</span>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>

export default {
  data() {
    return {}
  },
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;

  .item {
    display: flex;
    align-items: center;
    gap: 10px;

    .img_box {
      width: 76px;
      height: 76px;
      position: relative;

      img {
        position: absolute;
      }

      .quan {
        top: 10px;
      }

      .icon {
        top: 18px;
        left: 28px;
        z-index: 1;
      }
    }


    .word_box {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .word {
        font-size: 16px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #FFFFFF;
      }

      .num {
        height: 24px;
        line-height: 24px;
        font-size: 24px;
        font-family: DIN;
        font-weight: bold;
        color: #3C7CF9;
      }
    }
  }
}
</style>
