var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "popup-wrap" }, [
    _c("div", { staticClass: "title" }, [_vm._v("个人收藏")]),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c(
          "el-row",
          { staticClass: "row", attrs: { gutter: 10 } },
          [
            _c(
              "el-col",
              { staticClass: "col", attrs: { span: 8 } },
              [
                _c("el-tree", {
                  attrs: {
                    data: _vm.data,
                    "node-key": "id",
                    draggable: "",
                    "default-expand-all": "",
                    "highlight-current": true,
                    "expand-on-click-node": false,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var node = ref.node
                        var data = ref.data
                        return _c("span", { staticClass: "custom-tree-node" }, [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.onCheckChange(data)
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    display: "block",
                                    "max-width": "110px !important",
                                    "white-space": "nowrap",
                                    "text-overflow": "ellipsis",
                                    overflow: "hidden",
                                  },
                                  attrs: { title: data.remark },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        data.prescriptionName
                                          ? data.prescriptionName
                                          : node.label
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            [
                              _c(
                                "el-dropdown",
                                [
                                  _c(
                                    "span",
                                    { staticClass: "el-dropdown-link" },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-more-outline",
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "el-dropdown-menu",
                                    {
                                      attrs: { slot: "dropdown" },
                                      slot: "dropdown",
                                    },
                                    [
                                      _c(
                                        "el-dropdown-item",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: data.prescriptionName,
                                              expression:
                                                "data.prescriptionName",
                                            },
                                          ],
                                          nativeOn: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.editHandle(node, data)
                                            },
                                          },
                                        },
                                        [_vm._v(" 编辑 ")]
                                      ),
                                      _c(
                                        "el-dropdown-item",
                                        {
                                          nativeOn: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.remove(node, data)
                                            },
                                          },
                                        },
                                        [_vm._v(" 删除 ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ])
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c("el-col", { staticClass: "col", attrs: { span: 16 } }, [
              _vm.isEdit
                ? _c("div", { staticClass: "info-box" }, [
                    _c(
                      "div",
                      { staticClass: "top" },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.yaofangInfo.prescriptionName,
                            callback: function ($$v) {
                              _vm.$set(_vm.yaofangInfo, "prescriptionName", $$v)
                            },
                            expression: "yaofangInfo.prescriptionName",
                          },
                        }),
                        _c("div", { staticClass: "key" }, [_vm._v("药方")]),
                        _c("div", { staticClass: "value" }, [
                          _c(
                            "div",
                            { staticClass: "kf" },
                            [
                              _vm._l(
                                _vm.yaofangInfo.prescriptionZcStdJson,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "yf-item" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "yt-item-name" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: item.n,
                                                expression: "item.n",
                                              },
                                            ],
                                            style: {
                                              width: item.n
                                                ? item.n.length * 14 + "px"
                                                : "30px",
                                            },
                                            domProps: { value: item.n },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  item,
                                                  "n",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "yt-item-number" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: item.v,
                                                expression: "item.v",
                                              },
                                            ],
                                            attrs: { type: "number" },
                                            domProps: { value: item.v },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  item,
                                                  "v",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                      _c("div", { staticClass: "yt-item-g" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: item.dw,
                                              expression: "item.dw",
                                            },
                                          ],
                                          domProps: { value: item.dw },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                item,
                                                "dw",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "yt-item-remove",
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeF(index)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-close",
                                          }),
                                        ]
                                      ),
                                    ]
                                  )
                                }
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "add_plus",
                                  on: { click: _vm.addPrescription },
                                },
                                [_c("i", { staticClass: "el-icon-plus" })]
                              ),
                            ],
                            2
                          ),
                        ]),
                        _c("div", { staticClass: "key" }, [
                          _vm._v("用法、制法"),
                        ]),
                        _c("el-input", {
                          model: {
                            value: _vm.yaofangInfo.prescriptionZhifa,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.yaofangInfo,
                                "prescriptionZhifa",
                                $$v
                              )
                            },
                            expression: "yaofangInfo.prescriptionZhifa",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "button",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.save($event)
                          },
                        },
                      },
                      [_vm._v("保存")]
                    ),
                  ])
                : _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.yaofangInfo.prescriptionName,
                          expression: "yaofangInfo.prescriptionName",
                        },
                      ],
                      staticClass: "info-box",
                    },
                    [
                      _c("div", { staticClass: "top" }, [
                        _c("div", { staticClass: "title box-title" }, [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.yaofangInfo.prescriptionName || "-")
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "key" }, [_vm._v("药方")]),
                        _c("div", { staticClass: "value" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.yaofangInfo.prescriptionDetail || "-"
                              ) +
                              " "
                          ),
                        ]),
                        _c("div", { staticClass: "key" }, [
                          _vm._v("用法、制法"),
                        ]),
                        _c("div", { staticClass: "value" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.yaofangInfo.prescriptionZhifa || "-") +
                              " "
                          ),
                        ]),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "button",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.reuse($event)
                            },
                          },
                        },
                        [_vm._v("复用")]
                      ),
                    ]
                  ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }