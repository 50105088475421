<template>
  <div class="container">
    <div class="item">
      <div class="item_title">
        视频
      </div>
      <div class="item_num">
        <img src="../../../assets/qualityConyrolScreen/left_top_video.png" alt=""/>
        <span>623个</span>
      </div>
    </div>
    <div class="item">
      <div class="item_title">
        文献
      </div>
      <div class="item_num">
        <img src="../../../assets/qualityConyrolScreen/left_top_audio.png" alt=""/>
        <span>3817部</span>
      </div>
    </div>
    <div class="item">
      <div class="item_title">
        医案
      </div>
      <div class="item_num">
        <img src="../../../assets/qualityConyrolScreen/left_top_yian.png" alt=""/>
        <span>826例</span>
      </div>
    </div>
    <div class="item">
      <div class="item_title">
        医生
      </div>
      <div class="item_num">
        <img src="../../../assets/qualityConyrolScreen/left_top_doctor.png" alt=""/>
        <span>497名</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'leftTop',
  data() {
    return {
      options: {}
    }
  },
  filters: {},
  created() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;

  .item {
    width: 128px;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 8px;

    .item_title {
      height: 32px;
      line-height: 32px;
      border-radius: 8px 8px 0 0;
      background: rgba(58, 106, 243, .7);
      text-align: center;
      font-size: 16px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      color: #FFFFFF;
    }

    .item_num {
      background: rgba(58, 165, 243, .3);
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      border-radius: 0 0 8px 8px;

      img {
        width: 84px;
        height: 84px;
      }

      span {
        width: 84px;
        background: #022146;
        border-radius: 4px;
        font-size: 18px;
        font-family: DIN;
        font-weight: 400;
        color: #CAECFF;
        text-align: center;
        line-height: 24px;
        height: 24px;
      }
    }
  }
}
</style>
