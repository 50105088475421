<script>
export default {
  name: 'itemTitle',
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<template>
  <div class="item_title">
    <img src="../../../assets/qualityConyrolScreen/item_title_icon.png" alt=""/>
    <span>{{ title }}</span>
    <div class="divider"></div>
    <img src="../../../assets/qualityConyrolScreen/item_title_jiantou.png" alt=""/>
  </div>
</template>

<style scoped lang="scss">
.item_title {
  height: 44px;
  color: #FFFFFF;
  line-height: 44px;
  width: 100%;
  font-size: 20px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  font-style: italic;
  display: flex;
  align-items: center;
  text-shadow: 0 0 8px rgba(255, 255, 255, 1);
  .divider {
    flex: 1;
    margin-left: 20px;
    margin-right: 10px;
    border-bottom: 0.5px solid #6EB1FE;
  }

  img {
    height: 16px;
  }
}
</style>
