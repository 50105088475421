var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Echart", {
    ref: "leftCenterTwo",
    staticStyle: { width: "100%", height: "100%", margin: "0 auto" },
    attrs: { options: _vm.options, id: "leftCenterTwo" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }