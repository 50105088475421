<template>
  <sizhen-layout
    @handleLastStep="handleLastStep()"
    @handleNextStep="handleNextStep()"
  >
    <div class="content">
      <div class="item-box">
        <div class="title-box" @click="init()">
          <img src="" alt="" />
          <div class="title-word">
            <div class="title">{{ sizhenData.tizhi }}</div>
            <div clsss="title-desc">
              神情抑郁，情感脆弱，烦闷不乐，舌淡红，苔薄白，脉弦
            </div>
          </div>
        </div>
        <div>
          <div class="left">
            <div>
              <div class="value">
                <div
                  class="item border"
                  v-for="(item, index) in bingyibingjiList"
                  :key="index"
                  :title="item.desc"
                  v-if="index < 3"
                >
                  <span>{{ convertToChinese(index) }}</span
                  >{{ item.title }}
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <div>
              <div class="shade"></div>
              <div class="top">
                <div
                  class="item"
                  :class="{ active: charts.curTab === item.value }"
                  v-for="(item, index) in charts.tabList"
                  :key="index"
                  @click="changeChartsTab(item)"
                >
                  {{ item.label }}
                </div>
              </div>
              <div class="bottom" ref="echarts">
                <div
                  class="item"
                  v-for="(item, index) in charts.data"
                  :key="index"
                >
                  <div class="boun" :style="{ height: item * 100 + '%' }"></div>
                  <div class="label">{{ index }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </sizhen-layout>
</template>

<script>
import sizhenLayout from "@/views/sizhen/components/layout";
import { aiDiagnoseByFamousDoctor } from "@/api/zkyz/aictm";
import { convertToChinese } from "@/utils";
import { mapActions, mapState } from "vuex";
import * as echarts from "echarts";
export default {
  name: "sizhen-tizhi",
  components: { sizhenLayout },
  data() {
    return {
      charts: {
        tabList: [
          {
            label: "病因",
            value: "bx",
          },
          {
            label: "病位",
            value: "bw",
          },
          {
            label: "基本物质",
            value: "jw",
          },
          {
            label: "病理状态",
            value: "blzt",
          },
          {
            label: "阴阳表里",
            value: "yybl",
          },
          {
            label: "寒热虚实",
            value: "hrxs",
          },
        ],
        dataSet: {},
        curTab: null,
        data: [],
      },
      curTab: null,
      // 推理证候
      bingyibingjiList: [{}, {}, {}],
    };
  },
  computed: {
    ...mapState(["sizhenData"]),
  },
  methods: {
    ...mapActions(["saveInfo", "touchActive"]),
    init() {
      let arr = [
        "sheDianci",
        "sheLaonen",
        "sheTaise",
        "sheFuni",
        "sheChihen",
        "sheRunzao",
        "shePangshou",
        "sheBoluo",
        "sheHoubo",
        "sheShese",
        "sheLiewen",
      ];
      let zhengzhuangJson = [];
      arr.map((item) => {
        let obj = {
          zy: this.sizhenData.shezhen[item],
          main: "",
          fashengyinsu: "",
          tezheng: "",
          chengdu: "",
          fazuopinci: "",
          bingcheng: "",
          jiazhong: "",
          huanjie: "",
          chixushijian: "",
          field: "shezhi",
        };
        zhengzhuangJson.push(obj);
      });
      let obj = {
        comedate: "",
        billno: this.sizhenData.billno,
        name: this.sizhenData.name,
        gender: this.sizhenData.gender,
        age: this.sizhenData.age,
        birthday: this.sizhenData.birthday,
        accidentDate: null,
        zhengzhuangJson: JSON.stringify(zhengzhuangJson),
        disease: "全",
        idcard: this.sizhenData.idcard,
        zhusu: null,
        jiwangshi: null,
        jiazushi: null,
        xianbingshi: null,
        cellphone: null,
        patientType: "初诊",
      };
      const params = new URLSearchParams();
      for (let key in obj) {
        params.append(key, obj[key]);
      }
      aiDiagnoseByFamousDoctor(params)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          let dataList = {
            diagnoseJson: {
              resultZH: {
                "twobw-bingli-qixue": ["肺脾气虚证()", "肺肾气阴两虚证()"],
                "twobw-bingyin": ["湿热闭肺证()", "湿热瘀阻证()"],
              },
              fj_jf: {
                "twobw-bingli-qixue": [
                  {
                    zhengming: "肺脾气虚证",
                    fangji: [
                      {
                        zc_std: [
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "9",
                            n: "人参",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "12",
                            n: "白术",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "15",
                            n: "茯苓",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "9",
                            n: "半夏",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "10",
                            n: "陈皮",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "10",
                            n: "川贝母",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "9",
                            n: "百部",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "12",
                            n: "莱菔子",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "9",
                            n: "白芥子",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "5",
                            n: "苏子",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "30",
                            n: "矮地茶",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "5",
                            n: "肉桂",
                          },
                        ],
                        fjname: "六君子汤合三子养亲汤加减",
                        zhifa: "补肺健脾、益气化痰",
                      },
                      {
                        zc_std: [
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "9",
                            n: "人参",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "30",
                            n: "黄芪",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "12",
                            n: "白术",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "10",
                            n: "陈皮",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "30",
                            n: "山药",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "10",
                            n: "升麻",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "10",
                            n: "柴胡",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "5",
                            n: "千层纸",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "10",
                            n: "桔梗",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "10",
                            n: "甘草",
                          },
                        ],
                        fjname: "补中益气汤加减",
                        zhifa: "补肺健脾、益气开音。",
                      },
                      {
                        zc_std: [
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "9",
                            n: "人参",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "12",
                            n: "白术",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "15",
                            n: "茯苓",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "15",
                            n: "大枣",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "30",
                            n: "黄芪",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "10",
                            n: "防风",
                          },
                        ],
                        fjname: "玉屏风散加减",
                        zhifa: "补肺健脾、益气化痰",
                      },
                    ],
                  },
                  {
                    zhengming: "肺肾气阴两虚证",
                    fangji: [
                      {
                        zc_std: [
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "6",
                            n: "西洋参",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "12",
                            n: "麦冬",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "9",
                            n: "阿胶",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "10",
                            n: "玄参",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "9",
                            n: "百部",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "6",
                            n: "五味子",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "12",
                            n: "百合",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "30",
                            n: "生地黄",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "15",
                            n: "白芍",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "9",
                            n: "白蔹",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "10",
                            n: "黄芩",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "15",
                            n: "白及",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "15",
                            n: "地骨皮",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "12",
                            n: "墨旱莲",
                          },
                        ],
                        fjname: "生脉散合百合固金汤加减",
                        zhifa: "益气养阴、润肺化痰",
                      },
                    ],
                  },
                ],
                "twobw-bingyin": [
                  {
                    zhengming: "湿热闭肺证",
                    fangji: [
                      {
                        zc_std: [
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "10",
                            n: "麻黄",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "10",
                            n: "苦杏仁",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "60",
                            n: "石膏",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "10",
                            n: "甘草",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "9",
                            n: "半夏",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "20",
                            n: "滑石",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "30",
                            n: "薏苡仁",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "5",
                            n: "白蔻仁",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "10",
                            n: "竹叶",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "10",
                            n: "厚朴",
                          },
                        ],
                        fjname: "麻杏石甘汤合三仁汤加减",
                        zhifa: "清热利湿、开肺化痰",
                      },
                    ],
                  },
                  {
                    zhengming: "湿热瘀阻证",
                    fangji: [
                      {
                        zc_std: [
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "9",
                            n: "苍术",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "12",
                            n: "黄柏",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "30",
                            n: "薏苡仁",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "12",
                            n: "当归",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "12",
                            n: "赤芍",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "15",
                            n: "丹参",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "10",
                            n: "桃仁",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "15",
                            n: "葛根",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "30",
                            n: "忍冬藤",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "12",
                            n: "牛膝",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "15",
                            n: "虎杖",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "30",
                            n: "生黄芪",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "10",
                            n: "皂角刺",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "10",
                            n: "生甘草",
                          },
                        ],
                        fjname: "三妙丸、萆薢渗湿汤加减",
                        zhifa: "清热利湿、化瘀通络。",
                      },
                      {
                        zc_std: [
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "6",
                            n: "白蔻",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "10",
                            n: "杏仁",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "30",
                            n: "苡仁",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "10",
                            n: "半夏",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "10",
                            n: "厚朴",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "10",
                            n: "木通",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "20",
                            n: "滑石",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "15",
                            n: "大腹皮",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "15",
                            n: "桑白皮",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "30",
                            n: "车前子",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "30",
                            n: "石苇",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "30",
                            n: "益母草",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "10",
                            n: "郁金",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "10",
                            n: "桃仁",
                          },
                          {
                            fw: "",
                            b: 0,
                            dw: "g",
                            v: "10",
                            n: "红花",
                          },
                        ],
                        fjname: "三仁汤加减",
                        zhifa: "化瘀清利湿热",
                      },
                    ],
                  },
                ],
              },
              vote: {
                bingyin: {
                  房事: 2.15,
                  火: 4.01,
                  虫: 0.75,
                  疫: 0.73,
                  瘀c: 0.23,
                  风: 10.7,
                  饮: 3.24,
                  痰: 8.93,
                  暑: 0,
                  毒: 2.1,
                  燥c: 4.3,
                  劳: 0,
                  水: 1.07,
                  寒c: 7.63,
                  热c: 12.95,
                  湿: 11.18,
                  食: 0.8,
                },
                biaoli: {
                  表: 7.98,
                  里: 14.95,
                },
                xushi: {
                  虚: 9,
                  实: 14,
                },
                zangfu: {
                  膀胱: 0,
                  大肠: 0,
                  心: 1.21,
                  胃: 0.81,
                  胆: 0.59,
                  肺: 13.6,
                  小肠: 0.63,
                  肝: 3.62,
                  肾: 8.37,
                  脾: 7.55,
                },
                qixue: {
                  血: 2.84,
                  液: 0.87,
                  气: 14,
                  津: 1.11,
                },
                yinyang: {
                  阳: 3.49,
                  阴: 11,
                },
                bingli: {
                  不固: 1.13,
                  逆: 6.05,
                  瘀d: 3.98,
                  闭: 1.23,
                  犯: 3.52,
                  困: 0.12,
                  脱: 0.81,
                  燥d: 0.83,
                  不和: 0.48,
                  陷: 0.79,
                  蒙: 0.79,
                  虚: 14,
                  盛: 2.19,
                  滞: 9.65,
                },
                hanre: {
                  寒d: 2.42,
                  热d: 14,
                },
              },
            },
            duxingJson: null,
            bingyibingji: null,
            fangji: null,
            zcy: null,
            shiliao: null,
            other: null,
            shjgFangji: null,
            rid: null,
          };
          let chartsTab = [
            {
              label: "病因",
              value: "bx",
            },
            {
              label: "病位",
              value: "bw",
            },
            {
              label: "基本物质",
              value: "jw",
            },
            {
              label: "病理状态",
              value: "blzt",
            },
            {
              label: "阴阳表里",
              value: "yybl",
            },
            {
              label: "寒热虚实",
              value: "hrxs",
            },
          ];
          this.charts.tabList = chartsTab;
          this.charts.dataSet["bx"] =
            dataList.diagnoseJson?.vote?.bingyin || {};
          this.charts.dataSet["bw"] = dataList.diagnoseJson?.vote?.zangfu || {};
          this.charts.dataSet["jw"] = dataList.diagnoseJson?.vote?.qixue || {};
          this.charts.dataSet["blzt"] =
            dataList.diagnoseJson?.vote?.bingli || {};
          this.charts.dataSet["yybl"] = Object.assign(
            dataList.diagnoseJson?.vote?.yinyang || {},
            dataList.diagnoseJson?.vote?.biaoli || {}
          );
          this.charts.dataSet["hrxs"] = Object.assign(
            dataList.diagnoseJson?.vote?.hanre || {},
            dataList.diagnoseJson?.vote?.xushi || {}
          );

          this.changeChartsTab(this.charts.tabList[0]);

          // 症候
          let zh1 = dataList.diagnoseJson.resultZH["twobw-bingli-qixue"] || [];
          let zh2 = dataList.diagnoseJson.resultZH["twobw-bingyin"] || [];
          zh1.push(...zh2);
          let zhList = [];
          for (let item of zh1) {
            let arr = item.split("(");
            let title = arr[0];
            let desc = arr.length < 2 ? "" : item.split("(")[1].split(")")[0];
            zhList.push({
              title,
              desc,
            });
          }
          this.bingyibingjiList = zhList;
        });
    },
    /**
     * @description: 阿拉伯数字转汉字数字
     * @return {*}
     */
    convertToChinese(index) {
      return convertToChinese(index);
    },
    /**
     * @description: 切换选项卡
     * @param {*} tab
     * @return {*}
     */
    changeChartsTab(tab) {
      if (Object.keys(this.charts.dataSet).length < 1) {
        return;
      }
      if (this.charts.curTab == tab.value) {
        return;
      }
      this.charts.curTab = tab.value;
      let data = this.charts.dataSet[tab.value] || {};

      let list = [];
      for (let key in data) {
        list.push({
          key,
          value: data[key],
        });
      }

      list.sort((o1, o2) => {
        return o2.value - o1.value;
      });

      if (list.length > 5) {
        list = list.slice(0, 5);
      }

      data = {};
      for (let item of list) {
        data[item.key] = item.value;
      }

      let max = 0;
      for (let key in data) {
        let value = data[key];
        if (max < value) {
          max = value;
        }
      }

      let xList = [];
      let yList = [];
      let i = 0;
      for (let key in data) {
        let newKey = key
          .replace("c", "")
          .replace("d", "")
          .replace("C", "")
          .replace("D", "");
        let value = data[key];
        xList.push(newKey);
        yList.push(value);
        i++;
      }
      let myChart = echarts.init(this.$refs.echarts);
      var option;

      option = {
        color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
          {
            offset: 0,
            color: "#AE7000",
          },
          {
            offset: 1,
            color: "#C48D6E",
          },
        ]),
        xAxis: {
          type: "category",
          data: xList,
          axisLabel: {
            fontSize: 18,
            color: "black",
            fontFamily: "隶书",
          },
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: yList,
            type: "bar",
            barWidth: 40,
            itemStyle: {
              borderWidth: 1,
              borderColor: "#00aa00",
            },
          },
        ],
        grid: {
          left: 40,
          top: 30,
          right: 40,
          bottom: 30,
        },
        tooltip: {
          formatter: "{b0}",
        },
      };
      option && myChart.setOption(option);
    },
    /**
     * @description: 上一步
     * @return {*}
     */
    handleNextStep() {
      this.touchActive(4);
      this.$emit("handleNextStep");
    },
    /**
     * @description: 下一步
     * @return {*}
     */
    handleLastStep() {
      this.touchActive(2);
      this.$emit("handleLastStep");
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  width: 100%;
  height: calc(100vh - 120px);
  display: flex;
  justify-content: center;
  align-items: center;
  .item-box {
    width: 1200px;
    min-height: 600px;
    max-height: 700px;
    background: #ffffff;
    border-radius: 16px;
    color: #323233;
    padding: 16px;
    display: flex;
    flex-direction: column;
    .title-box {
      display: flex;
      align-items: center;
      img {
        width: 56px;
        height: 56px;
      }
      .title-word {
        .title {
          line-height: 24px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          font-size: 24px;
          color: #080a1b;
        }
        .title-desc {
          line-height: 19px;
          font-weight: bold;
          font-size: 19px;
          color: #df6570;
        }
      }
    }

    .tabs {
      display: flex;
      justify-content: space-evenly;
      border-bottom: 1px solid #c48d6e;
      height: 50px;
      box-sizing: border-box;

      .item {
        height: 40px;
        background: #e0eefb;
        font-size: 16px;
        line-height: 40px;
        padding: 0 10px;
        cursor: pointer;
        box-sizing: border-box;
        border-bottom: 1px solid #c48d6e;
        user-select: none;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        flex: 1;
        position: relative;
        margin-top: 10px;
        &.active {
          height: 50px;
          line-height: 50px;
          margin-top: 0;
          color: #0166e2;
          font-weight: bolder;
          background: #ffffff;
          z-index: 99;
          overflow: visible;
        }
        &.active::before {
          content: "";
          width: 40px;
          border: #0166e2 2px solid;
          border-radius: 2px;
          position: absolute;
          bottom: 5px;
          left: calc(50% - 20px);
        }
        &.active::after {
          content: "";
          width: 50px;
          height: 50px;
          background: #ffffff;
          border-radius: 0 50px 0 0;
          position: absolute;
          right: -50px;
          bottom: 0;
        }
      }
    }
  }
}
.container {
  width: 50%;
  margin: 0 auto;
}
.begin-btn {
  width: 220px;
  height: 84px;
  background: #ffffff;
  border-radius: 16px;
  line-height: 84px;
  text-align: center;
  color: #0166e2;
  font-weight: bold;
  font-size: 24px;
}
.left {
  border-radius: 20px;
  background-color: #df6570;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .label {
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
    margin-left: 10px;
  }

  .value {
    height: 100px;
    display: flex;
    justify-content: space-evenly;
    user-select: none;
    .item {
      display: flex;
      font-weight: 600;
      font-family: KaiTi;
      font-size: 20px;
      color: #ae7000;
      align-items: center;

      span {
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        margin-bottom: 5px;
        color: #ffffff;
        background: url(../../../assets/images/num-border.png);
        background-size: 30px 30px;
      }
    }
  }
}

.right {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: url("../../../assets/images/tuili.png") no-repeat;
  background-size: 100% 100%;
  padding: 5px 20px;
  // box-shadow: 0 5px 5px #1ab394;
  .top {
    display: flex;
    gap: 5px;
    border-bottom: 1px solid #c48d6e;
    height: 40px;
    box-sizing: border-box;

    .item {
      height: 40px;
      background: #ebe5dd;
      font-size: 16px;
      border-radius: 4px 4px 0 0;
      padding: 0 10px;
      cursor: pointer;
      box-sizing: border-box;
      border-bottom: 1px solid #c48d6e;
      user-select: none;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      &.active {
        color: #ae7000;
        background: #f0e8da;
        border: 1px solid #c48d6e;
        border-bottom: 1px solid #f0e8da;
      }
    }
  }

  .bottom {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 179px;

    .item {
      flex: 1;
      height: 100%;
      font-size: 16px;
      color: black;
      font-weight: bold;
      text-align: left;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-right: 10px;

      .label {
        font-size: 16px;
        color: black;
        font-weight: bold;
      }
    }
  }
}
</style>
