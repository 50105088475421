import request from '@/utils/request'

// 查询埋点列表
export function listTracking(query) {
  return request({
    url: '/tracking/tracking/list',
    method: 'get',
    params: query
  })
}

// 查询埋点详细
export function getTracking(id) {
  return request({
    url: '/tracking/tracking/' + id,
    method: 'get'
  })
}

// 新增埋点
export function addTracking(data) {
  return request({
    url: '/tracking/tracking',
    method: 'post',
    data: data
  })
}

// 修改埋点
export function updateTracking(data) {
  return request({
    url: '/tracking/tracking',
    method: 'put',
    data: data
  })
}

// 删除埋点
export function delTracking(id) {
  return request({
    url: '/tracking/tracking/' + id,
    method: 'delete'
  })
}

export function toReport(data) {
  return request({
    url: '/tracking/tracking/report',
    headers: {
      isToken: false,
      isRepeatSubmit: false
    },
    method: 'post',
    data
  })
}
