<template>
  <div>
    <div class="page-wrap" style="margin-bottom: 20px">
      <!-- 身份信息顶部 -->
      <div class="page-search">
        <div @click.stop="querySiZhen">
          <img
            src="../../../assets/images/sizhenyi-icon.png"
            style="margin-right: 5px"
            alt=""
          />
          四诊仪
        </div>
        <div @click.stop="readCard">
          <img
            src="../../../assets/images/duqujiuzhenka-icon.png"
            style="margin-right: 5px"
            alt=""
          />
          读卡
        </div>
        <div>{{ form.billno }}</div>
        <div @click.stop="scanCode">
          <img
            src="../../../assets/images/duqujiuzhenka-icon.png"
            style="margin-right: 5px"
            alt=""
          />
          扫码
        </div>
        <div @click.stop="queryBl">
          <img
            src="../../../assets/images/lishibingli-icon.png"
            style="margin-right: 5px"
            alt=""
          />
          历史病历
        </div>
        <div @click.stop="refreshPage" style="width: 30px">
          <i class="el-icon-refresh-right" style="margin: 0"></i>
        </div>
      </div>

      <!-- 身份信息表单 -->
      <div
        class="page-info"
        v-loading="getCardLoading"
        :background="'rgba(0,0,0,0)'"
      >
        <el-row :gutter="10">
          <el-col :span="6">
            <div class="item">
              <div class="key"><span style="color: red">*</span>患者姓名：</div>
              <div class="value">
                <el-select
                  v-model="form.name"
                  allow-create
                  filterable
                  remote
                  placeholder="请输入关键词"
                  default-first-option
                  clearable
                  value-key="patientId"
                  :remote-method="getNameList"
                  :loading="selectLoading"
                  @change="handleSelect($event)"
                  @clear="clearName"
                  @blur="inputBlur"
                >
                  <el-option
                    v-for="item in patientsName"
                    :key="item.patientId"
                    :label="item.name"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="item">
              <div class="key"><span style="color: red">*</span>性别：</div>
              <div class="value" style="margin-top: 8px">
                <el-radio-group v-model="form.gender" @change="genderChange">
                  <el-radio label="男">男性</el-radio>
                  <el-radio label="女">女性</el-radio>
                </el-radio-group>
              </div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="item">
              <div class="key"><span style="color: red">*</span>年龄：</div>
              <div class="value">
                <el-input
                  type="number"
                  placeholder="年龄"
                  v-model="form.age"
                  @change="agechange"
                  style="width: 100%"
                ></el-input>
              </div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="item">
              <div class="key"><span style="color: red">*</span>手机号：</div>
              <div class="value">
                <el-input placeholder="手机号" v-model="form.cellphone" />
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="6">
            <div class="item">
              <div class="key">出生日期：</div>
              <div class="value">
                <el-date-picker
                  v-model="form.birthday"
                  type="date"
                  prefix-icon="none"
                  style="width: 100%"
                  @change="changeBirthday"
                  placeholder="出生日期"
                >
                </el-date-picker>
              </div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="item">
              <div class="key">发病日期：</div>
              <div class="value">
                <el-date-picker
                  v-model="form.accidentDate"
                  type="date"
                  prefix-icon="none"
                  style="width: 100%"
                  placeholder="发病日期"
                >
                </el-date-picker>
              </div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="item">
              <div class="key">身份证号：</div>
              <div class="value">
                <el-input
                  placeholder="身份证号"
                  v-model="form.idcard"
                  @change="getIdCardInfo"
                />
              </div>
            </div>
          </el-col>

          <el-col :span="6">
            <div class="item">
              <div class="key">患者类型：</div>
              <div class="value">
                <el-select
                  v-model="form.patientType"
                  clearable
                  placeholder="患者类型"
                >
                  <el-option
                    v-for="item in patientTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="12">
            <div class="item">
              <div class="key">主诉症状：</div>
              <div class="value">
                <el-input
                  type="textarea"
                  style="width: 100%"
                  placeholder=""
                  v-model="form.zhusu"
                />
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="item">
              <div class="key">既往史：</div>
              <div class="value">
                <el-input
                  type="textarea"
                  style="width: 100%"
                  placeholder=""
                  v-model="form.jiwangshi"
                />
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="12">
            <div class="item">
              <div class="key">家族史：</div>
              <div class="value">
                <el-input
                  type="textarea"
                  style="width: 100%"
                  placeholder=""
                  v-model="form.jiazushi"
                />
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="item">
              <div class="key">现病史：</div>
              <div class="value">
                <el-input
                  type="textarea"
                  style="width: 100%"
                  placeholder=""
                  v-model="form.xianbingshi"
                />
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- 主症兼症，舌象，左右脉 -->
    <div class="page-wrap">
      <el-row :gutter="10">
        <el-col :span="8">
          <div class="item">
            <div class="key preview-box">
              舌象：
              <div class="preview" @click="preview" v-show="shefileUrl">
                查看图片
              </div>
              <el-image
                style="width: 100px; height: 100px"
                :src="shefileUrl"
                ref="preview"
                :preview-src-list="[shefileUrl]"
                v-show="false"
              >
              </el-image>
            </div>
            <el-select
              class="multiple-select-half"
              v-model="sheAllIdList"
              multiple
              allow-create
              filterable
              remote
              placeholder="请输入关键词"
              default-first-option
              :remote-method="remotesheAllMethod"
              v-el-select-loadmore="selectLoadmoreSheAll"
              :loading="selectLoading"
              @focus="shexiangFocus"
            >
              <el-option
                v-for="(item, index) in sheAllList"
                :key="item.id"
                :label="item.zy"
                :value="item.zy"
              >
              </el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="8">
          <el-row>
            <div class="item">
              <div class="key">左脉：</div>
              <div class="value">
                <el-popover placement="top" width="240" v-model="leftMaiShow">
                  <div class="mai-container">
                    <div
                      class="box"
                      v-for="(item, index) in maiType"
                      :key="index"
                    >
                      <div class="mai-name">{{ item.name }}</div>
                      <div class="mai-value">
                        <div
                          :class="{
                            active: leftMai[item.value].indexOf(mai.name) > -1,
                          }"
                          v-for="(mai, i) in item.children"
                          :key="i"
                          @click="
                            leftMai[item.value].indexOf(mai.name) > -1
                              ? removeMai(
                                  '左',
                                  item.value,
                                  mai.name,
                                  leftMai[item.value].findIndex(
                                    (val) => val === mai.name
                                  )
                                )
                              : maiHandle('左', item.value, mai.name)
                          "
                        >
                          {{ mai.name }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="keratosis hei" slot="reference">
                    <div class="item-wrap tags">
                      <div
                        v-for="(item, index) in leftMai"
                        :key="index"
                        class="item-tag"
                      >
                        <span v-if="item.length">{{ index }}：</span>
                        <div class="item-box">
                          <div
                            class="item"
                            v-for="(ite, ind) in item"
                            :key="ind"
                          >
                            {{ ite }}
                            <i
                              class="el-icon-close"
                              @click.stop="removeMai('左', index, ite, ind)"
                            ></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-popover>
              </div>
            </div>
          </el-row>
        </el-col>
        <el-col :span="8">
          <el-row>
            <div class="item">
              <div class="key">右脉：</div>
              <div class="value">
                <el-popover placement="top" width="240" v-model="rightMaiShow">
                  <div class="mai-container">
                    <div
                      class="box"
                      v-for="(item, index) in maiType"
                      :key="index"
                    >
                      <div class="mai-name">{{ item.name }}</div>
                      <div class="mai-value">
                        <div
                          :class="{
                            active: rightMai[item.value].indexOf(mai.name) > -1,
                          }"
                          v-for="(mai, i) in item.children"
                          :key="i"
                          @click="
                            rightMai[item.value].indexOf(mai.name) > -1
                              ? removeMai(
                                  '右',
                                  item.value,
                                  mai.name,
                                  rightMai[item.value].findIndex(
                                    (val) => val === mai.name
                                  )
                                )
                              : maiHandle('右', item.value, mai.name)
                          "
                        >
                          {{ mai.name }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="keratosis hei" slot="reference">
                    <div class="item-wrap tags">
                      <div
                        v-for="(item, index) in rightMai"
                        :key="index"
                        class="item-tag"
                      >
                        <span v-if="item.length">{{ index }}：</span>
                        <div class="item-box">
                          <div
                            class="item"
                            v-for="(ite, ind) in item"
                            :key="ind"
                          >
                            {{ ite }}
                            <i
                              class="el-icon-close"
                              @click.stop="removeMai('右', index, ite, ind)"
                            ></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-popover>
              </div>
            </div>
          </el-row>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24">
          <div class="item">
            <div class="key">刻下症：</div>
            <div class="value">
              <el-select
                class="multiple-select-half"
                v-model="zhengzhuangIdList"
                multiple
                allow-create
                filterable
                remote
                placeholder="请输入关键词"
                :remote-method="remotezhengzhuangMethod"
                default-first-option
                v-el-select-loadmore="selectLoadmoreZhengzhuang"
                :loading="selectLoading"
                @focus="zhuzhengFocus"
              >
                <el-option
                  v-for="item in zhengzhuangList"
                  :key="item.id"
                  :label="item.zy"
                  :value="item.zy"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <div class="bottom-symptom">
          <div class="item-wrap">
            <div
              class="item"
              :class="{ active: guanliantuijianIdList.indexOf(item) > -1 }"
              @click="clickGuanliantuijian(item)"
              v-for="(item, index) in guanliantuijianList"
              :key="index"
            >
              {{ item }}
            </div>
          </div>
        </div>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24">
          <div class="item">
            <div class="key">疾病辨别：</div>
            <div class="value">
              <el-select
                style="margin-top: 8px; text-align: left"
                v-model="jibingbianbieId"
                filterable
                remote
                reserve-keyword
                clearable
                placeholder="请输入关键词"
                :remote-method="remotejibingbianbieMethod"
                :loading="selectLoading"
                @clear="clearjibingbianbie"
              >
                <el-option
                  v-for="(item, index) in jibingbianbieList"
                  :key="index"
                  :label="item.zy"
                  :value="item.zy"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <div class="page-search">
          <div class="button" @click="confirm">
            <img
              src="../../../assets/images/zhinengbianzheng-icon.png"
              style="margin-right: 5px"
              alt=""
            />智能辨证
          </div>
        </div>
      </el-row>
    </div>

    <!-- 病历查询 -->
    <el-dialog
      :visible.sync="queryVisible"
      :close-on-click-modal="false"
      :modal="false"
      width="70%"
      title="查询"
      v-dialogDrag
    >
      <query
        @blInfoReuseData="blInfoReuseData"
        @prescriptionZcStd="prescriptionZcStd"
        ref="query"
      ></query>
    </el-dialog>

    <!--四诊查询-->
    <el-dialog
      :visible.sync="sizhenVisible"
      :modal="false"
      :close-on-click-modal="false"
      ref="sizhenRef"
      width="50%"
      title="四诊仪"
      v-dialogDrag
    >
      <popupSizhen
        ref="sizhen"
        @sizhenData="sizhenData"
        :idcard="form.idcard"
        :telephone="form.cellphone"
      />
    </el-dialog>

    <!-- 四诊-症状 弹窗 -->
    <zhenduan ref="zhenduan" @confirm="zhenzhuangPopupConfirm"></zhenduan>
  </div>
</template>

<script>
import zhenduan from "./popup/popup-zhenduan.vue";
import query from "./popup/popup-query.vue";
import popupSizhen from "@/views/zkyz/aitcm/popup/popup-sizhen.vue";
import {
  aiDiagnoseByFamousDoctor,
  aiDiagnoseWithgoldPrescriptionNew,
  getBillNo,
  getBkdDiseaseList,
  getPatternZyListMai,
  getPatternZyListZhengzhuang,
  getTj,
  getPatternZyListsheAll,
  GetPatientInfoSearch,
  save,
  getPatientInfoByQrCode,
} from "@/api/zkyz/aictm";
import ButtonClick from "@/tools/impl/ButtonClick";
export default {
  components: { query, zhenduan, popupSizhen },
  data() {
    return {
      items: "", //扫码结果
      isStart: false, //是否开启扫码
      //患者姓名列表
      patientsName: [],
      patientForm: {
        pageNum: 1,
        pageSize: 10,
        name: "",
      },
      //患者类型
      patientTypeList: [
        {
          value: "初诊",
          label: "初诊",
        },
        {
          value: "复诊",
          label: "复诊",
        },
      ],
      getCardLoading: false, //读卡loading
      // 表单顶部
      queryParams: {
        curDate: null,
        keyword: null,
      },
      // 四诊 - 诊断查询参数
      zhengzhuangForm: {
        zy: null,
        pageNum: 1,
        pageSize: 10,
      },
      //脉开关
      leftMaiShow: false,
      rightMaiShow: false,
      //脉类型:
      maiType: [
        {
          name: "寸脉",
          value: "寸",
          children: [
            {
              name: "浮",
              value: "浮",
            },
            {
              name: "沉",
              value: "沉",
            },
            {
              name: "迟",
              value: "迟",
            },
            {
              name: "数",
              value: "数",
            },
            {
              name: "滑",
              value: "滑",
            },
            {
              name: "涩",
              value: "涩",
            },
            {
              name: "虚",
              value: "虚",
            },
            {
              name: "实",
              value: "实",
            },
            {
              name: "长",
              value: "长",
            },
            {
              name: "短",
              value: "短",
            },
            {
              name: "洪",
              value: "洪",
            },
            {
              name: "微",
              value: "微",
            },
            {
              name: "紧",
              value: "紧",
            },
            {
              name: "缓",
              value: "缓",
            },
            {
              name: "弦",
              value: "弦",
            },
            {
              name: "芤",
              value: "芤",
            },
            {
              name: "濡",
              value: "濡",
            },
            {
              name: "弱",
              value: "弱",
            },
            {
              name: "强",
              value: "强",
            },
            {
              name: "细",
              value: "细",
            },
            {
              name: "促",
              value: "促",
            },
            {
              name: "节",
              value: "节",
            },
            {
              name: "代",
              value: "代",
            },
          ],
        },
        {
          name: "关脉",
          value: "关",
          children: [
            {
              name: "浮",
              value: "浮",
            },
            {
              name: "沉",
              value: "沉",
            },
            {
              name: "迟",
              value: "迟",
            },
            {
              name: "数",
              value: "数",
            },
            {
              name: "滑",
              value: "滑",
            },
            {
              name: "涩",
              value: "涩",
            },
            {
              name: "虚",
              value: "虚",
            },
            {
              name: "实",
              value: "实",
            },
            {
              name: "长",
              value: "长",
            },
            {
              name: "短",
              value: "短",
            },
            {
              name: "洪",
              value: "洪",
            },
            {
              name: "微",
              value: "微",
            },
            {
              name: "紧",
              value: "紧",
            },
            {
              name: "缓",
              value: "缓",
            },
            {
              name: "弦",
              value: "弦",
            },
            {
              name: "芤",
              value: "芤",
            },
            {
              name: "濡",
              value: "濡",
            },
            {
              name: "弱",
              value: "弱",
            },
            {
              name: "强",
              value: "强",
            },
            {
              name: "细",
              value: "细",
            },
            {
              name: "促",
              value: "促",
            },
            {
              name: "节",
              value: "节",
            },
            {
              name: "代",
              value: "代",
            },
          ],
        },
        {
          name: "尺脉",
          value: "尺",
          children: [
            {
              name: "浮",
              value: "浮",
            },
            {
              name: "沉",
              value: "沉",
            },
            {
              name: "迟",
              value: "迟",
            },
            {
              name: "数",
              value: "数",
            },
            {
              name: "滑",
              value: "滑",
            },
            {
              name: "涩",
              value: "涩",
            },
            {
              name: "虚",
              value: "虚",
            },
            {
              name: "实",
              value: "实",
            },
            {
              name: "长",
              value: "长",
            },
            {
              name: "短",
              value: "短",
            },
            {
              name: "洪",
              value: "洪",
            },
            {
              name: "微",
              value: "微",
            },
            {
              name: "紧",
              value: "紧",
            },
            {
              name: "缓",
              value: "缓",
            },
            {
              name: "弦",
              value: "弦",
            },
            {
              name: "芤",
              value: "芤",
            },
            {
              name: "濡",
              value: "濡",
            },
            {
              name: "弱",
              value: "弱",
            },
            {
              name: "强",
              value: "强",
            },
            {
              name: "细",
              value: "细",
            },
            {
              name: "促",
              value: "促",
            },
            {
              name: "节",
              value: "节",
            },
            {
              name: "代",
              value: "代",
            },
          ],
        },
      ],
      // 四诊 - 舌苔舌质合一查询参数
      sheAllForm: {
        zy: null,
        pageNum: 1,
        pageSize: 10,
      },
      // 四诊 - 脉象查询参数
      maixiangForm: {
        zy: null,
        pageNum: 1,
        pageSize: 10,
      },
      leftMai: {
        寸: [],
        关: [],
        尺: [],
      },
      rightMai: {
        寸: [],
        关: [],
        尺: [],
      },
      // 四诊 - 疾病辨别查询参数
      jibingbianbieForm: {
        zy: null,
        pageNum: 1,
        pageSize: 100,
      },
      // 四诊 - 诊断表单
      form: {
        // ak: '9190acb595514d68b972a5c1f441a300',
        comedate: null,
        billno: null,
        name: null,
        gender: null,
        age: null,
        birthday: null,
        accidentDate: null,
        zhengzhuangJson: null,
        disease: null,
        idcard: null,
        zhusu: null,
        jiwangshi: null,
        jiazushi: null,
        xianbingshi: null,
        cellphone: null,
        patientType: "初诊",
        patientCaseId: null,
        patientInfoId: null,
      },
      // 返回结果
      zhengzhuangList: [],
      zhengzhuangAllList: [],
      sheAllList: [], //舌苔舌质合一
      maixiangList: [],
      jibingbianbieList: [],
      jibingbianbieListCopy: [],
      guanliantuijianList: [],

      // 选择结果
      zhengzhuangIdList: [], //主症
      shefileUrl: null, //四诊仪舌诊照片
      sheAllIdList: [], //舌象：舌苔舌质合一
      maixiangIdList: [],
      jibingbianbieId: null,
      jibingbianbieIdList: [],
      guanliantuijianIdList: [],

      zhenduanForm: {},

      selectLoading: false,
      hasAdvanced: true,
      advanced: false,
      maiConclusion: [], //脉象结论，智能辨证传参

      //弹窗开关
      queryVisible: false,
      sizhenVisible: false,
    };
  },
  computed: {
    maixiangIdLists() {
      return JSON.parse(JSON.stringify(this.maixiangIdList));
    },
  },
  watch: {
    items: {
      handler(newVal, oldVal) {
        if (newVal) {
          getPatientInfoByQrCode(newVal).then((res) => {
            if (res.code == 200) {
              this.$refs.sizhenRef.$children[0].checkedItem(res.data);
              this.isStart = false;
              this.getCardLoading = false;
            }
          });
        }
      },
    },
    /**
     * 监听症状选择
     */
    zhengzhuangIdList: {
      handler(newVal, oldVal) {
        if (newVal == oldVal) {
          return;
        }
        if (newVal.length > oldVal.length) {
          if (newVal.length > 1) {
            newVal.map((item) => {
              this.zhenzhuangConfirm("zhengzhuang", item);
            });
          } else {
            this.zhenzhuangConfirm("zhengzhuang", newVal[newVal.length - 1]);
          }
        } else {
          this.zhenzhuangCancel(
            "zhengzhuang",
            this.getDiffer(oldVal, newVal)[0]
          );
        }
      },
    },

    /**
     * 舌苔舌质合一
     */
    sheAllIdList: {
      handler(newVal, oldVal) {
        // console.log(newVal, oldVal, "舌象list");
        if (newVal == oldVal) {
          return;
        }
        if (newVal.length > oldVal.length) {
          if (newVal.length > 1) {
            newVal.map((item) => {
              this.zhenzhuangConfirm("shezhi", item);
            });
          } else {
            this.zhenzhuangConfirm("shezhi", newVal[newVal.length - 1]);
          }
        } else {
          this.zhenzhuangCancel("shezhi", this.getDiffer(oldVal, newVal)[0]);
        }
      },
    },
    /**
     * 监听脉象选择
     */
    maixiangIdLists: {
      handler(newVal, oldVal) {
        // console.log(newVal, oldVal, "脉象list");
        if (newVal == oldVal) {
          return;
        }
        if (newVal.length > oldVal.length) {
          if (newVal.length > 1) {
            newVal.map((item) => {
              this.zhenzhuangConfirm("maixiang", item);
            });
          } else {
            this.zhenzhuangConfirm("maixiang", newVal[newVal.length - 1]);
          }
        } else {
          this.zhenzhuangCancel("maixiang", this.getDiffer(oldVal, newVal)[0]);
        }
      },
    },
  },
  mounted() {
    this.sizhenVisible = true;
    this.$refs.sizhenRef.rendered = true;
    this.sizhenVisible = false;
    this.startScannerHandler();
    // 获取当前日期
    this.form.comedate = this.getDate();
    // 格式化日期
    this.queryParams.curDate = this.form.comedate.split(" ")[0];
    this.form.comedate = this.queryParams.curDate;
    // 初始化设置
    this.init();
  },
  methods: {
    scanCode() {
      this.isStart = !this.isStart;
      this.getCardLoading = !this.getCardLoading;
    },
    startScannerHandler() {
      if (!this.isStart) {
        this._scanner = this.$scanner({
          callback: (v) => {
            this.items = v;
          },
        });
      } else {
        this._scanner.cancel();
      }
      this.isStart = !this.isStart;
    },
    refreshPage() {
      this.$router.go(0);
    },
    queryBl() {
      this.queryVisible = true;
      this.$nextTick(() => {
        this.$refs.query.query();
      });
    },
    querySiZhen() {
      this.sizhenVisible = true;
      this.$nextTick(() => {
        this.$refs.sizhen.query();
      });
    },
    readCard() {
      this.getCardLoading = true;
      this.$jsonp("http://127.0.0.1:8989/api/ReadMsg?waitTime=3")
        .then((res) => {
          if (res.code === "0") {
            const { name, cardno, sex } = res;
            this.form.name = name;
            this.form.idcard = cardno;
            this.form.gender = sex;
            this.getIdCardInfo(cardno);
            this.getCardLoading = false;
          } else {
            setTimeout(() => {
              this.$message.error("读卡失败，请重试或手动输入信息。");
              this.getCardLoading = false;
            }, 1000);
          }
        })
        .catch((err) => {
          this.$message.error("读卡失败，请重试或手动输入信息。");
          this.getCardLoading = false;
        });
    },
    //四诊仪数据会写
    sizhenData(e) {
      this.form.patientType = "初诊";
      this.leftMai = e.leftMai;
      this.rightMai = e.rightMai;
      this.sheAllIdList = e.shexiangList;
      this.maixiangIdList = e.maixiangList;
      this.form.idcard = e.idcard;
      this.form.cellphone = e.telephone || e.cellphone;
      this.form.name = e.patientName || e.name;
      this.form.gender = e.sex || e.gender;
      this.genderChange();
      this.form.age = e.age;
      this.shefileUrl = e.shefileUrl;
      this.maiConclusion = e.maiConclusion;
      this.form.therapeuticRegimen = e.id;
      this.agechange(e.age);
      if (e.idCard) {
        this.getIdCardInfo(e.idCard);
      }
      if (e) {
        this.form.patientType = "复诊";
        this.sizhenVisible = false;
      }
    },
    //历史病例数据会写
    blInfoReuseData(e) {
      this.form.status = e.status;
      this.form.billno = e.billno;
      this.form.patientCaseId = e.patientCaseId;
      this.form.patientInfoId = e.patientInfoId;
      this.form.patientType = "初诊";
      this.leftMai = e.leftMai;
      this.rightMai = e.rightMai;
      this.form.idcard = e.idCard;
      this.form.age = e.age;
      this.form.name = e.name;
      this.form.birthday = e.birthday;
      this.form.cellphone = e.cellphone;
      this.form.gender = e.gender == "M" ? "男" : "女";
      this.sheAllIdList = e.tongueInfo.split(",").filter((item) => item != "");
      this.maixiangIdList = e.maixiang;
      this.zhengzhuangIdList = e.cardinalSymptom.split(",");
      if (e.idCard) {
        this.getIdCardInfo(e.idCard);
      }
      if (e) {
        this.form.patientType = "复诊";
      }
    },
    prescriptionZcStd(e) {
      let right = this.$parent.$refs.right;
      right.fList = JSON.parse(e).map((item) => {
        return {
          name: item.n,
          number: item.v,
          g: item.dw,
        };
      });
    },
    handleSelect(item) {
      if (Object.prototype.toString.call(item) === "[object Object]") {
        this.form.patientType = item.patientType;
        this.form.idcard = item.idcard;
        this.form.name = item.name;
        this.form.age = item.age;
        if (item.gender === "M") {
          this.form.gender = "男";
        } else if (item.gender === "F") {
          this.form.gender = "女";
        }
        this.form.cellphone = item.cellphone;
        this.form.birthday = item.birthday;
      } else {
        this.form = {
          // ak: '9190acb595514d68b972a5c1f441a300',
          comedate: null,
          billno: null,
          name: item,
          gender: null,
          age: null,
          birthday: null,
          accidentDate: null,
          zhengzhuangJson: null,
          disease: null,
          idcard: null,
          zhusu: null,
          jiwangshi: null,
          jiazushi: null,
          xianbingshi: null,
          cellphone: null,
          patientType: "初诊",
        };
      }
    },
    clearName() {
      //清空名字时初始化data数据
      Object.assign(this.$data, this.$options.data.call(this));
      this.init();
    },
    inputBlur(e) {
      this.form.name = e.target.value;
    },
    /**
     * @description: 搜索患者列表
     * @param {*} name  患者姓名
     * @return {*}
     */
    getNameList(name) {
      this.selectLoading = true;
      this.patientForm.name = name;

      if (name !== "") {
        GetPatientInfoSearch(this.patientForm).then((res) => {
          this.patientsName = res.rows;
          this.selectLoading = false;
        });
      } else {
        GetPatientInfoSearch(this.patientForm).then((res) => {
          this.patientsName = res.rows;
          this.selectLoading = false;
        });
      }
    },
    getNameListMore() {
      this.patientForm.pageNum++;
      GetPatientInfoSearch(this.patientForm).then((res) => {
        this.patientsName.push(...(res.rows || []));
      });
    },
    preview() {
      this.$refs.preview.clickHandler();
    },
    //主症选择框获得焦点时
    zhuzhengFocus() {
      this.leftMaiShow = false;
      this.rightMaiShow = false;
    },
    shexiangFocus() {
      this.leftMaiShow = false;
      this.rightMaiShow = false;
    },
    init() {
      // 获取病历号
      getBillNo().then((res) => {
        this.form.billno = res.msg;
      });
      // 获取症状
      getPatternZyListZhengzhuang(this.zhengzhuangForm).then((res) => {
        this.zhengzhuangList = res.rows || [];
      });
      // 获取脉象
      getPatternZyListMai(this.maixiangForm).then((res) => {
        this.maixiangList = res.rows || [];
      });
      // 获取疾病辨别
      getBkdDiseaseList(this.jibingbianbieForm).then((res) => {
        this.jibingbianbieList = res.rows || [];
        this.jibingbianbieListCopy = res.rows || [];
      });
      // 获取舌苔舌质合一
      getPatternZyListsheAll(this.sheAllForm).then((res) => {
        this.sheAllList = res.rows || [];
      });
      //
      let form = { zy: null, pageNum: 1, pageSize: 399 };
      getPatternZyListZhengzhuang(form).then((res) => {
        this.zhengzhuangAllList = res.rows || [];
      });
      GetPatientInfoSearch({ name: "" }).then((res) => {
        this.patientsName = res.rows.map((item) => {
          return { value: item.name, ...item };
        });
      });
    },

    /**
     * 计算差异
     */
    getDiffer(arr1, arr2) {
      return arr1.filter(function (val) {
        return arr2.indexOf(val) === -1;
      });
    },
    /**
     * 获取当前时间
     */
    getDate() {
      const myDate = new Date();
      const year = myDate.getFullYear();
      const month = myDate.getMonth() + 1;
      const day = myDate.getDate();
      const hour = myDate.getHours();
      const minu = myDate.getMinutes();
      const second = myDate.getSeconds();
      return `${year}-${month < 10 ? "0" + month : month}-${
        day < 10 ? "0" + day : day
      } ${hour < 10 ? "0" + hour : hour}:${minu < 10 ? "0" + minu : minu}:${
        second < 10 ? "0" + second : second
      }`;
    },
    /**
     * @description: 修改性别
     * @return {*}
     */
    genderChange() {
      if (this.form.gender === "男") {
        if (this.form.age <= 12) {
          let arr = JSON.parse(JSON.stringify(this.jibingbianbieListCopy));
          this.jibingbianbieList =
            arr.filter((item) => item.zyFlag === "C" || item.zyFlag === "B") ||
            [];
          // console.log(this.jibingbianbieList, "男且小于12岁");
        } else {
          let arr = JSON.parse(JSON.stringify(this.jibingbianbieListCopy));
          this.jibingbianbieList =
            arr.filter((item) => item.zyFlag === "M" || item.zyFlag === "B") ||
            [];
          // console.log(this.jibingbianbieList, "男且大于12岁");
        }
      } else if (this.form.gender === "女") {
        if (this.form.age <= 12 || null) {
          let arr = JSON.parse(JSON.stringify(this.jibingbianbieListCopy));
          this.jibingbianbieList =
            arr.filter((item) => item.zyFlag === "C" || item.zyFlag === "B") ||
            [];
          // console.log(this.jibingbianbieList, "女且小于12岁");
        } else {
          let arr = JSON.parse(JSON.stringify(this.jibingbianbieListCopy));
          this.jibingbianbieList =
            arr.filter((item) => item.zyFlag === "F" || item.zyFlag === "B") ||
            [];
          // console.log(this.jibingbianbieList, "女且大于12岁");
        }
      }
    },
    /**
     * 修改生日
     */
    changeBirthday() {
      let date = this.form.birthday;
      if (!date) {
        return;
      }
      let val = date + "";
      let currentYear = new Date().getFullYear();
      let calculationYear = new Date(val).getFullYear();
      const wholeTime = currentYear + val.substring(4);
      let calculationAge = currentYear - calculationYear;
      if (new Date().getTime() <= new Date(wholeTime).getTime()) {
        calculationAge = calculationAge - 1;
      }
      if (calculationAge < 0) {
        return;
      }
      this.form.age = calculationAge;
    },
    /**
     * 修改年龄
     */
    agechange(e) {
      if (e <= 12) {
        let jibingbianbieListCopy = JSON.parse(
          JSON.stringify(this.jibingbianbieListCopy)
        );
        this.jibingbianbieList =
          jibingbianbieListCopy.filter(
            (item) => item.zyFlag === "C" || item.zyFlag === "B"
          ) || [];
        // console.log(this.jibingbianbieList, "小于12岁");
      } else {
        if (this.form.gender === "男") {
          let jibingbianbieListCopy = JSON.parse(
            JSON.stringify(this.jibingbianbieListCopy)
          );
          this.jibingbianbieList =
            jibingbianbieListCopy.filter(
              (item) => item.zyFlag === "M" || item.zyFlag === "B"
            ) || [];
          // console.log(this.jibingbianbieList, "男且大于12岁");
        } else if (this.form.gender === "女") {
          let jibingbianbieListCopy = JSON.parse(
            JSON.stringify(this.jibingbianbieListCopy)
          );
          this.jibingbianbieList =
            jibingbianbieListCopy.filter(
              (item) => item.zyFlag === "F" || item.zyFlag === "B"
            ) || [];
        } else {
          let jibingbianbieListCopy = JSON.parse(
            JSON.stringify(this.jibingbianbieListCopy)
          );
          this.jibingbianbieList = jibingbianbieListCopy || [];
        }
      }
    },
    /**
     * 身份证号修改事件
     */
    idCardChange(e) {},
    /**
     * @description: 根据身份证号解析出年龄、出生日期、性别等信息
     * @param {*} idCard 身份证号
     * @return {*}
     */
    getIdCardInfo(idCard) {
      var p =
        /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
      if (p.test(idCard)) {
        let sex = null;
        let birth = null;
        let myDate = new Date();
        let month = myDate.getMonth() + 1;
        let day = myDate.getDate();
        let age = 0;

        if (idCard.length === 18) {
          age = myDate.getFullYear() - idCard.substring(6, 10) - 1;
          sex = idCard.substring(16, 17);
          birth =
            idCard.substring(6, 10) +
            "-" +
            idCard.substring(10, 12) +
            "-" +
            idCard.substring(12, 14);
          if (
            idCard.substring(10, 12) < month ||
            (idCard.substring(10, 12) === month &&
              idCard.substring(12, 14) <= day)
          )
            age++;
        }
        if (idCard.length === 15) {
          age = myDate.getFullYear() - idCard.substring(6, 8) - 1901;
          sex = idCard.substring(13, 14);
          birth =
            "19" +
            idCard.substring(6, 8) +
            "-" +
            idCard.substring(8, 10) +
            "-" +
            idCard.substring(10, 12);
          if (
            idCard.substring(8, 10) < month ||
            (idCard.substring(8, 10) === month &&
              idCard.substring(10, 12) <= day)
          )
            age++;
        }

        if (sex % 2 === 0) sex = "女";
        else sex = "男";
        this.form.gender = sex;
        this.form.age = age;
        this.form.birthday = birth;
      } else {
        this.form.gender = null;
        this.form.age = null;
        this.form.birthday = null;
        this.$message.error("输入正确的身份证号");
        return;
      }
    },
    /**
     * 取消症状、舌苔、舌质、脉象、关联推荐等
     *
     * @param field 类型：zhengzhuang、shetai、shezhi、maixiang、guanliantuijian
     * @param zy 类型对应的值
     */
    zhenzhuangCancel(field, zy) {
      // 获取当前保存的对应值
      let form = this.zhenduanForm[field] || {};
      // 进行删除
      delete form[zy];
      // 保存删除后的值
      this.zhenduanForm[field] = form;
      // 刷新关联推荐
      this.$nextTick(() => {
        this.toBuildTj(field);
      });
    },
    /**
     * 构建症状、舌苔、舌质、脉象、关联推荐等的新增参数
     *
     * @param field 类型：zhengzhuang、shetai、shezhi、maixiang、guanliantuijian
     * @param zy 类型对应的值
     * @param isOpen 是否需要打开弹窗
     */
    zhenzhuangConfirm(field, zy, isOpen = false) {
      let res = {
        form: {
          zy: zy,
          main: "",
          fashengyinsu: "",
          tezheng: "",
          chengdu: "",
          fazuopinci: "",
          bingcheng: "",
          jiazhong: "",
          huanjie: "",
          chixushijian: "",
          field: field,
        },
        item: {
          createBy: "",
          createTime: null,
          updateBy: "",
          updateTime: null,
          remark: null,
          zy: zy,
          field: field,
        },
      };
      this.zhenzhuangPopupConfirm(res, isOpen);
    },
    /**
     * 新增症状、舌苔、舌质、脉象、关联推荐等
     *
     * @param res 新增的参数
     * @param isOpen 是否需要打开弹窗
     */
    zhenzhuangPopupConfirm(res, isOpen = false) {
      let field = res.item.field;
      let zy = res.item.zy;
      let form = this.zhenduanForm[res.item.field] || {};
      form[res.item.zy] = res.form;
      this.zhenduanForm[res.item.field] = form;
      this.$nextTick(() => {
        this.toBuildTj(res.item.field);
      });
      if (isOpen) {
        this.chooseZhengzhuangPopup(field, zy);
      }
    },
    /**
     * 打开症状弹窗
     */
    chooseZhengzhuangPopup(field, zy) {
      // console.log(field, zy, "打开症状弹窗");
      let list = this[field + "List"];
      let item = list.filter((r) => {
        return r.zy == zy;
      })[0];
      item.field = field;
      this.$refs.zhenduan.open(item, this.jibingbianbieId);
    },
    /**
     * 构建症状、舌苔、舌质、脉象、关联推荐等结构
     */
    buildZhenduanList() {
      let list = [];

      for (let formKey in this.zhenduanForm) {
        let form = this.zhenduanForm[formKey] || {};
        if (!form && Object.keys(form).length < 1) {
          continue;
        }
        for (let key in form) {
          let item = form[key];
          if (!item) {
            continue;
          }
          list.push(item);
        }
      }
      return list;
    },
    /**
     * 获取关联推荐
     */
    async toBuildTj(field) {
      // if (field == 'guanliantuijian') {
      //   return;
      // }
      let zhegnzhuangArr = [];
      for (let formKey in this.zhenduanForm) {
        let form = this.zhenduanForm[formKey] || {};
        if (!form && Object.keys(form).length < 1) {
          continue;
        }
        for (let key in form) {
          let item = form[key];
          if (!item) {
            continue;
          }
          zhegnzhuangArr.push(item.zy);
        }
      }
      let v = zhegnzhuangArr.join("|");
      if (!v) {
        this.guanliantuijianList = [];
        return;
      }
      getTj(v).then((res) => {
        let arr = [];
        //已经同步阿里云数据
        if (res?.code === 200) {
          let dataArr = res.data || [];
          for (let item of dataArr) {
            let n = item && item.length > 0 ? item[0] : "";
            if (!item) {
              continue;
            }
            arr.push(n);
          }
          this.guanliantuijianList = arr;
        }
      });
    },
    /**
     * 点击关联推荐
     */
    clickGuanliantuijian(item) {
      let index = this.zhengzhuangIdList.indexOf(item);
      if (index < 0) {
        this.zhengzhuangIdList.push(item);
        this.zhenzhuangConfirm("zhengzhuang", item);
      } else {
        this.zhengzhuangIdList.splice(index, 1);
        this.zhenzhuangCancel("zhengzhuang", item);
      }
    },
    /**
     * 查询症状 - 第一页
     */
    remotezhengzhuangMethod(e) {
      this.zhengzhuangForm.zy = e;
      this.zhengzhuangForm.pageNum = 1;
      getPatternZyListZhengzhuang(this.zhengzhuangForm).then((res) => {
        this.zhengzhuangList = res.rows || [];
      });
    },
    /**
     * 查询症状 - 更多
     */
    selectLoadmoreZhengzhuang(e, v) {
      this.zhengzhuangForm.pageNum++;
      getPatternZyListZhengzhuang(this.zhengzhuangForm).then((res) => {
        this.zhengzhuangList.push(...(res.rows || []));
      });
    },
    /**
     * 查询舌苔舌质合一 - 第一页
     */
    remotesheAllMethod(e) {
      this.sheAllForm.zy = e;
      this.sheAllForm.pageNum = 1;
      getPatternZyListsheAll(this.sheAllForm).then((res) => {
        this.sheAllList = res.rows || [];
      });
    },
    //脉弹窗
    maiHandle(whichHand, mai, value) {
      Array.prototype.contains = function (value) {
        for (var i = 0; i < arguments.length; i++) {
          var ele = arguments[i];
          if (this.indexOf(ele) == -1) {
            this.push(ele);
          }
        }
      };

      switch (whichHand) {
        case "左":
          switch (mai) {
            case "关":
              this.leftMai["关"].contains(value);
              break;
            case "寸":
              this.leftMai["寸"].contains(value);
              break;
            case "尺":
              this.leftMai["尺"].contains(value);
              break;
          }
          break;

        case "右":
          switch (mai) {
            case "关":
              this.rightMai["关"].contains(value);
              break;
            case "寸":
              this.rightMai["寸"].contains(value);
              break;
            case "尺":
              this.rightMai["尺"].contains(value);
              break;
          }
          break;
      }
      this.$set(
        this.maixiangIdList,
        this.maixiangIdList.length,
        whichHand + mai + "脉" + value
      );
    },
    //脉删除
    removeMai(whichHand, mai, ite, type) {
      switch (whichHand) {
        case "左":
          switch (mai) {
            case "关":
              this.leftMai["关"].splice(this.leftMai["关"].indexOf(ite), 1);
              break;
            case "寸":
              this.leftMai["寸"].splice(this.leftMai["寸"].indexOf(ite), 1);
              break;
            case "尺":
              this.leftMai["尺"].splice(this.leftMai["尺"].indexOf(ite), 1);
              break;
          }
          break;

        case "右":
          switch (mai) {
            case "关":
              this.rightMai["关"].splice(this.rightMai["关"].indexOf(ite), 1);
              break;
            case "寸":
              this.rightMai["寸"].splice(this.rightMai["寸"].indexOf(ite), 1);
              break;
            case "尺":
              this.rightMai["尺"].splice(this.rightMai["尺"].indexOf(ite), 1);
              break;
          }
          break;
      }
      this.maixiangIdList.splice(
        this.maixiangIdList.indexOf(whichHand + mai + "脉" + ite),
        1
      );
    },
    /**
     * 查询舌苔舌质合一 - 更多
     */
    selectLoadmoreSheAll() {
      this.sheAllForm.pageNum++;
      getPatternZyListsheAll(this.sheAllForm).then((res) => {
        this.sheAllList.push(...(res.rows || []));
      });
    },
    /**
     * 查询脉象 - 第一页
     */
    remotemaixiangMethod(e) {
      this.maixiangForm.zy = e;
      this.maixiangForm.pageNum = 1;
      getPatternZyListMai(this.maixiangForm).then((res) => {
        this.maixiangList = res.rows || [];
      });
    },
    /**
     * 查询脉象 - 更多
     */
    selectLoadmoreMaixiang(e, v) {
      this.maixiangForm.pageNum++;
      getPatternZyListMai(this.maixiangForm).then((res) => {
        this.maixiangList.push(...(res.rows || []));
      });
    },
    /**
     * 查询疾病辨别 - 第一页
     */
    remotejibingbianbieMethod(e) {
      this.jibingbianbieForm.zy = e;
      this.jibingbianbieForm.pageNum = 1;
      getBkdDiseaseList(this.jibingbianbieForm).then((res) => {
        this.jibingbianbieList = res.rows || [];
      });
    },
    /**
     * @description: 清除疾病辨别
     * @return {*}
     */
    clearjibingbianbie() {
      this.jibingbianbieForm = {
        zy: null,
        pageNum: 1,
        pageSize: 100,
      };
      getBkdDiseaseList(this.jibingbianbieForm).then((res) => {
        this.jibingbianbieList = res.rows || [];
      });
    },
    /**
     * 查询疾病辨别 - 更多
     */
    selectLoadmoreJibingbianbie(e, v) {
      this.jibingbianbieForm.pageNum++;
      getBkdDiseaseList(this.jibingbianbieForm).then((res) => {
        this.jibingbianbieList.push(...(res.rows || []));
      });
    },

    /**
     * 构建四诊结果
     */
    buildzhenduanForm(zhenduanForm, field) {
      let form = zhenduanForm[field] || {};
      return Object.keys(form).join(",");
    },

    /**
     * 点击智能辩证
     */
    confirm() {
      this.form.disease = this.jibingbianbieId;
      if (!this.form.name) {
        this.$message.error("输入患者名称");
        return;
      }
      if (!this.form.gender) {
        this.$message.error("选择性别");
        return;
      }
      if (!this.form.age) {
        this.$message.error("输入年龄");
        return;
      }
      let count = 0;
      count += this.zhengzhuangIdList.length;
      count += this.sheAllIdList.length;
      count += this.maixiangIdList.length;
      count += this.guanliantuijianIdList.length;
      if (count < 2) {
        this.$message.error("症状，舌苔，舌质，脉象，关联推荐最少需要两项");
        return;
      }
      let list = JSON.parse(
        JSON.stringify(this.buildZhenduanList(this.zhenduanForm))
      );

      // 按照症状-舌象-脉象排序
      list = list.sort((a, b) => {
        let sort1 = 0,
          sort2 = 0;
        if (a.field === "zhengzhuang") {
          sort1 = 1;
        }
        if (b.field === "zhengzhuang") {
          sort2 = 1;
        }
        if (a.field === "shezhi") {
          sort1 = 2;
        }
        if (b.field === "shezhi") {
          sort2 = 2;
        }
        if (a.field === "maixiang") {
          sort1 = 3;
        }
        if (b.field === "maixiang") {
          sort2 = 3;
        }
        return sort1 - sort2;
      });

      //组装脉象
      list.map((item) => {
        if (item.zy.indexOf("脉") > -1) {
          item.zy = item.zy.slice(2, 4);
        }
      });
      //去重
      list = list.filter((item, index, self) => {
        return (
          self.findIndex((t) => JSON.stringify(t) === JSON.stringify(item)) ===
          index
        );
      });

      this.form.zhengzhuangJson = JSON.stringify(list);
      //将用户信息和四诊仪信息传送到right组件
      this.$parent.$refs.right.patientInfo = this.form;
      // 金匮方请求数据
      let form1 = JSON.parse(JSON.stringify(this.form));
      form1.disease = "全";
      const params1 = new URLSearchParams();
      for (let key in form1) {
        params1.append(key, form1[key]);
      }

      // 名医名方请求数据
      let form2 = JSON.parse(JSON.stringify(this.form));
      if (!this.form.disease) {
        form2.disease = "全";
      }
      const params2 = new URLSearchParams();
      for (let key in form2) {
        params2.append(key, form2[key]);
      }

      ButtonClick.click("智能辨症");

      let loading = this.$loading({
        text: "智能辨症中...",
      });

      let data1;
      let data2;
      // 请求金匮方
      let promise1 = aiDiagnoseWithgoldPrescriptionNew(
        list
          .map((r) => {
            return r.zy;
          })
          .join("|")
      )
        .then((res) => {
          data1 = res.data;
        })
        .catch((err) => {
          loading.close();
          console.log("金匮方报错：", err);
        });
      // 请求名医名方
      let promise2 = aiDiagnoseByFamousDoctor(params2)
        .then((res) => {
          data2 = res.data;
        })
        .catch((err) => {
          loading.close();
          console.log("名医药方报错：", err);
        });
      // 暂存患者病例信息
      let form = this.form;
      let zhenduanForm = this.zhenduanForm;
      // engravingSyndrome 刻下症描述 应该不对吧
      let obj = {
        ...zhenduanForm["zhengzhuang"],
        ...zhenduanForm["guanliantuijian"],
      };
      let arr = Object.keys(obj).filter((key) => obj[key] !== "");
      let kfForm = {
        patientCase: {
          id: form.patientCaseId,
          billno: form.billno,
          accidentDate: form.accidentDate,
          cardinalSymptom: arr.join(","),
          jiwangshi: form.jiwangshi,
          tongueInfo: this.buildzhenduanForm(zhenduanForm, "shezhi"),
          pulseConditionInfo: this.buildzhenduanForm(zhenduanForm, "maixiang"),
          disease: this.jibingbianbieId || "全",
          caseAnalysis: this.caseAnalysis,
          therapeuticRegimen: form.therapeuticRegimen,
          zhusu: form.zhusu,
          happenDate: form.accidentDate,
          comeDate: form.comedate,
          shefileUrl: this.shefileUrl,
          status: 0,
        },
        patientInfo: {
          id: form.patientInfoId,
          name: form.name,
          age: form.age,
          gender: form.gender == "男" ? "M" : "F",
          birthday: form.birthday,
          idType: null,
          cellphone: form.cellphone,
          idcard: form.idcard,
        },
      };
      let promise3 = save(kfForm)
        .then((res) => {
          if (res.code != 200) {
            this.$message.error(res.msg);
          } else {
            this.form.patientCaseId = res.data.patientCase.id;
            this.form.patientInfoId = res.data.patientInfo.id;
            //this.$message.success(res.msg);
          }
        })
        .catch((err) => {
          loading.close();
        });
      Promise.all([promise1, promise2])
        .then((res) => {
          loading.close();
          // 请求结果处理
          this.$parent.$refs.right.formatResult(
            data1,
            data2,
            form2.disease === "全"
          );
        })
        .catch((error) => {
          loading.close();
          console.log(error, "error");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "style-left";
</style>
