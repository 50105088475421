import Methods from "./Methods";
const generatePdfFiles = {
  install: function (Vim) {
    Vim.prototype.$generatePdfFiles = function (options = {}) {
      return new Methods(this, options);
    };
  },
};

export default generatePdfFiles;
