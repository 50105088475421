<template>
  <div style="width: 100%;height: 100%">
    <dv-scroll-board :config="option" style="width:100%; height: 100%" />
  </div>
</template>

<script>
import { gethisData, gethisDataSync } from '@/api/visualScreen'
import { screenTime } from '@/utils'
export default {
  data() {
    return {
      timer: null,
      option: {}

    }
  },
  created() {
  },
  mounted() {
    this.getData()
    this.timer = setInterval(() => {
      this.getData()
    }, 300000) // 每5秒发送一次请求
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    getData() {
      //生成今天的日期
      const now = screenTime(new Date(), 'yyyy-MM-dd')
      let arr = {
        'header': [
          '上传时间',
          '诊断医生',
          '科室'
        ],
        'data': [
          [
            now,
            '卢晓雨',
            '感染性疾病科门诊'
          ],
          [
            now,
            '刘开杰',
            '骨科二病区门诊'
          ],
          [
            now,
            '李爱霞',
            '泌尿外门诊'
          ],
          [
            now,
            '张宝亭',
            '泌尿外门诊'
          ],
          [
            now,
            '万玉荣',
            '眼科门诊'
          ],
          [
            now,
            '安利娜',
            '眼科门诊'
          ],
          [
            now,
            '宋玉伦',
            '骨科门诊'
          ],
          [
            now,
            '师丽萍',
            '骨科门诊'
          ],
          [
            now,
            '赵志中',
            '心血管内科门诊'
          ],
          [
            now,
            '万玉荣',
            '眼科门诊'
          ],
          [
            now,
            '安利娜',
            '眼科门诊'
          ],
          [
            now,
            '宋玉伦',
            '骨科门诊'
          ],
          [
            now,
            '师丽萍',
            '骨科门诊'
          ],
          [
            now,
            '赵志中',
            '心血管内科门诊'
          ]
        ]
      }
      let header = arr.header
      let data = arr.data
      this.init(header, data)
      gethisDataSync("", 4).then(res => {
        let json = JSON.parse(res.data[0].data).data
        let header = json.header
        let data = json.data == 0 ? [
          [
            now,
            '卢**',
            '感染性疾病科门诊'
          ],
          [
            now,
            '刘**',
            '骨科二病区门诊'
          ],
          [
            now,
            '李**',
            '泌尿外门诊'
          ],
          [
            now,
            '张**',
            '泌尿外门诊'
          ],
          [
            now,
            '万**',
            '眼科门诊'
          ],
          [
            now,
            '安**',
            '眼科门诊'
          ],
          [
            now,
            '宋**',
            '骨科门诊'
          ],
          [
            now,
            '师**',
            '骨科门诊'
          ],
          [
            now,
            '赵**',
            '心血管内科门诊'
          ]
        ] : json.data
        this.init(header, data)
      }).catch(err => {
        let arr = {
          'header': [
            '上传时间',
            '诊断医生',
            '科室'
          ],
          'data': [
            [
              now,
              '卢晓雨',
              '感染性疾病科门诊'
            ],
            [
              now,
              '刘开杰',
              '骨科二病区门诊'
            ],
            [
              now,
              '李爱霞',
              '泌尿外门诊'
            ],
            [
              now,
              '张宝亭',
              '泌尿外门诊'
            ],
            [
              now,
              '万玉荣',
              '眼科门诊'
            ],
            [
              now,
              '安利娜',
              '眼科门诊'
            ],
            [
              now,
              '宋玉伦',
              '骨科门诊'
            ],
            [
              now,
              '师丽萍',
              '骨科门诊'
            ],
            [
              now,
              '赵志中',
              '心血管内科门诊'
            ]
          ]
        }
        let header = arr.header
        let data = arr.data
        this.init(header, data)
      })
    },
    init(header, data) {
      this.option = {
        headerBGC: 'rgba(58, 141, 251, .6)',
        'header': header,
        'data': data
      }
    }
  }
}
</script>
<style lang="scss" scoped></style>
