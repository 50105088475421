var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "popup-wrap" }, [
    _c("div", { staticClass: "body" }, [
      _c("div", { staticClass: "mid" }, [
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "key" }, [_vm._v("医生评价：")]),
          _c("div", { staticClass: "value" }, [
            _c("div", { staticClass: "switch" }, [
              _c(
                "div",
                {
                  staticClass: "switch-item",
                  class: { active: _vm.form.docmark == 2 },
                  on: {
                    click: function ($event) {
                      return _vm.changeSwitch("2")
                    },
                  },
                },
                [_vm._v("准确")]
              ),
              _c(
                "div",
                {
                  staticClass: "switch-item",
                  class: { active: _vm.form.docmark == 1 },
                  on: {
                    click: function ($event) {
                      return _vm.changeSwitch("1")
                    },
                  },
                },
                [_vm._v("较准确")]
              ),
              _c(
                "div",
                {
                  staticClass: "switch-item",
                  class: { active: _vm.form.docmark == 0 },
                  on: {
                    click: function ($event) {
                      return _vm.changeSwitch("0")
                    },
                  },
                },
                [_vm._v("错误")]
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "key" }, [_vm._v("诊断评价：")]),
          _c(
            "div",
            { staticClass: "value border" },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 9 },
                  placeholder:
                    "请填写该医案的医生诊断结果，便于技术人员分析实际诊断与辅助诊断系统的差异",
                  clearable: "",
                },
                model: {
                  value: _vm.form.docdiagnose,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "docdiagnose", $$v)
                  },
                  expression: "form.docdiagnose",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "bottom" }, [
      _c("div", { staticClass: "btn", on: { click: _vm.close } }, [
        _vm._v("取消"),
      ]),
      _c("div", { staticClass: "btn", on: { click: _vm.confirm } }, [
        _vm._v("确定"),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }