<template>
  <Echart id="CenterMap" ref="CenterMap" :options="options" />
</template>

<script>
import * as echarts from "echarts";
import chinaJson from "../../../utils/map/china.json";


export default {
  data() {
    return {
      options: {},
    };
  },
  created() { },

  mounted() {
    this.init();
  },
  methods: {
    init() {
      var chinaGeoCoordMap = {
        北京市: [116.300058, 40.001385],
        天津市: [117.191328, 39.054611],
        哈尔滨市: [126.658022, 45.733901],
        高邮市: [119.467099, 32.782352],
        盐城市: [120.145649, 33.397163],
        成都市: [104.067516, 30.647254],
        郑州市: [113.64964385, 34.7566100641],
      };
      var chinaDatas = [
        [
          {
            name: "郑州市",
            value: 1,
          },
        ],
        [
          {
            name: "北京市",
            value: 3,
          },
        ],
        [
          {
            name: "天津市",
            value: 1,
          },
        ],
        [
          {
            name: "哈尔滨市",
            value: 1,
          },
        ],
        [
          {
            name: "高邮市",
            value: 1,
          },
        ],
        [
          {
            name: "盐城市",
            value: 0,
          },
        ],
        [
          {
            name: "成都市",
            value: 1,
          },
        ],

      ];

      var convertData = function (data) {
        var res = [];
        for (var i = 0; i < data.length; i++) {
          var dataItem = data[i];
          var fromCoord = chinaGeoCoordMap[dataItem[0].name];
          var toCoord = [113.64964385, 34.7566100641];
          if (fromCoord && toCoord) {
            res.push([
              {
                coord: fromCoord,
                value: dataItem[0].value,
              },
              {
                coord: toCoord,
              },
            ]);
          }
        }
        return res;
      };

      echarts.registerMap("河南", chinaJson);
      var series = [];
      [["郑州市", chinaDatas]].forEach(function (item, i) {
        console.log(item);
        series.push(
          {
            type: "lines",
            zlevel: 2,
            effect: {
              show: true,
              period: 4, //箭头指向速度，值越小速度越快
              trailLength: 0.02, //特效尾迹长度[0,1]值越大，尾迹越长重
              symbol: "arrow", //箭头图标
              symbolSize: 5, //图标大小
            },
            lineStyle: {
              color: "#EE5652",
              width: 1, //尾迹线条宽度
              opacity: 1, //尾迹线条透明度
              curveness: 0.3, //尾迹线条曲直度
            },
            data: convertData(item[1]),
          },
          {
            type: "effectScatter",
            coordinateSystem: "geo",
            zlevel: 2,
            rippleEffect: {
              //涟漪特效
              period: 4, //动画时间，值越小速度越快
              brushType: "stroke", //波纹绘制方式 stroke, fill
              scale: 4, //波纹圆环最大限制，值越大波纹越大
            },
            label: {
              show: true,
              position: "left", //显示位置
              offset: [-5, 0], //偏移设置
              formatter: function (params) {
                //圆环显示文字
                return params.data.name;
              },
              color: "#FFD200",
              fontSize: 12,
              emphasis: {
                show: true,
              },
            },
            symbol: "circle",
            symbolSize: function (val) {
              return 5 + val[2] * 5; //圆环大小
            },
            itemStyle: {
              show: true,
              color: "#ffffff",
            },
            data: item[1].map(function (dataItem) {
              return {
                name: dataItem[0].name,
                value: chinaGeoCoordMap[dataItem[0].name].concat([
                  dataItem[0].value,
                ]),
              };
            }),
          }
        );
      });
      this.options = {
        backgroundColor: "rgba(0,0,0,0)",
        geo: [
          {
            map: "河南",
            aspectScale: 0.9,
            roam: false, // 是否允许缩放
            zoom: 1, // 默认显示级别
            layoutSize: "120%",
            layoutCenter: ["50%", "60%"],
            itemStyle: {
              areaColor: {
                type: "linear-gradient",
                x: 0,
                y: 400,
                x2: 0,
                y2: 0,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(37,108,190,0.3)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(15,169,195,0.3)", // 50% 处的颜色
                  },
                ],
                global: true, // 缺省为 false
              },
              borderColor: "#4ecee6",
              borderWidth: 1,
              emphasis: {
                areaColor: {
                  type: "linear-gradient",
                  x: 0,
                  y: 300,
                  x2: 0,
                  y2: 0,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(37,108,190,1)", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "rgba(15,169,195,1)", // 50% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
            },
            emphasis: {
              itemStyle: {
                areaColor: "#0160AD",
                show: 0,
                color: "transparent",
              },

            },
            zlevel: 3,
          },
          {
            map: "河南",
            aspectScale: 0.9,
            roam: false, // 是否允许缩放
            zoom: 1, // 默认显示级别
            layoutSize: "120%",
            layoutCenter: ["50%", "60%"],
            itemStyle: {
              borderColor: "rgba(192,245,249,.6)",
              borderWidth: 2,
              shadowColor: "#2C99F6",
              shadowOffsetY: 0,
              shadowBlur: 120,
              areaColor: "rgba(29,85,139,.2)",
            },
            zlevel: 2,
            silent: true,
          },
          {
            map: "河南",
            aspectScale: 0.9,
            roam: false, // 是否允许缩放
            zoom: 1, // 默认显示级别
            layoutSize: "120%",
            layoutCenter: ["50%", "61.5%"],
            itemStyle: {
              areaColor: "rgba(0,27,95,0.4)",
              borderColor: "#004db5",
              borderWidth: 1,
            },
            zlevel: 1,
            silent: true,
          },
        ],
        series: series,
      };
      this.$refs.CenterMap.chart.on('click', (params) => {
        console.log('okok', params);
      })
    },
  },
};
</script>
<style lang="scss" scoped>
#centermap {
  height: 400px;
}
</style>
