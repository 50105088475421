var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "img_box" }, [
          _c("img", {
            staticClass: "quan",
            attrs: {
              src: require("../../../assets/qualityConyrolScreen/quan1.png"),
              alt: "",
            },
          }),
          _c("img", {
            staticClass: "icon",
            attrs: {
              src: require("../../../assets/qualityConyrolScreen/quan1_icon.png"),
              alt: "",
            },
          }),
        ]),
        _c("div", { staticClass: "word_box" }, [
          _c("span", { staticClass: "word" }, [_vm._v("药性")]),
          _c("span", { staticClass: "num" }, [_vm._v("79")]),
        ]),
      ]),
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "img_box" }, [
          _c("img", {
            staticClass: "quan",
            attrs: {
              src: require("../../../assets/qualityConyrolScreen/quan3.png"),
              alt: "",
            },
          }),
          _c("img", {
            staticClass: "icon",
            attrs: {
              src: require("../../../assets/qualityConyrolScreen/quan3_icon.png"),
              alt: "",
            },
          }),
        ]),
        _c("div", { staticClass: "word_box" }, [
          _c("span", { staticClass: "word" }, [_vm._v("用量")]),
          _c("span", { staticClass: "num" }, [_vm._v("68")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }