<template>
  <div>
    <div class="sizhen-header">
      <img src="../../assets/logo/logo.png" alt="" @click="goHome()" />
      <el-steps :active="sizhenData.active" finish-status="success" simple>
        <el-step
          v-for="(item, index) of stepTitle"
          :key="index"
          :title="item"
        />
      </el-steps>
    </div>

    <!--基本信息-->
    <sizhen-info
      v-show="sizhenData.active == '0'"
      @handleNextStep="handleNextStep()"
    ></sizhen-info>
    <!-- 体质辨识 -->
    <sizhen-tizhi
      v-show="sizhenData.active == '1'"
      @handleLastStep="handleLastStep()"
      @handleNextStep="handleNextStep()"
    ></sizhen-tizhi>
    <!--舌诊-->
    <sizhen-shezhen
      v-show="sizhenData.active == '2'"
      @handleLastStep="handleLastStep()"
      @handleNextStep="handleNextStep()"
    ></sizhen-shezhen>
    <!-- ai分析 -->
    <ai-analyse
      v-show="sizhenData.active == '3'"
      @handleLastStep="handleLastStep()"
      @handleNextStep="handleNextStep()"
    >
    </ai-analyse>
    <!-- 结果打印 -->
    <print
      v-show="sizhenData.active == '4'"
      @handleLastStep="handleLastStep()"
      @handleNextStep="handleNextStep()"
    >
    </print>
  </div>
</template>
<script>
import sizhenInfo from "@/views/sizhen/components/info";
import sizhenTizhi from "@/views/sizhen/components/tizhi";
import sizhenShezhen from "@/views/sizhen/components/shezhen";
import aiAnalyse from "@/views/sizhen/components/aiAnalyse";
import print from "@/views/sizhen/components/print";
import { mapActions, mapState } from "vuex";
export default {
  name: "AddContract",
  components: {
    sizhenInfo,
    sizhenShezhen,
    sizhenTizhi,
    aiAnalyse,
    print,
  },
  computed: {
    ...mapState(["sizhenData"]),
  },
  data() {
    return {
      // 步骤标题
      stepTitle: ["信息采集", "体质辨识", "舌象采集", "AI分析", "结果打印"],
    };
  },
  mounted() {},
  methods: {
    // 组件点击上一步
    handleLastStep() {
      console.log("上一步");
    },
    // 组件点击下一步
    handleNextStep() {
      console.log("下一步");
    },
    // 返回首页
    goHome() {
      this.$router.push({ path: "/sizhenSystem" });
    },
  },
};
</script>
<style lang="scss" scoped>
.sizhen-header {
  width: 100%;
  height: 64px;
  display: flex;

  img {
    width: 64px;
    height: 64px;
    margin-right: 10px;
  }
  .el-steps {
    flex: 1;
    background-color: #ffffff;
  }
}
</style>
