import {toReport} from '@/api/tracking/tracking'
import store from '@/store'

export default class Base {
  constructor(type) {
    this.type = type;
    this.startTimestamp = null;
    this.endTimestamp = null;
    this.start();
  }

  start() {
    if(this.startTimestamp){
      return;
    }
    this.startTimestamp = new Date().getTime();
  }

  stop() {
    this.endTimestamp = new Date().getTime();
  }


  getUserId() {
    return store.state.user.userId;
  }

  getData() {

  }

  report() {
    this.stop();
    let data = this.getData();
    if(!data){
      return;
    }
    toReport({
      userId: this.getUserId(),
      type: this.type,
      startTimestamp: this.startTimestamp,
      endTimestamp: this.endTimestamp,
      data: JSON.stringify(data)
    })
  }
}
