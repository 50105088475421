var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showSearch,
              expression: "showSearch",
            },
          ],
          ref: "queryForm",
          attrs: {
            model: _vm.queryParams,
            size: "small",
            inline: true,
            "label-width": "68px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "处方编码", prop: "billno" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入处方编码", clearable: "" },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleQuery($event)
                  },
                },
                model: {
                  value: _vm.queryParams.billno,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryParams, "billno", $$v)
                  },
                  expression: "queryParams.billno",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "医院名称", prop: "hospital" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入医院名称", clearable: "" },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleQuery($event)
                  },
                },
                model: {
                  value: _vm.queryParams.hospital,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryParams, "hospital", $$v)
                  },
                  expression: "queryParams.hospital",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "开方科室", prop: "depName" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入科室名称", clearable: "" },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleQuery($event)
                  },
                },
                model: {
                  value: _vm.queryParams.depName,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryParams, "depName", $$v)
                  },
                  expression: "queryParams.depName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "诊断医生", prop: "docName" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入医生名称", clearable: "" },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleQuery($event)
                  },
                },
                model: {
                  value: _vm.queryParams.docName,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryParams, "docName", $$v)
                  },
                  expression: "queryParams.docName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-search",
                    size: "mini",
                  },
                  on: { click: _vm.handleQuery },
                },
                [_vm._v("搜索")]
              ),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-refresh", size: "mini" },
                  on: { click: _vm.resetQuery },
                },
                [_vm._v("重置")]
              ),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-refresh", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.showDetails = true
                    },
                  },
                },
                [_vm._v("打开弹窗")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "mb8", attrs: { gutter: 10 } },
        [
          _c("el-col", { attrs: { span: 1.5 } }),
          _c("right-toolbar", {
            attrs: { showSearch: _vm.showSearch },
            on: {
              "update:showSearch": function ($event) {
                _vm.showSearch = $event
              },
              "update:show-search": function ($event) {
                _vm.showSearch = $event
              },
              queryTable: _vm.getList,
            },
          }),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.qualityControlList },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _c("el-table-column", {
            attrs: {
              label: "医院名称",
              width: "100",
              align: "center",
              prop: "hospital",
              fixed: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(" " + _vm._s(scope.row.hospital || "-") + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "科室",
              width: "100",
              align: "center",
              prop: "depName",
              fixed: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(" " + _vm._s(scope.row.depName || "-") + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "诊断医生",
              width: "100",
              align: "center",
              prop: "docName",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(" " + _vm._s(scope.row.docName || "-") + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "患者姓名",
              width: "100",
              align: "center",
              prop: "name",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(" " + _vm._s(scope.row.name || "-") + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "病症",
              width: "100",
              align: "center",
              prop: "cardinalSymptom",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " + _vm._s(scope.row.cardinalSymptom || "-") + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "上传时间",
              width: "150",
              align: "center",
              prop: "createTime",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.row.createTime || "-") + " "),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "预警类型",
              width: "auto",
              align: "center",
              prop: "qualityControl",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      _vm._l(
                        _vm.qualityControlNew(scope.row.qualityControl),
                        function (item, index) {
                          return _c(
                            "el-tag",
                            {
                              key: index,
                              attrs: {
                                size: "small",
                                type: _vm.tagColor(item),
                              },
                            },
                            [_vm._v(" " + _vm._s(item || "-") + " ")]
                          )
                        }
                      ),
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "预警药材",
              width: "auto",
              align: "center",
              prop: "qualityControl",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      _vm._l(
                        _vm.medicine(scope.row.qualityControl),
                        function (item, index) {
                          return _c(
                            "el-tag",
                            {
                              key: index,
                              attrs: {
                                size: "small",
                                type: _vm.tagColor(
                                  _vm.qualityControlNew(
                                    scope.row.qualityControl
                                  )[index]
                                ),
                              },
                            },
                            [_vm._v(" " + _vm._s(item || "-") + " ")]
                          )
                        }
                      ),
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              width: "120",
              align: "center",
              prop: "jzTodayCount",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.openDetails(scope.row)
                          },
                        },
                      },
                      [_vm._v("点击查看")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.queryParams.pageNum,
          limit: _vm.queryParams.pageSize,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryParams, "pageNum", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryParams, "pageSize", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showDetails,
            "modal-append-to-body": "false",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDetails = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "rows",
              attrs: { model: _vm.rows, "label-width": "80px", disabled: "" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "病历号", prop: "userId" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "" },
                    model: {
                      value: _vm.rows.billno,
                      callback: function ($$v) {
                        _vm.$set(_vm.rows, "billno", $$v)
                      },
                      expression: "rows.billno",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "医院名称", prop: "hospital" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "" },
                    model: {
                      value: _vm.rows.hospital,
                      callback: function ($$v) {
                        _vm.$set(_vm.rows, "hospital", $$v)
                      },
                      expression: "rows.hospital",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "科室", prop: "depName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "" },
                    model: {
                      value: _vm.rows.depName,
                      callback: function ($$v) {
                        _vm.$set(_vm.rows, "depName", $$v)
                      },
                      expression: "rows.depName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "诊断医生", prop: "docName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "" },
                    model: {
                      value: _vm.rows.docName,
                      callback: function ($$v) {
                        _vm.$set(_vm.rows, "docName", $$v)
                      },
                      expression: "rows.docName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "患者姓名", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "" },
                    model: {
                      value: _vm.rows.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.rows, "name", $$v)
                      },
                      expression: "rows.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "病症", prop: "cardinalSymptom" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "" },
                    model: {
                      value: _vm.rows.cardinalSymptom,
                      callback: function ($$v) {
                        _vm.$set(_vm.rows, "cardinalSymptom", $$v)
                      },
                      expression: "rows.cardinalSymptom",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "上传时间", prop: "createTime" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "" },
                    model: {
                      value: _vm.rows.createTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.rows, "createTime", $$v)
                      },
                      expression: "rows.createTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "开方详情", prop: "prescriptionDetail" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", placeholder: "" },
                    model: {
                      value: _vm.rows.prescriptionDetail,
                      callback: function ($$v) {
                        _vm.$set(_vm.rows, "prescriptionDetail", $$v)
                      },
                      expression: "rows.prescriptionDetail",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "预警类型", prop: "qualityControl" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", placeholder: "" },
                    model: {
                      value: _vm.rows.qualityControl,
                      callback: function ($$v) {
                        _vm.$set(_vm.rows, "qualityControl", $$v)
                      },
                      expression: "rows.qualityControl",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "预警药材", prop: "qualityControl" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", placeholder: "" },
                    model: {
                      value: _vm.rows.qualityControl,
                      callback: function ($$v) {
                        _vm.$set(_vm.rows, "qualityControl", $$v)
                      },
                      expression: "rows.qualityControl",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.qualityPass(_vm.rows.billno)
                    },
                  },
                },
                [_vm._v("通过")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.qualityReject()
                    },
                  },
                },
                [_vm._v("驳回")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }