<template>
  <Echart
    :options="options"
    id="leftCenterTwo"
    style="width: 100%; height: 100%; margin: 0 auto"
    ref="leftCenterTwo"
  />
</template>

<script>
import { getVisitTrendByDepartment } from "@/api/qualityControlScreen";
import { mixins } from "@/mixin";

export default {
  name: "leftCenterTwo",
  mixins: [mixins],
  data() {
    return {
      options: {},
      timer: null, // 定时器标志
    };
  },
  filters: {},
  created() {},
  mounted() {
    this.getData();
    this.timer = setInterval(() => {
      this.getData();
    }, 300000); // 每5秒发送一次请求
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    getData() {
      let copedata = {
        msg: "操作成功",
        code: 200,
        data: [
          { RS: 50, JZSJ: "2024-04-05", KSMC: "儿科门诊-东区" },
          { RS: 281, JZSJ: "2024-04-06", KSMC: "儿科门诊-东区" },
          { RS: 264, JZSJ: "2024-04-07", KSMC: "儿科门诊-东区" },
          { RS: 216, JZSJ: "2024-04-08", KSMC: "儿科门诊-东区" },
          { RS: 221, JZSJ: "2024-04-09", KSMC: "儿科门诊-东区" },
          { RS: 256, JZSJ: "2024-04-10", KSMC: "儿科门诊-东区" },
          { RS: 264, JZSJ: "2024-04-11", KSMC: "儿科门诊-东区" },
          { RS: 214, JZSJ: "2024-04-12", KSMC: "儿科门诊-东区" },
          { RS: 187, JZSJ: "2024-04-06", KSMC: "妇科门诊" },
          { RS: 190, JZSJ: "2024-04-07", KSMC: "妇科门诊" },
          { RS: 186, JZSJ: "2024-04-08", KSMC: "妇科门诊" },
          { RS: 158, JZSJ: "2024-04-09", KSMC: "妇科门诊" },
          { RS: 153, JZSJ: "2024-04-10", KSMC: "妇科门诊" },
          { RS: 141, JZSJ: "2024-04-11", KSMC: "妇科门诊" },
          { RS: 147, JZSJ: "2024-04-12", KSMC: "妇科门诊" },
          { RS: 81, JZSJ: "2024-04-05", KSMC: "急诊科门诊" },
          { RS: 209, JZSJ: "2024-04-06", KSMC: "急诊科门诊" },
          { RS: 194, JZSJ: "2024-04-07", KSMC: "急诊科门诊" },
          { RS: 223, JZSJ: "2024-04-08", KSMC: "急诊科门诊" },
          { RS: 221, JZSJ: "2024-04-09", KSMC: "急诊科门诊" },
          { RS: 209, JZSJ: "2024-04-10", KSMC: "急诊科门诊" },
          { RS: 235, JZSJ: "2024-04-11", KSMC: "急诊科门诊" },
          { RS: 99, JZSJ: "2024-04-12", KSMC: "急诊科门诊" },
          { RS: 1, JZSJ: "2024-04-05", KSMC: "心血管内科门诊" },
          { RS: 145, JZSJ: "2024-04-06", KSMC: "心血管内科门诊" },
          { RS: 153, JZSJ: "2024-04-07", KSMC: "心血管内科门诊" },
          { RS: 197, JZSJ: "2024-04-08", KSMC: "心血管内科门诊" },
          { RS: 94, JZSJ: "2024-04-09", KSMC: "心血管内科门诊" },
          { RS: 172, JZSJ: "2024-04-10", KSMC: "心血管内科门诊" },
          { RS: 123, JZSJ: "2024-04-11", KSMC: "心血管内科门诊" },
          { RS: 131, JZSJ: "2024-04-12", KSMC: "心血管内科门诊" },
          { RS: 153, JZSJ: "2024-04-06", KSMC: "心血管内科门诊-东区" },
          { RS: 136, JZSJ: "2024-04-07", KSMC: "心血管内科门诊-东区" },
          { RS: 197, JZSJ: "2024-04-08", KSMC: "心血管内科门诊-东区" },
          { RS: 133, JZSJ: "2024-04-09", KSMC: "心血管内科门诊-东区" },
          { RS: 173, JZSJ: "2024-04-10", KSMC: "心血管内科门诊-东区" },
          { RS: 177, JZSJ: "2024-04-11", KSMC: "心血管内科门诊-东区" },
          { RS: 127, JZSJ: "2024-04-12", KSMC: "心血管内科门诊-东区" },
        ],
      };
      const nameList = [...new Set(copedata.data.map((item) => item.KSMC))];
      //{名字,icon,各科室数据数组}
      const KSMC = nameList.map((item) => {
        let arr = [];
        copedata.data.map((i) => {
          if (item === i.KSMC) {
            arr.push(i.RS);
          }
        });
        return { name: item, icon: "roundRect", data: arr };
      });
      //日期数组
      // const JZSJ = [...new Set(copedata.data.map((item) => item.JZSJ))];
      console.log(this.dateArray);
      const JZSJ = this.dateArray;
      this.init(KSMC, JZSJ);

      // getVisitTrendByDepartment().then(res => {
      //   const nameList = [...new Set(res.data.map(item => item.KSMC))]
      //   //{名字,icon,各科室数据数组}
      //   const KSMC = nameList.map(item => {
      //     let arr = []
      //     res.data.map(i => {
      //       if (item === i.KSMC) {
      //         arr.push(i.RS)
      //       }
      //     })
      //     return { name: item, icon: 'roundRect', data: arr }
      //   })
      //   //日期数组
      //   const JZSJ = [...new Set(res.data.map(item => item.JZSJ))]
      //   this.init(KSMC, JZSJ)
      // })
    },
    init(KSMC, JZSJ) {
      this.options = {
        title: {
          text: "",
          textStyle: {
            color: "#fff",
            fontSize: 15,
          },
        },
        grid: {
          left: "7%",
          right: "1%",
          top: "10%",
          bottom: "15%",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#fff",
            },
          },
        },
        legend: {
          show: true,
          data: KSMC,
          top: "-5",
          textStyle: {
            color: "#fff",
            fontSize: 12,
            rich: {
              a: {
                color: "#fff",
              },
            },
          },
          formatter: function (name) {
            return name;
          },
        },
        xAxis: [
          {
            type: "category",
            data: JZSJ,
            axisPointer: {
              type: "shadow",
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              interval: 0, //全部显示x轴
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "",
            // min: 0,
            // max: 300,
            interval: 100,
            axisLabel: {
              formatter: "{value}",
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "RGBA(59, 60, 64, 1)",
                type: [5, 10],
                dashOffset: 5,
                width: "1",
              },
            },
          },
        ],
        series: [
          {
            name: KSMC[0].name,
            type: "line",
            barWidth: "20%",
            tooltip: {
              valueFormatter: function (value) {
                return value;
              },
            },
            data: KSMC[0].data,
          },
          {
            name: KSMC[1].name,
            type: "line",
            tooltip: {
              valueFormatter: function (value) {
                return value;
              },
            },
            data: KSMC[1].data,
          },
          {
            name: KSMC[2].name,
            type: "line",
            tooltip: {
              valueFormatter: function (value) {
                return value;
              },
            },
            data: KSMC[2].data,
          },
          {
            name: KSMC[3].name,
            type: "line",
            tooltip: {
              valueFormatter: function (value) {
                return value;
              },
            },
            data: KSMC[3].data,
          },
          // {
          //   name: KSMC[4].name,
          //   type: 'line',
          //   tooltip: {
          //     valueFormatter: function(value) {
          //       return value
          //     }
          //   },
          //   data: KSMC[4].data
          // }
        ],
      };
    },
  },
};
</script>
<style lang="scss" scoped></style>
