<template>
  <div class="container">
    <div class="top">
      <div class="top_box">
        <Echart :options="genderOptions" id="gender" style="width: 100%; height: 100%; margin: 0 auto" ref="gender" />
        <!--        <img src="../../../assets/qualityConyrolScreen/men.svg" alt=""/>-->
        <!--        <img src="../../../assets/qualityConyrolScreen/women.svg" alt=""/>-->
      </div>
      <div class="top_box">
        <Echart :options="officeOptions" id="office" style="width: 100%; height: 100%; margin: 0 auto" ref="office" />
      </div>
      <div class="top_box" ref="faculty">
        <Echart :options="facultyOptions" id="faculty" style="width: 100%; height: 100%; margin: 0 auto"
          ref="faculty" />
      </div>
    </div>
    <div class="bot">
      就诊年龄分布
      <Echart :options="ageOptions" id="age" style="width: 100%; height: 100%; margin: 0 auto" ref="age" />
    </div>
  </div>
</template>

<script>
import {
  getGenderRatio,
  getWeeklyVisitStatistics,
  getAgeStatistics,
  getTopDepartmentVisitTrend,
  gethisDataSync
} from '@/api/qualityControlScreen'

export default {
  data() {
    return {
      genderOptions: {},
      officeOptions: {},
      facultyOptions: {},
      ageOptions: {},
      timer: null
    }
  },
  created() {
  },
  computed: {},
  mounted() {
    this.init()
    this.timer = setInterval(() => {
      this.init()
    }, 300000) // 每5秒发送一次请求
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    init() {
      gethisDataSync('', 1).then(res => {
        //男性人数
        const MALE = json.MALE
        //女性人数
        const FEMALE = json.FEMALE
        const total = MALE + FEMALE
        // 计算比例
        const MALEpro = (MALE / total).toFixed(2) * 100
        const FEMALEpro = (FEMALE / total).toFixed(2) * 100
        this.genderOptionsInit(MALEpro, FEMALEpro)
      }).catch((err) => {
        let getGenderRatioData = { "msg": "操作成功", "code": 200, "data": { "MALE": 10909, "FEMALE": 12257 } }
        //男性人数
        const MALE = getGenderRatioData.data.MALE
        //女性人数
        const FEMALE = getGenderRatioData.data.FEMALE
        const total = MALE + FEMALE
        // 计算比例
        const MALEpro = (MALE / total).toFixed(2) * 100
        const FEMALEpro = (FEMALE / total).toFixed(2) * 100
        this.genderOptionsInit(MALEpro, FEMALEpro)
      })

      gethisDataSync('', 2).then((res) => {
        let json = JSON.parse(res.data[0].data).data
        //东院区人数
        const params1 = json.filter((item) => item.YQSB === 1)
        //西院区人数
        const params2 = json.filter((item) => item.YQSB === 2)
        this.facultyOptionsInit(params1[0].RS, params2[0].RS)
      }).catch((err) => {
        let getWeeklyVisitStatisticsData = { "msg": "操作成功", "code": 200, "data": [{ "RS": 15607, "YQSB": 1 }, { "RS": 7559, "YQSB": 2 }] }
        //东院区人数
        const params1 = getWeeklyVisitStatisticsData.data.filter((item) => item.YQSB === 1)
        //西院区人数
        const params2 = getWeeklyVisitStatisticsData.data.filter((item) => item.YQSB === 2)
        this.facultyOptionsInit(params1[0].RS, params2[0].RS)
      })

      //年龄分布
      gethisDataSync('', 3).then((res) => {
        let json = JSON.parse(res.data[0].data).data
        res.data.pop()
        const KSMC = json.map(item => item.KSMC)
        const RS = json.map(item => item.RS)
        this.ageOptionsInit(KSMC, RS)
      }).catch((err) => {
        let getAgeStatisticsData = { "msg": "操作成功", "code": 200, "data": [{ "RS": 235, "KSMC": "0—30天" }, { "RS": 1636, "KSMC": "0—5岁" }, { "RS": 1923, "KSMC": "10—20岁" }, { "RS": 189, "KSMC": "1—12月" }, { "RS": 2191, "KSMC": "20—30岁" }, { "RS": 3730, "KSMC": "30—40岁" }, { "RS": 2274, "KSMC": "40—50岁" }, { "RS": 3912, "KSMC": "50—60岁" }, { "RS": 1335, "KSMC": "5—10岁" }, { "RS": 5724, "KSMC": "60岁以上" }, { "RS": 17, "KSMC": "其他年龄段" }] }
        getAgeStatisticsData.data.pop()
        const KSMC = getAgeStatisticsData.data.map(item => item.KSMC)
        const RS = getAgeStatisticsData.data.map(item => item.RS)
        this.ageOptionsInit(KSMC, RS)
      })


      gethisDataSync("", 4).then((res) => {
        let json = JSON.parse(res.data[0].data).data
        const data = json.map((item) => {
          return { name: item.KSMC, value: item.RS }
        })
        this.officeOptionsInit(data)
      }).catch((err) => {
        let getTopDepartmentVisitTrendData = { "msg": "操作成功", "code": 200, "data": [{ "RS": 1767, "KSMC": "儿科门诊-东区" }, { "RS": 1472, "KSMC": "急诊科门诊" }, { "RS": 1162, "KSMC": "妇科门诊" }, { "RS": 1096, "KSMC": "心血管内科门诊-东区" }, { "RS": 1016, "KSMC": "心血管内科门诊" }] }
        const data = getTopDepartmentVisitTrendData.data.map((item) => {
          return { name: item.KSMC, value: item.RS }
        })
        this.officeOptionsInit(data)
      })
    },
    //男女比例
    genderOptionsInit(MALE, FEMALE) {
      const symbols = [
        'path://M694.7 248.2c-6.3-43.6-43.9-77.2-89.3-77.2L498.9 171l0 0.2-92.8 0c-45.4 0-82.9 33.5-89.3 77.2-0.6 3-1 6-1 9.2L315.8 441l0 120c0 21.8 16 39.4 35.8 39.4 19.8 0 35.8-17.6 35.8-39.4l0-46.8 0-228.4 14.5 0 0 685.4c0 28.3 20.9 51.2 46.6 51.2 25.7 0 46.6-22.9 46.6-51.3L495.1 622.5l21.4 0 0-0.2 0 348.9c0 28.3 20.9 51.2 46.6 51.2 25.7 0 46.6-22.9 46.6-51.3l0-685.5 14.5 0 0 275.1c0 21.8 16 39.4 35.8 39.4 19.8 0 35.8-17.6 35.8-39.4l0-303.4C695.8 254.3 695.4 251.2 694.7 248.2z M503.1 145.8c40.3 0 72.9-32.6 72.9-72.9 0-40.3-32.6-72.9-72.9-72.9s-72.9 32.6-72.9 72.9C430.2 113.2 462.9 145.8 503.1 145.8z',
        'path://M511.6 190.5c-39.4 0-71.3-39.9-71.3-89.1 0-49.2 31.9-89.1 71.3-89.1s71.3 39.9 71.3 89.1c0 49.2-31.8 89.1-71.3 89.1zM706 500.3c12.4 51-42.5 72.1-55.7 23l-53.8-216h-17.2l94.3 379.5h-88.7V972c0 51.6-63.2 51.6-63.2 0V686.7h-20.4v285.2c0 51.6-65.4 51.6-65.4 0V686.7H349.5l92.4-379.5H427l-53.6 216c-13.6 48-68.6 28.1-55.8-23.1L377.7 266c7-27.1 35.8-73.3 86-75.5h96.6c48.6 2.1 77.7 48.8 85.7 75.3l60 234.5z m0 0'
      ]
      const bodyMax = 100//指定图形界限的值
      const labelSetting = {
        show: true,
        position: 'bottom',
        offset: [0, 0],
        formatter: function (param) {
          return (param.value / bodyMax * 100).toFixed(0) + '%'
        },
        fontSize: 30,
        fontFamily: 'Arial',
        fontStyle: 'italic',
        fontWeight: '600',
        color: 'RGBA(39, 129, 254, 1)'

      }
      const markLineSetting = { //设置标线
        symbol: 'none',
        lineStyle: {
          opacity: 0.3
        },
        data: [{
          type: 'max',
          label: {
            formatter: 'max: {c}'
          }
        }, {
          type: 'min',
          label: {
            formatter: 'min: {c}'
          }
        }]
      }
      //男女比例
      this.genderOptions = {
        grid: {
          // left: '20%',
          // right: '20%',
          top: '0',
          bottom: '20%',
          left: '1%',
          right: '1%',
          containLabel: true
        },
        xAxis: {
          data: ['男', '女'],
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            show: false,
            textStyle: {
              color: '#fff',
              fontSize: 16
            }
          }
        },
        yAxis: {
          splitLine: {
            show: false
          },
          axisTick: {
            // 刻度线
            show: false
          },
          axisLine: {
            // 轴线
            show: false
          },
          axisLabel: {
            show: false,
            textStyle: {
              color: '#00badb',
              fontSize: 16
            }
          }
        },
        tooltip: {
          show: false
        },
        series: [
          {
            name: '男女比例',
            type: 'pictorialBar',
            symbolClip: true,
            symbolBoundingData: bodyMax,
            label: labelSetting,
            barWidth: 60,
            data: [
              {
                value: MALE,
                symbol: symbols[0],
                itemStyle: {
                  color: 'RGBA(39, 129, 254, 1)' //单独控制颜色
                }
              },
              {
                value: FEMALE,
                symbol: symbols[1],
                itemStyle: {
                  color: 'RGBA(255, 101, 101, 1)' //单独控制颜色
                }
              }],
            markLine: markLineSetting,
            z: 10
          },
          {
            // 设置背景底色，不同的情况用这个
            name: 'full',
            type: 'pictorialBar', //异型柱状图 图片、SVG PathData
            symbolBoundingData: bodyMax,
            animationDuration: 0,
            barWidth: 60,
            itemStyle: {
              color: '#ccc' //设置全部颜色，统一设置
            },
            z: 10,
            data: [{
              itemStyle: {
                color: 'RGBA(219, 234, 255, .3)' //单独控制颜色
              },
              value: 100,
              symbol: symbols[0]
            },
            {
              itemStyle: {

                color: 'RGBA(219, 234, 255, .3)' //单独控制颜色

              },
              value: 100,
              symbol: symbols[1]
            }
            ]
          }
        ]
      }
    },
    //就诊科别TOP5
    officeOptionsInit(data) {
      this.officeOptions = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: '5%',
          left: 'center',
          show: false
        },
        title: {
          text: '就诊科别TOP5',
          left: 'center',
          top: 'center',
          textStyle: {
            color: '#fff',
            fontSize: 14,
            align: 'center'
          }
        },

        series: [
          {
            type: 'pie',
            radius: ['70%', '95%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 3,
              borderColor: '#0B0C11',
              borderWidth: 1
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: false,
                fontSize: 40,
                fontWeight: 'none'
              }
            },
            labelLine: {
              show: false
            },
            data: data
          }
        ]
      }
    },
    //东西院区就诊人数
    facultyOptionsInit(num1, num2) {
      this.facultyOptions = {
        tooltip: {},
        xAxis: {
          // max: 20000,
          splitLine: { show: false },
          offset: 10,
          axisLine: {
            lineStyle: {
              color: '#999'
            }
          },
          axisLabel: {
            margin: 5
          }
        },
        yAxis: {
          data: ['西院区', '东院区'],
          nameLocation: 'top',
          position: 'top',
          inverse: true,
          axisTick: { show: false },
          axisLine: { show: false },
          axisLabel: {
            margin: 5,
            color: '#fff',
            fontSize: 12
          }
        },
        grid: {
          top: 'center',
          height: 120,
          left: '30%',
          right: '5%',
          bottom: 'center'
        },
        series: [
          {
            type: 'bar',
            barWidth: '30%',
            data: [num2, num1],
            label: {
              show: true,
              offset: [5, -20],
              color: '#fff',
              fontFamily: 'PingFangSC',
              fontSize: 12,
              fontWeight: 200,
              position: 'right',
              align: 'right',
              formatter: function (params) {
                return params.data + '人次'
              }
            }
          }

        ]
      }
    },
    //就诊年龄统计
    ageOptionsInit(KSMC, RS) {
      this.ageOptions = {
        grid: {//定义折线图距离左边多少右边多少上边多少的距离
          left: '0',
          right: '0',
          bottom: '10%',
          top: '5%',
          containLabel: true //grid 区域是否包含坐标轴的刻度标签。如果不写true，那么纵坐标的位置就不会算进上面设置的坐边右边上边下边里面去
        },
        xAxis: {
          type: 'category',
          data: KSMC,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              color: 'RGBA(59, 60, 64, 1)',
              type: [5, 10],
              dashOffset: 5,
              width: '1'
            }
          }
        },
        series: [
          {
            data: RS,
            type: 'bar',
            barWidth: '20%',
            itemStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 1,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(99, 153, 221, 1)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(15, 56, 89, 1)'
                  }
                ]
              },
              borderRadius: [3, 3, 0, 0]
            }
          }
        ]
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  .top {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: space-around;

    .top_box {
      height: 100%;
      width: 33%;
      display: flex;

      img {
        width: 50%;
        height: 70%;
      }
    }
  }

  .bot {
    width: 100%;
    height: 50%;
  }
}
</style>
