var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "left" }, [
      _c("div", { staticClass: "title" }, [_vm._v("子午流注养生提示")]),
      _c("div", { staticClass: "content_box" }, [
        _c("img", { attrs: { src: _vm.imgUrl2, alt: "" } }),
      ]),
    ]),
    _c("div", { staticClass: "right" }, [
      _c("div", { staticClass: "title" }, [_vm._v("时节养生大全")]),
      _c(
        "div",
        { staticClass: "content_box" },
        [
          _c(
            "el-carousel",
            {
              attrs: {
                height: "100%",
                "indicator-position": "none",
                arrow: "never",
                interval: 5000,
              },
            },
            _vm._l(_vm.imgUrl, function (item, index) {
              return _c("el-carousel-item", { key: index }, [
                _c("img", { attrs: { src: item, alt: "" } }),
              ])
            }),
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }