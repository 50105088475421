<template>
  <Echart
    :options="options"
    id="leftCenter"
    style="width: 100%; height: 100%; margin: 0 auto"
    ref="leftCenter"
  />
</template>

<script>
import * as echarts from "echarts";
import { mixins } from "@/mixin";
export default {
  name: "leftCenter",
  mixins: [mixins],
  data() {
    return {
      options: {},
    };
  },
  filters: {},
  created() {},
  mounted() {
    this.getData();
  },
  beforeDestroy() {},
  methods: {
    getData() {
      this.init();
    },
    init(newData) {
      this.options = {
        backgroundColor: "RGBA(11, 28, 44, .3)",
        title: {
          color: "#fff",
          fontSize: 15,
        },
        grid: {
          left: "7%",
          right: "1%",
          top: "12%",
          bottom: "10%",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#fff",
            },
          },
        },
        legend: {
          show: true,
          data: [
            { name: "视频", icon: "roundRect" },
            { name: "音频", icon: "roundRect" },
            { name: "医案", icon: "roundRect" },
            { name: "文献", icon: "roundRect" },
          ],
          textStyle: {
            color: "#fff",
            fontSize: 12,
            rich: {
              a: {
                color: "#fff",
              },
            },
          },
          formatter: function (name) {
            return name;
          },
        },
        xAxis: [
          {
            type: "category",
            data: this.dateArray,
            axisPointer: {
              type: "shadow",
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              interval: 0, //全部显示x轴
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "",
            min: 0,
            max: 400,
            interval: 100,
            axisLabel: {
              formatter: "{value}",
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "RGBA(59, 60, 64, 1)",
                type: [5, 10],
                dashOffset: 5,
                width: "1",
              },
            },
          },
        ],
        series: [
          {
            name: "视频",
            type: "line",
            barWidth: "20%",
            tooltip: {
              valueFormatter: function (value) {
                return value;
              },
            },
            data: [20, 14, 70, 122, 356, 367, 336, 362, 36, 200, 64, 33],
          },
          {
            name: "音频",
            type: "line",
            tooltip: {
              valueFormatter: function (value) {
                return value;
              },
            },
            data: [20, 22, 33, 45, 63, 102, 103, 334, 130, 365, 220, 62],
          },
          {
            name: "医案",
            type: "line",
            tooltip: {
              valueFormatter: function (value) {
                return value;
              },
            },
            data: [20, 22, 12, 16, 86, 345, 203, 124, 261, 165, 120, 62],
          },
          {
            name: "文献",
            type: "line",
            tooltip: {
              valueFormatter: function (value) {
                return value;
              },
            },
            data: [20, 22, 33, 45, 63, 102, 203, 234, 230, 165, 120, 62],
          },
        ],
      };
    },
  },
};
</script>
<style lang="scss" scoped></style>
