import Base from './Base'

/**
 * 6. 模块点击次数
 *
 */
class ModuleClick extends Base {

  constructor() {
    super('moduleClick');
    this.pool = {};
    this.list = [];
  }

  click(button) {
    let count = this.pool[button] || 0;
    count++;
    this.pool[button] = count;

    this.list.push({
      timestamp: new Date().getTime(),
      button: button
    })
  }

  getData() {
    if (this.list.length < 1) {
      return null;
    }
    return {
      pool: this.pool,
      list: this.list
    }
  }
}

let moduleClick = new ModuleClick();
export default moduleClick
