var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-button", { on: { click: _vm.exportPdf } }, [_vm._v("导出pdf")]),
      _c(
        "div",
        {
          ref: "pdf",
          style: "background: #ffffff;width: " + _vm.pageWidth + "px;",
        },
        [
          _c(
            "div",
            {
              staticClass: "page-home",
              style:
                "width: " +
                _vm.pageWidth +
                "px;height:" +
                _vm.pageHeight +
                "px",
            },
            [
              _c("div", { staticClass: "first-page" }, [
                _c("img", {
                  staticClass: "first-page-cover",
                  attrs: { src: require("@/assets/report/cover.png"), alt: "" },
                }),
                _c("div", { staticClass: "title-word" }, [
                  _vm._v("中医健康体质报告"),
                ]),
                _c("div", { staticClass: "title-en" }, [
                  _vm._v("TCM Health Physique Report"),
                ]),
                _c("div", { staticClass: "info" }, [
                  _c("div", [_vm._v("姓名:" + _vm._s(_vm.patientInfo.name))]),
                  _c("div", [_vm._v("性别:" + _vm._s(_vm.patientInfo.gender))]),
                  _c("div", [
                    _vm._v(
                      "采集编号:" + _vm._s(_vm.patientInfo.therapeuticRegimen)
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "page-cont" },
            [
              _c(
                "el-descriptions",
                [
                  _c("el-descriptions-item", { attrs: { label: "姓名" } }, [
                    _vm._v(_vm._s(_vm.patientInfo.name)),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "性别" } }, [
                    _vm._v(_vm._s(_vm.patientInfo.gender)),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "采集编号" } }, [
                    _vm._v(_vm._s(_vm.patientInfo.therapeuticRegimen)),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "年龄" } }, [
                    _vm._v(" 99 "),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "出生日期" } }, [
                    _vm._v(" " + _vm._s(_vm.patientInfo.birthday || "-") + " "),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "手机号" } }, [
                    _vm._v(" " + _vm._s(_vm.patientInfo.cellphone) + " "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "page-cont" },
            [
              _c("h1", { staticStyle: { "text-align": "center" } }, [
                _vm._v("舌象解读"),
              ]),
              _c(
                "el-row",
                {
                  staticStyle: {
                    height: "278px",
                    display: "flex",
                    "justify-content": "center",
                  },
                  attrs: { gutter: 10 },
                },
                [
                  _c(
                    "el-col",
                    { staticStyle: { height: "100%" }, attrs: { span: 12 } },
                    [
                      _c("img", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          src: _vm.patientInfo.shefileUrl,
                          alt: "舌象照片加载失败",
                        },
                      }),
                    ]
                  ),
                  _vm.isEmptyObject(_vm.shexiangtezheng)
                    ? _c(
                        "el-col",
                        {
                          staticStyle: { height: "100%" },
                          attrs: { span: 12 },
                        },
                        [
                          _c("Echart", {
                            attrs: {
                              id: "shexiangOption",
                              options: _vm.shexiangOption,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.isEmptyObject(_vm.shexiangtezheng)
                ? _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _c("el-col", { attrs: { span: 20, offset: 2 } }, [
                        _c(
                          "table",
                          { attrs: { border: "1" } },
                          [
                            _c("tr", { staticClass: "table-title" }, [
                              _c("td", { attrs: { colspan: "4" } }, [
                                _vm._v("舌象特征提示"),
                              ]),
                            ]),
                            _c("tr", { staticClass: "bg-gray" }, [
                              _c("td", [_vm._v("舌象特征")]),
                              _c("td", [_vm._v("数值")]),
                              _c("td", [_vm._v("特征结果")]),
                              _c("td", [_vm._v("参考范围")]),
                            ]),
                            _vm._l(_vm.shexiangtezheng, function (item, index) {
                              return _c("tr", { key: index }, [
                                _c("td", { staticClass: "bg-gray" }, [
                                  _vm._v(_vm._s(item.name)),
                                ]),
                                _c("td", [_vm._v(_vm._s(item.value["数值"]))]),
                                _c("td", [
                                  _vm._v(_vm._s(item.value["特征结果"])),
                                ]),
                                _c("td", [
                                  _vm._v(_vm._s(item.value["参考范围"])),
                                ]),
                              ])
                            }),
                          ],
                          2
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isEmptyObject(_vm.maixiangbo) &&
              _vm.isEmptyObject(_vm.maixiangcanshubiao) &&
              _vm.isEmptyObject(_vm.maixiangyaosufenxi)
                ? _c("h1", { staticStyle: { "text-align": "center" } }, [
                    _vm._v("脉象解读"),
                  ])
                : _vm._e(),
              _vm.isEmptyObject(_vm.maixiangbo)
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "flex-direction": "row",
                        "flex-wrap": "wrap",
                        width: "100%",
                      },
                    },
                    [
                      _c("Echart", {
                        staticStyle: {
                          width: "50%",
                          height: "100%",
                          margin: "10px auto",
                        },
                        attrs: { options: _vm.leftguan, id: "leftguan" },
                      }),
                      _c("Echart", {
                        staticStyle: {
                          width: "50%",
                          height: "100%",
                          margin: "10px auto",
                        },
                        attrs: { options: _vm.rightguan, id: "rightguan" },
                      }),
                      _c("Echart", {
                        staticStyle: {
                          width: "50%",
                          height: "100%",
                          margin: "10px auto",
                        },
                        attrs: { options: _vm.leftcun, id: "leftcun" },
                      }),
                      _c("Echart", {
                        ref: "rightcun",
                        staticStyle: {
                          width: "50%",
                          height: "100%",
                          margin: "10px auto",
                        },
                        attrs: { options: _vm.rightcun },
                      }),
                      _c("Echart", {
                        staticStyle: {
                          width: "50%",
                          height: "100%",
                          margin: "10px auto",
                        },
                        attrs: { options: _vm.leftchi, id: "leftchi" },
                      }),
                      _c("Echart", {
                        staticStyle: {
                          width: "50%",
                          height: "100%",
                          margin: "10px auto",
                        },
                        attrs: { options: _vm.rightchi, id: "rightchi" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isEmptyObject(_vm.maixiangyaosufenxi)
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "flex-direction": "row",
                        "flex-wrap": "wrap",
                      },
                    },
                    [
                      _c("Echart", {
                        staticStyle: { width: "50%", margin: "0 auto" },
                        attrs: {
                          options: _vm.maiweiOption,
                          id: "maiweiOption",
                          height: "200px",
                        },
                      }),
                      _c("Echart", {
                        staticStyle: { width: "50%", margin: "0 auto" },
                        attrs: {
                          options: _vm.mailvOption,
                          id: "mailvOption",
                          height: "200px",
                        },
                      }),
                      _c("Echart", {
                        staticStyle: { width: "50%", margin: "0 auto" },
                        attrs: {
                          options: _vm.maixingOption,
                          id: "maixingOption",
                          height: "200px",
                        },
                      }),
                      _c("Echart", {
                        staticStyle: { width: "50%", margin: "0 auto" },
                        attrs: {
                          options: _vm.mailiOption,
                          id: "mailiOption",
                          height: "200px",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.isEmptyObject(_vm.maixiangcanshubiao)
            ? _c(
                "div",
                { staticClass: "page-cont" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _c("el-col", { attrs: { span: 20, offset: 2 } }, [
                        _c(
                          "table",
                          { attrs: { border: "1" } },
                          [
                            _c("tr", { staticClass: "table-title" }, [
                              _c("td", { attrs: { colspan: "7" } }, [
                                _vm._v("脉象参数量化表"),
                              ]),
                            ]),
                            _c("tr", { staticClass: "bg-gray" }, [
                              _c("td"),
                              _c("td", [_vm._v("脉位")]),
                              _c("td", [_vm._v("脉率")]),
                              _c("td", [_vm._v("节律")]),
                              _c("td", [_vm._v("脉力")]),
                              _c("td", [_vm._v("紧张度")]),
                              _c("td", [_vm._v("流利度")]),
                            ]),
                            _vm._l(
                              _vm.maixiangcanshubiao,
                              function (item, index) {
                                return _c("tr", { key: index }, [
                                  _c("td", { staticClass: "bg-gray" }, [
                                    _vm._v(_vm._s(item.name)),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(item.value["脉位"])),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(item.value["脉率"])),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(item.value["节律"])),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(item.value["脉力"])),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(item.value["紧张度"])),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(item.value["流利度"])),
                                  ]),
                                ])
                              }
                            ),
                          ],
                          2
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.isEmptyObject(_vm.wenzhenjieguo) ||
          _vm.isEmptyObject(_vm.wenzhentizhifangan)
            ? _c(
                "div",
                { staticClass: "page-cont" },
                [
                  _c(
                    "div",
                    [
                      _c("h1", { staticStyle: { "text-align": "center" } }, [
                        _vm._v("中医体检检测结果（一）"),
                      ]),
                      _c("h1", { staticStyle: { "text-align": "center" } }, [
                        _vm._v("体质状态评估"),
                      ]),
                      _vm.isEmptyObject(_vm.wenzhenjieguo)
                        ? _c("Echart", {
                            attrs: {
                              options: _vm.tizhiOption,
                              height: "400px",
                              width: "100%",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.isEmptyObject(_vm.wenzhentizhifangan)
                    ? [
                        _c("p", [
                          _vm._v(" 根据中医健康评估分析得出您的体质结果为"),
                          _c("span", { staticStyle: { color: "#ef4e51" } }, [
                            _vm._v(_vm._s(_vm.wenzhentizhifangan.constitution)),
                          ]),
                          _vm._v("兼"),
                          _c("span", { staticStyle: { color: "#f7992a" } }, [
                            _vm._v(_vm._s(_vm.wenzhentizhifangan.tendency)),
                          ]),
                        ]),
                        _c("p", [_vm._v("您的结果分析：")]),
                        _c("p", { staticStyle: { "text-indent": "2em" } }, [
                          _vm._v(_vm._s(_vm.wenzhentizhifangan.resultAnalysis)),
                        ]),
                        _c(
                          "p",
                          {
                            staticStyle: {
                              color: "#232f60",
                              "font-size": "16px",
                            },
                          },
                          [_vm._v("体质特征")]
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "justify-content": "space-between",
                              "flex-wrap": "wrap",
                            },
                          },
                          _vm._l(
                            _vm.wenzhentizhifangan.constitutionCharacteristics,
                            function (item, index) {
                              return _c(
                                "span",
                                {
                                  key: index,
                                  staticStyle: {
                                    width: "160px",
                                    height: "30px",
                                  },
                                },
                                [_vm._v(_vm._s(item))]
                              )
                            }
                          ),
                          0
                        ),
                        _c("p", [
                          _vm._v(
                            "宜：" + _vm._s(_vm.wenzhentizhifangan.foodUseful)
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            "忌：" + _vm._s(_vm.wenzhentizhifangan.foodAvoid)
                          ),
                        ]),
                        _c(
                          "p",
                          {
                            staticStyle: {
                              color: "#232f60",
                              "font-size": "16px",
                            },
                          },
                          [_vm._v("季节调理：")]
                        ),
                        _c("p", { staticStyle: { "text-indent": "2em" } }, [
                          _vm._v(_vm._s(_vm.wenzhentizhifangan.seasonAdvice)),
                        ]),
                        _c(
                          "p",
                          {
                            staticStyle: {
                              color: "#232f60",
                              "font-size": "16px",
                            },
                          },
                          [_vm._v("运动调理：")]
                        ),
                        _c("p", { staticStyle: { "text-indent": "2em" } }, [
                          _vm._v(_vm._s(_vm.wenzhentizhifangan.exerciseAdvice)),
                        ]),
                        _vm._l(
                          _vm.wenzhentizhifangan.exercises,
                          function (item, index) {
                            return _c(
                              "table",
                              { key: index, attrs: { border: "1" } },
                              [
                                _c("tr", { staticClass: "table-title" }, [
                                  _c("td", { attrs: { colspan: "2" } }, [
                                    _vm._v(_vm._s(item.exercise)),
                                  ]),
                                ]),
                                _c("tr", [
                                  _c(
                                    "td",
                                    { staticStyle: { "text-align": "left" } },
                                    [
                                      _c("p", [
                                        _vm._v(
                                          "动作一：" + _vm._s(item.operate1)
                                        ),
                                      ]),
                                      _c("p", [
                                        _vm._v(
                                          "动作二：" + _vm._s(item.operate2)
                                        ),
                                      ]),
                                      _c("p", [
                                        _vm._v(
                                          "动作三：" + _vm._s(item.operate3)
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticStyle: {
                                        height: "240px",
                                        width: "300px",
                                      },
                                    },
                                    [
                                      _c("img", {
                                        staticStyle: {
                                          width: "100%",
                                          height: "100%",
                                        },
                                        attrs: {
                                          src: _vm.getBaDuanJinSrc(item.imgUrl),
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  ),
                                ]),
                              ]
                            )
                          }
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
          _c("div", { staticClass: "page-cont" }, [
            _c("h1", [_vm._v("推理证候")]),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "justify-content": "space-evenly",
                  "margin-bottom": "30px",
                },
              },
              _vm._l(_vm.bingyibingjiList, function (item, index) {
                return index < 3
                  ? _c("div", { key: index, staticClass: "zhenghou-item" }, [
                      _c("span"),
                      _vm._v(_vm._s(item.title) + " "),
                    ])
                  : _vm._e()
              }),
              0
            ),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "flex-direction": "row",
                  "flex-wrap": "wrap",
                },
              },
              [
                _c("Echart", {
                  staticStyle: {
                    width: "50%",
                    height: "100%",
                    margin: "0 auto",
                  },
                  attrs: { options: _vm.bingyin, id: "bingyin" },
                }),
                _c("Echart", {
                  staticStyle: {
                    width: "50%",
                    height: "100%",
                    margin: "0 auto",
                  },
                  attrs: { options: _vm.bingwei, id: "bingwei" },
                }),
                _c("Echart", {
                  staticStyle: {
                    width: "50%",
                    height: "100%",
                    margin: "0 auto",
                  },
                  attrs: { options: _vm.jibenwuzhi, id: "jibenwuzhi" },
                }),
                _c("Echart", {
                  staticStyle: {
                    width: "50%",
                    height: "100%",
                    margin: "0 auto",
                  },
                  attrs: {
                    options: _vm.binglizhuangtai,
                    id: "binglizhuangtai",
                  },
                }),
                _c("Echart", {
                  staticStyle: {
                    width: "50%",
                    height: "100%",
                    margin: "0 auto",
                  },
                  attrs: { options: _vm.yinyangbiaoli, id: "yinyangbiaoli" },
                }),
                _c("Echart", {
                  staticStyle: {
                    width: "50%",
                    height: "100%",
                    margin: "0 auto",
                  },
                  attrs: { options: _vm.hanrexushi, id: "hanrexushi" },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "body" }, [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "flex-direction": "row",
                    "flex-wrap": "wrap",
                  },
                },
                [
                  _c("Echart", {
                    staticStyle: { width: "50%", margin: "0 auto" },
                    attrs: {
                      options: _vm.yaoxingOption,
                      id: "yaoxingOption",
                      height: "300px",
                    },
                  }),
                  _c("Echart", {
                    staticStyle: { width: "50%", margin: "0 auto" },
                    attrs: {
                      options: _vm.yaoweiOption,
                      id: "yaoweiOption",
                      height: "300px",
                    },
                  }),
                  _c("Echart", {
                    attrs: {
                      options: _vm.guijingOption,
                      id: "guijingOption",
                      height: "300px",
                      width: "100%",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "body-item" },
                [
                  _c(
                    "el-row",
                    { staticClass: "row" },
                    [
                      _c(
                        "el-col",
                        { staticClass: "col", attrs: { span: 12 } },
                        [
                          _c("div", { staticClass: "item-title" }, [
                            _vm._v("方名"),
                          ]),
                          _c("div", { staticClass: "item-value red" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.jingfangList.fangming || "-") +
                                " "
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-col",
                        { staticClass: "col", attrs: { span: 12 } },
                        [
                          _c("div", { staticClass: "item-title" }, [
                            _vm._v("来源"),
                          ]),
                          _c("div", { staticClass: "item-value red" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.jingfangList.laiyuan || "-") +
                                " "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { staticClass: "row" },
                    [
                      _c(
                        "el-col",
                        { staticClass: "col", attrs: { span: 24 } },
                        [
                          _c("div", { staticClass: "item-title" }, [
                            _vm._v("组成"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "item-value bold flex" },
                            _vm._l(_vm.yaomingList, function (item, index) {
                              return _c(
                                "div",
                                { key: index },
                                [
                                  _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        placement: "top",
                                        width: "800",
                                        trigger: "hover",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "body-item" },
                                        [
                                          _c(
                                            "el-row",
                                            { staticClass: "row" },
                                            [
                                              _c(
                                                "el-col",
                                                {
                                                  staticClass: "col",
                                                  attrs: { span: 12 },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "item-title",
                                                    },
                                                    [_vm._v("药名")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "item-value bold",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.ym || "-"
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "el-col",
                                                {
                                                  staticClass: "col",
                                                  attrs: { span: 12 },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "item-title",
                                                    },
                                                    [_vm._v("别名")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "item-value",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.bm || "-"
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-row",
                                            { staticClass: "row" },
                                            [
                                              _c(
                                                "el-col",
                                                {
                                                  staticClass: "col",
                                                  attrs: { span: 6 },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "item-title",
                                                    },
                                                    [_vm._v("拼音")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "item-value",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.py || "-"
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "el-col",
                                                {
                                                  staticClass: "col",
                                                  attrs: { span: 6 },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "item-title",
                                                    },
                                                    [_vm._v("性")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "item-value bold",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.xing || "-"
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "el-col",
                                                {
                                                  staticClass: "col",
                                                  attrs: { span: 6 },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "item-title",
                                                    },
                                                    [_vm._v("味")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "item-value bold",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.wei || "-"
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "el-col",
                                                {
                                                  staticClass: "col",
                                                  attrs: { span: 6 },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "item-title bold",
                                                    },
                                                    [_vm._v("毒")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "item-value",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.duxing || "-"
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-row",
                                            { staticClass: "row" },
                                            [
                                              _c(
                                                "el-col",
                                                {
                                                  staticClass: "col",
                                                  attrs: { span: 12 },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "item-title",
                                                    },
                                                    [_vm._v("归经")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "item-value bold",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.gj || "-"
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "el-col",
                                                {
                                                  staticClass: "col",
                                                  attrs: { span: 12 },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "item-title",
                                                    },
                                                    [_vm._v("功效")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "item-value bold",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.gx || "-"
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-row",
                                            { staticClass: "row" },
                                            [
                                              _c(
                                                "el-col",
                                                {
                                                  staticClass: "col",
                                                  attrs: { span: 24 },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "item-title",
                                                    },
                                                    [_vm._v("主治")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "item-value bold",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.zz || "-"
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-row",
                                            { staticClass: "row" },
                                            [
                                              _c(
                                                "el-col",
                                                {
                                                  staticClass: "col",
                                                  attrs: { span: 24 },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "item-title",
                                                    },
                                                    [_vm._v("用法用量")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "item-value bold",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.yfyl || "-"
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-row",
                                            { staticClass: "row" },
                                            [
                                              _c(
                                                "el-col",
                                                {
                                                  staticClass: "col",
                                                  attrs: { span: 24 },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "item-title",
                                                    },
                                                    [_vm._v("应用禁忌")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "item-value red",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.yyjj || "-"
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-row",
                                            { staticClass: "row" },
                                            [
                                              _c(
                                                "el-col",
                                                {
                                                  staticClass: "col",
                                                  attrs: { span: 24 },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "item-title",
                                                    },
                                                    [_vm._v("配制方法")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "item-value",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.pzff || "-"
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "span",
                                        {
                                          attrs: { slot: "reference" },
                                          slot: "reference",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                index + 1 ===
                                                  _vm.yaomingList.length
                                                  ? item.ym + "。"
                                                  : item.ym + "、"
                                              )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { staticClass: "row" },
                    [
                      _c(
                        "el-col",
                        { staticClass: "col", attrs: { span: 24 } },
                        [
                          _c("div", { staticClass: "item-title" }, [
                            _vm._v("用法"),
                          ]),
                          _c("div", { staticClass: "item-value" }, [
                            _vm._v(
                              " " + _vm._s(_vm.jingfangList.yongfa || "-") + " "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { staticClass: "row" },
                    [
                      _c(
                        "el-col",
                        { staticClass: "col", attrs: { span: 24 } },
                        [
                          _c("div", { staticClass: "item-title" }, [
                            _vm._v("医案举例"),
                          ]),
                          _c("div", { staticClass: "item-value" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.jingfangList.yianjuli || "-") +
                                " "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { staticClass: "row" },
                    [
                      _c(
                        "el-col",
                        { staticClass: "col", attrs: { span: 24 } },
                        [
                          _c("div", { staticClass: "item-title" }, [
                            _vm._v("功用"),
                          ]),
                          _c("div", { staticClass: "item-value bold" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.jingfangList.gongyong || "-") +
                                " "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { staticClass: "row" },
                    [
                      _c(
                        "el-col",
                        { staticClass: "col", attrs: { span: 24 } },
                        [
                          _c("div", { staticClass: "item-title" }, [
                            _vm._v("主治"),
                          ]),
                          _c("div", { staticClass: "item-value" }, [
                            _vm._v(
                              " " + _vm._s(_vm.yaoxingForm.zhuzhi || "-") + " "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { staticClass: "row" },
                    [
                      _c(
                        "el-col",
                        { staticClass: "col", attrs: { span: 24 } },
                        [
                          _c("div", { staticClass: "item-title" }, [
                            _vm._v("证治机理"),
                          ]),
                          _c("div", { staticClass: "item-value" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.jingfangList.zhengzhijili || "-") +
                                " "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { staticClass: "row" },
                    [
                      _c(
                        "el-col",
                        { staticClass: "col", attrs: { span: 24 } },
                        [
                          _c("div", { staticClass: "item-title" }, [
                            _vm._v("方解"),
                          ]),
                          _c("div", { staticClass: "item-value" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.jingfangList.fangjie || "-") +
                                " "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { staticClass: "row" },
                    [
                      _c(
                        "el-col",
                        { staticClass: "col", attrs: { span: 24 } },
                        [
                          _c("div", { staticClass: "item-title" }, [
                            _vm._v("配伍特点"),
                          ]),
                          _c("div", { staticClass: "item-value" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.jingfangList.peiwutedian || "-") +
                                " "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { staticClass: "row" },
                    [
                      _c(
                        "el-col",
                        { staticClass: "col", attrs: { span: 24 } },
                        [
                          _c("div", { staticClass: "item-title" }, [
                            _vm._v("运用"),
                          ]),
                          _c("div", { staticClass: "item-value" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.jingfangList.yunyong || "-") +
                                " "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { staticClass: "row" },
                    [
                      _c(
                        "el-col",
                        { staticClass: "col", attrs: { span: 24 } },
                        [
                          _c("div", { staticClass: "item-title" }, [
                            _vm._v("鉴别"),
                          ]),
                          _c("div", { staticClass: "item-value" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.jingfangList.jianbie || "-") +
                                " "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { staticClass: "row" },
                    [
                      _c(
                        "el-col",
                        { staticClass: "col", attrs: { span: 24 } },
                        [
                          _c("div", { staticClass: "item-title" }, [
                            _vm._v("方论选录"),
                          ]),
                          _c("div", { staticClass: "item-value" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.jingfangList.fanglunxuanlu || "-") +
                                " "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { staticClass: "row" },
                    [
                      _c(
                        "el-col",
                        { staticClass: "col", attrs: { span: 24 } },
                        [
                          _c("div", { staticClass: "item-title" }, [
                            _vm._v("方歌"),
                          ]),
                          _c("div", { staticClass: "item-value" }, [
                            _vm._v(
                              " " + _vm._s(_vm.jingfangList.fangge || "-") + " "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "page-head",
              style: "height:" + _vm.headerHeight + "px",
            },
            [
              _vm._m(0),
              _c("div", { staticClass: "page-head-right" }, [
                _c("div", [_vm._v("姓名:" + _vm._s(_vm.patientInfo.name))]),
                _c("div", [_vm._v("性别:" + _vm._s(_vm.patientInfo.gender))]),
                _c("div", [
                  _vm._v(
                    "采集编号:" + _vm._s(_vm.patientInfo.therapeuticRegimen)
                  ),
                ]),
              ]),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "page-foot",
              style: "height:" + _vm.footerHeight + "px",
            },
            [
              _c("div", { staticClass: "page-foot-right" }, [
                _c("span", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: false,
                      expression: "false",
                    },
                  ],
                  attrs: { id: "pageNumber" },
                }),
                _vm._v(
                  "温馨提示：此方案是面向具有法定中医执业资格的医师作参考之用，如需要进行任何治疗，请前往具有资质的医疗机构向专业医生咨询 "
                ),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-head-left" }, [
      _c("img", {
        staticClass: "page-head-left-img",
        attrs: { src: require("@/assets/logo/logoLine.png"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }