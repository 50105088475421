var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "sizhen-layout",
    {
      on: {
        handleLastStep: function ($event) {
          return _vm.handleLastStep()
        },
        handleNextStep: function ($event) {
          return _vm.handleNextStep()
        },
      },
    },
    [
      _vm.itemShow
        ? _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "start_bg" }, [
              _c(
                "div",
                { staticClass: "begin-btn", on: { click: _vm.beginHandle } },
                [_vm._v("开始问诊")]
              ),
            ]),
          ])
        : _c(
            "div",
            { staticClass: "content" },
            [
              _vm._l(_vm.questionData, function (item, index) {
                return item.num === _vm.nowNum
                  ? _c("div", { key: index, staticClass: "item-box" }, [
                      _c("h1", [_vm._v(" " + _vm._s(item.questionName) + " ")]),
                      _c(
                        "ol",
                        _vm._l(item.option, function (vItem, ind) {
                          return _c(
                            "li",
                            {
                              key: ind,
                              on: {
                                click: function ($event) {
                                  return _vm.itemHandle(vItem.label)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(vItem.value) + " ")]
                          )
                        }),
                        0
                      ),
                    ])
                  : _vm._e()
              }),
              _vm.submitShow
                ? _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      { staticClass: "begin-btn", on: { click: _vm.onSubmit } },
                      [_vm._v("提交")]
                    ),
                  ])
                : _vm._e(),
              _vm.contentShow
                ? _c("div", { staticClass: "content" }, [
                    _c("div", { staticClass: "item-box" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v("您的体质辨识结果为"),
                      ]),
                      _c("div", { staticClass: "tizhi-name" }, [
                        _vm._v(" " + _vm._s(_vm.tizhiData.tizhi) + "-"),
                        _c("span", [_vm._v("主体质")]),
                      ]),
                      _c("div", { staticClass: "tizhi-desc" }, [
                        _c("div", [
                          _vm._v(" 易导致疾病： "),
                          _c("div", {
                            staticClass: "desc-item",
                            domProps: { innerHTML: _vm._s(_vm.tizhiData.yhjb) },
                          }),
                        ]),
                        _c("div", [
                          _vm._v(" 养生原则： "),
                          _c("div", {
                            staticClass: "desc-item",
                            domProps: { innerHTML: _vm._s(_vm.tizhiData.ysyz) },
                          }),
                        ]),
                        _c("div", [
                          _vm._v(" 饮食宜忌： "),
                          _c("div", {
                            staticClass: "desc-item",
                            domProps: { innerHTML: _vm._s(_vm.tizhiData.ysjj) },
                          }),
                        ]),
                        _c("div", [
                          _vm._v(" 宜吃： "),
                          _c("div", {
                            staticClass: "desc-item",
                            domProps: { innerHTML: _vm._s(_vm.tizhiData.yc) },
                          }),
                        ]),
                        _c("div", [
                          _vm._v(" 忌吃： "),
                          _c("div", {
                            staticClass: "desc-item",
                            domProps: { innerHTML: _vm._s(_vm.tizhiData.jc) },
                          }),
                        ]),
                        _c("div", [
                          _vm._v(" 精神养生： "),
                          _c("div", {
                            staticClass: "desc-item",
                            domProps: { innerHTML: _vm._s(_vm.tizhiData.jsys) },
                          }),
                        ]),
                        _c("div", [
                          _vm._v(" 起居养生： "),
                          _c("div", {
                            staticClass: "desc-item",
                            domProps: { innerHTML: _vm._s(_vm.tizhiData.qjys) },
                          }),
                        ]),
                        _c("div", [
                          _vm._v(" 运动： "),
                          _c("div", {
                            staticClass: "desc-item",
                            domProps: { innerHTML: _vm._s(_vm.tizhiData.yd) },
                          }),
                        ]),
                        _c("div", [
                          _vm._v(" 适合汤品/中药茶饮： "),
                          _c("div", {
                            staticClass: "desc-item",
                            domProps: {
                              innerHTML: _vm._s(_vm.tizhiData.shtpzycy),
                            },
                          }),
                        ]),
                        _c("div", [
                          _vm._v(" 注意事项： "),
                          _c("div", {
                            staticClass: "desc-item",
                            domProps: { innerHTML: _vm._s(_vm.tizhiData.zysx) },
                          }),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ],
            2
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }