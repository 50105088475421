<template>
  <div
    style="min-height: 930px"
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.6)"
  >
    <div class="tools">
      <div class="page" v-if="toolShow">
        当前页：
        <!-- {{ pageNum }} -->
        <input type="text" v-model.number="toPageNum" />
        <button @click="toPage">跳转</button> 页/总页数：{{
          pageTotalNum || 1
        }}页
      </div>
      <!-- <el-button
        :theme="'default'"
        type="submit"
        :title="'基础按钮'"
        @click.stop="clock"
        class="mr10"
      >
        顺时针</el-button
      >
      <el-button
        :theme="'default'"
        type="submit"
        :title="'基础按钮'"
        @click.stop="counterClock"
        class="mr10"
      >
        逆时针</el-button
      > -->
    </div>
    <div class="tool">
      <div
        class="back"
        @click.stop="prePage"
        @mouseover="onBackHover"
        @mouseout="onBackOut"
      >
        <transition name="el-fade-in-linear">
          <div class="page_turn backbtn" v-show="backShow">上一页</div>
        </transition>
      </div>
      <div
        class="next"
        @click.stop="nextPage"
        @mouseover="onNextHover"
        @mouseout="onNextOut"
      >
        <transition name="el-fade-in-linear">
          <div class="page_turn nextbtn" v-show="nextShow">下一页</div>
        </transition>
      </div>
      <pdf
        ref="pdf"
        :src="pdfUrl"
        :page="pageNum"
        :rotate="pageRotate"
        @progress="loadedRatio = $event"
        @page-loaded="pageLoaded($event)"
        @num-pages="pageTotalNum = $event"
        @error="pdfError($event)"
        @link-clicked="page = $event"
        style="height: 100%; width: 50%; transform: translate(50%)"
      >
      </pdf>
    </div>
  </div>
</template>

<script>
import pdf from "vue-pdf";
import { scrollTo } from "@/utils/scroll-to";

export default {
  components: {
    pdf,
  },
  name: "preViewBook",
  data() {
    return {
      pdfUrl: "",
      pageNum: 1,
      pageTotalNum: 1,
      pageRotate: 0,
      // 加载进度
      loadedRatio: 0,
      curPageNum: 0,
      bookId: "",
      backShow: false,
      nextShow: false,
      loading: true,
      toolShow: false,
      toPageNum: 1,
    };
  },
  mounted() {
    this.bookId = this.$route.query.bookId;
    this.pdfUrl =
      process.env.VUE_APP_BASE_API +
      `/library/book/api/pdf?bookId=${this.bookId}`; // 上传的图片服务器地址
  },
  methods: {
    // 上一页函数，
    prePage() {
      var page = this.pageNum;
      page = page > 1 ? page - 1 : this.pageTotalNum;
      this.pageNum = page;
      this.toPageNum = page;
      console.log("上一页");
    },
    // 下一页函数
    nextPage() {
      var page = this.pageNum;
      page = page < this.pageTotalNum ? page + 1 : 1;
      this.pageNum = page;
      this.toPageNum = page;
      console.log("下一页");
      scrollTo(0, 800);
    },
    //跳转到页面
    toPage() {
      this.pageNum = this.toPageNum;
    },
    onBackHover() {
      this.backShow = true;
    },
    onNextHover() {
      this.nextShow = true;
    },
    onBackOut() {
      this.backShow = false;
    },
    onNextOut() {
      this.nextShow = false;
    },
    // 页面顺时针翻转90度。
    clock() {
      this.pageRotate += 90;
    },
    // 页面逆时针翻转90度。
    counterClock() {
      this.pageRotate -= 90;
    },
    // 页面加载回调函数，其中e为当前页数
    pageLoaded(e, i) {
      if (e) {
        this.loading = false;
        this.toolShow = true;
        this.curPageNum = e;
      }
    },
    // 其他的一些回调函数。
    pdfError(error) {
      console.error(error);
    },
  },
};
</script>

<style scoped lang="scss">
.tool {
  width: 100%;
  height: 100%;
  position: relative;

  .back {
    position: absolute;
    height: 100%;
    width: 50%;
    z-index: 100;
    left: 0;
  }
  .next {
    position: absolute;
    height: 100%;
    width: 50%;
    z-index: 100;
    right: 0;
  }
  .page_turn {
    width: 120px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    background-color: rgba(black, 0.3);
    position: absolute;
    top: 50%;
  }
  .nextbtn {
    right: 0;
  }
  .backbtn {
    left: 0;
  }
}
.page {
  line-height: 30px !important;
  text-align: center !important;
}
</style>
