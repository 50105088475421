var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c("div", { staticClass: "container" }, [_vm._t("default")], 2),
    _c("div", { staticClass: "bottom-box" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.lastPathShow,
              expression: "lastPathShow",
            },
          ],
          staticClass: "button",
          on: { click: _vm.handleLastStep },
        },
        [_vm._v(" 上一步 ")]
      ),
      _c("div", { staticClass: "button", on: { click: _vm.handleNextStep } }, [
        _vm._v("下一步"),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }