<template>
  <div>
    <vue-core-video-player
      :src="videoUrl"
      :cover="cover"
      :title="title"
      :core="HLSCore"
      autoplay
      :loop="true"
      style="height: 540px; width: 720px"
    />
  </div>
</template>
<script>
import HLSCore from "@core-player/playcore-hls";
export default {
  name: "videoPreview",
  data() {
    return {
      videoId: "",
      videoUrl: "",
      HLSCore,
      cover: "",
      title: "",
    };
  },
  mounted() {
    this.videoId = this.$route.params.videoId;
    console.log(this.videoId);
    this.videoUrl =
      process.env.VUE_APP_BASE_API +
      `/api/videos/HLS/play-video?videoId=${this.videoId}`;
    console.log(this.videoUrl);
  },
};
</script>
