<template>
  <div class="popup-wrap">
    <div class="title">个人收藏</div>
    <div class="content">
      <!-- <el-input type="text" />  -->
      <el-row :gutter="10" class="row">
        <el-col :span="8" class="col">
          <el-tree
            :data="data"
            node-key="id"
            draggable
            default-expand-all
            :highlight-current="true"
            :expand-on-click-node="false"
          >
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span @click="onCheckChange(data)">
                <span
                  :title="data.remark"
                  style="
                    display: block;
                    max-width: 110px !important;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                  "
                >
                  {{
                    data.prescriptionName ? data.prescriptionName : node.label
                  }}
                </span>
              </span>
              <span>
                <el-dropdown>
                  <span class="el-dropdown-link">
                    <i class="el-icon-more-outline"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      @click.native.stop="editHandle(node, data)"
                      v-show="data.prescriptionName"
                    >
                      编辑
                    </el-dropdown-item>
                    <el-dropdown-item @click.native.stop="remove(node, data)">
                      删除
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </span>
            </span>
          </el-tree>
        </el-col>
        <el-col :span="16" class="col">
          <div class="info-box" v-if="isEdit">
            <div class="top">
              <el-input v-model="yaofangInfo.prescriptionName"></el-input>
              <div class="key">药方</div>
              <div class="value">
                <div class="kf">
                  <div
                    class="yf-item"
                    v-for="(item, index) in yaofangInfo.prescriptionZcStdJson"
                    :key="index"
                  >
                    <div class="yt-item-name">
                      <input
                        v-model="item.n"
                        :style="{
                          width: item.n ? item.n.length * 14 + 'px' : '30px',
                        }"
                      />
                    </div>
                    <div class="yt-item-number">
                      <input type="number" v-model="item.v" />
                    </div>
                    <div class="yt-item-g"><input v-model="item.dw" /></div>
                    <div class="yt-item-remove" @click="removeF(index)">
                      <i class="el-icon-close"></i>
                    </div>
                  </div>
                  <div class="add_plus" @click="addPrescription">
                    <i class="el-icon-plus" />
                  </div>
                </div>
              </div>

              <div class="key">用法、制法</div>

              <el-input v-model="yaofangInfo.prescriptionZhifa"></el-input>
            </div>
            <div class="button" @click.stop="save">保存</div>
          </div>
          <div class="info-box" v-else v-show="yaofangInfo.prescriptionName">
            <div class="top">
              <div class="title box-title">
                <div>{{ yaofangInfo.prescriptionName || "-" }}</div>
              </div>
              <div class="key">药方</div>
              <div class="value">
                {{ yaofangInfo.prescriptionDetail || "-" }}
              </div>

              <div class="key">用法、制法</div>
              <div class="value">
                {{ yaofangInfo.prescriptionZhifa || "-" }}
              </div>
            </div>
            <div class="button" @click.stop="reuse">复用</div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import {
  deteleCollect,
  getFavoritesList,
  saveFavorites,
} from "@/api/zkyz/aictm";
let id = 1000;
export default {
  name: "popup-shoucang",
  props: {},
  data() {
    const data = [];
    return {
      data: JSON.parse(JSON.stringify(data)),
      isEdit: false,
      yaofangInfo: {
        prescriptionZcStdJson: [],
      },
    };
  },
  mounted() {},
  methods: {
    getList() {
      getFavoritesList().then((res) => {
        this.data = res.data;
      });
    },
    editHandle(node, data) {
      this.isEdit = true;
      this.yaofangInfo = JSON.parse(JSON.stringify(data));
    },
    onCheckChange(e) {
      this.isEdit = false;
      if (!Array.isArray(e.prescriptionZcStdJson)) {
        e.prescriptionZcStdJson = [];
      }
      this.yaofangInfo = JSON.parse(JSON.stringify(e));
    },
    /**
     * 删除药
     */
    removeF(index) {
      this.yaofangInfo.prescriptionZcStdJson.splice(index, 1);
    },
    /**
     * 添加药
     */
    addPrescription() {
      this.yaofangInfo.prescriptionZcStdJson.push({
        n: null,
        v: null,
        dw: "g",
        fw: null,
        b: 0,
      });
    },
    //删除收藏药方
    remove(node, data) {
      // console.log(data.children.length > 0);
      if (data.children.length > 0) {
        this.$message.error("无法删除非空文件夹");
        return;
      } else {
        deteleCollect(data.id).then((res) => {
          if (res.code === 200) {
            this.$message.success(res.msg);
            this.getList();
            this.yaofangInfo = {};
          }
        });
      }
    },
    reuse() {
      this.$emit("reusefList", this.yaofangInfo.prescriptionZcStdJson);
    },
    save() {
      let arr = this.yaofangInfo.prescriptionZcStdJson.map((item) => {
        return item.n + item.v + item.dw;
      });
      let str = arr.toString();
      this.yaofangInfo.prescriptionDetail = str;
      saveFavorites(this.yaofangInfo).then((res) => {
        if (res.code === 200) {
          this.$message.success(res.msg);
          this.getList();
          this.yaofangInfo = {};
          this.isEdit = false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.popup-wrap {
  display: flex;
  flex-direction: column;
  font-family: cursive;
  .title {
    font-size: 20px;
    line-height: 20px;
    height: 20px;
    font-weight: bold;
    color: #ae7000;
  }
  .content {
    font-family: Source Han Sans SC;
    .row {
      display: flex;
      gap: 10px;
      margin-top: 10px;
      .col {
        border: 1px solid #dadada;
        border-radius: 2px;
        min-height: 300px;
        overflow: auto;
        .key {
          font-weight: bold;
          font-size: 16px;
          color: #626366;
          line-height: 16px;
          margin: 10px 0;
          font-family: Microsoft YaHei;
        }
        .value {
          font-weight: 400;
          font-size: 12px;
          color: #000000;
        }
        .box-title {
          display: flex;
          justify-content: space-between;
          height: auto;
          line-height: 24px;
        }
        .info-box {
          height: 100%;
          display: flex;
          flex-direction: column;
          padding: 10px;
          overflow-y: auto;
          .top {
            flex: 1;
          }
          .button {
            height: 30px;
            margin-top: 10px;
            align-self: flex-end;
          }
        }
      }
    }
  }
}

.kf {
  width: 100%;
  height: 216px;
  padding: 0 8px 8px 8px;
  box-sizing: border-box;
  overflow-y: scroll;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  background-size: 100% 100%;

  .yf-item {
    min-height: 30px;
    margin-top: 8px;
    margin-right: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 4px;
    border: 1px solid var(--main-border-color);
    width: fit-content;
    border-radius: 4px;

    input {
      background: rgba(0, 0, 0, 0);
    }

    .yt-item-name {
      font-weight: 500;
      font-size: 16px;
      color: black;
      border-bottom: 1px dashed gray;

      input {
        border: none;
        outline: none;
        min-width: 40px;
        width: auto;
        text-align: center;
      }
    }

    .yt-item-number {
      border-bottom: 1px dashed gray;
      margin: 0 10px;
      font-size: 16px;

      input {
        border: none;
        outline: none;
        width: 25px;
        text-align: center;
      }
    }

    .yt-item-g {
      font-weight: 500;
      font-size: 16px;
      color: black;
      border-bottom: 1px dashed gray;

      input {
        border: none;
        outline: none;
        width: 25px;
        text-align: center;
      }
    }

    .yt-item-remove {
      width: 20px;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background: #ad5800;
      font-size: 12px;
      cursor: pointer;
      color: white;
      margin-left: 4px;
    }

    &.red {
      border-color: red;

      .yt-item-remove {
        background: red;
      }

      .yt-item-g {
        border-color: red;
      }

      .yt-item-number {
        border-color: red;
      }

      .yt-item-name {
        border-color: red;
      }
    }

    &.green {
      border-color: green;

      .yt-item-remove {
        background: green;
      }

      .yt-item-g {
        border-color: green;
      }

      .yt-item-number {
        border-color: green;
      }

      .yt-item-name {
        border-color: green;
      }
    }
  }

  .add_plus {
    width: 32px;
    height: 32px;
    background: #ad5800;
    border-radius: 4px;
    text-align: center;
    line-height: 32px;
    margin-top: 8px;
  }
}
::v-deep {
  .custom-tree-node {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
