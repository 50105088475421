<template>
  <!-- layout布局 -->
  <div class="app-container">
    <div class="container">
      <slot> </slot>
    </div>
    <div class="bottom-box">
      <div v-show="lastPathShow" class="button" @click="handleLastStep">
        上一步
      </div>
      <div class="button" @click="handleNextStep">下一步</div>
    </div>
  </div>
</template>
<script>
import titleVue from "@/views/sizhen/components/title";

export default {
  name: "sizhenLayout",
  components: { titleVue },
  props: {
    lastPath: {
      type: String,
      default: "",
      require: false,
    },
    nextPath: {
      type: String,
      default: "",
      require: false,
    },
    lastPathShow: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      title: "诊断结果",
    };
  },
  methods: {
    handleLastStep() {
      this.$emit("handleLastStep");
    },
    handleNextStep() {
      this.$emit("handleNextStep");
    },
  },
};
</script>
<style scoped lang="scss">
.app-container {
  width: 100%;
  height: calc(100vh - 84px);
  background-color: #eef1f6;
  padding: 16px;
  user-select: none;
  .bottom-box {
    height: 60px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    background: #ffffff;
    box-shadow: 0px -9px 18px 0px rgba(1, 23, 51, 0.14);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .button {
      height: 34px;
      min-width: 90px;
      max-width: 120px;
      background: #e6f0fc;
      border-radius: 20px;
      font-weight: 400;
      font-size: 14px;
      text-align: center;
      line-height: 34px;
      color: #0166e2;
      margin-right: 20px;
    }
  }
}
</style>
