import request from '@/utils/request'

//质控list请求
export function getzhikongList(data) {
  return request({
    url: `/zmPeopleHospital/crud/zhikong/list`,
    method: 'get',
    data: data
  })
}

//质控查询
export function getzhikongSelect(data) {
  return request({
    url: `/zmPeopleHospital/crud/zhikong/select`,
    method: 'post',
    data: data
  })
}

//审核通过
export function getzhikongPass(data) {
  return request({
    url: `/zmPeopleHospital/crud/zhikong/${data}`,
    method: 'post'
  })
}
