<template>
  <!-- 诊断结果页面 -->
  <sizhen-layout>
    <div>sssssss</div>
  </sizhen-layout>
</template>
<script>
import sizhenLayout from "@/views/sizhen/components/layout";

export default {
  components: { sizhenLayout },
  data() {
    return {
      title: "诊断结果",
    };
  },
};
</script>
<style scoped lang="scss">

</style>
