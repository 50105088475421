var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "sizhen-header" },
        [
          _c("img", {
            attrs: { src: require("../../assets/logo/logo.png"), alt: "" },
            on: {
              click: function ($event) {
                return _vm.goHome()
              },
            },
          }),
          _c(
            "el-steps",
            {
              attrs: {
                active: _vm.sizhenData.active,
                "finish-status": "success",
                simple: "",
              },
            },
            _vm._l(_vm.stepTitle, function (item, index) {
              return _c("el-step", { key: index, attrs: { title: item } })
            }),
            1
          ),
        ],
        1
      ),
      _c("sizhen-info", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.sizhenData.active == "0",
            expression: "sizhenData.active == '0'",
          },
        ],
        on: {
          handleNextStep: function ($event) {
            return _vm.handleNextStep()
          },
        },
      }),
      _c("sizhen-tizhi", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.sizhenData.active == "1",
            expression: "sizhenData.active == '1'",
          },
        ],
        on: {
          handleLastStep: function ($event) {
            return _vm.handleLastStep()
          },
          handleNextStep: function ($event) {
            return _vm.handleNextStep()
          },
        },
      }),
      _c("sizhen-shezhen", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.sizhenData.active == "2",
            expression: "sizhenData.active == '2'",
          },
        ],
        on: {
          handleLastStep: function ($event) {
            return _vm.handleLastStep()
          },
          handleNextStep: function ($event) {
            return _vm.handleNextStep()
          },
        },
      }),
      _c("ai-analyse", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.sizhenData.active == "3",
            expression: "sizhenData.active == '3'",
          },
        ],
        on: {
          handleLastStep: function ($event) {
            return _vm.handleLastStep()
          },
          handleNextStep: function ($event) {
            return _vm.handleNextStep()
          },
        },
      }),
      _c("print", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.sizhenData.active == "4",
            expression: "sizhenData.active == '4'",
          },
        ],
        on: {
          handleLastStep: function ($event) {
            return _vm.handleLastStep()
          },
          handleNextStep: function ($event) {
            return _vm.handleNextStep()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }