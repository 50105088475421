var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%", width: "100%" } },
    [
      _c(
        "vue-seamless-scroll",
        {
          ref: "seamlessScroll",
          staticClass: "content",
          attrs: { data: _vm.doc, "class-option": _vm.classOption },
        },
        [
          _c(
            "ul",
            _vm._l(_vm.doc, function (item, index) {
              return _c("li", { key: index }, [
                _c("div", { staticClass: "item_all" }, [
                  _c("div", { staticClass: "item" }, [
                    _c("img", {
                      attrs: {
                        src: require("../../../assets/visualScreenImg/default_doc.png"),
                        alt: "",
                      },
                    }),
                    _c("div", { staticClass: "item_title" }, [
                      _c("p", { staticClass: "doc_name" }, [
                        _vm._v(" " + _vm._s(item.name)),
                        _c("span", { staticClass: "doc_post" }, [
                          _vm._v(_vm._s(item.post)),
                        ]),
                      ]),
                      _c("p", { staticClass: "doc_units" }, [
                        _vm._v(_vm._s(item.units)),
                      ]),
                    ]),
                  ]),
                ]),
              ])
            }),
            0
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }