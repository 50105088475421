<template>
  <sizhen-layout
    @handleLastStep="handleLastStep()"
    @handleNextStep="handleNextStep()"
  >
    <div class="content">
      <div class="item-box">sss</div>
    </div>
  </sizhen-layout>
</template>

<script>
import sizhenLayout from "@/views/sizhen/components/layout";
import { mapActions, mapState } from "vuex";
export default {
  name: "sizhen-tizhi",
  components: { sizhenLayout },
  data() {
    return {};
  },
  computed: {
    ...mapState(["sizhenData"]),
  },
  methods: {
    ...mapActions(["saveInfo", "touchActive"]),

    handleNextStep() {
      this.$emit("handleNextStep");
    },
    handleLastStep() {
      this.touchActive(3);
      this.$emit("handleLastStep");
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  width: 100%;
  height: calc(100vh - 120px);
  display: flex;
  justify-content: center;
  align-items: center;
  .item-box {
    width: 1200px;
    min-height: 600px;
    max-height: 700px;
    background: #ffffff;
    border-radius: 16px;
    color: #323233;
    padding: 16px;
    display: flex;
    flex-direction: column;
    ol {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      list-style-position: inside;
      padding: 0;
      li {
        font-family: Microsoft YaHei;
        font-weight: bold;
        font-size: 24px;
        width: 100%;
        flex: 1;
        text-align: center;
        line-height: 100px;
      }
      li:hover {
        color: #0166e2;
        background: RGBA(229, 239, 252, 1);
        border-radius: 8px;
      }
    }
    .title {
      font-family: Microsoft YaHei;
      font-weight: bold;
      font-size: 24px;
      color: #323233;
      line-height: 24px;
    }
    .tizhi-name {
      line-height: 60px;
      color: #0166e2;
      background: RGBA(229, 239, 252, 1);
      border-radius: 8px;
      text-align: center;
      margin-top: 10px;
      font-size: 24px;
    }
    .tizhi-desc {
      height: 100%;
      padding: 16px;
      overflow-y: auto;
    }
    .tizhi-desc > div {
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 10px;
      .desc-item {
        font-weight: 400;
        color: #0166e2;
        font-size: 14px;
      }
    }
  }
}
.container {
  width: 50%;
  margin: 0 auto;
}
.begin-btn {
  width: 220px;
  height: 84px;
  background: #ffffff;
  border-radius: 16px;
  line-height: 84px;
  text-align: center;
  color: #0166e2;
  font-weight: bold;
  font-size: 24px;
}
</style>
