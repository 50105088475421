<template>
  <div style="height: 100%; width: 100%">
    <vue-seamless-scroll
      :data="doc"
      :class-option="classOption"
      class="content"
      ref="seamlessScroll"
    >
      <ul>
        <li v-for="(item, index) in doc" :key="index">
          <div class="item_all">
            <div class="item">
              <img
                src="../../../assets/visualScreenImg/default_doc.png"
                alt=""
              />
              <div class="item_title">
                <p class="doc_name">
                  {{ item.name }}<span class="doc_post">{{ item.post }}</span>
                </p>
                <p class="doc_units">{{ item.units }}</p>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </vue-seamless-scroll>
  </div>
</template>

<script>
import vueSeamlessScroll from "vue-seamless-scroll";
import { getVisualHisDoctorInfoList, gethisDataSync } from "@/api/visualScreen";

export default {
  components: { vueSeamlessScroll },
  data() {
    return {
      timer: null,
      doc: [],
      classOption: {
        step: 0.3,
        limitMoveNum: 1,
        autoPlay: true,
      },
    };
  },
  created() {},
  computed: {},
  mounted() {
    this.getData();
    this.timer = setInterval(() => {
      this.getData();
    }, 300000); // 每5秒发送一次请求
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    getData() {
      let params = {
        pageNum: 1,
        pageSize: 10,
      };
      gethisDataSync(params, 2)
        .then((res) => {
          let data = JSON.parse(res.data[0].data).rows;
          this.doc = data;
        })
        .catch((err) => {
          this.doc = [
            {
              name: "李**",
              post: "主治医师",
              units: "风湿免疫肾病内科门诊",
            },
            {
              name: "杜**",
              post: "医师",
              units: "整形外科门诊",
            },
            {
              name: "张**",
              post: "主治医师",
              units: "耳鼻咽喉科门诊",
            },
            {
              name: "韩*",
              post: "医师",
              units: "围产期保健门诊",
            },
            {
              name: "吴**",
              post: "主治医师",
              units: "产科门诊",
            },
            {
              name: "靳**",
              post: "主治医师",
              units: "胸外科门诊",
            },
            {
              name: "宋**",
              post: "副主任医师",
              units: "儿科门诊",
            },
            {
              name: "马**",
              post: "主任医师",
              units: "消化内科门诊",
            },
            {
              name: "陈**",
              post: "医师",
              units: "口腔科门诊",
            },
            {
              name: "衡**",
              post: "主治医师",
              units: "全科医学科门诊",
            },
          ];
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.item_all {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
}

.content {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

ul {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;

  li {
    width: 50%;
    height: 100%;
    display: block;
    margin-bottom: 15px;
  }
}

.item {
  display: flex;
  width: 240px;
  height: 60px;
  justify-content: flex-start;

  img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 0 30px;
  }

  .item_title {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    min-width: 90px;

    p {
      margin: 0;
    }

    height: 60px;

    .doc_name,
    .doc_units {
      font-size: 14px;
      color: #38afb0;

      .doc_post {
        font-size: 14px;
        color: #d4dbde;
      }
    }
  }
}
</style>
