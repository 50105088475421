import onlineTime from "@/tools/impl/OnlineTime";
import prescription from "@/tools/impl/Prescription";
import buttonClick from "@/tools/impl/ButtonClick";
import moduleClick from "@/tools/impl/ModuleClick";

window.addEventListener('unload', close);
document.addEventListener('mousemove', (e) => {
  active();
})
document.addEventListener('keydown', (e) => {
  active();
})

/**
 * 埋点类
 */
export default {
  // 初始化
  init,
  // 退出登录
  logout,
  // 活跃
  active
}


/**
 * 初始化埋点
 * @param source 来源： index 首页，aitcm 诊断
 */
function init(source) {
  console.log('初始化', source)
}


/**
 * 退出登录
 */
function logout() {
  onlineTime.report();
  prescription.report();
}

/**
 * 关闭网页前
 */
function close() {
  onlineTime.report();
  prescription.report();
  buttonClick.report();
  moduleClick.report();
}

/**
 * 活跃
 */
function active() {
  onlineTime.start();
}
