var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "block" },
    [
      _c(
        "el-carousel",
        {
          attrs: {
            height: "240px",
            "indicator-position": "none",
            arrow: "never",
            interval: 60000,
          },
        },
        _vm._l(_vm.liuxingbingList, function (item, index) {
          return _c("el-carousel-item", { key: index }, [
            _c("ul", [
              _c("li", [
                _c("div", { staticClass: "item_name" }, [
                  _vm._v(" " + _vm._s(item.name) + " "),
                ]),
              ]),
              _c("li", [_vm._v(" 表现：" + _vm._s(item.biaoxian1) + " ")]),
              _c("li", [
                _c("div", { staticClass: "item_fangjiname" }, [
                  _vm._v(" 方剂推荐：" + _vm._s(item.fangjiname1) + " "),
                ]),
              ]),
              _c("li", [
                _c("div", { staticClass: "item_yongfa" }, [
                  _vm._v(" 用法：" + _vm._s(item.yongfa1) + " "),
                ]),
              ]),
            ]),
          ])
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }