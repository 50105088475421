<template>
  <div class="qr-page">
    <el-form
      ref="elForm"
      :model="formData"
      :rules="rules"
      size="medium"
      label-width="auto"
      v-show="showForm"
    >
      <h3 style="text-align: center">请填写信息</h3>
      <el-form-item label="姓名" prop="name">
        <el-input
          v-model="formData.name"
          placeholder="请输入姓名"
          clearable
          :style="{ width: '100%' }"
        ></el-input>
      </el-form-item>
      <el-form-item label="年龄" prop="age">
        <el-input
          v-model.number="formData.age"
          placeholder="请输入年龄"
          clearable
          :style="{ width: '100%' }"
        ></el-input>
      </el-form-item>
      <el-form-item label="性别" prop="gender">
        <el-radio-group v-model="sex" @change="genderChange">
          <el-radio :label="1">男性</el-radio>
          <el-radio :label="2">女性</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="手机号" prop="cellphone">
        <el-input
          v-model="formData.cellphone"
          placeholder="请输入手机号"
          clearable
          :style="{ width: '100%' }"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="出生日期" prop="birthday">
        <el-date-picker
          v-model="formData.birthday"
          type="date"
          style="width: 100%"
          placeholder="请输入出生日期"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="身份证号" prop="idcard">
        <el-input
          v-model="formData.idcard"
          placeholder="请输入身份证号"
          clearable
          :style="{ width: '100%' }"
        >
        </el-input>
      </el-form-item>

      <el-form-item size="large">
        <el-button type="primary" size="small" @click="submitForm"
          >提交</el-button
        >
        <el-button size="small" @click="resetForm">重置</el-button>
      </el-form-item>
    </el-form>
    <div v-show="!showForm">
      <div style="display: flex; flex-direction: column">
        <h3 style="text-align: center">信息列表</h3>
        <el-button
          type="number"
          size="small"
          @click="adduser"
          style="align-self: flex-end"
          >新增</el-button
        >
      </div>

      <el-divider></el-divider>

      <div v-for="(item, index) in infoList" :key="index">
        <span> 推送设备：</span>
        <el-select v-model="item.deviceId" placeholder="请选择推送设备">
          <el-option
            v-for="item in deviceIdList"
            :key="item.deviceId"
            :label="item.descs"
            :value="item.deviceId"
          >
          </el-option>
        </el-select>
        <el-descriptions class="margin-top" :column="3" size="small">
          <template slot="extra">
            <el-button type="primary" size="small" @click="deleteItem(item)"
              >删除
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="generateCode(item.userId)"
              >生成二维码
            </el-button>
            <el-button type="primary" size="small" @click="pushItem(item)"
              >推送
            </el-button>
          </template>
          <el-descriptions-item>
            <template slot="label"> 姓名 </template>
            {{ item.name }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 年龄 </template>
            {{ item.age }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 性别 </template>
            {{ item.gender }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 出生日期 </template>
            {{ item.birthday }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 手机号 </template>
            {{ item.cellphone }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 身份证号 </template>
            {{ item.idcard }}
          </el-descriptions-item>
        </el-descriptions>
        <el-divider></el-divider>
      </div>
    </div>

    <el-dialog title="二维码" :visible.sync="tipsShow" width="250px">
      <div id="myQrCode" ref="myQrCode"></div>
      <p style="text-align: center">长按二维码保存到本地</p>
    </el-dialog>
  </div>
</template>
<script>
import QRCode from "qrcodejs2";
import {
  pushData,
  getLsit,
  saveForm,
  deleteForm,
  getAssessToken,
} from "@/api/weixin";
import { getDeviceList } from "@/api/sizhen/sizhen";
export default {
  name: "info-QRcode",
  components: {},
  props: [],
  data() {
    return {
      appid: "wxae1f0b98fdd3fe19",
      infoList: [],
      showForm: true,
      formData: {
        name: "",
        age: "",
        gender: "",
        cellphone: "",
        birthday: "",
        idcard: "",
      },
      sex: "",
      userId: "",
      deviceIdList: [],
      deviceId: "",
      unionId: "",
      qrdata: "",
      tipsShow: false, //提示文字展示开关
      rules: {
        name: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
        ],
        age: [
          {
            required: true,
            message: "请输入年龄",
            trigger: "blur",
            type: "number",
          },
        ],
        gender: [
          {
            required: true,
            message: "请输入性别",
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {},
  watch: {},
  created() {
    const ua = navigator.userAgent.toLowerCase();
    const redirectUrl = encodeURIComponent(window.location.href);
    const url = this.getUrlCode();
    if (ua.match(/MicroMessenger/i)) {
      const isWeixin = ua
        .match(/MicroMessenger/i)
        .some((item) => item === "micromessenger");
      if (isWeixin) {
        if (Object.keys(url).length) {
          // 解决code 5分钟后失效
          // 有code 判断code是否与本地一致，一致则重新获取
          if (url.code === sessionStorage.getItem("wxCode")) {
            window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.appid}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo#wechat_redirect&connect_redirect=1`;
          } else {
            // code不一致则重新存入本地进行授权
            sessionStorage.setItem("wxCode", url.code);
            this.getWxUserInfo(url.code);
          }
        } else {
          window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.appid}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo#wechat_redirect&connect_redirect=1`;
        }
      }
    } else {
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.appid}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo#wechat_redirect&connect_redirect=1`;
    }
  },
  mounted(query) {
    this.getdeviceIdList();
  },
  methods: {
    getdeviceIdList() {
      getDeviceList().then((res) => {
        this.deviceIdList = res.rows;
      });
    },
    getUrlCode() {
      var url = document.location.search;
      var theRequest = {};
      if (url.indexOf("?") !== -1) {
        var str = url.substring(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
      return theRequest;
    },
    getWxUserInfo(code) {
      getAssessToken({ code }).then((res) => {
        if (res.code === 200) {
          this.unionId = res.data.openid;
          this.formData.name = res.data.nickname;
          this.formData.gender = res.data.sex == 1 ? "男" : "女";
          this.getList();
        }
      });
      //测试用
      // axios
      //   .post(
      //     `https://api.weixin.qq.com/sns/oauth2/access_token?appid=${this.appid}&secret=59f05fb260f44185aa7b493f927c0669&code=${code}&grant_type=authorization_code`
      //   )
      //   .then((res) => {
      //     if (res.code === 200) {
      //       this.unionId = res.data.openid;
      //       this.formData.name = res.data.nickname;
      //       this.formData.gender = res.data.sex == 1 ? "男" : "女";
      //       this.getList();
      //     }
      //   });
    },
    genderChange(e) {
      console.log(e);
      if (e == 1) {
        this.formData.gender = "男";
      } else if (e == 2) {
        this.formData.gender = "女";
      }
    },
    adduser() {
      this.showForm = true;
      this.formData = {
        name: "",
        age: "",
        gender: "",
        cellphone: "",
        birthday: "",
        idcard: "",
      };
    },
    getList() {
      getLsit({ unionId: this.unionId }).then((res) => {
        if (res.code === 200) {
          this.infoList = res.data;
          if (res.data.length > 0) {
            this.showForm = false;
          } else {
            this.showForm = true;
          }
        }
      });
    },
    pushItem(item) {
      if (!item.deviceId) {
        this.$message({
          message: "请选择设备",
          type: "warning",
        });
        return;
      }
      pushData({
        deviceId: item.deviceId,
        userId: item.userId,
      }).then((res) => {
        this.$message({
          message: "推送成功",
          type: "success",
        });
      });
    },
    deleteItem(item) {
      deleteForm(item.userId).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.getList();
        }
      });
    },
    submitForm() {
      this.$refs["elForm"].validate((valid) => {
        if (!valid) return;
        // TODO 提交表单
        this.formData.unionId = this.unionId;
        saveForm(this.formData).then((res) => {
          console.log(res);
          if (res.code === 200) {
            this.$message({
              message: "保存成功",
              type: "success",
            });
            this.getList();
          }
        });
      });
    },
    resetForm() {
      this.$refs["elForm"].resetFields();
    },
    generateCode(id) {
      this.tipsShow = true;

      this.$nextTick(() => {
        document.getElementById("myQrCode").innerHTML = ""; //每次生成的时候清空内容，否则会叠加，二维码背景色透明会一目了然
        try {
          const qrcode = new QRCode("myQrCode", {
            width: 200, //宽度
            height: 200, // 高度
            text: `${id}`, // 二维码内容，也可以通过接口获取'
          });
        } catch (e) {
          console.log(e);
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
body::-webkit-scrollbar {
  display: none;
}
.qr-page {
  box-sizing: border-box;
  padding: 16px;
  #myQrCode {
    width: 200px;
    height: 200px;
    margin: 20px auto;
  }
}
</style>
