<template>
  <div class="container">
    <div class="top">
      <img class="fixed" style="z-index: 2" src="../../../assets/visualScreenImg/fixed_bottom.png" alt=""/>
      <img class="rotate rotate-high" src="../../../assets/visualScreenImg/yuan1.png" alt=""/>
      <img class="rotate" src="../../../assets/visualScreenImg/yuan2.png" alt=""/>
      <img class="fixed yuanpan" id="yuanpan" src="../../../assets/visualScreenImg/yuan3.png" alt=""/>
      <img class="rotate" src="../../../assets/visualScreenImg/yuan4.png" alt=""/>
      <img class="rotate rotate-low" src="../../../assets/visualScreenImg/yuan5.png" alt=""/>
      <img class="rotate rotate-high" src="../../../assets/visualScreenImg/yuan6.png" alt=""/>
      <div class="ziwuliu_box" v-for="item in ziwuliuList" v-show="item.shichen ===shichen">
        <img :src="item.imgUrl" alt=""/>
        <div>{{item.name}}</div>
        <div>{{ item.desc}}当令</div>
      </div>
    </div>
    <div class="bottom">
      <img src="../../../assets/visualScreenImg/fixed_bottom.png" alt=""/>
    </div>
  </div>
</template>
<script>
export default {
  name: 'center-top',
  props:{
    shichen: {
      type: String,
      default: null
    },
  },
  watch:{
    shichen(val){
      let element = document.getElementById("yuanpan"); // "myElement"为元素的id或其他选择器
      this.ziwuliuList.map((item)=>{
        if(val===item.shichen){
          element.style.transform=item.rotate;
          this.$emit('shichenData',item)
        }
      })
    }
  },
  data() {
    return {
      ziwuliuList:[
        {
          name:'足少阳',
          datetime:'23点至1点',
          shichen:'子时',
          desc:'胆经',
          explain:'子时睡得足，黑眼圈不露；中医理论认为；“肝之余气，泄于明胆，聚而成精。”人在子时前入眠，胆方能完成代谢。“胆汁有多清，脑就有多清。”子时前入睡者，晨醒后头脑清晰、气色红润，没有黑眼圈。反之，常于子时内不能入睡者，则气色青白，眼眶昏黑。',
          zhengzhuang:'口干口苦，偏头痛，容易惊悸；善叹息，便溏便秘，皮肤萎黄；消化不良，关节痛脂肪瘤；痰湿结节积聚，因胆汁排毒代谢不良更容易生成结晶、结石。',
          means:'拍打通用部位+胆经+肝经+头面部+用拉筋凳拉筋',
          imgUrl:require('../../../assets/visualScreenImg/dan.png'),
          imgUrl2:require('../../../assets/visualScreenImg/zishi.png'),
          rotate:'rotate(0deg)'
        },
        {
          name:'足厥阴',
          datetime:'1点至3点',
          shichen:'丑时',
          desc:'肝经',
          explain:'丑时不睡晚，脸上不长斑；中医理论认为：“肝藏血。”“人卧则血归于肝。”如果丑时不能入睡，肝脏还在输出能量支持人的思维和行动，就无法完成新陈代谢。所以丑时前未能入睡者，面色青灰，情志怠慢而躁，易生肝病，脸色晦暗长斑。',
          zhengzhuang:'口干口苦，情志抑郁，胸胁胀痛；眩晕，血压不稳，易怒冲动；皮肤萎黄，易倦乏力，前列腺肥大；月经不调，乳房疾病，小便黄。',
          means:'拍打通用部位+肝经+腹股沟+腋窝+两肋+用拉筋凳拉筋',
          imgUrl:require('../../../assets/visualScreenImg/gan.png'),
          imgUrl2:require('../../../assets/visualScreenImg/choushi.png'),
          rotate:'rotate(30deg)'
        },
        {
          name:'手太阴',
          datetime:'3点至5点',
          shichen:'寅时',
          desc:'肺经',
          explain:'寅时睡得熟，色红精气足；“肺朝百脉。”肝在丑时把血液推陈出新之后，将新鲜血液提供给肺，通过肺送往全身。所以，人在清晨面色红润，精力充沛。寅时，有肺病者反映最为强烈，如剧咳或哮喘而醒。',
          zhengzhuang:'怕风易汗，咽干咳嗽；过敏性鼻炎，皮肤干燥容易过敏；动则气短胸翳面色皮肤无华。',
          means:'拍打通用部位+肺经+前胸+后背+头面部+用拉筋凳拉筋',
          imgUrl:require('../../../assets/visualScreenImg/fei.png'),
          imgUrl2:require('../../../assets/visualScreenImg/yanshi.png'),
          rotate:'rotate(60deg)'
        },
        {
          name:'手阳明',
          datetime:'5点至7点',
          shichen:'卯时',
          desc:'大肠经',
          explain:'卯时大肠蠕，排毒渣滓出；“肺与大肠相表里。”肺将充足的新鲜血液布满全身，紧接着促进大肠进入兴奋状态，完成吸收食物中的水分和营养、排出渣滓的过程。清晨起床后最好排大便。',
          zhengzhuang:'牙痛头痛，口干，皮肤过敏；青筋斑点多，肠胃功能减弱；肩周痛慢性咽喉炎。',
          means:'拍打通用部位+大肠经+肺经+肚子+用拉筋凳拉筋',
          imgUrl:require('../../../assets/visualScreenImg/dachang.png'),
          imgUrl2:require('../../../assets/visualScreenImg/maoshi.png'),
          rotate:'rotate(90deg)'
        },
        {
          name:'足阳明',
          datetime:'7点至9点',
          shichen:'辰时',
          desc:'胃经',
          explain:'辰时吃早餐，营养身体安；人在此时段吃早餐最容易消化，吸收也最好。早餐可安排温和养胃的食品如稀粥、麦片、包点等。过于燥热的食品容易引起胃火盛，出现嘴唇干裂、唇疮等问题。',
          zhengzhuang:'咽喉痛，胃痛，怕热，消化不良；倦怠，膝关节酸痛，便秘；唇干舌燥，身体消瘦。',
          means:'拍打通用部位+胃经+肚子+头面部+腹股沟+用拉筋凳拉筋',
          imgUrl:require('../../../assets/visualScreenImg/wei.png'),
          imgUrl2:require('../../../assets/visualScreenImg/chenshi.png'),
          rotate:'rotate(120deg)'
        },        {
          name:'足太阴',
          datetime:'9点至11点',
          shichen:'巳时',
          desc:'脾经',
          explain:'巳时脾经旺，造血身体状；“脾主运化，脾统血。”脾是消化、吸收、排泄的总调度，又是人体血液的统领。“脾开窍于口，其华在唇。”脾的功能好，消化吸收好，血液质量好，所以嘴唇是红润的。唇白标志血气不足，唇暗、唇紫标志寒入脾经。',
          zhengzhuang:'脘腹胀气，吸收不良，口淡；容易呕吐作闷，容易倦怠虚肥；头胀，头脑不清，湿重脚肿便溏；关节酸胀，糖尿病。',
          means:'拍打通用部位+头面部+肚子+脾经+胃经+腹股沟+用拉筋凳拉筋',
          imgUrl:require('../../../assets/visualScreenImg/pi.png'),
          imgUrl2:require('../../../assets/visualScreenImg/sishi.png'),
          rotate:'rotate(150deg)'
        },
        {
          name:'手少阴',
          datetime:'11点至13点',
          shichen:'午时',
          desc:'心经',
          explain:'午时一小憩，安神养精气；“心主神明，开窍于舌，其华在面。”心气推动血液运行，养神、养气、养筋。人在午时能睡片段，对于养心大有好处，并使下午至晚上精力充沛。',
          zhengzhuang:'心烦、心惊、心悸、心闷、心痛；短气，上气有压力感，忧郁易怒；口腔溃疡，口干口臭。',
          means:'拍打通用部位+心经、心包经（整个手臂内侧）+头面部+用拉筋凳拉筋',
          imgUrl:require('../../../assets/visualScreenImg/xin.png'),
          imgUrl2:require('../../../assets/visualScreenImg/wushi.png'),
          rotate:'rotate(180deg)'
        },
        {
          name:'手太阳',
          datetime:'13至15点',
          shichen:'未时',
          desc:'小肠经',
          explain:'未时分清浊，饮水能降火；小肠分清浊，把水液归于膀胱，糟粕送入大肠，精华上输于脾。小肠经在未时对人一天的营养进行调整。如小肠有热，人会干咳、排屁。',
          zhengzhuang:'小腹绕脐而痛，心闷，头顶痛；容易腹泻，手脚寒凉；吸收不良，虚肥；肩周炎。',
          means:'拍打通用部位+小肠经+腹部+头面部+用拉筋凳拉筋',
          imgUrl:require('../../../assets/visualScreenImg/xiaochang.png'),
          imgUrl2:require('../../../assets/visualScreenImg/weishi.png'),
          rotate:'rotate(210deg)'
        },
        {
          name:'足太阳',
          datetime:'15点至17点',
          shichen:'申时',
          desc:'膀胱经',
          explain:'申时津液足，养阴身体舒；膀胱贮藏水液和津液，水液排出体外，津液循环在体内。若膀胱有热可致膀胱咳，且咳而遗尿。申时人体温较热，阴虚的人最为突出。',
          zhengzhuang:'恶风怕寒，颈项不舒，腰背肌肉胀痛；腰膝酸软，静脉曲张，尿频尿多；尿黄，前列腺肥大。',
          means:'拍打通用部位+膀胱经+小腹+腰骶+腹股沟+大腿内侧+用拉筋凳拉筋',
          imgUrl:require('../../../assets/visualScreenImg/pangguang.png'),
          imgUrl2:require('../../../assets/visualScreenImg/shenshi.png'),
          rotate:'rotate(240deg)'
        },
        {
          name:'足少阴',
          datetime:'17点至19点',
          shichen:'酉时',
          desc:'肾经',
          explain:'酉时肾藏精，纳华元气清；“肾藏生殖之精和五脏六腑之精。肾为先天之根。”人体经过申时泻火排毒，肾在酉时进入贮藏精华的阶段。此时不适宜太强的运动量，也不适宜大量喝水。',
          zhengzhuang:'手足怕冷，口干舌燥，腰膝酸痛，咽喉炎；月经不调，性欲减退；前列腺肥大，足跟痛，尿频尿少尿黄。',
          means:'拍打通用部位+膀胱经+肾经+小腹+腰骶+腹股沟+大腿内侧+用拉筋凳拉筋',
          imgUrl:require('../../../assets/visualScreenImg/shen.png'),
          imgUrl2:require('../../../assets/visualScreenImg/youshi.png'),
          rotate:'rotate(270deg)'
        },
        {
          name:'手厥阴',
          datetime:'19点至21点',
          shichen:'戌时',
          desc:'心包经',
          explain:'戌时护心脏，减压心舒畅；“心包为心之外膜，附有脉络，气血通行之道。邪不能容，容之心伤。”心包是心的保护组织，又是气血通道。',
          zhengzhuang:'失眠多梦易醒难入睡；心烦健忘胸闷口容干；还有神经衰弱。威胁你生命。',
          means:'拍打通用部位+心经、心包经（整个手臂内侧）+用拉筋凳拉筋',
          imgUrl:require('../../../assets/visualScreenImg/xinbao.png'),
          imgUrl2:require('../../../assets/visualScreenImg/xushi.png'),
          rotate:'rotate(300deg)'
        },
        {
          name:'手少阳',
          datetime:'21点至23点',
          shichen:'亥时',
          desc:'三焦经',
          explain:'亥时百脉通，养身养娇容。三焦是六腑中最大的腑，具有主持诸气，疏通水道的作用。亥时三焦能通百脉。人如果在亥时睡眠，百脉可得到最好的休养生息，对身体对美容十分有益。百岁老人有个共同特点，即在亥时睡觉。',
          zhengzhuang:'偏头痛，头晕耳鸣，上热又下寒；手足怕冷，易怒，皮肤易过敏；肌肉关节酸又痛，全身无力，食欲又不振。',
          means:'拍打通用部位+三焦经（整个手臂外侧）+后背+头面部+用拉筋凳拉筋',
          imgUrl:require('../../../assets/visualScreenImg/sanjiao.png'),
          imgUrl2:require('../../../assets/visualScreenImg/haishi.png'),
          rotate:'rotate(330deg)'
        },
      ]
    }
  },
  mounted() {
  },
  methods:{

  }
}
</script>
<style scoped lang="scss">


@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  /* 初始状态为不旋转 */
  100% {
    transform: rotate(360deg);
  }
  /* 结束状态为完全旋转一周（360度）*/
}
@keyframes rotate_reverse{
  0% {
    transform: rotate(0deg);
  }
  /* 初始状态为不旋转 */
  100% {
    transform: rotate(-360deg);
  }
  /* 结束状态为完全旋转一周（360度）*/
}

.container {
  width: 100%;
  height: 100%;
  position: relative;

  .top {
    width: 640px;
    height: 640px;
    position: relative;
    top: 30px;
    left: calc(50% - 320px);

    .rotate {
      -webkit-user-drag: none;
      position: absolute;
      animation-name: rotate; /* 指定要应用的动画名称 */
      animation-duration: 60s; /* 设置动画持续时间 */
      animation-iteration-count: infinite; /* 无限次重复播放动画 */
      animation-timing-function: linear;
    }
    .rotate-low{
      animation-duration: 120s; /* 设置动画持续时间 */
      animation-name: rotate_reverse; /* 指定要应用的动画名称 */
    }
    .rotate-high{
      animation-duration: 20s; /* 设置动画持续时间 */
      animation-name: rotate_reverse; /* 指定要应用的动画名称 */
    }
    .fixed {
      position: absolute;
      -webkit-user-drag: none;
    }
  .yuanpan{
    z-index: 1;
  }
    .ziwuliu_box{
      position: absolute;
      width: 200px;
      height: 200px;
      left: 50%;
      top: calc(50% - 100PX);
      transform: translate(-50%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 32px;
      line-height: 32px;
      font-weight: 400;
      color: #FFFFFF;
      font-family: KaiTi;
      img{
        width: 100px;
        height: 100px;
      }
    }

  }

  .bottom {
    width: 640px;
    height: 640px;
    position: absolute;
    bottom: -290px;
    left: calc(50% - 320px);
    transform: rotateX(-70deg);

    img {
      width: 640px;
      height: 640px;
      transform-style: preserve-3d;
      animation-name: rotate; /* 指定要应用的动画名称 */
      animation-duration: 60s; /* 设置动画持续时间 */
      animation-iteration-count: infinite; /* 无限次重复播放动画 */
      animation-timing-function: linear;
    }
  }
}
</style>
