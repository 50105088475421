var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ScaleScreen",
    {
      staticClass: "scale-wrap",
      attrs: { width: 1920, height: 1080, selfAdaption: true },
    },
    [
      _c(
        "div",
        { staticClass: "bg" },
        [
          _vm.loading
            ? _c(
                "dv-loading",
                { staticStyle: { height: "100%", width: "100%" } },
                [_vm._v("Loading...")]
              )
            : _c("div", { staticClass: "host-body" }, [
                _c("div", { staticClass: "animation-wrapper" }, [
                  _c("div", { staticClass: "particle particle-1" }),
                  _c("div", { staticClass: "particle particle-2" }),
                  _c("div", { staticClass: "particle particle-3" }),
                  _c("div", { staticClass: "particle particle-4" }),
                ]),
                _c("div", { staticClass: "title_wrap" }, [
                  _c("div", { staticClass: "headline" }, [
                    _c("img", {
                      attrs: {
                        src: require("../../assets/visualScreenImg/title_shan.png"),
                        alt: "",
                      },
                    }),
                    _c("img", {
                      attrs: {
                        src: require("../../assets/visualScreenImg/title_word.png"),
                        alt: "",
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "timers" }, [
                    _c("div", { staticClass: "dateyear" }, [
                      _c("div", { staticClass: "clock_box" }),
                      _c("div", [_vm._v(_vm._s(_vm.dateYear))]),
                    ]),
                    _c("div", { staticClass: "yinli_box" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.YearInGanZhi) +
                          "年 " +
                          _vm._s(_vm.MonthInChinese) +
                          "月 " +
                          _vm._s(_vm.DayInChinese) +
                          " "
                      ),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.jieQi,
                              expression: "jieQi",
                            },
                          ],
                        },
                        [_vm._v("今日" + _vm._s(_vm.jieQi))]
                      ),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.jieQi,
                              expression: "!jieQi",
                            },
                          ],
                        },
                        [_vm._v(_vm._s(_vm.lastJieQi))]
                      ),
                    ]),
                  ]),
                  _c("div", {
                    staticClass: "skip",
                    on: { click: _vm.toQualityControlScreen },
                  }),
                ]),
                _c("div", { staticClass: "content" }, [
                  _c("div", { staticClass: "content-top" }, [
                    _c("div", { staticClass: "contenttop-left" }, [
                      _c("div", { staticClass: "left-top" }, [
                        _c("div", { staticClass: "item" }, [
                          _c("div", { staticClass: "item_title" }, [
                            _vm._v("近7日采集设备采集量"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "com-box" },
                            [_c("leftTop")],
                            1
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "left-mid" }, [
                        _c("div", { staticClass: "item" }, [
                          _c("div", { staticClass: "item_title" }, [
                            _vm._v("疾病确诊量"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "com-box" },
                            [_c("leftCenter")],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "contenttop-mid" }, [
                      _c("div", { staticClass: "centre-top" }, [
                        _c(
                          "div",
                          [
                            _c("CenterTop", {
                              attrs: { shichen: _vm.shichen },
                              on: { shichenData: _vm.shichenData },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "contenttop-right" }, [
                      _c("div", { staticClass: "right-top" }, [
                        _c("div", { staticClass: "item" }, [
                          _c("div", { staticClass: "item_title" }, [
                            _vm._v("实时上传数据"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "com-box" },
                            [_c("rightTop")],
                            1
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "right-mid" }, [
                        _c("div", { staticClass: "item" }, [
                          _c("div", { staticClass: "item_title" }, [
                            _vm._v("坐诊医生"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "com-box" },
                            [_c("rightCenter")],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "content-bot" }, [
                    _c("div", { staticClass: "contentbot-letf" }, [
                      _c("div", { staticClass: "left-bot" }, [
                        _c("div", { staticClass: "item" }, [
                          _c("div", { staticClass: "item_title" }, [
                            _vm._v("流行病防治"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "com-box" },
                            [_c("leftBottom")],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "contentbot-mid" }, [
                      _c("div", { staticClass: "centre-mid" }, [
                        _c(
                          "div",
                          { staticStyle: { height: "100%" } },
                          [
                            _c("CenterBottom", {
                              attrs: {
                                jieqi: _vm.jieQi ? _vm.jieQi : _vm.lastJieQi,
                                shichenObj: _vm.shichenObj,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "contentbot-right" }, [
                      _c("div", { staticClass: "right-bot" }, [
                        _c("div", { staticClass: "item" }, [
                          _c("div", { staticClass: "item_title" }, [
                            _vm._v("待就诊人数TOP5"),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "com-box",
                              staticStyle: { "padding-left": "20px" },
                            },
                            [_c("rightBottom")],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }