var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-wrap" },
    [
      _c("p", { staticClass: "title" }, [_vm._v("中医智能辅助诊断服务系统")]),
      _c(
        "el-form",
        {
          ref: "loginForm",
          staticClass: "login-container",
          attrs: {
            "label-position": "left",
            model: _vm.loginForm,
            rules: _vm.loginRules,
            "label-width": "0px",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "button1" }, [_vm._v("用户名")]),
              ]),
              _c(
                "el-col",
                { attrs: { span: 18 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "username" } },
                    [
                      _c("el-input", {
                        attrs: { type: "text", "auto-complete": "off" },
                        model: {
                          value: _vm.loginForm.username,
                          callback: function ($$v) {
                            _vm.$set(_vm.loginForm, "username", $$v)
                          },
                          expression: "loginForm.username",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "button1" }, [_vm._v("密码")]),
              ]),
              _c(
                "el-col",
                { attrs: { span: 18 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "password" } },
                    [
                      _c("el-input", {
                        attrs: { type: "password", "auto-complete": "off" },
                        model: {
                          value: _vm.loginForm.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.loginForm, "password", $$v)
                          },
                          expression: "loginForm.password",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "button1" }, [_vm._v("验证码")]),
              ]),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _vm.captchaEnabled
                    ? _c(
                        "el-form-item",
                        { attrs: { prop: "code" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                "auto-complete": "off",
                                placeholder: "请输入验证码",
                              },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.handleLogin($event)
                                },
                              },
                              model: {
                                value: _vm.loginForm.code,
                                callback: function ($$v) {
                                  _vm.$set(_vm.loginForm, "code", $$v)
                                },
                                expression: "loginForm.code",
                              },
                            },
                            [
                              _c("svg-icon", {
                                staticClass: "el-input__icon input-icon",
                                attrs: {
                                  slot: "prefix",
                                  "icon-class": "validCode",
                                },
                                slot: "prefix",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("el-col", { attrs: { span: 1 } }, [
                _c("div", { staticClass: "login-code" }, [
                  _c("img", {
                    staticClass: "login-code-img",
                    attrs: { src: _vm.codeUrl },
                    on: { click: _vm.getCode },
                  }),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { "margin-top": "20px" }, attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c(
                  "div",
                  {
                    staticClass: "button2",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.handleLogin($event)
                      },
                    },
                  },
                  [
                    _vm.loading
                      ? _c("i", { staticClass: "el-icon-loading" })
                      : _vm._e(),
                    _vm._v(" 登录 "),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "login-footer" }, [
      _c("div", [_vm._v("中科云诊（河南）医疗科技有限公司")]),
      _c("div", {}, [
        _c(
          "a",
          { attrs: { href: "https://beian.miit.gov.cn/", target: "_blank" } },
          [_vm._v(" 豫ICP备2024065559号 ")]
        ),
        _vm._v("| "),
        _c("img", {
          staticStyle: { width: "18px", height: "18px" },
          attrs: { src: require("@/assets/images/gongan.png"), alt: "" },
        }),
        _c(
          "a",
          {
            attrs: {
              href: "https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=41010702003447",
              target: "_blank",
            },
          },
          [_vm._v(" 豫公网安备41010702003447号 ")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }