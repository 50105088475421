import Base from './Base'

/**
 * 1. 中医智能诊断页面中统计表单完整度
 * 智能辩证请求中表单完整度，身份信息。主诉既往史，zhengzhuangJson选了几个
 * 例如：表单完整度：0.8 身份信息：5 （患者姓名：1 性别：1 年龄：1 出生日期：0 ..）填写了5项包含了哪五项
 *
 */
class Integrity extends Base {

  constructor() {
    super('integrity');
    // 开方集合
    this.kf = null;
    this.id = null;
    this.integrity = 0;
  }

  compute(data) {
    this.kf = data;
    // todo 具体的计算逻辑
    this.integrity = 0.5;
  }

  setId(id){
    this.id = id;
    this.report();
  }

  getData() {
    if (!this.kf) {
      return null;
    }
    return {
      kf: this.kf,
      integrity: this.integrity,
      id: this.id
    }
  }
}

let integrity = new Integrity();
export default integrity
