import JSEncrypt from 'jsencrypt/bin/jsencrypt.min'

// 密钥对生成 http://web.chacuo.net/netrsakeypair

const publicKey = 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAok3JSiStWvcFErsDhsjJ\n' +
  'hSqE+7GTF20T1QCPfDOFjkbM8HmF6+q0yy6x+dOqZlAAWxBiaLVwgFkU3rsWXUJs\n' +
  'vv6vq5y2f1mezJJNk4WSIoKO36UNMNtA0uUBY1NEIXFB4+hpQYHw7eeWg5nwdvNd\n' +
  'MNKLknxH2Cj6NDObUZXknKXr9lzYCdBPQ25EkQpSjcqAi054uvOSdXgYHHa/raxD\n' +
  'O6oCWu3a0E0eTwQKTGRuCqWnRBLz0Yx8SlWB3VYzMTwFX2DWgB8SCivnQwEutZFO\n' +
  'cezEOu0OPtYKfhhKr3R1RWGjM65Ni/HPT1g7QzGMMsY7ht+aM4wdsdg70W57zPVm\n' +
  '5wIDAQAB'

const privateKey = 'MIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCiTclKJK1a9wUS\n' +
  'uwOGyMmFKoT7sZMXbRPVAI98M4WORszweYXr6rTLLrH506pmUABbEGJotXCAWRTe\n' +
  'uxZdQmy+/q+rnLZ/WZ7Mkk2ThZIigo7fpQ0w20DS5QFjU0QhcUHj6GlBgfDt55aD\n' +
  'mfB2810w0ouSfEfYKPo0M5tRleScpev2XNgJ0E9DbkSRClKNyoCLTni685J1eBgc\n' +
  'dr+trEM7qgJa7drQTR5PBApMZG4KpadEEvPRjHxKVYHdVjMxPAVfYNaAHxIKK+dD\n' +
  'AS61kU5x7MQ67Q4+1gp+GEqvdHVFYaMzrk2L8c9PWDtDMYwyxjuG35ozjB2x2DvR\n' +
  'bnvM9WbnAgMBAAECggEAPH+8UXvcdrTvWi0Fwn8jxxSzX1Kw3qGVK+e6lHRsCz3O\n' +
  '9LCHwAnL/JTaWKKSuPKHP2qbFCbV2bHa+q9IRdkVyhgLiW+wA0Z30A+VBTFb6Ks7\n' +
  '8JeIlDaWMkaUvt5x3flmszqVyYpTMYsK6uE95kkO6r0As8jJywRzR3QZKE80WUNT\n' +
  '7ne87tjMyVDyqVx0CUpfJHA9bh+x6n2qhB0nAsO1Y7ie8JLaDvQu4aMJDaztW1It\n' +
  'V1sxVO3LQgCrd+mdOHctR4THOh/GcsKc3mknNo8j0ytXaP7z00tluaI12aEbDB3S\n' +
  'u6mywCpak78LGioKlBGUoDsTz/02vLKDEfe6BpASaQKBgQDRQDZ2/+G13ANQnE8D\n' +
  'RsConwe4EV4P6u6KydiMv5eGS/E0w2TuCMlMImwj00tWFP/1mEHiHulWxijGWRZe\n' +
  '9rm6NjHah/hGmEHpE+SmqtmP0mB8uZzAftNBA3h1ph9wKouwRvxlJ4eIeb9KW/n0\n' +
  'UzYo9782FMSkI7wB7KvqBnQy9QKBgQDGkIL1vBAS+UL8JcjILjAJQFEWK4wu0dfa\n' +
  'iXBcaHuUq5XGMQSAUfR/JfCZycdatd1FAVVCR16OqGGzXjgKnZOZATGYpah4fB7K\n' +
  'sDIiH2vWs5fdCAr5ZjCnqW0AlG95I+w1aTS/o7WVgHTqJE9tDbOh0wEeycU8MGFf\n' +
  'Dw6Ldrwg6wKBgGa0dMctyvQ/G+VqAyIhjRh2jgxbgeagMhsu/ToAI/hHuAlkylpH\n' +
  'N75FuvxOjlCfGA7Mf7Hj6DWuJ39LZL6RrRFn3kpbqJHF07v6BqWDgCumX7N4Syb0\n' +
  'WO41LN/Z/N5KE6we+20O3OgwkaKGyIWSfifXiiOaR/Ykj/gF2U+RgWEtAoGAZiD1\n' +
  '+sZbziZ+mYollzCKbHe0PR7w3zP3PePq8tyak4Eda+7STd3ZT3yIH9F0w+c+g7uH\n' +
  'yqtCq0VX2xXe5TbfL5c5lzW9TRsqCz5Mytg2cxu0RG9alaRF6jZuZhnyq/AaEe6m\n' +
  'vtrJDrgfaj56y7B8cANDVD6bP7Xynx3zcTAE2zkCgYAnp1eAlyjzmTJAibe5DLMA\n' +
  'BULVP//VI3Ac/45wf+kyKENjRIk6MeHF+eXuYxODVa+QCvkvZ06qri/uZPjLrbG2\n' +
  'yMc2v/pMmYR30C28zZesgryXtN7gQrAaFDdw4kkW7gmqRruueEWk02vOKiShyM+S\n' +
  'M2VYsygZtH3Y93GcG9Sk0A=='

// 加密
export function encrypt(txt) {
  const encryptor = new JSEncrypt()
  encryptor.setPublicKey(publicKey) // 设置公钥
  return encryptor.encrypt(txt) // 对数据进行加密
}

// 解密
export function decrypt(txt) {
  const encryptor = new JSEncrypt()
  encryptor.setPrivateKey(privateKey) // 设置私钥
  return encryptor.decrypt(txt) // 对数据进行解密
}

