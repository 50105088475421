var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Popup",
    { ref: "popup", attrs: { "show-title": false, padding: "16px" } },
    [
      _c("div", { staticClass: "popup-wrap" }, [
        _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.form.zy))]),
        _c("div", { staticClass: "body" }, [
          _c("div", { staticClass: "item" }, [
            _c("div", { staticClass: "label" }, [_vm._v("是否主症:")]),
            _c(
              "div",
              { staticClass: "value" },
              _vm._l(_vm.info.main, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "text-item",
                    class: { active: _vm.form.main.indexOf(item) > -1 },
                    on: {
                      click: function ($event) {
                        return _vm.choose("main", item, true)
                      },
                    },
                  },
                  [_vm._v(_vm._s(item) + " ")]
                )
              }),
              0
            ),
          ]),
          _c("div", { staticClass: "item" }, [
            _c("div", { staticClass: "label" }, [_vm._v("发生因素:")]),
            _c(
              "div",
              { staticClass: "value" },
              _vm._l(_vm.info.fashengyinsu, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "text-item",
                    class: { active: _vm.form.fashengyinsu.indexOf(item) > -1 },
                    on: {
                      click: function ($event) {
                        return _vm.choose("fashengyinsu", item)
                      },
                    },
                  },
                  [_vm._v(_vm._s(item) + " ")]
                )
              }),
              0
            ),
          ]),
          _c("div", { staticClass: "item" }, [
            _c("div", { staticClass: "label" }, [_vm._v("症状特征:")]),
            _c(
              "div",
              { staticClass: "value" },
              _vm._l(_vm.info.tezheng, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "text-item",
                    class: { active: _vm.form.tezheng.indexOf(item) > -1 },
                    on: {
                      click: function ($event) {
                        return _vm.choose("tezheng", item)
                      },
                    },
                  },
                  [_vm._v(_vm._s(item) + " ")]
                )
              }),
              0
            ),
          ]),
          _c("div", { staticClass: "item" }, [
            _c("div", { staticClass: "label" }, [_vm._v("轻重程度:")]),
            _c(
              "div",
              { staticClass: "value" },
              _vm._l(_vm.info.chengdu, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "text-item",
                    class: { active: _vm.form.chengdu.indexOf(item) > -1 },
                    on: {
                      click: function ($event) {
                        return _vm.choose("chengdu", item)
                      },
                    },
                  },
                  [_vm._v(_vm._s(item) + " ")]
                )
              }),
              0
            ),
          ]),
          _c("div", { staticClass: "item" }, [
            _c("div", { staticClass: "label" }, [_vm._v("发作频次:")]),
            _c(
              "div",
              { staticClass: "value" },
              _vm._l(_vm.info.fazuopinci, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "text-item",
                    class: { active: _vm.form.fazuopinci.indexOf(item) > -1 },
                    on: {
                      click: function ($event) {
                        return _vm.choose("fazuopinci", item)
                      },
                    },
                  },
                  [_vm._v(_vm._s(item) + " ")]
                )
              }),
              0
            ),
          ]),
          _c("div", { staticClass: "item" }, [
            _c("div", { staticClass: "label" }, [_vm._v("病程时长:")]),
            _c(
              "div",
              { staticClass: "value" },
              _vm._l(_vm.info.bingcheng, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "text-item",
                    class: { active: _vm.form.bingcheng.indexOf(item) > -1 },
                    on: {
                      click: function ($event) {
                        return _vm.choose("bingcheng", item)
                      },
                    },
                  },
                  [_vm._v(_vm._s(item) + " ")]
                )
              }),
              0
            ),
          ]),
          _c("div", { staticClass: "item" }, [
            _c("div", { staticClass: "label" }, [_vm._v("加重因素:")]),
            _c(
              "div",
              { staticClass: "value" },
              _vm._l(_vm.info.jiazhong, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "text-item",
                    class: { active: _vm.form.jiazhong.indexOf(item) > -1 },
                    on: {
                      click: function ($event) {
                        return _vm.choose("jiazhong", item)
                      },
                    },
                  },
                  [_vm._v(_vm._s(item) + " ")]
                )
              }),
              0
            ),
          ]),
          _c("div", { staticClass: "item" }, [
            _c("div", { staticClass: "label" }, [_vm._v("缓解因素:")]),
            _c(
              "div",
              { staticClass: "value" },
              _vm._l(_vm.info.huanjie, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "text-item",
                    class: { active: _vm.form.huanjie.indexOf(item) > -1 },
                    on: {
                      click: function ($event) {
                        return _vm.choose("huanjie", item)
                      },
                    },
                  },
                  [_vm._v(_vm._s(item) + " ")]
                )
              }),
              0
            ),
          ]),
          _c("div", { staticClass: "item" }, [
            _c("div", { staticClass: "label" }, [_vm._v("持续时间:")]),
            _c(
              "div",
              { staticClass: "value" },
              _vm._l(_vm.info.chixushijian, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "text-item",
                    class: { active: _vm.form.chixushijian.indexOf(item) > -1 },
                    on: {
                      click: function ($event) {
                        return _vm.choose("chixushijian", item)
                      },
                    },
                  },
                  [_vm._v(_vm._s(item) + " ")]
                )
              }),
              0
            ),
          ]),
        ]),
        _c("div", { staticClass: "btn", on: { click: _vm.confirm } }, [
          _vm._v("确认"),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }