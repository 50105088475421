var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Echart", {
    staticStyle: { width: "240px", height: "200px", margin: "0 auto" },
    attrs: { options: _vm.options, id: "leftTops" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }