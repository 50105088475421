<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item label="处方编码" prop="billno">
        <el-input
          v-model="queryParams.billno"
          placeholder="请输入处方编码"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="医院名称" prop="hospital">
        <el-input
          v-model="queryParams.hospital"
          placeholder="请输入医院名称"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="开方科室" prop="depName">
        <el-input
          v-model="queryParams.depName"
          placeholder="请输入科室名称"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="诊断医生" prop="docName">
        <el-input
          v-model="queryParams.docName"
          placeholder="请输入医生名称"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="showDetails=true">打开弹窗</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
      </el-col>
      <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
    </el-row>

    <el-table v-loading="loading" :data="qualityControlList" @selection-change="handleSelectionChange">
      <el-table-column
        type="selection"
        width="55"
      >
      </el-table-column>
      <el-table-column label="医院名称" width="100" align="center" prop="hospital" fixed>
        <template #default="scope">
          {{ scope.row.hospital || '-' }}
        </template>
      </el-table-column>
      <el-table-column label="科室" width="100" align="center" prop="depName" fixed>
        <template #default="scope">
          {{ scope.row.depName || '-' }}
        </template>
      </el-table-column>
      <el-table-column label="诊断医生" width="100" align="center" prop="docName">
        <template #default="scope">
          {{ scope.row.docName || '-' }}
        </template>
      </el-table-column>
      <el-table-column label="患者姓名" width="100" align="center" prop="name">
        <template #default="scope">
          {{ scope.row.name || '-' }}
        </template>
      </el-table-column>
      <el-table-column label="病症" width="100" align="center" prop="cardinalSymptom">
        <template #default="scope">
          {{ scope.row.cardinalSymptom || '-' }}
        </template>
      </el-table-column>
      <el-table-column label="上传时间" width="150" align="center" prop="createTime">
        <template #default="scope">
          {{ scope.row.createTime || '-' }}
        </template>
      </el-table-column>
      <el-table-column label="预警类型" width="auto" align="center" prop="qualityControl">
        <template #default="scope">
          <div>
            <el-tag v-for="(item,index) in qualityControlNew(scope.row.qualityControl)" :key="index" size="small"
                    :type="tagColor(item)"
            >
              {{ item || '-' }}
            </el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="预警药材" width="auto" align="center" prop="qualityControl">
        <template #default="scope">
          <div>
            <el-tag v-for="(item,index) in medicine(scope.row.qualityControl)" :key="index" size="small"
                    :type="tagColor(qualityControlNew(scope.row.qualityControl)[index])"
            >
              {{ item || '-' }}
            </el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="120" align="center" prop="jzTodayCount">
        <template #default="scope">
          <el-button type="text" @click.stop="openDetails(scope.row)">点击查看</el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />

    <el-dialog :visible.sync="showDetails"  modal-append-to-body="false" :close-on-click-modal="false">
      <el-form ref="rows" :model="rows" label-width="80px" disabled>
        <el-form-item label="病历号" prop="userId">
          <el-input v-model="rows.billno" placeholder=""/>
        </el-form-item>
        <el-form-item label="医院名称" prop="hospital">
          <el-input v-model="rows.hospital" placeholder=""/>
        </el-form-item>
        <el-form-item label="科室" prop="depName">
          <el-input v-model="rows.depName" placeholder=""/>
        </el-form-item>
        <el-form-item label="诊断医生" prop="docName">
          <el-input v-model="rows.docName" placeholder=""/>
        </el-form-item>
        <el-form-item label="患者姓名" prop="name">
          <el-input v-model="rows.name" placeholder=""/>
        </el-form-item>
        <el-form-item label="病症" prop="cardinalSymptom">
          <el-input v-model="rows.cardinalSymptom" placeholder=""/>
        </el-form-item>
        <el-form-item label="上传时间" prop="createTime">
          <el-input v-model="rows.createTime" placeholder=""/>
        </el-form-item>
        <el-form-item label="开方详情" prop="prescriptionDetail">
          <el-input v-model="rows.prescriptionDetail" type="textarea" placeholder=""/>
        </el-form-item>
        <el-form-item label="预警类型" prop="qualityControl">
          <el-input v-model="rows.qualityControl" type="textarea" placeholder=""/>
        </el-form-item>
        <el-form-item label="预警药材" prop="qualityControl">
          <el-input v-model="rows.qualityControl" type="textarea" placeholder=""/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="qualityPass(rows.billno)">通过</el-button>
        <el-button @click="qualityReject()">驳回</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  addTrackingStatics,
  delTrackingStatics,
  getTrackingStatics,
  updateTrackingStatics
} from '@/api/tracking/trackingStatics'
import { getzhikongList, getzhikongPass, getzhikongSelect } from '@/api/qualityControl'
import log from '@/views/monitor/job/log.vue'

export default {
  name: 'qualityControl',
  data() {
    return {
      rows: {},
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 统计表格数据
      qualityControlList: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        depName: null,
        docName: null,
        billno: null,
        hospital: null
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        userId: [
          { required: true, message: '用户id不能为空', trigger: 'blur' }
        ]
      },
      showDetails: false,
      clickCountMap: {}
    }
  },
  created() {
    this.getList()
  },
  computed: {
    qualityControlNew() {
      return function(data) {
        if (data && data !== '分析结果集') {
          return JSON.parse(data)?.pop()?.map(item => {
            return item.t
          })
        } else if (data === '分析结果集') {
          return []
        } else {
          return []
        }

      }
    },
    medicine() {
      return function(data) {
        if (data && data !== '分析结果集') {
          return JSON.parse(data).pop().map(item => {
            return item.v.join('-') || item.v[0].join('-')
          })
        } else if (data === '分析结果集') {
          return []
        } else {
          return []

        }

      }
    },
    tagColor() {
      return function(name) {
        switch (name) {
          case '孕妇禁用':
            return 'info'
            break
          case '孕妇慎用':
            return 'warning'
            break
          case '相反':
            return 'danger'
            break
        }
      }
    }
  },
  methods: {
    /** 查询统计列表 */
    getList() {
      this.loading = true
      console.log(this.queryParams)
      getzhikongSelect(this.queryParams).then(response => {
        this.qualityControlList = response.rows || []
        this.total = response.total
        this.loading = false
      }).catch(error => {
        this.qualityControlList = []
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm('queryForm')
      this.handleQuery()
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    qualityPass(id) {
      getzhikongPass(id).then(res => {
        if (res.code === 200) {
          this.$modal.msgSuccess('修改成功')
          this.showDetails = false
        }
      })
    },
    qualityReject() {
      this.showDetails = false
    },

    openDetails(rows) {
      this.rows = rows
      this.showDetails = true
    }
  }
}
</script>

<style lang="scss" scoped>
table {
  border-left: 1px solid #e6a23c;
  border-top: 1px solid #e6a23c;
  width: 100%;
}

table tr td {
  padding: 0;
}

td {
  height: 30px;
  line-height: 30px;
  border-right: 1px solid #e6a23c;
  border-bottom: 1px solid #e6a23c;
  text-align: center;
  width: 50%;
}

</style>
