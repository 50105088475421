import request from "@/utils/request";

export function getBook(data) {
  return request({
    url: `/library/book/api/pdf?bookId=${data}`,
    method: "get",
  });
}
//获取文库列表
export function getBookList(query) {
  return request({
    url: `library/book/allList`,
    method: "get",
    params: query,
  });
}
