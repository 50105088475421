<template>
  <div class="popup-wrap">
    <div class="body">
      <!-- <div v-for="(item, index) in list" :key="index">
          <el-row class="row">
            <el-col :span="12" class="col">
              <div class="item-title" @click="fy(item)">复用</div>
            </el-col>
            <el-col :span="12" class="col">
              <div class="item-title">医方名</div>
              <div class="item-value red">
                {{ item.yfm || "-" }}
              </div>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="12" class="col">
              <div class="item-title">item,zz</div>
              <div class="item-value red">
                {{ item.zz || "-" }}
              </div>
            </el-col>
            <el-col :span="12" class="col">
              <div class="item-title">其他</div>
              <div class="item-value red">
                {{ item.yfs || "-" }}
              </div>
            </el-col>
          </el-row>
        </div> -->

      <div class="item" v-for="(item, index) in list" :key="index">
        <div class="label" @click="fy(item)">复用</div>
        <div class="value" v-if="type == 3">
          <span>【{{ item.yfm }}】</span>{{ item.zz }}(其他:{{ item.yfs }})
        </div>
        <div class="value" v-else>
          <span>【{{ item.zz }}】</span><span>【{{ item.yfm }}】</span
          >{{ item.yfs }}——来源<span>{{ item.laiyuan || "-" }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "popup-zhenduan",
  props: {},
  data() {
    return {
      title: null,
      list: [],
      type: 3,
      func: null,
    };
  },
  mounted() {},
  methods: {
    open(title, list, type, func) {
      this.title = title;
      this.list = list;
      this.type = type;
      this.func = func;
    },
    choose(index) {
      this.curIndex = index;
      this.diseaseList = this.diseaseForm[index] || [];
    },
    fy(item) {
      if (!item) {
        return;
      }
      if (this.func) {
        this.func(item.yf, this.type);
      } else {
        this.$parent.$parent.std(item.yf, this.type, item.yfm);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.popup-wrap {
  height: 50vh;
  display: flex;
  flex-direction: column;
  font-family: cursive;
}

.title {
  background-color: #c48d6e;
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: left;
  color: white;
  font-size: 20px;
  font-weight: 700;
  text-indent: 20px;
}

.btn {
  background-color: #ae7000;
  border-radius: 20px;
  background-size: 100% 100%;
  height: 30px;
  width: 100px;
  padding: 0 10px;
  line-height: 30px;
  text-align: center;
  color: white;
  font-weight: 500;
  cursor: pointer;
  margin: 0 auto;
}

.body {
  border: 1px solid var(--main-border-color);
  border-radius: 8px;
  margin: 10px 10px;
  flex: 1;
  overflow-y: scroll;

  .item {
    display: flex;
    flex-direction: row;
    padding: 2px;
    align-items: flex-start;
    border-bottom: 1px dashed var(--main-border-color);
    margin-top: 8px;
    padding-bottom: 10px;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      border-bottom: 0;
    }

    .label {
      font-size: 16px;
      font-weight: bold;
      margin-right: 4px;
      background: var(--main-color);
      color: white;
      padding: 2px 4px;
      border-radius: 4px;
      cursor: pointer;
    }

    .value {
      flex: 1;
      font-size: 16px;

      span {
        background: rgb(230, 206, 191);
        margin-right: 2px;
      }
    }
  }
}

.cate-item {
  font-size: 16px;
  padding: 2px 4px;
  margin-right: 4px;
  border-radius: 4px;
  margin-top: 8px;
  border: 1px solid var(--main-color);
  color: black;
  cursor: pointer;

  &.active {
    background: var(--main-color);
    color: white;
  }
}

.cate-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  //border-bottom: 1px solid var(--main-border-color);
}

.row {
  height: auto;
  border: 1px solid #ebeef5;
  border-top: none;
  display: flex;
  flex-direction: row;

  &:nth-child(1) {
    border-top: 1px solid #ebeef5;
  }

  .col {
    height: auto;
    line-height: 30px;
    display: flex;

    .item-title {
      height: auto;

      padding-left: 20px;
      min-width: 85px;
      font-size: 14px;
      color: #606266;
    }

    .item-value {
      height: auto;
      padding-left: 20px;
      flex: 1;
      // border-left: 1px solid #e6e9f0;
      border-left: 1px solid #ebeef5;
      border-right: 1px solid #ebeef5;
      // color: #3D4966;
      color: #303133;
      font-size: 14px;
      //默认显示
      // display: -webkit-box;
      // -webkit-box-orient: vertical;
      // -webkit-line-clamp: 3;
      // overflow: hidden;
      // text-overflow: ellipsis;
    }

    .red {
      color: #9e6600;
      font-weight: 700;
    }

    .bold {
      font-weight: 700;
    }

    .flex {
      display: flex;
      padding-left: 20px;
      flex-direction: row;
    }

    &:nth-last-child(1) {
      border-right: none;
    }
  }
}
</style>
