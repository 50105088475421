<template>
  <div class="title">
    <div class="left"></div>
    <div class="right">{{ title }}</div>
  </div>
</template>

<script>
export default {
  name:'titleVue',
  props: {
    title: {
      type: String,
      required: false,
      default:''
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.title {
  font-weight: 500;
  font-size: 24px;
  color: #0166e2;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;
  .left {
    background: #0166e2;
    height: 24px;
    width: 4px;
  }
}
</style>
