var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sizhen-container" }, [
    _c(
      "div",
      { staticClass: "bg" },
      [
        _vm.loading
          ? _c(
              "dv-loading",
              { staticStyle: { height: "100%", width: "100%" } },
              [_vm._v("Loading...")]
            )
          : _c("div", { staticClass: "host-body" }, [
              _c("div", { staticClass: "title_wrap" }, [
                _c("div", { staticClass: "headline" }, [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/sizhen/title-word.png"),
                      alt: "",
                    },
                  }),
                ]),
                _c("div", { staticClass: "timers" }, [
                  _c("div", { staticClass: "dateyear" }, [
                    _c("i", { staticClass: "el-icon-time" }),
                    _c("div", { staticClass: "time" }, [
                      _vm._v(_vm._s(_vm.dateDay)),
                    ]),
                    _c("div", [_vm._v(_vm._s(_vm.dateYear))]),
                  ]),
                ]),
                _c("div", { staticClass: "skip" }, [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/logo/logo.png"),
                      alt: "",
                    },
                  }),
                  _vm._v("中医辅助智能采集系统 "),
                ]),
              ]),
              _c("div", { staticClass: "content" }, [
                _c("div", { staticClass: "menu" }, [
                  _c(
                    "div",
                    {
                      staticClass: "menu-item",
                      on: {
                        click: function ($event) {
                          return _vm.gotoStep(0)
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "item-img",
                        attrs: {
                          src: require("../../assets/sizhen/huanzhetianjia.png"),
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "menu-item",
                      on: {
                        click: function ($event) {
                          return _vm.gotoStep(1)
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "item-img",
                        attrs: {
                          src: require("../../assets/sizhen/tizhibianshi.png"),
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "menu-item",
                      on: {
                        click: function ($event) {
                          return _vm.gotoStep(2)
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "item-img",
                        attrs: {
                          src: require("../../assets/sizhen/shexiangzhenduan.png"),
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "menu-item",
                      on: {
                        click: function ($event) {
                          return _vm.gotoStep(3)
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "item-img",
                        attrs: {
                          src: require("../../assets/sizhen/aizhenduan.png"),
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "menu-item",
                      on: {
                        click: function ($event) {
                          return _vm.gotoStep(4)
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "item-img",
                        attrs: {
                          src: require("../../assets/sizhen/bianzhengbaogao.png"),
                        },
                      }),
                    ]
                  ),
                ]),
              ]),
            ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }