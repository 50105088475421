import request from "@/utils/request";

export function getTiZhiResult(data) {
  return request({
    url: `/aitcm/pattern/apipublic/tizhi/getTiZhiResult?v=${data}`,
    method: "get",
  });
}

export function getTiZhiGuide(data, gender) {
  return request({
    url: `/aitcm/pattern/apipublic/tizhi/getTiZhiGuide?v=${data}&g=${gender}`,
    method: "get",
  });
}

//获取体质辨识结果
export function getTiZhi(data) {
  return request({
    url: `/tcmrobot/patinfo/sz_tizhi_calc`,
    method: "post",
    params: data,
  });
}
