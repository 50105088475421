var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "item_title" }, [
    _c("img", {
      attrs: {
        src: require("../../../assets/qualityConyrolScreen/item_title_icon.png"),
        alt: "",
      },
    }),
    _c("span", [_vm._v(_vm._s(_vm.title))]),
    _c("div", { staticClass: "divider" }),
    _c("img", {
      attrs: {
        src: require("../../../assets/qualityConyrolScreen/item_title_jiantou.png"),
        alt: "",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }